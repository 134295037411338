import {
  patchParking,
  getParking,
  getCommodities,
  patchCommodities,
  getMedias,
  uploadMedia,
  deleteMedia,
  saveMedias,
  getRulesets,
  getCertificates
} from '@/calls/parkings';

import i18n from '../i18n';

const parkings = {
  namespaced: true,
  state: {
    id: null,
    data: null,
    form: {
      startAtString: '',
      endAtString: '',
      localName: '',
      internationalName: '',
      street: '',
      zipCode: '',
      city: '',
      state: '',
      country: '',
      roadName: '',
      roadDirection: '',
      contactPhone: '',
      rulesetId: 1,
      latitude: 0,
      longitude: 0,
      bookable: false,
      status: false,
      payAtParking: false,
      securityLevelId: 0,
      guichetAvailable: false,
      guichetOpeningHours: [],
      parkingDescriptions: [],
      parkManagerType: null,
      isLimitHour: false,
      standardApiAuthToken: null,
      parkingFlow: 1,
      certificateFiles: []
    },
    currentLang: 'en',
    requests: 0,
    commodities: [],
    medias: [],
    mediasToDelete: [],
    rulesets: [],
    parkingFlows: [
      { value: 'Standard', label: "No additional integration" },
      { value: 'StandardApi', label: "Standard API integration" },
      { value: 'Triton', label: "Triton Integration" }
    ]
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateData(state, data) {
      state.data = data;
    },
    updateRulesets(state, rulesets) {
      state.rulesets = rulesets;
    },
    updateLang(state, lang) {
      state.currentLang = lang;
    },
    updateDataValue(state, { value, property }) {
      if (property.match(/street|zipCode|city|state/)) {
        state.data.address[property] = value;
        return;
      }

      state.data[property] = value;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },
    // Informations
    updateFormValue(state, { value, property }) {     
      state.form[property] = value;
    },
    addDescription(state, lang) {
      state.form.parkingDescriptions.push({
        description: '',
        lang
      });
    },
    updateDescription(state, value) {
      state.form.parkingDescriptions = state.form.parkingDescriptions.map(d => {
        if (d.lang === state.currentLang) {
          d.description = value;
        }

        return d;
      });
    },
    updateDayOpen(state, { day, value }) {
      state.form.guichetOpeningHours = state.form.guichetOpeningHours.map(d => {
        if(d.day === day) d.isOpen = value;
        return d;
      });
    },
    updateDayOpen24(state, { day, value }) {
      state.form.guichetOpeningHours = state.form.guichetOpeningHours.map(d => {
        if(d.day === day) d.isDay24 = value;
        return d;
      });
    },
    updateOpeningHour(state, { value, day, type }) {
      state.form.guichetOpeningHours = state.form.guichetOpeningHours.map(d => {
        if(d.day === day) d[`${type}At`] = value;
        return d;
      });
    },
    // Commodities
    updateCommodities(state, commodities) {
      state.commodities = commodities;
    },
    updateCommodity(state, { groupId, commodityId }) {
      const commodityGroup = state.commodities.filter(c => c.id === groupId)[0];

      for (let i = 0; i < commodityGroup.commodities.length; i++) {
        const commodity = commodityGroup.commodities[i];
        if (commodity.id === commodityId) {
          commodity.isActive = !commodity.isActive;
          break;
        }
      }
    },
    updateCommodityScore(state, { groupId, score }) {
      const commodityGroup = state.commodities.filter(c => c.id === groupId)[0];
      commodityGroup.score = score;
    },
    //  Medias
    removeMedia(state, index) {
      state.medias.splice(index, 1);
    },
    addMedia(state, file) {
      state.medias.push({
        id: state.medias.length + 1,
        url: null,
        file: file ? file : null,
        img: null
      });
    },
    updateMediasOrder(state, { index, current }) {
      state.medias.splice(index, 0, state.medias.splice(current, 1)[0]);
    },
    updateMediaFile(state, { index, file }) {
      state.medias[index].file = file;
    },
    updateMediaImage(state, { index, img }) {
      state.medias[index].img = img;
    },
    updateMedias(state, medias) {
      state.medias = medias;
    },
    updateMediaToDelete(state, medias) {
      state.mediasToDelete = medias;
    },
    addMediaToDelete(state, media) {
      state.mediasToDelete.push(media);
    },
    updateCertificateData(state, certificates) {
      state.form.certificateFiles = certificates;
    },
    clearCertificateData(state) {
      state.form.certificateFiles = [];
    },
  },
  actions: {
    setFormFromData({ rootState, state, commit }) {
      const properties = [
        'startAtString',
        'endAtString',
        'localName',
        'internationalName',
        'street',
        'zipCode',
        'city',
        'state',
        'country',
        'roadName',
        'roadDirection',
        'contactPhone',
        'latitude',
        'longitude',
        'bookable',
        'status',
        'description',
        'payAtParking',
        'securityLevelId',
        'guichetAvailable',
        'guichetOpeningHours',
        'parkManagerType',
        'standardApiAuthToken',
        'whitelistable',
        'subscribable',
        'rulesetId',
        'isLimitHour',
        'parkingFlow'
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        switch (true) {
          case /description/.test(property):
            const descriptions = [];

            state.data.translations.map(t => {
              descriptions.push({
                description: t.description,
                lang: t.language
              })
            });

            if (!descriptions.length) {
              descriptions.push({
                description: null,
                lang: 'en'
              });
            }

            commit('updateFormValue', { property: 'parkingDescriptions', value: descriptions });
            break;
          case /country/.test(property):
            commit('updateFormValue', { property, value: state.data.address?.country?.code ? state.data.address.country.code.toLowerCase() : null});
            break;
          case /status/.test(property):
              commit('updateFormValue', { property, value: state.data.status.toLowerCase() === 'published' });
              break;
          case /street|zipCode|city|state/.test(property):
            commit('updateFormValue', { property, value: state.data.address[property]});
            break;
          case /parkManagerType/.test(property):
            commit('updateFormValue', { property, value: state.data.parkManagerType ? state.data.parkManagerType : null});
            break;
        
          case /standardApiAuthToken/.test(property):
            commit('updateFormValue', { property, value: state.data.standardApiAuthToken ? state.data.standardApiAuthToken : null});
            break;
        
          default:
            commit('updateFormValue', {
              property, value: state.data[property]
            });
            break;

        }
      }
    },
    // Calls
    async saveParking({ commit, dispatch }) {
      commit('increaseRequests');
      const data = await dispatch('saveData');
      const medias = await dispatch('saveMedias');
      const commodities = await dispatch('saveCommodities');
      const { t } = i18n.global;

      if (data.hasError || medias.hasError || commodities.hasError) {
        if (data.hasError) {
          dispatch('notifications/addNotifs', [{
            text: `Error while saving data : ${data.error}`,
            type: 'error'
          }], { root: true });
        }

        if (medias.hasError) {
          dispatch('notifications/addNotifs', [{
            text: `Error while saving pictures : ${medias.error.message ? medias.error.message : medias.error}`,
            type: 'error'
          }], { root: true });
        }

        if (commodities.hasError) {
          dispatch('notifications/addNotifs', [{
            text: `Error while saving features : ${commodities.error.message ? commodities.error.message : commodities.error}`,
            type: 'error'
          }], { root: true });
        }
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.parking.save'),
          // text: 'Parking saved !',
          type: 'success'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    // Data
    async getData({ commit, dispatch, state }) {
      commit('increaseRequests');
      const request = await getParking(state.id);

      if(!request.hasError) {
        if (!request.translation) {
          request.translation = { content: '', description: '', language: "en" };
        }

        commit('updateData', request);
        dispatch('setFormFromData');
      }

      commit('decreaseRequests');
    },
    async saveData({ commit, state, dispatch }) {
      const data = {...state.form};
      data.visible = data.status;
      delete data.status;
      delete data.rulesetId;
      if (!data.securityLevelId && data.securityLevelId !== 0) delete data.securityLevelId;


      if (!data.parkManagerType) {
        if (state.data.parkManagerType) {
          data.parkManagerType = '';
        }
      }

      if (!data.standardApiAuthToken) {
        if (state.data.standardApiAuthToken) {
          data.standardApiAuthToken = '';
        }
      }
      
      const request = await patchParking(state.id, data);

      if (data.parkingFlow != 'Triton') {
        commit('clearCertificateData');
      }
      
      return request;
    },
    // 
    async getRulesets({ commit, state }) {
      commit('increaseRequests');

      const request = await getRulesets();

      console.log('request ruleset :', request);
      if(!request.hasError) {
        commit('updateRulesets', request);
      }

      commit('decreaseRequests');
    },
    // Commodities
    async getCommodities({ commit, state }) {
      commit('increaseRequests');

      const commodities = await getCommodities(state.id);
      commit('updateCommodities', commodities);

      commit('decreaseRequests');
      return true;
    },
    async saveCommodities({ commit, state, dispatch }) {
      const commodityTypes = {};
      const commodities = {};
      const stateCommodities = state.commodities;

      stateCommodities.forEach(type => {
        commodityTypes[type.id] = type.score;
        type.commodities.forEach(com => {
          commodities[com.id] = com.isActive;
        });
      });

      const request = await patchCommodities(state.id, { commodityTypes, commodities });
      return request;
    },
    // Medias
    async getMedias({ commit, dispatch, state }) {
      commit('increaseRequests');
      let medias = await getMedias(state.id);

      if (medias.hasError) {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting medias : ${medias.error.message ? medias.error.message : medias.error}`,
          type: 'error'
        }], { root: true });

        medias = [];
      }

      const diff = medias.length >= 5 ? 1 : 5 -  medias.length;

      for (let i = 0; i < diff; i++) {
        medias.push({ id: i, src: null, file: null, img: null });
      }

      commit('updateMedias', medias);
      commit('decreaseRequests');
    },
    async saveMedias({ dispatch, state }) {
      // Delete medias
      for (let i = 0; i < state.mediasToDelete.length; i++) {
        const del = await dispatch('deleteMedia', { fileId: state.mediasToDelete[i].id });
      }

      // Upload medias
      let files = [...state.medias.filter(item => item.file || item.url)];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.file) {
          const media = await dispatch('uploadMedia', { file });
          if (!media.hasError && media !== 'error') {
            file.id = media;
          }
        }
      }

      // Save order
      const ids = files.map(file => file.id);
      const medias = await saveMedias(state.id, ids);

      return medias;
    },
    async uploadMedia({ dispatch, state }, { file }) {
      const media = await uploadMedia(state.id, file);

      if (media.hasError) {
        dispatch('notifications/addNotifs', [{
          text: `Error while uploading picture : ${media.error.message ? media.error.message : media.error}`,
          type: 'error'
        }], { root: true });
      }

      return media;
    },
    async deleteMedia({ dispatch, state }, { fileId }) {
      const del = await deleteMedia(state.id, fileId);

      if (del.hasError) {
        dispatch('notifications/addNotifs', [{
          text: `Error while deleting picture : ${del.error.message ? del.error.message : del.error}`,
          type: 'error'
        }], { root: true });
      }

      return del;
    },
    async getCertificates({ commit, dispatch, state }) {
      commit('increaseRequests');

      const request = await getCertificates(state.id);

      if(!request.hasError) {
        commit('updateCertificateData', request);
      } else {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting certificates : ${request.error.message ? request.error.message : request.error}`,
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
  }
};

export default parkings;
