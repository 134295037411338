<template>
  <div class="tabs">
    <button 
      v-for="button in buttons"
      :key="button.id"
      :id="button.id"
      :class="button.id === active ? 'tab--active' : '' "
      class="tab"
      type="button"
      @click="handleClick"
    >
      {{button.label}}
      <span class="number">{{values[button.id]}}</span>
    </button>
  </div>
</template>

<script>

export default {
  name: 'Status',
  // props: {
  //   checkin: {
  //     type: Number,
  //     default: 0
  //   },
  //   checkout: {
  //     type: Number,
  //     default: 0
  //   },
  //   parked: {
  //     type: Number,
  //     default: 0
  //   },
  // },
  data() {
    return {
      active: 'checkin',
      values: {
        checkin: 0,
        checkout: 0,
        parked: 0,
      },
      buttons: [
        {
          label: 'To check in',
          id: 'checkin'
        },
        {
          label: 'To check out',
          id: 'checkout'
        },
        {
          label: 'Parked in',
          id: 'parked'
        },
      ]
    };
  },
  computed: {
    checkin() {
      return this.$store.state.bookings.booked.length
    },
    checkout() {
      return this.$store.state.bookings.checkout.length
    },
    parked() {
      return this.$store.state.bookings.parked.length
    },
  },
  watch: {
    checkin(value) {
      this.values.checkin = value;
    },
    checkout(value) {
      this.values.checkout = value;
    },
    parked(value) {
      this.values.parked = value;
    },
  },
  mounted() {
    // this.$emit('clicked', this.active);
    this.$store.commit('updateCurrentTab', this.active)
    this.buttons.forEach( b => {
      this.values[b.id] = this[b.id];
    })
  },
  methods: {
    handleClick(evt) {
      const { id } = evt.target;
      this.active = id;
      // this.$emit('clicked', this.active);
      this.$store.commit('updateCurrentTab', this.active)
    }
  }
}
</script>
