import axios from 'axios';
import i18n from '../i18n';
import router from '../router';
import { Auth } from 'aws-amplify';
// import { CognitoUserPool, CookieStorage, CognitoUserAttribute, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import {  checkEmailVerified } from '@/calls/user';
const authentication = {
  state: {
    authorization: 'otra-admin',
    authState: null,
    tokens: {
      accessToken: null,
      refreshToken: null,
    },
    login: {
      userPool: null,
      username: null,
      errors: {
        global: null,
        link: null,
        email: null,
        password: null
      }
    },
    lostPassword: {
      success: false,
      errors: {
        global: null,
        email: null
      }
    },
    resetPassword: {
      success: {
        moreThan: '',
        uppercase: '',
        num: ''
      },
      errors: {
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
        global: '',
        moreThan: '',
        uppercase: '',
        num: '',
      }
    },
    confirmChange: {
      visible: false,
      success: false,
      requests: 0,
      errors: false
    },
    verifyEmail: {
      visible: false,
      success: false,
      requests: 0,
      code: null,
      errors: {
        code: ''
      }
    }
  },
  mutations: {
    updateAuthState(state, authState) {
      state.authState = authState
    },
    updateTokens(state, tokens) {
      state.tokens = {
        ...tokens
      };
      axios.defaults.headers.common = {
        "X-Api-Key": process.env.VUE_APP_API_KEY,
        Authorization: `Bearer ${tokens.accessToken.jwtToken}`
      };
      state.authState = 'signedin'
    },
    updatePopupProperty(state, { popup, property, value }) {
      state[popup][property] = value;
    },
    updateFormProperty(state, { form, property, value }) {
      state[form][property] = value;
    },
    updateErrors(state, { form, property, error }) {
      state[form].errors[property] = error;
    },
    updateSuccess(state, { form, property, success }) {
      state[form].success[property] = success;
    }
  },
  actions: {
    authCheck({ state, commit, dispatch }) {
      const User = Auth.currentSession();
      User.then(promise => {
        if(promise) {
          if(promise.accessToken.payload['cognito:groups'].indexOf(state.authorization) === -1) return;
          commit('updateTokens', promise);
          // Getting the context
          dispatch('settings/setContext', null, { root: true });
          return Auth.currentUserInfo();
        } else {
          commit('updateAuthState', null);
          router.push('/')
        }
      })
      .then(promise => {
        if(promise) {
          // dispatch('user/setUser', promise.attributes, { root: true });
          dispatch('user/setProfile', null, { root: true });
        }
      })
      .catch(err => {
        if(err) console.log(err.message);
      });
    },
    signin({ state, commit, dispatch }, { email, password }) {
      const { t } = i18n.global;
      const requestAuthentification = Auth.signIn(email, password)
      requestAuthentification.then(promise => {
        if(promise.challengeName === "NEW_PASSWORD_REQUIRED") {
          const email = promise?.challengeParam?.userAttributes?.email;
          commit('updateErrors', {
            form: 'login',
            property: 'global',
            error: t('errors.authentication.global.text'),
          });

          commit('updateErrors', {
            form: 'login',
            property: 'link',
            error: {
              label: t('errors.authentication.global.link'),
              to: 'ResetPassword',
              query: {
                newPasswordRequired: 'true',
                email
              },
              url: `/reset-password?newPasswordRequired=true&email=${email}`
            }
          });
          return;
        }
        return Auth.currentUserPoolUser();
      }).then(promise => {
        if(!promise) return;

        // These lines were present in login.vue (but not in fleet)
        // Infos were used by the Authencation methods which is not currently used since the refactor
        // commit('updateFormProperty', { form: 'login', property: 'userPool', value: promise.pool });
        // commit('updateFormProperty', { form: 'login', property: 'username', value: promise.username });

        if(promise.signInUserSession && promise.signInUserSession.accessToken) {
          // this.Authencation(email, password)
          commit('updateTokens', promise.signInUserSession);
          dispatch('settings/setContext', { root: true });
          return Auth.currentUserInfo();
        }
      }).then(promise => {
        if(promise) {
          dispatch('user/setUser', promise.attributes, { root: true });
          dispatch('user/setProfile', null, { root: true });
        }
      })
      .catch(err => {
        commit('updateErrors', {
          form: 'login',
          property: 'global',
          error: err.message,
        });
      });
    },
    async lostPassword({ state, commit, dispatch }, { email }) {
      const isVerified = await checkEmailVerified(email)
      if(!isVerified)
      {
        const { t } = i18n.global;
        commit('updateErrors', {
          form: 'lostPassword', property: 'global', error: t('authentication.EmailNotVerified')
        });
      }
      else{
      const requestAuthentification = Auth.forgotPassword(email, (event, context, callback) => {
        //
        if(event.userPoolId === "theSpecialUserPool") {
            // Identify why was this function invoked
            if(event.triggerSource === "CustomMessage_ForgorPassword") {
                // Ensure that your message contains event.request.codeParameter. This is the placeholder for code that will be sent
                event.response.smsMessage = "Welcome to the service. Your verification code is " + event.request.codeParameter;
                event.response.emailSubject = "Welcome to the service";
                event.response.emailMessage = "Thank you for signing up. " + event.request.codeParameter + " is your verification code";
            }
            // Create custom message for other events
        }
        // Customize messages for other user pools

        // Return to Amazon Cognito
        callback(null, event)}
      );

      requestAuthentification.then(promise => {
        commit('updateFormProperty', {
          form: 'lostPassword',
          property: 'success',
          value: true
        });
      })
      .catch(err => {
        commit('updateErrors', {
          form: 'lostPassword', property: 'global', error: err.message
        });
      });

      return requestAuthentification;
    }
    },
    resetPassword({ state, commit, dispatch }, { email, password, code }) {
      const { t } = i18n.global;
      const requestAuthentification = Auth.forgotPasswordSubmit(email, code, password)
      requestAuthentification.then(promise => {
        return Auth.signIn(email, password);
      }).then(promise => {
        if(promise.signInUserSession && promise.signInUserSession.accessToken) {
          dispatch('redirectToApp', promise.signInUserSession);
          return Auth.currentUserInfo();
        }
      }).then(promise => {
        dispatch('setUser', promise.attributes);
        dispatch('user/setProfile', null, { root: true });
      })
      .catch(err => {
        if(err.message.includes('code')) {
          commit('updateErrors', { form: 'resetPassword', property: 'code', error: !regex.test(email) ?  t('notifications.errors.aunthentication.email_invalid') : '' });
        }
        commit('updateErrors', { form: 'resetPassword', property: 'global', error: err.message });
      });
    },
    changePassword({ state, commit, dispatch }, { username, password, code }) {
      // 1st param = username (in this case, email)
      // 2nd param = tmp password (in this case, code verification)
      Auth.signIn(username, code)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(user, password)
          .then(promise => {
            if (promise.signInUserSession && promise.signInUserSession.accessToken) {
              // this.redirectToApp(promise.signInUserSession);
              dispatch('redirectToApp', promise.signInUserSession);
              return Auth.currentUserInfo();
            }
          })
          .then(promise => {
            dispatch('setUser', promise.attributes);
            dispatch('user/setProfile', null, { root: true });
            // this.setUser(promise.attributes);
          })
          .catch(e => {
            commit('updateErrors', { form: 'resetPassword', property: 'global', error: e.message });
            // console.log('error :', e);
          });
        }
      }).catch(e => {
        commit('updateErrors', { form: 'resetPassword', property: 'global', error: e.message });
        // console.log('error :', e);
      });
    },
    async updateUser({ state, commit, dispatch }, { form, initialForm }) {
      const properties = ['email', 'firstname', 'lastname', 'locale']
      const params = {};

      if (form.email !== initialForm.email && state.confirmChange.visible === false) {
        commit('updatePopupProperty', { popup: 'confirmChange',  property: 'visible', value: true });
        return;
      }

      const emailChange = state.confirmChange.visible;

      if (emailChange) {
        commit('updatePopupProperty', { popup: 'confirmChange', property: 'requests', value: state.confirmChange.requests + 1 });
      } else {
        commit('user/increaseRequests', 'form', { root: true });
      }

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const initialValue = initialForm[property];
        const value = form[property];

        if (value && value.length && value !== initialValue) {
          switch (property) {
            case 'firstname':
              params.given_name = value;
              break;
            case 'lastname':
              params.family_name = value;
              break;
            default:
              params[property] = value;
              break;
          }
        }
      }

      try {
        const user = await Auth.currentAuthenticatedUser();
        const update = await Auth.updateUserAttributes(user, params);
        dispatch('user/setProfile', null, { root: true });

        if (emailChange) {
          commit('updatePopupProperty', { popup: 'confirmChange', property: 'success', value: true });
        } else {
          dispatch('notifications/addNotifs', [{
            text: 'Profile updated !',
            type: 'success'
          }], { root: true });
        }
      } catch(e) {
        dispatch('notifications/addNotifs', [{
          text: e.message,
          type: 'error'
        }], { root: true });
      }

      if (emailChange) {
        commit('updatePopupProperty', { popup: 'confirmChange', property: 'requests', value: state.confirmChange.requests - 1 });
      } else {
        commit('user/decreaseRequests', 'form', { root: true });
      }
    },
    async updateUserLanguage({ state, dispatch }, locale) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const update = await Auth.updateUserAttributes(user, { locale });
        window.localStorage.setItem('otra-locale', locale);
        dispatch('user/setProfile', null, { root: true });
      } catch(e) {
        dispatch('notifications/addNotifs', [{
          text: e.message,
          type: 'error'
        }], { root: true });
      }
    },
    async verifyEmail({ state, commit, dispatch }) {
      const popup = state.confirmChange.visible ? 'confirmChange' : 'verifyEmail';
      const { code } = state.verifyEmail;

      commit('updateErrors', { form: 'verifyEmail', property: 'code', error: null });

      if (!code || !code.length) {
        commit('updateErrors', { form: 'verifyEmail', property: 'code', error: 'This field is required' });
        return;
      }

      commit('updatePopupProperty', { popup, property: 'requests', value: state[popup].requests + 1 });

      try {
        const request = Auth.verifyCurrentUserAttributeSubmit('email', code);
        commit('updatePopupProperty', { popup: 'confirmChange', property: 'success', value: false });
        commit('updatePopupProperty', { popup: 'confirmChange', property: 'visible', value: false });
        commit('updatePopupProperty', { popup: 'verifyEmail', property: 'visible', value: true });
        commit('updatePopupProperty', { popup: 'verifyEmail', property: 'success', value: true });
      }
      catch(e) {
        dispatch('notifications/addNotifs', [{
          text: e.message ? e.message : e,
          type: 'error'
        }], { root: true });
      }

      commit('updatePopupProperty', { popup, property: 'requests', value: state[popup].requests - 1 });
    },
    redirectToApp({ commit, dispatch }, tokens) {
      commit('updateAuthState', null);
      commit('updateTokens', tokens);
      dispatch('settings/setContext', null, { root: true });
      router.push('/');
    },
    setUser({ dispatch }, attributes) {
      const { t } = i18n.global;
      dispatch('user/setUser', attributes, { root: true });
      dispatch('notifications/addNotifs', [{
        text: t('authentication.reset_password.success'),
        type: 'success'
      }], { root: true });
    },
    // This actions was a method in login.vue
    // It was not in fleet so it's currently not in use
    // Need to determinate what it does
    authenticate() {
      let authenticationData = {
        Username: email,
        Password: password,
      };
      let authenticationDetails = new AuthenticationDetails(authenticationData);

      let userData = {
          Username: this.username,
          Pool: this.userPool,
          Storage: new CookieStorage({secure: false, domain: "https://pmng.otra-local.laniche.io"}),
      };
      let cognitoUser = new CognitoUser(userData);
      return cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          return result;
        },
        onFailure: error => {
          console.log(error)
        },
      });
    }
  } 
}

export default authentication