<template>
  <router-view v-if="$route.name !== 'Owners'"/>
  <section
    v-else
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <table class="table" cellspacing="0">
      <THead
        :head="thead"
        :order="order"
        @onOrder="updateOrder"
      />
      <tbody>
        <Owner
          v-for="owner in owners"
          :key="owner.id"
          :owner="owner"
          :resendActivationLink="resendActivationLink"
          :showResendButton=true
        />
      </tbody>
    </table>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <Loader />
    </div>

    <Popup v-if="confirmation">
      <template v-slot:content>
        <h4 class="popup-title">{{ $t('globals.popup.resend_activation_link_title') }}</h4>
        <div class="popup-actions">
          <button
            type="button"
            class="button button-popup button-border"
            @click.prevent="onPopupClose"
          >
            {{ $t('globals.no') }}
          </button>
          <button
            type="submit"
            class="button button-popup"
            @click.prevent="onPopupConfirm"
          >
            <!-- :disabled="disableAdd" -->
            {{ $t('globals.yes') }}
          </button>
        </div>
      </template>
    </Popup>

    <div
      v-show="showLoader"
      class="main-container-loader"
      style="position: fixed;"
    >
      <loader />
    </div>

  </section>
</template>

<script>
import Loader from '@/components/elements/Loader';
import THead from '@/components/elements/THead';
import Owner from '@/components/partials/Owner';
import Popup from '@/components/partials/Popup';

export default {
  name: "ListParking",
  components: {
    THead,
    Owner,
    Loader,
    Popup
  },
  beforeMount() {
    this.$store.commit('owners/updateSearch', '');
    this.getData();
  },
  data() {
    return {
      confirmation: false,
      ownerId: '',
      showLoader: false
    };
  },
  computed: {
    owners() {
      const { order, list } = this.$store.state.owners;
      const owners = list
        .filter(o => o.user)
        .map(o => {
          o.orderProps = {
            firstname: o?.user?.firstname ? o.user.firstname.trim() : '-',
            lastname: o?.user?.lastname ? o.user.lastname.trim() : '-',
            email: o?.user?.email ? o.user.email.trim() : '-'
          }
        return o;
      });

      const ordereds = owners.sort((a, b) => {
        const aProp = typeof a.orderProps[order.type] === 'string' ? a.orderProps[order.type].toUpperCase() : a.orderProps[order.type];
        const bProp = typeof b.orderProps[order.type] === 'string' ? b.orderProps[order.type].toUpperCase() : b.orderProps[order.type];
        if(aProp < bProp) return order.dir === 'asc' ? -1 : 1;
        if(aProp > bProp) return order.dir === 'asc' ? 1 : -1;
        return 0;
      });

      return ordereds;
    },
    loading() {
      return this.$store.state.owners.requests !== 0;
    },
    order() {
      return this.$store.state.owners.order;
    },
    thead() {
      return [
        {
          title: this.$t('owners.list.columns.firstname'),
          class: 'small head',
          type: 'firstname'
        },
        {
          title: this.$t('owners.list.columns.lastname'),
          class: 'small head',
          type: 'lastname'
        },
        {
          title: this.$t('owners.list.columns.email'),
          class: 'small head',
          type: 'email'
        },
        {
          title: this.$t('owners.list.columns.edit'),
          class: 'big-col head head-hidden'
        },
      ]
    },
    message() {
      return this.$t('owners.list.no_owner');
    }
  },
  watch: {
    $route() {
      this.getData();
    },
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    getData() {
      if(this.$route.name.toLowerCase() === 'owners') {
        this.$store.dispatch('owners/set');
      }
    },
    updateOrder(e) {
      this.$store.commit('owners/updateOrder', e);
    },
    resendActivationLink(id) {
      this.ownerId = id;
      this.confirmation = true;
    },
    onPopupClose() {
      this.ownerId = '';
      this.confirmation = false;
    },
    async onPopupConfirm() {      
      if (this.confirmation) {
        this.confirmation = false; 
        this.showLoader = true;
        await this.$store.dispatch('owners/resendActivationLink', this.ownerId);
        this.showLoader = false;
      }
      this.confirmation = false;      
      this.ownerId = '';
    }
  }
}
</script>

<style>
</style>