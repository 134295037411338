import {
  updateCompany,
  getCompany
} from '@/calls/companies';
import i18n from '../i18n';

const company = {
  namespaced: true,
  state: {
    id: null,
    data: null,
    form: {
      name: '',
      vatnumber: '',
      street: '',
      state: '',
      city: '',
      zipCode: '',
      country: '',
      mobile: '',
      phone: ''
    },
    requests: 0,
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateData(state, data) {
      state.data = data;
    },
    updateDataValue(state, { value, property }) {
      if (property.match(/street|zipCode|city|state/)) {
        state.data.address[property] = value;
        return;
      }

      state.data[property] = value;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },
    // Informations
    updateFormValue(state, { value, property }) {
      state.form[property] = value;
    }
  },
  actions: {
    setFormFromData({ state, commit }) {
      const properties = [
        'name',
        'vatnumber',
        'street',
        'state',
        'city',
        'zipCode',
        'country',
        'mobile',
        'email',
        'phone',
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        switch (true) {
          case /country/.test(property):
            commit('updateFormValue', {
              property, value: state.data.address?.country?.code ? state.data.address.country.code.toLowerCase() : null
            });
            break;
          case /street|zipCode|city|state/.test(property):
            commit('updateFormValue', {
              property, value: state.data.address[property]
            });
            break;
        
          default:
            commit('updateFormValue', {
              property, value: state.data[property]
            });
            break;

        }
      }
    },
    // Data
    async getData({ commit, dispatch, state }) {
      commit('increaseRequests');
      const request = await getCompany(state.id);
      const { t } = i18n.global;

      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parking-company.get'),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async saveData({ commit, state, dispatch }) {
      commit('increaseRequests');
      const data = {...state.form};
      const request = await updateCompany(state.id, data);
      const { t } = i18n.global;

      if(!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.parking-company.update'),
          type: 'success'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parking-company.update'),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    }
  }
};

export default company;
