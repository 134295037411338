<template>
  <section
    ref="container"
    class="main-container main-container__form main-container__new"
    :class="{ 'main-container--loading': loading }"
  >
    <form
      novalidate
      @submit.prevent="onSave"
    >
      <!-- <h2 class="h5">Edit</h2> -->
      <fieldset>
        <div class="container-row container-5-5">
          <Inputs
            ref="firstname"
            name="firstname"
            type="text" 
            :label="$t('fleet.form.labels.firstname')"
            :error="errors.firstname"
            :required="true"
            :value="form.firstname"
            @input="onInput($event, 'firstname')"
          />
          <Inputs
            ref="lastname"
            name="lastname"
            type="text" 
            :label="$t('fleet.form.labels.lastname')"
            :error="errors.lastname"
            :required="true"
            :value="form.lastname"
            @input="onInput($event, 'lastname')"
          />
        </div>
        <div class="container-row container-5-5">
          <Inputs
            ref="email"
            name="email"
            type="text" 
            :label="$t('fleet.form.labels.email')"
            :error="errors.email"
            :required="true"
            :value="form.email"
            @input="onInput($event, 'email')"
          />

          <!-- <NewSelect
            :name="'fleetCompanyId'"
            :label="$t('fleet.form.labels.fleet')"
            :options="fleets"
            :placeholder=" $t('fleet.form.labels.fleet_select')"
            :value="fleet"
            :error="errors.fleetCompanyId"
            :class="'classic'"
            @onChange="onInput($event, 'fleetCompanyId')"
          /> -->
        </div>
      </fieldset>

      <button
        class="button"
        type="submit"
      >
        {{$t('fleet.form.buttons.save')}}
      </button>

      <div 
        v-if="hasError"
        class="form--error small"
      >
       {{$t('notifications.errors.several_required')}}
      </div>
    </form>

    <div
      v-if="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </section>
</template>

<script>
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
// import NewSelect from '@/components/elements/NewSelect';

import langs from '@/data/languages';

export default {
  components: {
    Loader,
    Inputs,
    // NewSelect
  },
  beforeMount() {
    const { id } = this.$route.params;

    this.$store.commit('fleetOwner/resetForm');
    this.$store.commit('fleetOwner/updateId', id);
    this.$store.dispatch('fleetOwner/getData');
    this.$store.dispatch('fleetOwner/getFleets');
  },
  data() {
    return {
      hasError: false,
      errors: {
        firstname: '',
        lastname: '',
        email: '',
        // fleet: '',
        locale: ''
      }
    }
  },
  computed: {
    form() {
      return this.$store.state.fleetOwner.form;
    },
    // fleet() {
    //   const { fleets } = this.$store.state.fleetOwner;
    //   const { fleetCompanyId } = this.$store.state.fleetOwner.form;

    //   if (!fleets.length || !fleetCompanyId) return null;
    //   const fleet = fleets.filter(f => f.id === fleetCompanyId);

    //   if(!fleet.length) return null;
    //   return fleet[0].id;
    // },
    // fleets() {
    //   const fleets = this.$store.state.fleetOwner.fleets.map(f => {
    //     return {
    //       value: f.id,
    //       label: f.name ||  this.$t('globals.no_name')
    //     }
    //   });

    //   fleets.unshift({
    //     value: 0,
    //     label: this.$t('owners.form.unassigned')
    //   });

    //   return fleets;
    // },
    locale() {
      const { locale } = this.$store.state.fleetOwner.form;
      return locale && locale.length ? locale : 'en';
    },
    languages() {
      return langs.map(l => {
        return {
          value: l.code,
          label: this.$t(`globals.languages.${l.code}`)
        }
      });
    },
    loading() {
      return this.$store.state.fleetOwner.requests !== 0;
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    validate() {
      this.hasError = false;
      this.errors = {};

      const properties = [
        'firstname',
        'lastname',
        'email'
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const data = this.form[property];

        switch (true) {
          case /email/.test(property):
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');;
              break;
            }

            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(data)) {
              this.errors[property] =  this.$t('notifications.errors.email_invalid');
            }

            break;
          default:
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');;
            }
            break;
        }
      }
    },
    onInput($event, property) {
      const { target } = $event;
      if (!target && (property !== 'fleetCompanyId' && property !== 'locale')) return;

      switch (true) {
        case /fleetCompanyId|locale/.test(property):
          this.$store.commit('fleetOwner/updateFormValue', { value: $event, property });
          break;
        default:
          const { value } = target;
          this.$store.commit('fleetOwner/updateFormValue', { value, property });
          break;
      }
    },
    async onSave() {
      this.validate();

      if (this.hasError) return;

      const request = await this.$store.dispatch('fleetOwner/saveData');
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    padding-bottom: 50px;
  }
  .button {
    margin-top: 20px;
  }
  .small.form--error {
    margin-top: 20px;
    color: $error;
  }
</style>
