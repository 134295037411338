import axios from 'axios';

const get = (id) => {
  return axios.get(`/admin/fleet-planners/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};
const createPlanner = (item) => {
  return axios.post(`/admin/fleet-planners`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};
const list = async (search, FleetCompanyId) => {
  return await axios.get(`/admin/fleet-planners`, {
    params: {
      search,
      FleetCompanyId
    }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const update = (id, item) => {
  return axios.patch(`/admin/fleet-planners/${id}`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const getPlanner = (id) => {
  // return axios.get(`/admin/parkings/${id}`) => translation not returned
  return axios.get(`/admin/fleet-planners/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const patchPlanner = (id, item) => {
  return axios.patch(`/admin/fleet-planners/${id}`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);

    return {
      hasError: true,
      error
    }
  });
};

const resendActivationLink = (id) => {
  return axios.post(`/admin/fleet-planners/resendactivationlink/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {hasError: true, error: error};
  });
};

export {
  list,
  get,
  createPlanner,
  update,
  getPlanner,
  patchPlanner,
  resendActivationLink
}
