const path = require("path");

import { createI18n } from 'vue-i18n';

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  const languages = []
  locales.keys().forEach((key) => {
    const language = path.dirname(key).replace(/[^a-zA-Z0-9]/g,'');
    if(!language) return
    if(!(language in messages)) {
      messages[language] = {}
      languages.push(language)
    }
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[language][locale] = locales(key)
    }
  })
  return messages
}

export default new createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});