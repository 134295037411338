import axios from 'axios';

const getParameters = async (section) => {
  try {
    ///api/v1/admin/parameters/{section}
    const request = await axios.get(`/admin/parameters/${section}`);
    console.log('request :', request);
    return request;
  } catch (error) {
    return {
      hasError: true,
      error
    }
  }
};

const updateParameters = async (data) => {
  try {
    ///api/v1/admin/parameters/{section}
    const request = await axios.patch('/admin/parameters', { parameters: data });
    console.log('request update :', request);
    return request;
  } catch (error) {
    return {
      hasError: true,
      error
    }
  }
};

export { 
  getParameters,
  updateParameters
};