<template>
  <section class="main-container main-container__form">
    <form>
      <h2 class="h4">{{ $t('owners.form.title') }}</h2>
      <fieldset class="container-row container-5-5">
        <Select
          ref="gender"
          :label="$t('owners.form.labels.gender')"
          :name="'gender'"
          :options="genders"
          :placeholder="form.gender ? form.gender : $t('owners.form.labels.gender_select')"
          :select="{ value: form.gender }"
          :error="error.gender"
          :required="true"
          :class="'classic'"
          @change="handleGender"
        />
        <Inputs
          ref="position"
          name="position"
          type="text" 
          :label="$t('owners.form.labels.function')"
          :error="error.position"
          :required="true"
          :value="form.position"
          @input="handleInput"
        />
      </fieldset>
      <fieldset class="container-row container-5-5">
        <Inputs
          ref="firstname"
          name="firstname"
          type="text" 
          :label="$t('owners.form.labels.firstname')"
          :error="error.firstname"
          :required="true"
          :value="form.firstname"
          @input="handleInput"
        />
        <Inputs
          ref="lastname"
          name="lastname"
          type="text" 
          :label="$t('owners.form.labels.lastname')"
          :error="error.lastname"
          :required="true"
          :value="form.lastname"
          @input="handleInput"
        />
      </fieldset>
      <fieldset class="container-row container-5-5">
        <Inputs
          ref="email"
          type="email" 
          :label="$t('owners.form.labels.email')"
          :error="error.email"
          :required="true"
          :name="'email'"
          :value="form.email"
          @input="handleInput"
        />
        <!-- <Select
          ref="company"
          :name="'company'"
          :label="$t('owners.form.labels.company')"
          :options="companies"
          :placeholder="form.parkingCompanyId ? form.parkingCompanyId : $t('owners.form.labels.company_select')"
          :error="error.company"
          :required="false"
          :class="'classic'"
          @change="handleCompany"
        /> -->
      </fieldset>
      <button-save 
        :loading="loading"
        :labels="{
          default: $t('owners.form.buttons.save'),
          end: $t('owners.form.buttons.saved')
        }"
        :class="{disabled: !canValidate}"
        @click="onSubmit"
      />
      <div 
        v-if="hasError"
        class="form--error small"
      >
        {{$t('notifications.errors.several_required')}}
      </div>
    </form>
  </section>
</template>

<script>
// import {isValidPhoneNumber, parsePhoneNumber, ParseError} from 'libphonenumber-js'

import Select from '@/components/elements/Select';
import Inputs from '@/components/elements/Inputs';
import ButtonSave from '@/components/elements/ButtonSave';

export default {
  name: "editCompany",
  components: {
    Inputs,
    Select,
    ButtonSave
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    owner() {
      return this.$store.state.owners.list.filter(owner => owner.id === Number(this.$route.params.id)).shift();
    },
    companies() {
      return this.setForSelect(this.$store.state.companies.list);
    },
    genders() {
      return [
        { value: "male", label: this.$t('owners.form.gender.male') },
        { value: "female", label: this.$t('owners.form.gender.female') },
        { value: "other", label: this.$t('owners.form.gender.other') },
      ]
    }
  },
  data() {
    return {
      inputs: {},
      loading: false,
      canValidate: false,
      hasError: false,
      notEmpty: [],
      form: {
        firstname: '',
        lastname: '',
        email: '',
        position: '',
        gender: '',
        parkingCompanyId: '',
      },
      error: {
        firstname: '',
        lastname: '',
        email: '',
        position: '',
        gender: '',
        parkingCompanyId: '',
      }
    };
  },
  mounted() {
    const { submit, ...refs } = this.$refs;
    this.inputs = Object.values(refs);
   
    this.setForm(this.owner)

    this.submit = submit;
  },
  watch: {
    owner(arr) {
      if(!arr) return;
      this.setForm(arr)
      setTimeout(() => {
        this.handleError();
      }, 100);
      return arr;
    }
  },
  methods: {
    setForm(arr) {
      const user = arr && arr.user ? arr.user: {};
      const owner = {...arr, ...user };
      if (owner.gender && owner.gender.match(/0|1|2/)) {
        switch (owner.gender) {
          case '1':
            owner.gender = 'male';
            break;
          case '2':
            owner.gender = 'female';
            break;
          default:
            owner.gender = 'other';
            break;
        }
      }

      this.originalObject(this.form, owner) 
    },
    setForSelect(arr) {
      const tmpArr = [{
        value: 0,
        label: this.$t('owners.form.unassigned')
      }]
      if(!arr) return
      arr.forEach(company => {
          tmpArr.push({
            value: company.id,
            label: company.name
          })
      });
      return tmpArr;
    },
    // On
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = true;
      this.hasError = false;
      this.error = {
        firstname: '',
        lastname: '',
        email: '',
        position: '',
        gender: '',
        parkingCompanyId: '',
      }
      this.handleValidation()
    },
    handleGender(gender) {
      if(gender) {
        this.notEmpty.push(true);
        this.form.gender = gender;
      }
      else this.notEmpty.push(false);

      this.handleError();
    },
    handleCompany(company) {
      if(company != null && company != undefined) {
        this.notEmpty.push(true);
        this.form.parkingCompanyId = company;
      }
      else this.notEmpty.push(false);

      this.handleError();
    },
    // Handle
    handleInput(evt) {
      const {target} = evt;
      this.form[target.name] = target.value;
      this.handleError();
    },
    handleValidation() {
      const { email } = this.form
      this.checkEmail(email);
      this.handleError()
      if(this.canValidate && !this.hasError) this.send()
    },
    handleError() {
      this.notEmpty = [];
      for (const ref in this.inputs) {
        const input = this.inputs[ref];
        if(input.required) this.notEmpty.push(input.required && input.$refs.input.value !== '');
        else this.notEmpty.push(true);
      }

      this.canValidate = this.notEmpty.length === this.inputs.length && this.notEmpty.indexOf(false) === -1;
    },
    // Check
    checkEmail(email) {
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.error.email = !email.trim() ? this.$t('notifications.errors.email_required') : "";
      if(!this.error.email) this.error.email = !regex.test(email) ? this.$t('notifications.errors.email_invalid') : "";
      if(this.error.email) this.hasError = true;
    },
    send() {
      const items = this.filterObject( this.form, (input) => input != null && input != undefined );

      const sent = this.$store.dispatch('owners/update', {id: this.id, item: items});

      sent.then((response) => {
        if(response && response.noReload) return { noReload: true };
        if(items.parkingCompanyId) return this.$store.dispatch('companies/set')
      }).then((res) => {
        if(res && res.noReload) {
          this.loading = false;
          return { noReload: true }
        };

        this.loading = "finish";
      }).then((r) => {
        if(r && r.noReload) return { noReload: true };
        this.$router.push('/owners');
      })
    },
    filterObject(obj, callback) {
      return Object.fromEntries(Object.entries(obj).
        filter(([key, val]) => callback(val, key)));
    },
    originalObject(obj1, obj2) {
      Object.keys(obj2).filter(key => key in obj1).forEach(key => {
          obj1[key] = obj2[key];
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    padding-bottom: 50px;
  }
  .button {
    margin-top: 20px;
  }
</style>
