import { createStore } from 'vuex'

import user from './user';
import owners from './owners';
import parking from './parking';
import company from './company';
import settings from './settings';
import parkings from './parkings';
import companies from './companies';
import mobileApp from './mobile-app';
import teamAdmin from './team-admin';
import teamAdmins from './team-admins';
import fleetOwner from './fleet-owner';
import fleetOwners from './fleet-owners';
import translations from './translations';
import fleetPlanner from './fleet-planner';
import fleetCompany from './fleet-company';
import notifications from './notifications';
import fleetPlanners from './fleet-planners';
import authentication from './authentication';
import popups from './popups';
import fleetCompanies from './fleet-companies';
import partners from './partners';
import partner from './partner';
import countryAbbreviations from './country-abbreviations';
import bookingFile from './booking-file';

export default createStore({
  modules: {
    user,
    owners,
    parking,
    company,
    parkings,
    settings,
    companies,
    mobileApp,
    teamAdmin,
    teamAdmins,
    fleetOwner,
    fleetOwners,
    translations,
    fleetCompany,
    fleetPlanner,
    fleetPlanners,
    notifications,
    authentication,
    fleetCompanies,
    partners,
    partner,
    countryAbbreviations,
    popups,
    bookingFile
  },
});
