<template>
  <div class="features">
    <h3 class="h5">{{ $t('parkings.form.details_features.titles.details') }}</h3>

    <div class="description">
      <Inputs
        ref="description"
        type="textarea" 
        :label="$t('parkings.form.labels.parking_description')"
        :error="errors.description"
        :required="true"
        :value="description"
        :counting="descriptionMaxLength"
        :placeholder="$t('parkings.form.placeholders.description')"
        @input="updateDescription"
        :readonly="disableSnapReleaseButtons"
      />

      <div class="description-langs">
        <NewSelect
          :name="'description-lang'"
          :options="languages"
          :value="currentLang"
          :class="'classic'"
          :placeholder="''"
          @onChange="onLangChange($event)"
          :readonly="disableSnapReleaseButtons"
        />
      </div>
    </div>

    <div class="container-row container-5-5">
      <NewSelect
        :name="'securityLevel'"
        :label="$t('parkings.form.labels.security_level')"
        :options="securityLevels"
        :placeholder="$t('parkings.form.placeholders.security_level')"
        :value="form.securityLevelId"
        :error="errors.securityLevelId"
        :class="'classic'"
        @onChange="onSecurity($event, 'securityLevelId')"
        :readonly="disableSnapReleaseButtons"
      />
    </div>

    <h3 class="h5">{{ $t('parkings.form.details_features.titles.hours') }}</h3>
    <Hours
      :available="form.guichetAvailable"
      :days="form.guichetOpeningHours"
      @onChange="onDayChange"
      @onToggle="onToggleHours"
      @onToggleDay="onDayToggle"
      @onToggleOpen24="onDayToggleOpen24"
    />
    <!-- --- -->

    <h3 class="h5">{{ $t('parkings.form.details_features.titles.features') }}</h3>
    <features-list
      :features="commodities"
      @rating="onRating"
      @feature="onFeature"
    />
  </div>
</template>

<script>
import Hours from '@/components/modules/Hours';
import Inputs from '@/components/elements/Inputs';
import NewSelect from '@/components/elements/NewSelect';
import FeaturesList from '@/components/modules/Features-list';

import langs from '@/data/languages.json';

export default {
  name: 'Features',
  components: {
    Hours,
    Inputs,
    NewSelect,
    FeaturesList
  },
  data() {
    return {
      errors: {
        description: '',
        securityLevelId: null
      },
      descriptionMaxLength : 500,
      securityLevels: [
        { value: 6, label: this.$t('parkings.form.options.security_level.none') },
        { value: 1, label: this.$t('parkings.form.options.security_level.bronze') },
        { value: 2, label: this.$t('parkings.form.options.security_level.silver') },
        { value: 3, label: this.$t('parkings.form.options.security_level.gold') },
        { value: 4, label: this.$t('parkings.form.options.security_level.platinum') }
      ]
    }
  },
  computed: {
    currentLang() {
      return this.$store.state.parking.currentLang;
    },
    langs() {
      return langs.map(l => {
        return {
          value: l.code,
          label: this.$t(`globals.languages.${l.code}`)
        }
      })
    },
    languages() {
      return this.$store.state.settings.languages.map(l => {
        return {
          value: l.code,
          label: l.name
        }
      });
    },
    form() {
      return this.$store.state.parking.form;
    },
    description() {
      if (!this.$store.state.parking.form) return '';
      const lang = this.$store.state.parking.currentLang;
      console.log('this.$store.state.parking.form.parkingDescriptions :', this.$store.state.parking.form.parkingDescriptions);
      const desc = this.$store.state.parking.form.parkingDescriptions.filter(desc => desc.lang === lang)[0];

      // if (desc)
      return desc.description;

      return this.$store.state.parking.form.parkingDescriptions.filter(desc => desc.lang === lang)[0].description;
    },
    commodities() {
      return this.$store.state.parking.commodities;
    },
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  },
  methods: {
    // Events
    onSecurity($event, property) {
      this.$store.commit('parking/updateFormValue', { value: $event, property });
    },
    onRating({ groupId, score }) {
      this.$store.commit('parking/updateCommodityScore', { groupId, score });
    },
    onFeature({ groupId, commodityId }) {
      this.$store.commit('parking/updateCommodity', { groupId, commodityId });
    },
    onToggleHours(value) {
      this.$store.commit('parking/updateFormValue', { value, property: 'guichetAvailable' });
    },
    onDayToggle($event) {
      this.$store.commit('parking/updateDayOpen', $event);
    },
    onDayToggleOpen24($event) {
      this.$store.commit('parking/updateDayOpen24', $event);
    },
    onDayChange($event) {
      this.$store.commit('parking/updateOpeningHour', $event);
    },
    onLangChange($event) {
      this.$store.commit('parking/updateLang', $event);
      const desc = this.$store.state.parking.form.parkingDescriptions.filter(desc => desc.lang === $event)[0];
      if (!desc) this.$store.commit('parking/addDescription', $event);
      this.checkDescriptionError();
    },
    checkDescriptionError() {
      const value = this.description;
      if (value && value.length > this.descriptionMaxLength) {
        this.errors.description = this.$t('notifications.errors.parking.description_max_char');
      } else {
        if (this.errors.description) this.errors.description = '';
      }
    },
    updateDescription(event) {
      if (event) {
        const { value } = this.$refs.description.$refs.input;

        if (value.length > this.descriptionMaxLength) {
          this.errors.description = this.$t('notifications.errors.parking.description_max_char');
        } else {
          if (this.errors.description) this.errors.description = '';
        }

        this.$store.commit('parking/updateDescription', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.h5 {
  margin-bottom: 24px;

  &:not(:first-child) {
    margin-top: 60px;
  }
}

.button {
  + .loader {
    display: inline-block;
    margin-left: 40px;
    margin-top: 30px;
    vertical-align: middle;
  }
}

.description {
  position: relative;
  padding-top: 16px;

  &-langs {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    background-color: $white;
  }
}
</style>

<style lang="scss">
.features {
  .description {
    .input {
      .label {
        margin-bottom: 20px;

        > .input-count {
          margin-left: 12px;
        }
      }
    }

    &-langs {
      .select {
        min-width: 200px;

        .items {
          border-radius: 0 0 3px 3px;
        }

        .custom-select {
          padding: 0;
        }

        .selected {
          padding: 13px 16px 10px;
        }
      }
    }
  }
}
</style>