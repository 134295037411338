<template>
  <footer class="footer">
    <p class="small">{{$t('globals.footer.copyright')}}</p>
    <!-- <nav class="footer-navigation">
      <ul class="footer-navigation__items">
        <li class="footer-navigation__item">
          <a class="small">{{$t('globals.footer.nav.terms')}}</a>
        </li>
      </ul>
    </nav> -->
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
