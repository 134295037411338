<template>
  <div id="map"></div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl';

import Pin from '@/assets/svg/pin.svg';

export default {
  name: Map,
  props: {
    lat: {
      type: [Number, String],
      default: 0
    },
    lng: {
      type: [Number, String],
      default: 0
    },
    lang: {
      type: String,
      default: 'en'
    }
  },
  data() {
    return {
      data:null,
      map: null,
      canGeoloc: true,
      marker: null,
      address: {
        street: '',
        country: '',
        zipCode: '',
        city: '',
      },
      center:[23.3833318, 48.499998],
      coordinates: {
        lat: 0,
        lng: 0,
      }
    };
  },
  watch: {
    lat(val) {
      this.coordinates.lat = Number(val)

      const latBetween = this.coordinates.lat >= -90 && this.coordinates.lat <= 90
      const lngBetween = this.coordinates.lng >= -90 && this.coordinates.lng <= 90

      if(!this.coordinates.lat || !this.coordinates.lng || !latBetween || !lngBetween) {
        this.canGeoloc = true;
        return 
      }
      this.setPin([this.coordinates.lng, this.coordinates.lat]);
      this.setMap([this.coordinates.lng, this.coordinates.lat]);
      this.canGeoloc = true;
    },
    lng(val) {
      this.coordinates.lng = Number(val)
      const latBetween = this.coordinates.lat >= -90 && this.coordinates.lat <= 90
      const lngBetween = this.coordinates.lng >= -90 && this.coordinates.lng <= 90

      if(!this.coordinates.lat || !this.coordinates.lng || !latBetween || !lngBetween) {
        this.canGeoloc = true;
        return
      }
      this.setPin([this.coordinates.lng, this.coordinates.lat]);
      this.setMap([this.coordinates.lng, this.coordinates.lat]);
      this.canGeoloc = true;
    },
    lang(val) {
      this.updateLang();
    }
  },
  mounted() {
    this.coordinates.lat = Number(this.lat);
    this.coordinates.lng = Number(this.lng);

    const latBetween = this.coordinates.lat >= -90 && this.coordinates.lat <= 90
    const lngBetween = this.coordinates.lng >= -90 && this.coordinates.lng <= 90

    if( !latBetween || !lngBetween) {
      this.coordinates.lat = 1
      this.coordinates.lng = 1
    }
    const center = this.coordinates.lat && this.coordinates.lng ? [this.coordinates.lng, this.coordinates.lat] : this.center;

    mapboxgl.accessToken = 'pk.eyJ1Ijoib3RyYW1hcGJveGFjY291bnQiLCJhIjoiY2tucHZtbjA1MTI0MzJubGF2MTA1OHA1ZiJ9.NP7_3MVerJ6Skc9fCSJ3fg';
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
      zoom: 8, // starting zoom
      center, // starting position [lng, lat]
      interactive: false 
    });

    const el = this.createPin();

    this.marker = new mapboxgl.Marker(el, {
      // draggable: true,
      offset: [0, -50/2]
    })
    .setLngLat(center)
    .addTo(this.map);

    // this.marker.on('dragend', () => this.onDragEnd());

    setTimeout(() => {
      this.updateLang();
    }, 200);

    if(this.coordinates.lat && this.coordinates.lng) this.$emit('coordinates', this.coordinates);
  },
  methods: {
    onDragEnd() {
      const coordinates = this.marker.getLngLat();
      this.$emit('coordinates', coordinates);
    },
    createPin(el) {
      el = document.createElement('div');
      el.style.backgroundImage = `url("${Pin}")`
      el.style.backgroundSize = 'contain'
      el.style.backgroundPosition = 'center'
      el.style.width = '64px';
      el.style.height = '68px';
      return el
    },
    setMap(coordinates) {
      this.map.jumpTo({
        center: coordinates, 
        zoom: 12,
      });
    },
    setPin(coordinates) {
      this.marker.setLngLat(coordinates)
    },
    updateLang() {
      if (!this.map) return;
      const props = [
        'country-label',
        'state-label',
        // 'settlement-major-label',
        // 'settlement-minor-label',
        'settlement-subdivision-label',
        'airport-label',
        'poi-label',
        'water-point-label',
        'water-line-label',
        // 'road-label-simple'
      ];

      for (let i = 0; i < props.length; i++) {
        this.map.setLayoutProperty(props[i], 'text-field', ['get',`name_${this.lang}`]);
      }
    },
  }
}
</script>
