<template>
  <router-view v-if="$route.name.toLowerCase() !== 'fleetcompanies'"/>
  <section
    v-else
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <table class="table" cellspacing="0" >
      <THead
        :head="thead"
        :order="order"
        @onOrder="updateOrder"
      />
      <tbody>
        <tr
          v-for="company in companies"
          :key="company.id"
        >  
          <td>
            <span class="grey">{{ company.name }}</span>
          </td>
          <td>
            <span class="grey">{{ company.email }}</span>
          </td>
          <td>
            <span class="grey">{{ company?.address?.city ? company.address.city : '-' }}</span>
          </td>
          <td>
            <span class="grey">{{ company?.address?.country ? company.address.country.name : '-' }}</span>
          </td>
          <td class="td--buttons">
            <router-link
              class="button button--small"
              :to="{name: 'editFleetCompanies', params: { id: company.id } }"
            >
              {{$t('fleet.list.buttons.edit')}}
            </router-link>
            <button
              type="button"
              class="button button--small"
              @click="toggleModalOwner(company)"
            >
              {{$t('company.list.buttons.owner')}}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <Loader />
    </div>

    <ModalSelect
      :abbr="$t('fleet.modal.owner.abbr')"
      :title="$t('fleet.modal.owner.title')"
      :visible="modalOwners.visible"
      :options="modalOwners.owners"
      :value="modalOwners.ownerId"
      :loading="modalOwners.requests !== 0"
      @onClose="closeModal"
      @onChange="onOwnerChange"
      @onSubmit="onOwnerSubmit"
    />
  </section>
</template>

<script>
import Loader from '@/components/elements/Loader';
import THead from '@/components/elements/THead';
import ModalSelect from '@/components/partials/ModalSelect';

export default {
  name: "ListFleetCompanies",
  components: {
    THead,
    Loader,
    ModalSelect
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    companies() {
      const { order, items } = this.$store.state.fleetCompanies;
      const companies = items.map(c => {
        c.orderProps = {
          name: c.name.trim(),
          email: c.email.trim(),
          city: c?.address?.city ? c.address.city.trim() : '-',
          country: c?.address?.country ? c.address.country.name.trim() : '-',
        }
        return c;
      });
      const ordereds = companies.sort((a, b) => {
        const aProp = typeof a.orderProps[order.type] === 'string' ? a.orderProps[order.type].toUpperCase() : a.orderProps[order.type];
        const bProp = typeof b.orderProps[order.type] === 'string' ? b.orderProps[order.type].toUpperCase() : b.orderProps[order.type];
        if(aProp < bProp) return order.dir === 'asc' ? -1 : 1;
        if(aProp > bProp) return order.dir === 'asc' ? 1 : -1;
        return 0;
      });

      return ordereds;
    },
    loading() {
      return this.$store.state.fleetCompanies.requests !== 0;
    },
    order() {
      return this.$store.state.fleetCompanies.order;
    },
    thead() {
      return [
        {
          title: this.$t('fleet.list.columns.name'),
          class:"small head",
          type: 'name'
        },
        {
          title: this.$t('fleet.list.columns.email'),
          class:"small head",
          type: 'email'
        },
        {
          title: this.$t('fleet.list.columns.city'),
          class:"small head",
          type: 'city'
        },
        {
          title: this.$t('fleet.list.columns.country'),
          class:"small head",
          type: 'country'
        },
        {
          title: this.$t('fleet.list.columns.actions'),
          class:"buttons-col head head-hidden"
        },
      ]
    },
    message() {
      return this.$t('fleet.list.no_company');
    },
    modalOwners() {
      const { owner } = this.$store.state.fleetCompanies.modals;
      return owner;
    }
  },
  watch: {
    $route() {
      this.getData();
    },
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },

  methods: {
    getData() {
      if(this.$route.name.toLowerCase() === 'fleetcompanies') {
        this.$store.dispatch('fleetCompanies/set');
      }
    },
    updateOrder(e) {
      this.$store.commit('fleetCompanies/updateOrder', e);
    },
    toggleModalOwner(company) {
      this.$store.commit('fleetCompanies/updateModalProperty', {
        modal: 'owner', property: 'fleetId', value: company.id
      });

      const { fleetPlanners } = company;
      const owner = fleetPlanners.filter(p => p.isOwner);

      if (owner.length) {
        this.$store.commit('fleetCompanies/updateModalProperty', {
          modal: 'owner', property: 'ownerId', value: owner[0].id
        });
        this.$store.commit('fleetCompanies/updateModalProperty', {
          modal: 'owner', property: 'currentOwnerId', value: owner[0].id
        });
      }

      this.$store.dispatch('fleetCompanies/getCompany');
      this.$store.dispatch('fleetCompanies/getOwners');

      // if (!this.$store.state.fleetCompanies.modals.owner.owners.length) {}

      this.$store.commit('fleetCompanies/updateModalProperty', {
        modal: 'owner', property: 'visible', value: true
      });
    },
    closeModal() {
      this.$store.commit('fleetCompanies/updateModalProperty', {
        modal: 'owner', property: 'visible', value: false
      });
      this.$store.commit('fleetCompanies/updateModalProperty', {
        modal: 'owner', property: 'fleet', value: null
      });
      this.$store.commit('fleetCompanies/updateModalProperty', {
        modal: 'owner', property: 'ownerId', value: null
      });
      this.$store.commit('fleetCompanies/updateModalProperty', {
        modal: 'owner', property: 'currentOwnerId', value: null
      });
    },
    onOwnerChange(value) {
      this.$store.commit('fleetCompanies/updateModalProperty', {
        modal: 'owner', property: 'ownerId', value
      });
    },
    onOwnerSubmit() {
      this.$store.dispatch('fleetCompanies/updateOwner');
    }
  }
}
</script>