import axios from 'axios';

const list = async () => {
  return await axios.get(`/admin/countryAbbreviations`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

export {
  list
}
