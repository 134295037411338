import axios from 'axios';

const getCompanies = async (search) => {
  return await axios.get(`/admin/parking-companies`, {
    params: { search }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const getCompany = async (id) => {
  return await axios.get(`/admin/parking-companies/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const addCompany = async (item) => {
  return await axios.post(`/admin/parking-companies`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};
const updateCompany = async (id, item) => {
  return await axios.patch(`/admin/parking-companies/${id}`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};


export {
  getCompanies,
  addCompany,
  updateCompany,
  getCompany
}
