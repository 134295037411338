<template>
  <div class="hours">
    <div class="hours-availability">
      <input
        id="parking-hour-availability"
        :checked="available"
        type="checkbox"
        class="u-visually-hidden hours-availability-checkbox"
        @change="onAvailable"
        :disabled="disableSnapReleaseButtons"
      />
      <label
        for="parking-hour-availability"
        class="h4"
      >
        {{ $t('components.hours.available') }}
        <!-- {{ $t(`globals.days.${day.day}`) }} -->
        <Sprite
          :type="'check'"
          :width="14"
          :height="10"
        />
      </label>
    </div>

    <div
      v-show="available"
      class="hours-table"
    >
      <div class="hours-row hours-row--head">
        <div class="hours-cell">
          <p>{{ $t('components.hours.head.day') }}</p>
        </div>

        <div class="hours-cell">
          <p>{{ $t('components.hours.head.start') }}</p>
        </div>

        <div class="hours-cell">
          <p>{{ $t('components.hours.head.end') }}</p>
        </div>
      </div>

      <div
        v-for="day in days"
        :key="`day-${day.day}`"
        :class="['hours-row',
          { 'hours-row--disabled': !day.isOpen },
          { 'hours-row--open': day.isDay24 }
        ]"
        @click="onRowClick(day.day)"
      >
        <div class="hours-cell">
          <!-- <div class="hours-checkbox"> -->
          <input
            :id="`day-${day.day}`"
            :checked="day.isOpen"
            type="checkbox"
            class="u-visually-hidden hours-cell-checkbox"
            @change="onOpen(day.day)"
            :disabled="disableSnapReleaseButtons"
          />
          <label
            :for="`day-${day.day}`"
            class="h4"
          >
            {{ $t(`globals.days.${day.day}`) }}
            <Sprite
              :type="'check'"
              :width="14"
              :height="10"
            />
          </label>
          <!-- </div> -->
        </div>
        <div class="hours-cell">
          <!-- <div class="hours-checkbox"> -->
          <input
            :id="`day-${day.day}-24open`"
            :checked="day.isDay24"
            type="checkbox"
            class="u-visually-hidden hours-cell-checkbox hours-cell-checkbox--small"
            @change="onOpen24(day.day)"
            :disabled="disableSnapReleaseButtons"
          />
          <label :for="`day-${day.day}-24open`">
            <!-- class="h5" -->
            {{ $t(`components.hours.label.open-24`) }}
            <Sprite
              :type="'check'"
              :width="10"
              :height="7"
            />
          </label>
          <!-- </div> -->
        </div>

        <div class="hours-cell">
          <Select
            :placeholder="''"
            :name="`day-${day.day}-start`"
            :value="getValue(day, 'openAt')"
            :options="getAvailableHours('start', day)"
            :class="'classic'"
            @onChange="onChange(`${$event}:00`, day.day, 'open')"
            :readonly="disableSnapReleaseButtons"
          />
            <!-- :options="hoursList" -->
          <span class="hours-cell-separator"></span>
        </div>

        <div class="hours-cell">
          <Select
            :placeholder="''"
            :name="`day-${day.day}-end`"
            :value="getValue(day, 'closeAt')"
            :options="getAvailableHours('end', day)"
            :class="'classic'"
            @onChange="onChange(`${$event}:00`, day.day, 'close')"
             :readonly="disableSnapReleaseButtons"
          />
            <!-- :options="hoursList" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '@/components/elements/NewSelect';
import Sprite from '@/components/elements/Sprite';
import hours from '@/data/hours.json';

export default {
  components: {
    Sprite,
    Select
  },
  props: {
    days: {
      type: Array,
      required: true
    },
    available: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      hoursList: [...hours]
    }
  },
  methods: {
    getValue(day, type) {
      return day[type].replace(/:00$/, '');
    },
    getAvailableHours(type, day) {
      if (type === 'start') {
        const hours = this.hoursList
          .filter(h => {
            const hNumber = parseInt(h.replace(':', ''));
            const closeAtNumber = parseInt(day.closeAt.replace(/:00$/, '').replace(':', ''));
            return hNumber < closeAtNumber;
          })
          .map(h => {
            return { value: h, label: h }
          });

        return hours;
      }

      if (type === 'end') {
        const hours = this.hoursList
          .filter(h => {
            const hNumber = parseInt(h.replace(':', ''));
            const openAtNumber = parseInt(day.openAt.replace(/:00$/, '').replace(':', ''));
            return hNumber > openAtNumber;
          })
          .map(h => {
            return { value: h, label: h }
          });

        return hours;
      }

      return [];
    },
    onRowClick(day) {
      const d = this.days.filter(d => d.day === day)[0];
      if (!d.isOpen) this.$emit('onToggleDay', { day: d.day, value: true });
    },
    onAvailable() {
      this.$emit('onToggle', !this.available);
    },
    onOpen(day) {
      const d = this.days.filter(d => d.day === day)[0];
      this.$emit('onToggleDay', { day: d.day, value: !d.isOpen });
    },
    onOpen24(day) {
      const d = this.days.filter(d => d.day === day)[0];
      this.$emit('onToggleOpen24', { day: d.day, value: !d.isDay24 });
    },
    onChange(value, day, type) {
      this.$emit('onChange', { value, day, type });
    }
  },
  computed: {
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  }
}
</script>

<style lang="scss" scoped>
.hours {
  &-availability {
    &-checkbox {
      &:checked {
        + label {
          .svg {
            opacity: 1;
          }
        }
      }

      + label {
        display: block;
        position: relative;
        width: 100%;
        padding: 8px 32px;
        cursor: pointer;
        transition: color .3s $ease-out-quart;
        font-size: rem(16px);

        &::before {
          content: '';
          position: absolute;
          top: 7px;
          left: 0;
          width: 20px;
          height: 20px;
          border: 1px solid rgba($grey, .4);
          border-radius: 3px;
        }

        .svg {
          position: absolute;
          top: 14px;
          left: 5px;
          transition: opacity .3s $ease-out-quart;
          opacity: 0;
        }
      }
    }
  }

  &-table {
    margin-top: 12px;
  }

  &-row {
    display: flex;
    align-items: center;
    border: 1px solid $lightestgrey;
    border-bottom: 0;

    &:first-child {
      border-radius: 3px 3px 0 0;
    }

    &:last-child {
      border-radius: 0 0 3px 3px;
      border-bottom: 1px solid $lightestgrey;
    }

    &--head {
      padding-top: 10px;
      padding-bottom: 10px;
      color: $mediumgrey;
      font-family: $secondary;
      font-size: rem(16px);
      line-height: (22 / 16);
    }

    &--disabled {
      cursor: pointer;

      .hours-cell {
        pointer-events: none;

        .hours-cell-checkbox:not(.hours-cell-checkbox--disabled) + label {
          color: rgba($snap-primary, .3);

          .svg { opacity: 0; }
        }

        .select { opacity: .3; }
      }
    }
  }

  &-cell {
    display: flex;
    align-items: center;
    padding: 12px 0 12px 20px;

    &:not(:first-child) {
      width: 250px;
    }

    &:first-child {
      width: 300px;
      padding-left: 30px;
    }

    // Checkbox cell
    &-checkbox {
      + label {
        display: block;
        position: relative;
        width: 100%;
        padding: 8px 32px;
        cursor: pointer;
        transition: color .3s $ease-out-quart;
        font-size: rem(16px);

        &::before {
          content: '';
          position: absolute;
          top: 7px;
          left: 0;
          width: 20px;
          height: 20px;
          border: 1px solid rgba($grey, .4);
          border-radius: 3px;
        }

        .svg {
          position: absolute;
          top: 14px;
          left: 5px;
          transition: opacity .3s $ease-out-quart;
        }
      }

      &--small {
        &:checked {
          +  label {
            .svg {
              opacity: 1;
            }
          }
        }

        + label {
          font-family: $secondary;
          color: $snap-text-primary;
          font-size:  rem(16px);

          &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            width: 18px;
            height: 18px;
            border: 1px solid rgba($grey, .4);
            border-radius: 3px;
          }

          .svg {
            position: absolute;
            top: 13px;
            left: 6px;
            opacity: 0;
            transition: opacity .3s $ease-out-quart;
          }
        }
      }
    }

    // Select cell
    &-separator {
      margin-left: 19px;
      width: 10px;
      height: 1px;
      background-color: $grey;
    }

    .select {
      width: 200px;
      transition: opacity .3s $ease-out-quart;
    }
  }
}
</style>

<style lang="scss">
.hours {
  .hours-row {
    &:not(.hours-row--disabled) {
      &.hours-row--open {
        .select {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
  }

  .hours-cell {
    .select {
      &.select--open {
        z-index: 2;
      }

      .custom-select {
        padding: 0;

        strong {
          padding: 12px 16px 10px;
        }
      }
    }
  }
}
</style>