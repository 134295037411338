<template>
  <div class="features-list">
    <feature
      v-for="(feature, index) in features"
      :key="`feature-${index}`"
      :index="index"
      :feature="feature"
      @rating="$emit('rating', $event)"
      @feature="$emit('feature', $event)"
    />
  </div>
</template>

<script>
import Feature from '@/components/elements/Feature';

export default {
  components: {
    Feature
  },
  props: {
    features: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.features-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  .feature {
    width: calc(50% - 13px);

    &:nth-child(n + 3) {
      margin-top: 24px;
    }
  }
}
</style>
