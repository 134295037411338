<template>
  <aside 
    class="modal"
    :class="isVisible ? 'modal--visible' : ''"
  >
    <div class="modal-container">
      <div class="abbr"><span>{{$t('parkings.modal.parking_company.abbr')}}</span></div>
      <h3 class="h4">
        {{$t('parkings.modal.parking_company.title')}}
      </h3>
      <NewSelect
        :name="'securityLevel'"
        :options="companies"
        :placeholder="$t('parkings.modal.parking_company.select')"
        :value="id"
        :allowNull="true"
        :class="'classic'"
        @onChange="handleChange"
      />
    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        class="button button-primary"
        @click="onClick"
      >
        {{$t('parkings.modal.buttons.cancel')}}
      </button>
      <button-save 
        :loading="loading"
        :labels="{
          default: $t('parkings.modal.buttons.confirm'),
          end: $t('parkings.modal.buttons.saved')
        }"
        :class="'button-primary'"
        @click="onSubmit"
        :disabled="disableSnapReleaseButtons"
      />
    </div>
    <div class="modal-backdrop" @click="onClick"></div>
  </aside>
</template>

<script>
import NewSelect from '@/components/elements/NewSelect';
// import Select from '@/components/elements/Select';
import ButtonSave from '@/components/elements/ButtonSave';

export default {
  name: "Modal",
  components: {
    NewSelect,
    // Select,
    ButtonSave
  },
  data() {
    return {
      company: null,
      loading: false
    }
  },
  watch: {
    isVisible() {
      this.loading = false;
    }
  },
  computed: {
    isVisible() {
      return this.$store.state.parkings.modal.visible;
    },
    id() {
      return this.$store.state.parkings.modal.company;
    },
    parking() {
      return this.$store.state.parkings.modal.id;
    },
    companies() {
      const companies = this.$store.state.companies.list.map(c => {
        return {
          value: c.id,
          label: c.name,
        };
      });
      return [{
        value: 0,
        label: this.$t('globals.unassigned')
      }, ...companies];
    },
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  },
  methods: {
    onClick() {
      this.$store.commit('parkings/updateModal', {id: null, company: null});
    },
    onClose(evt) {
      const {target} = evt;
      if(target === this.$el) this.onClick(); 
    },
    handleChange(value) {
      this.company = value;
      this.$store.commit('parkings/updateModalProperty', {
        property: 'company', value
      });
    },
    onSubmit() {
      if(this.selected === null) return;
      this.loading = true;
      
      const update = this.$store.dispatch('parkings/updateAssignCompany', {
        id: this.parking,
        item: {
          parkingCompanyId: this.company
        }
      });

      update.then((promise) => {
        this.loading = 'finish';
        this.$store.dispatch('parkings/getParkings');
        // this.$store.dispatch('companies/set')
        this.onClick();
      })
    }
  }
}
</script>
