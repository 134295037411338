// import { createApp } from 'vue'
// import App from './App.vue'
// import './registerServiceWorker'
// import router from './router'
// import axios from "axios";

// axios.defaults.headers.common['X-Api-Key'] = 'wiv3bxaUu3CiudohUfdGkvHkoZ3TV3er';

// createApp(App).use(router).mount('#app')

import { createApp } from 'vue'
import App from './App.vue'

import './registerServiceWorker'
import { 
  applyPolyfills,
  defineCustomElements
} from '@aws-amplify/ui-components/loader';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import router from './router';
import store from './store';
import i18n from '@/i18n';
import Pagination from 'v-pagination-3';
// import close from './directive';

Amplify.configure(awsconfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});
router.beforeEach((to, from, next) => {
  if(from.meta.bodyClass) document.body.classList.remove(from.meta.bodyClass)
  if(to.meta.bodyClass) document.body.classList.add(to.meta.bodyClass)
  next();
})

const app = createApp(App)
      app.config.isCustomElement = tag => tag.startsWith('amplify-');
      app.component('v-pagination', Pagination)
      app.use(store)
      app.use(router)
      app.use(i18n);
      // app.directive('close', close);
      app.mount('#app')