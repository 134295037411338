import axios from 'axios';
import i18n from '../i18n';
import { Auth } from 'aws-amplify';
import {
  getUser,
  updateUser
} from '@/calls/user';


const user = {
  namespaced: true,
  state: {
    profile: [],
    sidebar: true,
    burgerClick: false,
    notifications: [
      {},
      {},
      {},
      {},
    ],
    filters: [],
    modal: false,
    driver: null,
    opt: {
      per: 10,
      page: 1
    },
    initialForm: {
      firstname: null,
      lastname: null,
      email: null,
      locale: null
    },
    form: {
      firstname: null,
      lastname: null,
      email: null,
    },
    password: {
      current: null,
      new: null,
      confirm: null
    },
    errors: {
      form: null,
      password: null,
    },
    requests: {
      form: 0,
      password: 0
    },
    userProfile: null
  },
  mutations: {
    updateUser(state, user) {
      state.profile = user;
    },
    updatePagination(state, opt) {
      state.opt = opt;
    },
    updateSidebar(state) {
      state.sidebar = !state.sidebar;
    },
    updateBurger(state) {
      state.burgerClick = !state.burgerClick;
    },
    updateModal(state) {
      state.modal = !state.modal;
    },
    updateDriver(state, driver) {
      state.driver = driver;
    },
    updateUserProfile(state, user) {
      state.userProfile = user;
    },
    updateForm(state, form) {
      state.form = form;
    },
    updateInitialForm(state, form) {
      state.initialForm = form;
    },
    updateFormValue(state, { property, value }) {
      state.form[property] = value;
    },
    resetPassword(state) {
      state.password = {
        current: null,
        new: null,
        confirm: null
      }
    },
    updatePasswordValue(state, { property, value }) {
      state.password[property] = value;
    },
    updateErrors(state, { type, error }) {
      state.errors[type] = error;
    },
    resetErrors(state) {
      state.errors.form = null;
      state.errors.password = null;
    },
    increaseRequests(state, type = 'form') {
      state.requests[type]++;
    },
    decreaseRequests(state, type = 'form') {
      state.requests[type]--;
    },
  },
  actions: {
    setUser({ commit }, user) {
      user = {
        nickname: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
      }
      user.abbr = user.nickname ? user.nickname.charAt(0) : user.email;
      return commit('updateUser', user);
    },
    setGrid({commit}) {
      commit('updateBurger');
      commit('updateSidebar');
    },
    setSidebar({commit}) {
      return commit('updateSidebar');
    },
    setProfile({ commit, state, dispatch }) {
      commit('increaseRequests');
      const user = getUser();
      return user.then((promise) => {
        commit('updateUserProfile', promise);
        dispatch('setUser', promise.user);
        dispatch('setForm', promise.user);

        if (promise.user && promise.user.locale && promise.user.locale !== i18n.locale) {
          const locale = promise.user.locale ? promise.user.locale : 'en';
          i18n.global.locale = locale;
          axios.defaults.headers['Accept-Language'] = locale;
          window.localStorage.setItem('otra-locale', locale);
        }

        dispatch('settings/setSettings', ['languages'], { root: true });
        dispatch('settings/countries', null, { root: true });
        commit('decreaseRequests');
        return promise
      })
    },
    setForm({ commit }, user) {
      const form = {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        locale: user.locale
      }

      commit('updateInitialForm', {...form});
      commit('updateForm', form);
    },
    async updateUser({ state, commit, dispatch }) {
      const { form, initialForm } = state;
      dispatch('updateUser', {
        form, initialForm
      }, { root: true });
    },
    async updateUserLanguage({ state, dispatch }) {
      const { locale } = state.form;
      axios.defaults.headers['Accept-Language'] = locale;
      dispatch('updateUserLanguage', locale, { root: true });
      dispatch('translations/getTranslations', locale, { root: true });
    },
    async updatePassword({ state, commit, dispatch }) {
      commit('increaseRequests', 'password');
      const user = await Auth.currentAuthenticatedUser();
      const { password } = state;
      const { t } = i18n.global;

      try {
        const request = await Auth.changePassword(user, password.current, password.new);
        commit('resetPassword');
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.profile.password'),
          type: 'success'
        }], { root: true });
      } catch(e) {
        if (e.code === 'NotAuthorizedException' || e.code === 'LimitExceededException' || e.code === 'InvalidPasswordException') {
          commit('updateErrors', {
            type: 'password',
            error: t(`notifications.errors.${e.code}`)
          });
        } else {
          commit('updateErrors', {
            type: 'password',
            error: e.message
          });
        }
      }
      
      commit('decreaseRequests', 'password');
      return;
    }
  }
};

export default user;
