import axios from 'axios';

const get = (id) => {
  return axios.get(`/admin/parkings/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};
const add = (item) => {
  return axios.post(`/admin/parkings`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};
const list = async (search) => {
  return await axios.get(`/admin/parkings`, {
    params: { search }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const update = (id, item) => {
  return axios.patch(`/admin/parkings/${id}`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const assign = (id, item) => {
  return axios.patch(`/admin/parkings/${id}/assign`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const getParking = (id) => {
  // return axios.get(`/admin/parkings/${id}`) => translation not returned
  return axios.get(`/admin/parkings/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const patchParking = (id, item) => {
  return axios.patch(`/admin/parkings/${id}`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    let err = error.response.data;

    if (error?.response?.data?.errors && error?.response?.data?.errors['ParkingDescriptions[0].Description']) {
      err = error.response.data.errors['ParkingDescriptions[0].Description'];
    } else {
      if (error?.response?.data) {
        err = error.response.data;
      }
    }

    return {
      hasError: true,
      error: err
    }
  });
};

// Commodities
const getCommodities = (id) => {
  return axios.get(`/admin/parkings/${id}/commodities`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const patchCommodities = (id, datas) => {
  return axios.patch(`/admin/parkings/${id}/commodities`, datas)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    };
  });
};

// Medias
const getMedias = async (id) => {
  try {
    const request = await axios.get(`/admin/parkings/${id}/medias`);
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const saveMedias = async (id, ids) => {
  try {
    const request = await axios.post(`/admin/parkings/${id}/media/order`, { ids });
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const uploadMedia = async (id, file) => {
  const form = new FormData();
  form.append('file', file.file);

  try {
    const request = await axios.post(`/admin/parkings/${id}/media`, form);
    return request.data.id;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
}

const deleteMedia = async (parkingId, fileId) => {
  try {
    const request = await axios.delete(`/admin/parkings/${parkingId}/media/${fileId}/delete`);
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const download = async () => {
  try {
    const request = await axios.get('/admin/parkings/download', { responseType: 'arraybuffer', });
    return request;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const getRulesets = async () => {
  return await axios.get(`/admin/rulesets/select`)
    .then((promise) => {
      if(promise.status === 200) return promise.data
    }).catch(error => {
      console.log('error', error);
      return {
        hasError: true,
        error
      }
    });
};

const getCertificates = async (parkingId) => {
  return await axios.get(`/admin/parkings/${parkingId}/certificates`)
    .then((promise) => {
      if(promise.status === 200) return promise.data
    }).catch(error => {
      return {
        hasError: true,
        error
      }
    });
};

export {
  list,
  get,
  add,
  update,
  assign,
  download,
  getParking,
  patchParking,
  getCommodities,
  patchCommodities,
  getMedias,
  saveMedias,
  uploadMedia,
  deleteMedia,
  getRulesets,
  getCertificates
}
