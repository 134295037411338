<template>
  <article class="page">
    <h1 class="page-title">{{ title }}</h1>
    <Components :components="components" />
  </article>
</template>

<script>
import Components from '@/components/partials/Components.vue';

export default {
  name:"Help",
  components: { 
    Components
  },
  data() {
    return {
      title: this.$t("help.title"),
      components: {
        TwoColumns: [
          {
            title: this.$t("help.two_columns[0].title"),
            content: this.$t("help.two_columns[0].content"),
            phone: this.$t("help.two_columns[0].phone"),
            mail: this.$t("help.two_columns[0].mail")
          },
          {
            title: this.$t("help.two_columns[1].title"),
            subtitle: this.$t("help.two_columns[1].subtitle"),
            content: this.$t("help.two_columns[1].content"),
            phone: this.$t("help.two_columns[1].phone"),
            mail: this.$t("help.two_columns[1].mail")
          }
        ],
        Faqs: {
          title: this.$t("help.faqs.title"),
          questions: [
            {
              title: this.$t("help.faqs.questions[0].title"),
              content: this.$t("help.faqs.questions[0].content")
            },
            {
              title: this.$t("help.faqs.questions[1].title"),
              content: this.$t("help.faqs.questions[1].content")
            },
            {
              title: this.$t("help.faqs.questions[2].title"),
              content: this.$t("help.faqs.questions[2].content")
            },
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .page {
    padding: 40px 0;

    &-title {
      font-size: rem(34px);
      letter-spacing: 0;
      font-weight: 400;

      transform: translate(-100px);
    }
  }
</style>