import { list } from '@/calls/fleet-planners';

const fleetPlanners = {
  namespaced: true,
  state: {
    requests: 0,
    search: null,
    items: [],
    order: {
      type: 'name',
      dir: 'asc'
    }
  },
  mutations: {
    updateItems(state, items) {
      state.items = items;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    updateOrder(state, type) {
      if (state.order.type === type) {
        state.order.dir = state.order.dir === 'asc' ? 'desc' : 'asc';
        return;
      }

      state.order.type = type;
      state.order.dir = 'asc';
    },
    increaseRequest(state) {
      state.requests++;
    },
    decreaseRequest(state) {
      state.requests--;
    }
  },
  actions: {
    // updateOrder({ state, commit }, newOrder) {
    //   commit('updateOrder', newOrder);

    //   const { order, items } = state;
    //   const ordereds = items.sort((a, b) => {
    //     if(a[order.type] < b[order.type]) return order.dir === 'asc' ? -1 : 1;
    //     if(a[order.type] > b[order.type]) return order.dir === 'asc' ? 1 : -1;
    //     return 0;
    //   });

    //   commit('updateItems', ordereds);
    // },
    async set({ state, commit, dispatch }) {
      commit('increaseRequest');
      const search = state.search && state.search.length ? state.search : null;
      let planners = await list(search);

      if (planners.hasError) {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting planners : ${planners.error}`,
          type: 'error'
        }], { root: true });
      } else {
        planners = planners.map(p => {
          p.fleetName = p?.fleet?.name ? p.fleet.name.toLowerCase() : '';
          p.fullName = p?.user?.fullName ? p.user.fullName.toLowerCase() : '';
          return p;
        });
        commit('updateItems', planners);
      }

      commit('decreaseRequest');
    }
  }
};

export default fleetPlanners;
