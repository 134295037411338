<template>
  <section class="main-container main-container__form">
    <form>
      <h2 class="h4">{{$t('company.form.title')}}</h2>
      <fieldset class="container-row container-5-5">
        <Inputs
          ref="name"
          type="text" 
          :label="$t('company.form.labels.company_name')"
          :error="error.name"
          :required="true"
          :name="'name'"
          :value="form.name"
          @input="handleInput"
        />
        <Inputs
          ref="vatnumber"
          type="text" 
          :label="$t('company.form.labels.vat')"
          :error="error.vatnumber"
          :required="true"
          :name="'vatnumber'"
          :value="form.vatnumber"
          @input="handleInput"
        />
      </fieldset>
      <fieldset class="container-row ">
        <Inputs
          ref="street"
          type="street" 
          :label="$t('company.form.labels.street')"
          :error="error.street"
          :required="true"
          :name="'street'"
          :value="form.street"
          @input="handleInput"
        />
      </fieldset>
      <fieldset class="container-row container-5-5">
         <Inputs
          ref="state"
          type="text" 
          :label="$t('company.form.labels.state')"
          :error="error.state"
          :required="false"
          :name="'state'"
          :value="form.state"
          @input="handleInput"
        />
        <Inputs
          ref="city"
          :label="$t('company.form.labels.city')"
          :error="error.city"
          :required="true"
          :name="'city'"
          :value="form.city"
          @input="handleInput"
        />
      </fieldset>
      <fieldset class="container-row container-5-5">
         <Inputs
          ref="zipCode"
          type="text" 
          :label="$t('company.form.labels.zip')"
          :error="error.zipCode"
          :required="true"
          :name="'zipCode'"
          :value="form.zipCode"
          @input="handleInput"
        />
        <Select
          ref="country"
          :name="'country'"
          :label="$t('company.form.labels.country')"
          :options="countries"
          :placeholder="form.country ? form.country :  $t('company.form.labels.country_select')"
          :error="error.country"
          :required="true"
          :class="'classic'"
          @change="handleChange"
        />
      </fieldset>
      <fieldset class="container-row container-5-5">
        <Inputs
          ref="email"
          type="email" 
          :label="$t('company.form.labels.email')"
          :error="error.email"
          :required="true"
          :name="'email'"
          :value="form.email"
          @input="handleInput"
        />
         <Inputs
          ref="phoneNumber"
          type="phoneNumber" 
          :label="$t('company.form.labels.phone')"
          :error="error.phoneNumber"
          :required="true"
          :name="'phoneNumber'"
          :value="form.phoneNumber"
          @input="handleInput"
        />
      </fieldset>

      <button-save 
        :loading="loading"
        :labels="{
          default: $t('company.form.buttons.save'),
          end: $t('company.form.buttons.saved')
        }"
        @click="onSubmit"
      />
        <!-- :class="{disabled: !canValidate}" -->
      <div 
        v-if="hasError"
        class="form--error small"
      >
        {{$t('notifications.errors.several_required')}}
      </div>
    </form>
  </section>
</template>

<script>

import {isValidPhoneNumber, parsePhoneNumber, ParseError} from 'libphonenumber-js'


import Inputs from '@/components/elements/Inputs';
import Select from '@/components/elements/Select';
import ButtonSave from '@/components/elements/ButtonSave';

export default {
  name: "newCompany",
  components: {
    Inputs,
    Select,
    ButtonSave
  },
  computed: {
    countries() {
      const countries = this.$store.state.settings.minimalCountries;
      countries.sort((a, b) => {
        if(a.label < b.label) return -1;
        if(a.label > b.label) return 1;
        return 0;
      });
      return countries;
    },
  },
  data() {
    return {
      inputs: {},
      loading: false,
      canValidate: false,
      hasError: false,
      notEmpty: [],
      form: {
        name: '',
        vatnumber: '',
        street: '',
        state: '',
        city: '',
        zipCode: '',
        country: '',
        email: '',
        phoneNumber: '',
      },
      error: {
        name: '',
        vatnumber: '',
        street: '',
        state: '',
        city: '',
        zipCode: '',
        country: '',
        email: '',
        phoneNumber: '',
      }
    };
  },
  mounted() {
    const { submit, ...refs } = this.$refs;
    this.inputs = Object.values(refs);
    this.submit = submit;
  },
  methods: {
    // On
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = true;
      this.hasError = false;
      this.error = {
        name: '',
        vatnumber: '',
        street: '',
        state: '',
        city: '',
        zipCode: '',
        country: '',
        email: '',
        phoneNumber: '',
      }
      this.handleValidation()
    },
    // Handle
    handleInput(evt) {
      const {target} = evt;
      this.form[target.name] = target.value;
      this.handleError();
    },
    handleChange(country) {
      if(country) {
        if(typeof country != 'string') country = country.target.value;
        this.notEmpty.push(true);
        this.form.country = country;
      }
      else this.notEmpty.push(false);

      this.handleError();
    },
    handleValidation() {
      const { email, phoneNumber, country, vatnumber } = this.form
      this.checkEmail(email);
      this.checkPhone(phoneNumber);
      this.checkCountry(country);
      this.checkVat(vatnumber);
      this.handleError()
      if(this.canValidate && !this.hasError) this.send()
      else this.loading = false;
      // TVA API 
      // const lastCheck = this.checkVat(vatnumber);
      // if(lastCheck) {
      //   lastCheck.then(() => {
      //     this.handleError()
      //     if(this.canValidate && !this.hasError) this.send()
      //     else this.loading = false;
      //   });
      // } else {
      //   this.handleError()
      //   this.loading = false;
      // }
    },
    handleError() {
      this.notEmpty = [];
      for (const ref in this.inputs) {
        const input = this.inputs[ref];
        if(input.required) this.notEmpty.push(input.required && input.$refs.input.value !== '');
        else this.notEmpty.push(true);
      }

      this.canValidate = this.notEmpty.length === this.inputs.length && this.notEmpty.indexOf(false) === -1;
    },
    // Check
    checkEmail(email) {
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.error.email = !email ? this.$t('notifications.errors.email_required') : "";
      if(!this.error.email) this.error.email = !regex.test(email) ? this.$t('notifications.errors.email_invalid') : "";
      if(this.error.email) this.hasError = true;
    },
    checkPhone(phoneNumber) {
      this.error.phoneNumber = !phoneNumber ? this.$t('notifications.errors.email_required') : '';

      try {
        parsePhoneNumber(phoneNumber);
      } catch(error) {
        if (error instanceof ParseError) {
          this.error.phoneNumber = this.$t('notifications.errors.phone_invalid')
        }
      }
      if(!this.error.phoneNumber) this.error.phoneNumber = !isValidPhoneNumber(phoneNumber) ? this.$t('notifications.errors.phone_invalid') : '';
      if(this.error.phoneNumber) this.hasError = true;
    },
    checkCountry(country) {
      this.error.country = !country ? this.$t('notifications.errors.country_required') : "";
      if(this.error.country) this.hasError = true;
    },
    checkVat(vat) {
      // Test for only digits and letters
      if(!vat.match(/^[A-z0-9]+$/)) this.error.vatnumber = this.$t('notifications.errors.vat_invalid');
      
      if(this.error.vatnumber) {
        this.hasError = true;
        return false;
      }
      else return true;
      
      // const testVat = this.$store.dispatch('settings/vat', {
      //   vatNumberCountryCode: chars,
      //   vatNumber: numbs
      // });
      // return testVat.then( promise => {

      //   if(!promise) this.error.vatnumber = this.$t('notifications.errors.vat_invalid');
      //   else if(!promise.valid) this.error.vatnumber = this.$t('notifications.errors.vat_invalid');
      //   else this.error.vatnumber = "";

      //   if(this.error.vatnumber) this.hasError = true;
      // })
    },
    async send() {
      const request = await this.$store.dispatch('companies/new', this.form);

      this.loading = "finish";

      if (!request.hasError) this.$router.push('/');

      // sent.then(() => {
      //   this.loading = "finish";
      //   this.$router.push('/');
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    padding-bottom: 50px;
  }
  .button {
    margin-top: 20px;
  }
</style>