<template>
  <tr>  
    <td><span class="grey">{{owner.user.firstname}}</span></td>
    <td><span class="grey">{{owner.user.lastname}}</span></td>
    <td><span class="grey">{{owner.user.email}}</span></td>
    <td class="td--buttons">
      <button
      v-if="showResendButton"
              class="button button--small"
              @click="resendActivationLink(owner.id)"
            >
              {{$t('globals.buttons.resend_activation_link')}}
            </button>
      <router-link class="button button--small" :to="{name: 'editOwner', params: { id:owner.id } }">{{ $t('owners.list.buttons.edit') }}</router-link>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'Owner',
  props: {
    owner: {
      type: Object,
      required: true
    },
    resendActivationLink: {
      type: Function,
      default() {
        return {}
      }
    },
    showResendButton: {
        type: Boolean,
        default: false
    }
  },
}
</script>
