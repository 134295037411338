<template>
  <div class="authentification-form">
    <h2 class="authentification__title authentification__titleForm">{{ $t('authentication.reset_password.title') }}</h2>
    <div>

      <!-- Global error -->
      <fieldset
        v-if="errors.global"
        class="fieldset-input__message"
        :class="{'error': errors.global}"
      >
        <p class="error-msg">{{errors.global}}</p>
      </fieldset>

      <!-- Username -->
      <Fieldset 
        :type="'email'" 
        :label="$t('authentication.labels.email')"
        :name="'email'"
        :required="true"
        :autocomplete="'new-password'" 
        :error="errors.email"
        :propValue="form.email"
        @input="onInput"
      /> 

      <!-- Code -->
      <Fieldset 
        :type="'text'" 
        :label="$t('authentication.labels.code')"
        :name="'code'"
        :required="true" 
        :autocomplete="'off'"
        :error="errors.code"
        @input="onInput"
      /> 

      <Fieldset 
        :type="'password'" 
        :label="$t('authentication.labels.new_password')"
        :name="'password'"
        :required="true" 
        :autocomplete="'new-password'"
        :error="errors.password || errors.moreThan || errors.uppercase || errors.character || errors.num"
        @input="onInput"
      /> 
      <Fieldset 
        :type="'password'" 
        :label="$t('authentication.labels.confirm_password')"
        :name="'confirmPassword'"
        :required="true" 
        :error="errors.confirmPassword"
        @input="onInput"
      /> 

      <ul class="tellmywhy">
        <li class="criterion" :class="{error: errors.moreThan, success: success.moreThan}">
         {{$t('authentication.reset_password.tellmewhy.characters')}}
          <Sprite :width="14" :height="15" type="criterion" />
        </li>
        <li class="criterion" :class="{error: errors.uppercase, success: success.uppercase}">
         {{$t('authentication.reset_password.tellmewhy.uppercase')}}
          <Sprite :width="14" :height="15" type="criterion" />
        </li>
        <li class="criterion" :class="{error: errors.character, success: success.character}">
         {{$t('authentication.reset_password.tellmewhy.special_char')}}
          <Sprite :width="14" :height="15" type="criterion" />
        </li>
        <li class="criterion" :class="{error: errors.num, success: success.num}">
         {{$t('authentication.reset_password.tellmewhy.numeric_char')}}
          <Sprite :width="14" :height="15" type="criterion" />
        </li>
      </ul>

      <!-- Submit -->
      <button 
        type="button" 
        @click="ResetPassword" 
        class="button no-spacing"
        :class="{disabled: hasError}"
      >
        {{$t('authentication.reset_password.button')}}
      </button>
    </div>
  </div>
</template>

<script>
import Fieldset from '@/components/modules/Authentication/Fieldset'
import Sprite from '@/components/elements/Sprite'

export default {
  name: 'ResetPassword',
  components: {
    Sprite,
    Fieldset
  },
  beforeMount() {
    const { code, email, newPasswordRequired } = this.$route.query;

    if (newPasswordRequired) {
      this.translations = {
        title: this.$t('authentication.reset_password.new_password.title'),
        code: this.$t('authentication.reset_password.new_password.code'),
        button: this.$t('authentication.reset_password.new_password.button')
      };
    }

    if (email) this.form.email = email.replace(' ', '+');
    if (code) this.form.code = code;
  },
  data() {
    return {
      translations: {
        title: this.$t('authentication.reset_password.new_password.title'),
        code: this.$t('authentication.reset_password.new_password.code'),
        button: this.$t('authentication.reset_password.new_password.button')
      },
      hasError: false,
      form: {
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
      },
    }
  },
  computed: {
    success() {
      return this.$store.state.authentication.resetPassword.success;
    },
    errors() {
      return this.$store.state.authentication.resetPassword.errors;
    }
  },
  methods: {
    onInput(evt) {
      const {target} = evt;
      this.form[target.name] = target.value;
      this.hasError = !this.form.email.trim() || !this.form.code.trim() || !this.form.password.trim() || !this.form.confirmPassword.trim()
    },
    checkCondition(password) {
      const form = 'resetPassword';
      const specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      const numeric = /^(?=.*\d)/
      const uppercase = /^(?=.*[A-Z])/

      // Errors
      this.$store.commit('updateErrors', { form, property: 'moreThan', error: password.trim().length < 8});
      this.$store.commit('updateErrors', { form, property: 'character', error: !specialChar.test(password)});
      this.$store.commit('updateErrors', { form, property: 'num', error: !numeric.test(password)});
      this.$store.commit('updateErrors', { form, property: 'uppercase', error: !uppercase.test(password)});

      // Success
      this.$store.commit('updateSuccess', { form, property: 'moreThan', success: password.trim().length > 8});
      this.$store.commit('updateSuccess', { form, property: 'character', success: specialChar.test(password)});
      this.$store.commit('updateSuccess', { form, property: 'num', success: numeric.test(password)});
      this.$store.commit('updateSuccess', { form, property: 'uppercase', success: uppercase.test(password)});
    },
    checkEmail(email) {
      const form = 'resetPassword';
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

       this.$store.commit('updateErrors', {
        form, property: 'email', error: !email.trim() ? this.$t('notifications.errors.authentication.email_required') : ''
      });

      if(!this.errors.email) {
        this.$store.commit('updateErrors', {
          form, property: 'email', error: !regex.test(email) ?  this.$t('notifications.errors.authentication.email_invalid') : ''
        });
      }
    },
    checkPassword(password, confirmPassword) {
      const form = 'resetPassword';
      this.$store.commit('updateErrors', {
        form, property: 'confirmPassword', error: confirmPassword != password ? this.$t('notifications.errors.authentication.passwords_not_the_same') : ''
      });
    },
    async ResetPassword() {
      const form = 'resetPassword';
      const { email, password, confirmPassword, code } = this.form

      this.checkEmail(email)
      this.checkCondition(password)
      this.checkPassword(password, confirmPassword)

      this.$store.commit('updateErrors', { form, property: 'global', error: '' });

      if(
        this.errors.email 
        || this.errors.code 
        || this.errors.password 
        || this.errors.confirmPassword 
        || this.errors.moreThan 
        || this.errors.character
        || this.errors.num
        || this.hasError
      ) return;

      const { newPasswordRequired } = this.$route.query;
      if (newPasswordRequired) {
        this.changePassword();
        return;
      }

      this.$store.dispatch('resetPassword', {
        email, password, code
      });
    },
    changePassword() {
      const { email, password, code } = this.form;
      this.$store.dispatch('changePassword', {
        username: email,
        password,
        code
      });
    },
  }
}
</script>

<style lang="scss">
  .authentification-form {
    .button {
      transition: opacity .3s $ease-out-quad;
    }
  }
  .no-spacing {
    width: 100%;
    font-size: rem(14px);
    text-transform: uppercase;
    padding: 15px 30px;
  }
  .hr-h {
    width: 100%;
    height: 1px;
    margin: 20px 0;
  }
  .disabled {
    opacity: .5;
    pointer-events: none;
  }
</style>
