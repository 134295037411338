<template>
    <div class="parking-partner">
        <section class="main-filters">
            <div class="quicksearch"><input type="search" ref="input" :value="value" @input="onInput"
                    :placeholder="$t('partner.list.search_parking')">
            </div>
        </section>
        <table class="table">
            <thead>
                <tr>
                    <th class="th">
                        <div class="thead" @click="changeOrder('parkingid')">
                            {{ this.$t('partner.list.columns.id') }}
                            <span :class="['th-order', `${'parkingid' === orderType ? direction : ''}`]">
                                <Sprite :type="'chevron-up'" :width="16" :height="8" />
                                <Sprite :type="'chevron-down'" :width="16" :height="8" />
                            </span>
                        </div>
                    </th>
                    <th class="th">
                        <div class="thead" @click="changeOrder('parkingname')">
                            {{ this.$t('partner.list.columns.parking_name') }}
                            <span :class="['th-order', `${'parkingname' === orderType ? direction : ''}`]">
                                <Sprite :type="'chevron-up'" :width="16" :height="8" />
                                <Sprite :type="'chevron-down'" :width="16" :height="8" />
                            </span>
                        </div>
                    </th>
                    <th class="th">
                        <div class="thead" @click="changeOrder('countryname')">
                            {{ this.$t('partner.list.columns.country') }}
                            <span :class="['th-order', `${'countryname' === orderType ? direction : ''}`]">
                                <Sprite :type="'chevron-up'" :width="16" :height="8" />
                                <Sprite :type="'chevron-down'" :width="16" :height="8" />
                            </span>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
        </table>
        <div class="scrollit" @scroll="handleScroll">
            <table class="table">
                <tbody>
                    <tr v-if="parkings.length === 0 && !showLoader">
                        <td colspan="4" class="center">
                            {{ $t('partner.list.no_parking') }}
                        </td>
                    </tr>

                    <tr class="hover" v-for="parking in parkings" :key="parking.id">
                        <td><span class="grey">{{ parking.parkingId }}</span></td>
                        <td><span class="grey">{{ parking.parkingName }}</span></td>
                        <td><span class="grey">{{ parking.countryName }}</span></td>
                        <td>
                            <a class="button button--small no-expanded" type="button" @click="onDelete(parking)">
                                {{ this.$t('partner.list.buttons.remove') }}
                            </a>

                        </td>
                    </tr>
                    <tr v-if="showLoader">
                        <td colspan="4">
                            <div class="center">
                                <loader />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Popup v-if="confirmation">
            <template v-slot:content>
                <h4 class="popup-title">{{ $t('globals.popup.remove_parking') }}</h4>
                <div class="popup-actions">
                    <button type="button" class="button button-popup button-border" @click.prevent="onPopupClose">
                        {{ $t('globals.no') }}
                    </button>
                    <button type="submit" class="button button-popup" @click.prevent="onPopupConfirm">
                        {{ $t('globals.yes') }}
                    </button>
                </div>
            </template>
        </Popup>
    </div>
</template>

<script>
import Loader from '@/components/elements/Loader';
import Sprite from '@/components/elements/Sprite';
import Popup from '@/components/partials/Popup';
import axios from 'axios';
export default {
    name: 'TableParkingPartner',
    components: {
        Loader,
        Sprite,
        Popup
    },
    data() {
        return {
            showLoader: false,
            loading: false,
            orderType: null,
            direction: null,
            axiosSource: null,
            selectedItem: null,
            parkings: [],
            currentPage: 0,
            stopLoad: false,
            value: '',
            confirmation: false,
            items: []
        }
    },
    beforeMount() {
        this.onSearch();
    },
    props: {
        onSelected: {
            type: Object
        },
        id: {
            type: Number
        },
        refresh: {
            type: Number
        }
    },
    computed: {

    },
    watch: {
        id(newValue) {
            if (newValue)
                this.onSearch()
        },
        refresh(newValue) {
            this.currentPage = 0;
            this.parkings = [];
            if (newValue)
                this.onSearch()
        }
    },
    methods: {
        changeOrder(property) {
            if (this.orderType != property)
                this.direction = null;
            this.orderType = property;
            if (this.direction === 'asc')
                this.direction = "desc"
            else
                this.direction = "asc"
            this.parkings = [];
            this.stopLoad = false;
            this.currentPage = 0;
            this.onSearch();

        },
        handleScroll(event) {
            const scrollBottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight;
            if (scrollBottom <= 650 && !this.loading) {
                this.loadMoreData();
            }
        },
        async loadMoreData() {
            const self = this;
            if (this.stopLoad)
                return;
            this.showLoader = true;
            this.loading = true;
            await this.onSearch();
        },
        onPopupClose() {
            this.ownerId = '';
            this.confirmation = false;
        },
        async onPopupConfirm() {
            if (this.confirmation) {
                this.confirmation = false;
                this.showLoader = true;
                this.showLoader = false;
                const self = this;
                if (this.axiosSource) {
                    this.axiosSource.cancel();
                    this.axiosSource = null;
                }
                this.axiosSource = axios.CancelToken.source();
                return await axios.delete(`/admin/partners/${this.id}/parking/${this.selectedItem.parkingId}`)
                    .then((promise) => {
                        if (promise.status === 200) {
                            this.parkings = [];
                            this.currentPage = 0;
                            self.onSearch();
                            return promise.data
                        }
                    }).catch(error => {
                        console.error(error);
                    });
            }
            this.confirmation = false;
            this.ownerId = '';
        },
        async onDelete(item) {
            this.confirmation = true;
            this.selectedItem = item;
        }, async onInput(e) {
            const { value } = e.currentTarget;
            this.value = value;
            if (value.length > 1) {
                this.showPopup = true;
                this.showLoader = true;
                this.stopLoad = false;
                this.currentPage = 0;
                this.parkings = [];

                await this.onSearch();
            } else if (value.length === 0) {
                this.currentPage = 0;
                this.parkings = [];
                await this.onSearch();
            }
            if (this.maxLength && value.length > this.maxLength) {
                e.preventDefault();
                e.stopPropagation();
                this.$refs.input.value = this.value;
            }
        },
        async onSearch() {
            if (!this.id)
                return;
            const self = this;
            if (this.axiosSource) {
                this.axiosSource.cancel();
                this.axiosSource = null;
            }
            this.axiosSource = axios.CancelToken.source();
            this.showLoader = true;
            const pageSize = 20;
                        
            return await axios.get(`/admin/partners/${this.id}/parking/list`, {
                params: {
                    PageSize: pageSize,
                    CurrentPage: this.currentPage + 1,
                    SearchTerm: this.value,
                    OrderBy: this.orderType,
                    Sorting: this.direction
                },
                cancelToken: this.axiosSource.token,
            })
                .then((promise) => {
                    self.showLoader = false;
                    if (promise.status === 200) {
                        self.parkings = [...self.parkings, ...promise.data.datas]
                        this.$emit('parkings-chaged', self.parkings);
                        self.currentPage = self.parkings.length / pageSize;
                        if (promise.data.datas.length != pageSize)
                            self.stopLoad = true;
                        self.loading = false;
                        return promise.data
                    }
                }).catch(error => {
                    self.showLoader = false;
                });
        }
    }
}
</script>


<style lang="scss" scoped>
.parking-partner {
    width: 100%;

    .loader {
        width: 2rem;
        height: 2rem;
        min-height: 2rem;
        margin: 1rem 1rem;
    }

    .scrollit {
        max-height: 300px;
        overflow: auto;
    }

    table {
        width: 100%;
        font-size: .875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        border: none;
        max-height: 300px;

        tr {

            th,
            td {
                width: 33%;
            }

        }

        .thead {
            display: flex;
        }

        .button {
            &--small {
                padding: 5px 16px;
                width: 50px;
                min-width: 127px !important;
            }
        }

        .center {
            text-align: center;
            vertical-align: middle;
            width: 32px;
            margin: auto;
        }

        .th {
            cursor: pointer;

            &-wrapper {
                display: flex;
                align-items: center;
            }

            &-order {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 8px;

                &.asc {
                    .svg-chevron-up {
                        fill: $mediumgreen;
                    }
                }

                &.desc {
                    .svg-chevron-down {
                        fill: $mediumgreen;
                    }
                }

                .svg {
                    fill: $grey;

                    &:not(:first-child) {
                        margin-top: 3px;
                    }
                }
            }
        }
    }

}
</style>