import {
  list,
  add,
  get,
  update,
  resendActivationLink
} from '@/calls/owners';
import i18n from '../i18n';
import Constants from '../helpers/constants'

const owners = {
  namespaced: true,
  state: {
    requests: 0,
    search: null,
    list: [],
    modal: {
      visible: false,
      id: null
    },
    order: {
      type: 'firstname',
      dir: 'asc'
    }
  },
  mutations: {
    updateOwners(state, els) {
      state.list = els;
    },
    updateOrder(state, type) {
      if (state.order.type === type) {
        state.order.dir = state.order.dir === 'asc' ? 'desc' : 'asc';
        return;
      }

      state.order.type = type;
      state.order.dir = 'asc';
    },
    updateModal(state, id) {
      state.modal.visible = !state.modal.visible;
      state.modal.id = id;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    increaseRequest(state) {
      state.requests++;
    },
    decreaseRequest(state) {
      state.requests--;
    }
  },
  actions: {
    set({ state, commit }) {      
      commit('increaseRequest');
      const search = state.search && state.search.length ? state.search : null;
      const owners = list(search);
      return owners.then((promise) => {
        commit('updateOwners', promise);
        commit('decreaseRequest');
        return promise;
      }).catch(error => {
        // console.log(error);
      });
    },
    new({commit, dispatch}, item) {
      
      item.createdFromApp = Constants.SOURCE_APP; 

      const owner = add(item)
      return owner.then(promise => {        
        if (promise.error) {
          const data = promise?.error?.response?.data;

          if (data && data === 'user-already-exists') {
            dispatch('notifications/addNotifs', [{
              text: `Error while creating owner : The user already exists`,
              type: 'error'
            }], { root: true });
            return { noReload: true };
          }

          dispatch('notifications/addNotifs', [{
            text: `Error while creating owner : ${promise.error}`,
            type: 'error'
          }], { root: true });

          return { noReload: true };
        }

        this.dispatch('owners/set')
      })
    },
    update({commit}, obj) {      
      const owner = update(obj)     
      return owner.then(promise => {             
        if(promise.hasError) {
          const { t } = i18n.global;

          this.dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.update_owner',{ error: promise?.error?.response?.data?.detail ? promise?.error?.response?.data?.detail :  promise.error }),
            type: 'error'
          }], { root: true });

          return { noReload: true };

        } 
        else{
          this.dispatch('owners/set')
        }
        
      })
    },
    get({commit}, id) {
      const owner = get(id)
      return owner.then(promise => {
        return promise
      })
    },
    search({ dispatch }, terms) {
      if (!terms || !terms.length) {
        dispatch('set');
        return;
      }

      // TO DO
      const owners = list();
      return owners.then((promise) => {
        commit('updateOwners', promise);
        return promise;
      }).catch(error => {
      });
    },
    async resendActivationLink({ commit, state, dispatch }, id) {
       const request = await resendActivationLink(id);
       const { t } = i18n.global;

      if(!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.resend_activation_link'),
          type: 'success'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.resend_activation_link', { error: request.error }),
          type: 'error'
        }], { root: true });
      }
      return request;
    }
  }
};

export default owners;
