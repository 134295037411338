<template>
  <div>
    <button>
      <time @click="onClick" class="h4" datetime="{{today}}">
        {{ today }}
        <Sprite type="arrow" :width="15" :height="10"/>
      </time>
    </button>

    <!-- Calendar -->
    <Calendar 
      @change="handleDates"
      @close="onClick"
      :isVisible="calendarVisibility"
    />
  </div>
</template>

<script>
import moment from 'moment';

import Sprite from '@/components/elements/Sprite.vue';
import Calendar from '@/components/modules/Calendar.vue';

export default {
  name: 'DateCalendar',
  components: {
    Sprite,
    Calendar,
  },
  computed: {
    filtersStored() {
      return []
      // return this.$store.state.bookings.filters;
    }
  },
  data() {
    return {
      today: moment().format("DD[.]MM[.]YYYY"),
      calendarVisibility: false,
      filters: {}
    }
  },
  watch: {
    $route() {
      this.today = moment().format("DD[.]MM[.]YYYY");
    }
  },
  mounted() {
    this.filters = this.filtersStored;
  },
  methods: {
    // ON
    onClick() {
      this.calendarVisibility = !this.calendarVisibility
    },
    handleDates(dates, end = '') {
      this.filters.dates = dates;

      const start = moment(dates.start).format("DD[.]MM[.]YYYY");
      if(dates.end && dates.start != dates.end) end = `${moment(dates.end).format("DD[.]MM[.]YYYY")}`;
      this.today = start + `${end ? ' - ' + end : ''}`;

      this.$store.commit('updateDynamicFilters', this.filters);
    },
    // HANDLE
  }
}
</script>

