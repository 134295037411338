<template>
  <section class="topbar">
    <div>
      <h1 class="title">{{ title }}</h1>
      <!-- <h2 class="subtitle">{{user.nickname}}</h2> -->
    </div>
    <div class="topbar--left">
      <!-- <Notifications />
      <hr> -->
      <Languages />
      <hr>
      <Profile />
    </div>

  </section>
</template>

<script>
import Profile from '@/components/modules/Header/Profile.vue';
import Languages from '@/components/modules/Header/Languages.vue';
// import Notifications from '@/components/modules/Header/Notifications.vue';

export default {
  name: 'Topbar',
  components: {
    // Notifications,
    Languages,
    Profile,
  },
  data() {
    return {
      // user: null,
      title: this.$t('globals.title')
    }
  }, 
  computed: {
    // user() {
    //   return this.$store.state.user.profile;
    // }
  }
}
</script>
