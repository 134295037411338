<template>
  <header class="header">
    <div class="header--left">
      <Navigation />
      <Sprite 
        :type="'logo'"
        :width="101"
        :height="49"
      />
    </div>
    <div class="header--right">
      <Topbar />
      <Tabs />
    </div>
    <!-- <Subbar v-if="this.$route.meta.parent === 'parking'" /> -->
  </header>
</template>

<script>
import Topbar from '@/components/partials/Topbar.vue';
import Tabs from '@/components/partials/Tabs.vue';
// import Subbar from '@/components/partials/Subbar.vue';
import Navigation from '@/components/modules/Header/Navigation.vue';

import Sprite from '@/components/elements/Sprite.vue';

export default {
  name: 'Header',
  components: {
    Topbar,
    Tabs,
    // Subbar,
    Navigation,
    Sprite,
  }
}
</script>
