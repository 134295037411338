<template>
  <aside 
    ref="modal"
    class="modal"
    :class="visible ? 'modal--visible' : ''"
  >
    <div class="modal-wrapper">
      <div class="modal-container">
        <div
          v-if="abbr"
          class="abbr"
        >
          <span>{{ abbr }}</span>
        </div>
        <h3 class="h4">{{ title }}</h3>
        <Select
          ref="Owner"
          :name="$t('company.modal.owner.label')"
          :options="options"
          :placeholder="$t('company.modal.owner.select')"
          :required="true"
          :value="value"
          :class="'classic'"
          @onChange="handleChange"
        />
      </div>
      <div class="modal-footer">
        <button
          type="button" 
          class="button button-primary"
          @click="onClose"
        >
        {{$t('company.modal.owner.cancel')}}
        </button>
        <button
          type="button" 
          class="button button-primary"
          @click="onSubmit"
          :disabled="true"
        >
        {{ $t('company.modal.owner.confirm') }}
        </button>
        <!-- <button-save 
          :labels="{
            
            end: $t('company.modal.owner.saved')
          }"
          :class="'button-primary'"
          @click="onSubmit"
        /> -->
      </div>

      <div
        v-show="loading"
        class="modal-loading"
      >
        <loader/>
      </div>
    </div>
    <div class="modal-backdrop" @click="onClose"></div>
  </aside>
</template>

<script>
import Loader from '@/components/elements/Loader';
import Select from '@/components/elements/NewSelect';
// import ButtonSave from '@/components/elements/ButtonSave';

export default {
  name: "Modal",
  components: { 
    Select,
    Loader
    // ButtonSave
  },
  props: {
    abbr: {
      type: String,
    },
    title: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [String,Number],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
  },
  // watch: {
  //   value(newVal) {
  //   }
  // },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    handleChange(value) {
      this.$emit('onChange', value);
    },
    onSubmit() {
      this.$emit('onSubmit');
    }
  },
  computed: {
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  }
}
</script>

