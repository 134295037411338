<template>
  <div
    v-if="appLoaded" 
    class="grid-dashboard grid--nosubbar"
    :class="{
      'grid--small': sidebar === false,
    }"
  >
    <Header />
    <Sidebar />
    <main 
      class="main"
      :class="[className, {
        'grid--main': quickSearchComponent || statusComponent || buttonComponent,
        'grid--main-nofilter': !quickSearchComponent && !statusComponent && !buttonComponent,
        'grid--main-nofooter': !quickSearchComponent && !statusComponent && !buttonComponent,
      }]"
    >
      <section class="main-header">
        <div class="main-header-left">
          <h2 v-if="title" class="h4 download">{{ $t(title) }}</h2>
          <span v-if="subtitle" class="main-header-subtitle">{{ $t(subtitle) }}</span>
          <DownloadButton v-if="downloadComponent"
          @click="onDownloadBalance($event)"  
          :loading="downloadReport"
          />
        </div>

        <Date v-if="dateComponent" />
        <DateCalendar v-if="calendarComponent" />
        <Available v-if="availableComponent" />

        
         

      </section>

      <Filters 
        v-if="statusComponent || quickSearchComponent || buttonComponent" 
        :class="{'main-filters--spaced': statusComponent && quickSearchComponent, 'main-filters--spaced-padding': quickSearchComponent && buttonComponent}"
        :status="statusComponent"
        :quickSearch="quickSearchComponent"
        :button="buttonComponent"
        :buttons="buttons"
      />
      
      <router-view/>
      <!-- <section 
        v-if="paginateComponent" 
        class="main-footer"
      >
        <Paginate />
      </section> -->
    </main>
    <Footer />
   

    <!-- MODAL -->
    <Modal />
    <Notifications />
  </div>
   <div
    v-else
    class="app-loader"
  >
    <h1 class="app-loader-title h4">{{ $t('globals.layout.loader') }}</h1>
    <Loader/>
  </div>
</template>

<script>
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import Sidebar from '@/components/common/Sidebar';

import Date  from '@/components/partials/Header/Date';
import DateCalendar  from '@/components/partials/Header/DateCalendar';
import Available  from '@/components/partials/Header/Available';

import Loader from '@/components/elements/Loader';
import Filters from '@/components/partials/Header/Filters';

import Modal from '@/components/modules/Modal';
import Notifications from '@/components/common/Notifications';
import DownloadButton from '@/components/elements/DownloadButton.vue';

export default {
  name: 'Layout',
  components: {
    Header,
    Sidebar,
    Footer,
    Date,
    DateCalendar, 
    Available,
    Filters,
    Modal,
    Notifications,
    Loader,
    DownloadButton
  },
 
  computed: {
    sidebar() {
      return this.$store.state.user.sidebar;
    },
    className() {
      return `main-${this.$route.name ? this.$route.name.toLowerCase() : ''}`;
    },
    title() {
      return this.$route.meta.titleTemplate;
    },
    subtitle() {
      if (this.$route.name === 'editParking') {
        const isDemo = this.$store.state.parking.data && this.$store.state.parking.data.status === 'Virtual';

        if (isDemo) {
          return 'pages.parking_list.edit.page_subtitle';
        }
      }

      return null;
    },
    dateComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Date : null;
    },
    calendarComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Calendar : null;
    },
    quickSearchComponent() {
      return this.$route.meta.components ? this.$route.meta.components.QuickSearch : null;
    },
    statusComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Status : null;
    },
    buttonComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Button : null;
    },
    buttons() {
      if (this.$route.name === 'Parkings') {
        return [
          {
            label: this.$t('parkings.list.buttons.download_list'),
            disabled: this.$store.state.parkings.downloadRequests !== 0,
            action: () => {
              this.$store.dispatch('parkings/download')
            }
          }
        ]
      }

      return [];
    },
    availableComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Available : null;
    },
    paginateComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Pagination : null;
    },
    context() {
      return this.$store.state.settings.context;
    },
    appLoaded() {
      const { context } = this.$store.state.settings;
      return context;
    },
    downloadComponent() {
      return this.$route.meta.parent === 'report' && this.$route.path === '/report';
    },
    downloadReport() {     
     return this.$store.state.parkings.downloadReport;
   },
  },
  watch: {
    context(value) {
      if (!value) return;
      if (!value.isAdmin) {
        this.$store.dispatch('notifications/addNotifs', [{
          text: this.$t('notifications.errors.account.wrong_plateform'),
          type: 'error',
          signout: true
        }], { root: true });
      } else {
        this.setSettings();
      }
    },
  },
  methods: {
    setSettings() {
      this.$store.dispatch('translations/getTranslations');
      this.$store.dispatch('settings/countries');
    },
    onDownloadBalance(evt){
     
      if (evt) {
          evt.preventDefault();           
          this.$store.dispatch('parkings/downloadReport')
      }
    }

  }
}
</script>

<style lang="scss">
  /* SCSS */
  .app-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .app-loader-title {
      margin-bottom: 24px;
      margin-top: -24px;
      letter-spacing: 0;
    }
  }
  .download {
    align-self: center;
  }
</style>