import axios from 'axios';

const list = async (search) => {
  return await axios.get(`/admin/partners`, {
    params: { search }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const add = async (item) => {
  return await axios.post(`/admin/partners`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    // console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const update = async (id, item) => {
  return await axios.patch(`/admin/partners/${id}`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
     console.log(error);
     return {
      hasError: true,
      error
    }
  });
};

const get = async (id) => {
  return await axios.get(`/admin/partners/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

export {
  list,
  add,
  update,
  get
}
