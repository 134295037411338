<template>
  <div 
    class="input"
    :class="[{ 'error': error }, { 'deactivate': hasActivation && !isActive }]"
  >
    <!-- :class="error ? 'error' : '' " -->
    <label 
      class="label small"
      :class="{'no-label': !label, 'has-count': counting}"
    >
      {{ label ? label : name }}

      <span
        v-if="counting"
        :class="[
          'input-count',
          { 'has-warning': count <= 10 && count > 5 },
          { 'has-error': count <= 5 }
        ]"
      >
        <span class="input-count-number">{{ count }}</span>
        <!-- characters left -->
      </span>

      <span
        v-if="hasActivation"
        class="input-activation"
      >
        <input
          type="checkbox"
          :checked="isActive"
          :id="`activation-${name}`"
          class="u-visually-hidden"
          @input.stop="onActivation"
        />
        <label :for="`activation-${name}`">
          <Sprite
            :type="'check'"
            :width="14"
            :height="10"
          />
        </label>
      </span>
    </label>

    <textarea
      v-if="type === 'textarea'"
      ref="input"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      class="input-el"
      @input="$emit('input')"
      :readonly="readonly"
    ></textarea>
    <input
      v-else
      ref="input"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      :min="min"
      :max="max"
      :step="step"
      class="input-el"
      @input="onInput"
      :readonly="readonly"
    />
    <strong 
      v-if="error"
      class="small error-msg"
    >
      <span>{{ error }}</span>
      
    </strong>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  name: 'Inputs',
  components: {
    Sprite,
  },
  props: {
    label: {
      type: String,
      default: null
    },  
    type: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      // default: null
    },  
    error: {
      type: String,
      default: null
    },
    validation: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String, Number],
    },
    name: {
      type: String,
      // default: null
    },
    step: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    counting: {
      type: [Number, Boolean],
      default: false
    },
    maxLength: {
      type: [Number, Boolean],
      default: false
    },
    hasActivation: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    count() {
      if (!this.counting) return 0;
      if(!this.value) return this.counting;

      return this.counting - this.value.length;
    }
  },
  watch: {
    value(val) {
      return val;
    }
  },
  methods: {
    onInput(e) {
      const { value } = e.currentTarget;

      if (this.maxLength && value.length > this.maxLength) {
        e.preventDefault();
        e.stopPropagation();
        this.$refs.input.value = this.value;
      }
    },
    onActivation() {
      this.$emit('onActivate');
    }
  }
}
</script>


<style lang="scss" scoped>
.small {
  color: $mediumgrey;
  font-size:  rem(12px);
  // display: block;

  margin-bottom: 8px;

  transition: color .3s $ease-out-quart;
  .error & {
    color: $error;
  }
}

.no-label {
  opacity: 0;
}

label {
  width: 100%;
  position: relative;

  &.has-count {
    display: flex;
  }

  > span {
    margin-left: auto;
    color: $success;

    &.has-warning {
      color: $warning;
    }

    &.has-error {
      color: $error;
    }
  }
}

textarea,
input {
  border: 1px solid $lightestgrey;
  border-radius: 3px;

  padding: 20px 16px 18px;

  width: 100%;
  transition: border .3s $ease-out-quart;

  @extend %p;
  color: $snap-text-primary;
  &::placeholder {
    color: $mediumgrey;
  }
  .error & {
    border: 1px solid $error !important;
  }
}

textarea {
  min-height: 200px;
  resize: none;
}

.error-msg {
  display: block;
  color: $error;
  margin-top: 4px;

  height: 20px;
  opacity: 0;
  transition: opacity .3s $ease-out-quart;

  .error & {
    opacity: 1;
  }
}

.input {
  &.deactivate {
    border-radius: 0;
    border-bottom: 1px solid rgba($grey, .4);
    padding-bottom: 10px;

    .input-el {
      display: none;
    }
  }

  .label {
    .input-activation {
      position: absolute;
      top: 0;
      right: 0;

      input:checked {
        + label {
          .svg {
            opacity: 1;
          }
        }
      }

      label {
        display: block;
        position: relative;
        width: 18px;
        height: 18px;
        cursor: pointer;
        transition: color .3s $ease-out-quart;
        font-size: rem(16px);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid rgba($grey, .4);
          border-radius: 3px;
        }

        .svg {
          position: absolute;
          top: 6px;
          left: 4px;
          transition: opacity .3s $ease-out-quart;
          opacity: 0;
        }
      }
    }
  }
}
</style>