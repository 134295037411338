import { uploadBookings } from '@/calls/booking-file';

const bookingFile = {
    namespaced: true,
    state: {
        data: null,
        form: {
            filename: null,
            file: null
        },
        requests: 0,
    },
    mutations: {
        updateData(state, data) {
            state.data = data;
        },
        increaseRequests(state) {
            state.requests++;
        },
        decreaseRequests(state) {
            state.requests--;
        },
    },
    actions: {
        async uploadBookings({ dispatch, state, commit }) {
            commit('increaseRequests');

            if (state.form.file !== null) {
                const base64Data = await dispatch('uploadFile', { file: state.form.file });
                if (!base64Data.hasError && base64Data !== 'error') {
                    commit('updateData', base64Data);
                }
            }

            commit('decreaseRequests');
        },
        async uploadFile({ dispatch, state }, { file }) {
            const base64Data = await uploadBookings(file);
            if (base64Data.hasError) {
                dispatch('notifications/addNotifs', [{
                    text: `${base64Data.error.response["data"].detail ? base64Data.error.response["data"].detail : base64Data.error}`,
                    type: 'error'
                }], { root: true });
            }

            return base64Data;
        },
    }
};


export default bookingFile;
