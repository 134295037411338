<template>
  <LayoutPage v-if="authState === 'signedin' && user && $route.meta.layout === 'page'"/>
  <Layout v-else-if="authState === 'signedin' && user"/>
  <Authentication v-else />
</template>

<script>
import axios from 'axios';
import { Auth, AuthState, Storage } from 'aws-amplify';
import Layout from '@/views/Layout.vue';
import LayoutPage from '@/views/Layout-page.vue';
import Authentication from '@/views/Authentication.vue';

export default {
  name: 'AuthStateApp',
  components: {
    Layout,
    LayoutPage,
    Authentication,
  },
  mounted() {
    const locale = window.localStorage.getItem('otra-locale');
    if (locale && locale !== 'undefined' && locale !== this.$i18n.locale) {
      this.$i18n.locale = locale;
      axios.defaults.headers['Accept-Language'] = locale;
    }
  },
  computed: {
    authState() {
      return this.$store.state.authentication.authState;
    },
    user() {
      return this.$store.state.authentication.tokens;
    }
  },
  beforeMount() {
    this.$store.dispatch('authCheck');
    // const User = Auth.currentSession();
    // User.then(promise => {
    //   if(promise) {
    //     this.$store.commit('updateTokens', promise);
    //     this.$store.dispatch('settings/setContext');
    //     return Auth.currentUserInfo();
    //   } else {
    //     this.$store.commit('updateAuthState', null);
    //     this.$router.push('/')
    //   }
    // }).then(promise => {
    //   this.$store.dispatch('user/setUser', promise.attributes);
    //   this.$store.dispatch('user/setProfile');
    // })
    // .catch(err => {
    //   console.log(err.message);
    // });
  },
}
</script>
