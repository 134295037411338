import {
  update,
  get,
  add,
} from '@/calls/partners';
import i18n from '../i18n';

const partner = {
  namespaced: true,
  state: {
    id: null,
    data: null,
    form: {
      name: '',
      vatnumber: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      apiKey: '',
      flex: false,
      countries: [],
      parkingPartner:{}
    },
    requests: 0
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateData(state, data) {
      state.data = data;
    },
    resetForm(state) {
      state.form = {
        name: '',
        vatnumber: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        apiKey: '',
        flex: false,
        countries: []
      }
    },
    updateFormValue(state, { value, property }) {
      state.form[property] = value;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    setFormFromData({ state, commit }) {
      const properties = [  
        'countries'  ,     
        'name',
        'vatnumber',
        'contactName',
        'contactEmail',
        'contactPhone',
        'parkingPartner',
        'apiKey',
        'flex',
        'canAccessStandardApi'       
      ];
      
      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        switch (true) {
          default:
            commit('updateFormValue', {
              property, value: state.data ? state.data[property] : ''
            });
            break;          
          }
        }
    },
    // Calls
    async getData({ commit, dispatch, state }) {
      commit('increaseRequests');
      const request = await get(state.id);           
      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');
      } else {
        const { tc } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: tc('notifications.errors.partner.getting', 1, { error: request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async create({ commit, dispatch, state }) {
      commit('increaseRequests');
      const data = {...state.form};      
      const request = await add(data);
      const { t } = i18n.global;

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.partner.creation'),
          type: 'success'
        }], { root: true });
      } else {
        if(request?.error?.response?.data) {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.partner.creation', { error: request.error.response.data }),
            type: 'error'
          }], { root: true });
        } else {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.partner.creation', { error: request.error }),
            type: 'error'
          }], { root: true });
        }
        
        commit('decreaseRequests');
        return { noRedirect: true }
      }

      commit('decreaseRequests');
      return request;
    },
    async saveData({ commit, state, dispatch }) {
      commit('increaseRequests');
      
      const data = {...state.form};
      const request = await update(state.id, data);
      const { t } = i18n.global;

      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');
       
        dispatch("getData");

        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.partner.update'),
          type: 'success'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.partner.update', { error: request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
      return request;
    }
  }
};

export default partner;
