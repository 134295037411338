<template>
  <div class="select-multiple">
    <label v-if="label" class="label">{{ label }}</label>
    
      <multiselect :close-on-select="false" 
      track-by="value" label="label" v-model="value" :options="option" multiple
      @update:model-value="onInput" :selectedValues="selectedValues"
      :placeholder="''"
      :selectedLabel="$t('globals.multiselect.selected')"
      :selectLabel="$t('globals.multiselect.selectLabel')"
      :deselectLabel="$t('globals.multiselect.deselectLabel')">
      <template #noResult>
        {{ $t('globals.multiselect.no_result') }}
      </template>
    </multiselect>
   
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  
  props: {
    option: {
      type: Array,
      required: true,
    },
    selectedValues: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: { Multiselect },
  data() {
    return {
      value: null,
      options: this.option,
    }
  }, 
  watch: {
    selectedValues: {
      handler(values) {
        if (values && values.length > 0 && this.option.length > 0) {
          const dt = Object.values(this.selectedValues).map(item => item.toLowerCase())
          this.value =  this.option.filter(o => dt.includes(o.value))
          this.$emit('change', this.value)
        }
      },
      immediate: true
    }
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    onInput(evt) {
      this.$emit('onInput', Object.keys(evt).map(key => evt[key].value));
    }
  }
}
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">

.select-multiple{
  width: 100%;
}

.multiselect__tags-wrap{
  
  font-size: 18px;
}
.multiselect__tag{
  padding-top: 10px;
  padding-bottom: 10px;
  
    font-style: normal;   
    font-size: rem(18px);
    line-height: 140%;
}
</style>