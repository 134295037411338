<template>
  <strong  class="h4"><span class="available">{{total}}</span> available</strong>
</template>

<script>
export default {
  computed: {
    total() {
      return this.$store.state.parkings.current.totalSlotNumber
    }
  }
}
</script>
