<template>
  <div class="company" :class="{'company--open': isOpen}">  
      <div class="table--row" @click="onClick">
        <div class="big-col col nickname">{{company?.name ? company.name : '-' }}</div>
        <div class="col">{{ owner && owner.firstname ? owner.firstname : '-' }}</div>
        <div class="col">{{ owner && owner.lastname ? owner.lastname : '-' }}</div>
        <div class="col">{{ company?.address?.country?.name ? company.address.country.name : '-' }}</div>
        <div class="col">{{ company.vatnumber }}</div>
        <div class="col btns">
          <router-link class="button button--small no-expanded" :to="{name: 'editCompany', params: { id:company.id } }">{{$t('company.list.buttons.edit')}}</router-link>
          <button type="button" class="button button--small no-expanded" @click="handleModal">{{$t('company.list.buttons.owner')}}</button>
        </div>
        <div class="small-col">
          <button>
            <span class="u-visually-hidden">{{$t('company.list.buttons.view_all')}}</span>
            <Sprite type="chevron"/>
          </button>
        </div>
      </div>
      <div v-show="isOpen" class="parkings">
        <ol class="table--subrow">
          <li 
            v-for="head in theadparkings"
            :key="head.title"
            :class="head.class"
          >
            {{head.title}}
          </li>
        </ol>
        <div
          class="table--subrow"
          v-for="parking in parkings"
          :key="parking.id"
        >
          <div class="col darkblue">{{parking.localName}}</div>
          <div class="col grey">la. {{parking.latitude}} lo. {{parking.longitude}}</div>
          <div class="col grey">{{company.email}}</div>
          <div class="col grey">{{parking.contactPhone}}</div>
          <div>
            <button class="button button--small" @click="() => handleModalUnassign(parking)">
              {{$t('company.list.buttons.unassign')}}
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite.vue';

export default {
  name: "Company",
  components: {
    Sprite
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    theadparkings: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      // firstname: "",
      // lastname: "",
      isOpen: false
    }
  },
  computed: {
    // owner() {
    //   return this.company.parkingManagers;
    // },
    parkings() {
      return this.company.parkings;
    },
    owner() {
      if (!this.company) return null;
      const { parkingManagers } = this.company;
      const owners = parkingManagers.filter(o => o.isOwner);
      if (!owners.length) return null;
      return owners[0].user;
    }
  },
  watch: {
    owner(val) {
      if(val && val[0]) this.setOwner();
      else {
        this.firstname = ""
        this.lastname = ""
      }
    },
    parkings(arr) {
      return arr;
    }
  },
  created() {
    if(this.company.parkingManagers[0]) this.setOwner();
  },
  methods: {
    onClick(evt) {
      const {target} = evt;
      const NotExpandable = target.closest('.no-expanded') || target.classList.contains('no-expanded');
      if(!NotExpandable) this.isOpen = !this.isOpen
    },
    handleModal() {
      this.$store.commit('companies/updateModal', {id: this.company.id, owner: this.company.parkingManagers[0] ? this.company.parkingManagers[0].id : 0});
    },
    handleModalUnassign(parking) {
      this.$store.commit('companies/updateModalUnassign', {
        company: this.company.id,
        parking: parking.localName,
        id: parking.id
      });
    },
    setOwner() {
      // const getOwner = this.$store.dispatch('owners/get', this.company.parkingManagers[0].id)
      // getOwner.then(promise => {
      //   this.firstname = promise.user ? promise.user.firstname : null
      //   this.lastname = promise.user ? promise.user.lastname : null
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
  .company {
    min-width: 100%;
    cursor: pointer;
    &:last-child {
      border-bottom:1px solid $lightestgrey;
    }
  }
  .parkings {
    border-top:1px solid $lightestgrey;
  }
  .parkings, .company {
    width: min-content;
  }
  .grey {
    color: $mediumgrey;
  }
  
</style>