<template>
  <div class="timehours" >
    <div class="timehours-cell" :class="[this.error ? 'error-hour' : '']">
          <Select
            :placeholder="''"
            :name="`time-start`"        
            :value="getValue('start')"   
            :options="getAvailableHours('start')"
            :class="'classic'"
            @onChange="onChange(`${$event}`, 'start')"
          />
            <!-- :options="hoursList" -->
          <span class="timehours-separator"></span>

          <Select
            :placeholder="''"
            :name="`time-end`"        
            :value="getValue('end')"    
            :options="getAvailableHours('end')"
            :class="'classic'"
            @onChange="onChange(`${$event}`, 'end')"
          />

         
        </div>
       
        <strong 
            v-if="error"
            class="error-msg-hour"
          >
            <span>{{ error }}</span>
            
          </strong>
   
  </div>
  
</template>

<script>
import Select from '@/components/elements/NewSelect';
import hours from '@/data/timehours.json';

export default {
  components: {    
    Select
  },
  props: {
    startTime: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    },
    error: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      hoursList: [...hours]
    }
  },
  methods: {
    getValue(type) {
      if (type === 'start') {
        return this.startTime
      }

      if (type === 'end') {
        return this.endTime
      }
      
    },
    getAvailableHours(type) {      
     
      if (type === 'start') {
        const hours = this.hoursList 
        .filter(h => {
            const startNumber = parseInt(h.replace(':', ''));
            const endNumber = parseInt(this.endTime.replace(':', ''));
            return startNumber < endNumber;
          })
          .map(h => {
            return { value: h, label: h }
          });

        return hours;
      }

      if (type === 'end') {
        const hours = this.hoursList   
        .filter(h => {
            const startNumber = parseInt(h.replace(':', ''));
            const endNumber = parseInt(this.startTime.replace(':', ''));
            return startNumber > endNumber;
          })
          .map(h => {
            return { value: h, label: h }
          });

        return hours;
      }

      return [];
    },
    onChange(value, type) {
      this.$emit('onChange', { value, type });
    }
  }
}
</script>

<style lang="scss">
.timehours {
  .timehours-cell {
    .select {
      &.select--open {
        z-index: 2;
      }

      .custom-select {
        width: 200px;
        padding: 0;
        z-index: 3;
        strong {
          padding: 12px 16px 10px;
        }
      }
    }
  }
 
}
.timehours-separator {
      margin-left: 19px;
      width: 10px;
      height: 1px;
      background-color: $grey;
    }

.error-hour{
    border: 1px solid $error !important;
  }

.error-msg-hour {
  font-size:  rem(16px);
  display: block;
  color: $error;
  margin-top: 8px;

  height: 20px;  
  transition: opacity .3s $ease-out-quart;
  opacity: 1;

}



</style>