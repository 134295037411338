<template>
  <strong  class="h4"><span>{{ today }}</span></strong>
</template>

<script>
  import moment from 'moment';
  export default {
    data() {
      return {
        today: moment().format("DD[.]MM[.]YYYY")
      };
    }
  }
</script>
