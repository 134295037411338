import {
  list,
  add,
  get,
  update
} from '@/calls/partners';
import i18n from '../i18n';

const partners = {
  namespaced: true,
  state: {
    requests: 0,
    search: null,
    list: [],
    modal: {
      visible: false,
      id: null
    },
    order: {
      type: 'name',
      dir: 'asc'
    }
  },
  mutations: {
    updatePartners(state, els) {
      state.list = els;
    },
    updateOrder(state, type) {
      if (state.order.type === type) {
        state.order.dir = state.order.dir === 'asc' ? 'desc' : 'asc';
        return;
      }

      state.order.type = type;
      state.order.dir = 'asc';
    },
    updateModal(state, id) {
      state.modal.visible = !state.modal.visible;
      state.modal.id = id;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    increaseRequest(state) {
      state.requests++;
    },
    decreaseRequest(state) {
      state.requests--;
    }
  },
  actions: {
    set({ state, commit }) {
      commit('increaseRequest');
      const search = state.search && state.search.length ? state.search : null;
      const partners = list(search);
      return partners.then((promise) => {
        commit('updatePartners', promise);
        commit('decreaseRequest');
        return promise;
      }).catch(error => {
        // console.log(error);
      });
    },
    new({commit, dispatch}, item) {
      const partner = add(item)
      return partner.then(promise => {
        if (promise.error) {
          const data = promise?.error?.response?.data;

          if (data && data === 'partner-already-exists') {
            dispatch('notifications/addNotifs', [{
              text: `Error while creating partner : The partner already exists`,
              type: 'error'
            }], { root: true });
            return { noReload: true };
          }

          dispatch('notifications/addNotifs', [{
            text: `Error while creating partner : ${promise.error}`,
            type: 'error'
          }], { root: true });

          return { noReload: true };
        }

        this.dispatch('partners/set')
      })
    },
    update({commit}, obj) {
      const partner = update(obj)
      return partner.then(promise => {
        this.dispatch('partners/set')
      })
    },
    get({commit}, id) {
      const partner = get(id)
      return partner.then(promise => {
        return promise
      })
    },
    search({ dispatch }, terms) {
      if (!terms || !terms.length) {
        dispatch('partners/set');
        return;
      }

      // TO DO
      const partners = list();
      return partners.then((promise) => {
        commit('updatePartners', promise);
        return promise;
      }).catch(error => {
      });
    }
  }
};

export default partners;
