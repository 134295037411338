<template>
  <div 
    class="input"
    :class="[
      'input-checkbox',
      {
        'error': error
      },
    ]"
  >
    <input 
      ref="input"
      type="checkbox"
      :id="name"
      :name="name"
      :checked="isChecked"
      :disabled="disable"
      @change="updateInput"
    />
    <label :for="name" class="label small">{{ label }}</label>
    <strong 
      v-if="error"
      class="small error-msg"
    >
      <span>{{ error }}</span>
    </strong>
    <strong 
      v-if="detail"
      class="small detail-message"
    >
      {{ detail }}
    </strong>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    label: {
      type: String,
      default: null
    },  
    error: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: null
    },
    value: { 
      type: [String, Boolean]
    },
    disable: {
      type: Boolean,
      default: false
    },
    detail: {
      type: String,
      default: null
    }
  },
  computed: {
    isChecked() {
      return this.value;
    }
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked
      this.$emit('input', {name: event.target.name, value: isChecked});
    }
  }
}
</script>


<style lang="scss" scoped>
input[type='checkbox'] {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

input[type='checkbox'] ~ label {
  position: relative;

  display: flex;
  align-items: center;

  color: $snap-text-primary;
  cursor: pointer;

  &::before,
  &::after {
    content: '';

    display: block;

    
    border-radius:2px;
  }
  
  &::before {
    width: 16px;
    height: 16px;
    
    border: 1px solid $lightgrey;
    margin-right: 10px;
    // margin-top: -7px;
  }
  
  &::after {
    position: absolute;
    top: 50%;
    left:3.5px;
    

    width: 10px;
    height: 10px;

    background: $mediumgreen;
    transform: translateY(-50%) scale(0);

    transition: transform 0.3s ease;
  }
}

input[type='checkbox'][disabled] ~ label {
  color: $grey;
  pointer-events: none;
}

input[type='checkbox']:checked ~ label::after {
  transform: translateY(-50%) scale(1);
}

.error-msg {
  display: block;
  color: $error;
  margin-top: 4px;

  height: 20px;
  opacity: 0;
  transition: opacity .3s $ease-out-quart;
  .error & {
    opacity: 1;
  }
}

.input {
  display: flex;
  flex-direction: column;

  .small.detail-message {
    font-size: rem(12px);
    line-height: 17px;
  }
}
</style>