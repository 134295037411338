<template>
  <div
    ref="container"
    :class="[
      'select',
      { 'select--open': open },
      { 'error': error },
      { 'deactivate': hasActivation && !isActive }
    ]"
  >
    <label v-if="label" class="label">
      {{ label }}
      <span
        v-if="hasActivation"
        class="select-activation"
      >
        <input
          type="checkbox"
          :checked="isActive"
          :id="`activation-${name}`"
          class="u-visually-hidden"
          @input.stop="onActivation"
        />
        <label :for="`activation-${name}`">
          <Sprite
            :type="'check'"
            :width="14"
            :height="10"
          />
        </label>
      </span>
    </label>

    <div 
      class="custom-select"
      :class="{'custom-select--open': open}"
    >
      <strong 
        @click="onToggle"
        class="selected"
      > 
        {{ placeholderText }}
        <span class="selected-icon">
          <Sprite :type="'chevron'" />
        </span>
      </strong>
      <input
        v-show="hasSearch && open"
        ref="search"
        class="custom-select-search"
        type="text"
        :placeholder="$t('components.select.search')"
        :value="search"
        @input="onSearch"
      />
      <ul
        ref="ul"
        class="items"
      >
        <li 
          v-if="allowNull && (!search || !search.length)"
          :class="['item', { 'item--selected': !selected }]"
          @click="onClick({ value: '' })"
        >
          {{ placeholder }}
        </li>
        <!-- v-for="option in options" -->
        <li
          v-for="option in displayedOptions"
          :key="option.value"
          :rel="option.value"
          :class="['item', { 'item--selected': selected && selected.value === option.value }]"
          @click="() => onClick(option)"
        >
          {{ option.label ? option.label : option.value }}
        </li>
        <li
          v-show="search && search.length && !displayedOptions.length"
          class="item item--no-result"
        >
          {{ $t('components.select.no_result') }}
        </li>
      </ul>
    </div>
    <select
      ref="input"
      :name="name"
      :required="required"
      :value="value"
      autocomplete="off"
      @change="onChange"
    >
      <option 
        v-if="allowNull"
        :selected="!selected"
        value=""
      >
        {{ placeholder }}
      </option>
      <option 
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="value === option.value"
      >
        {{ option.label ? option.label : option.value }}
      </option>
    </select>
    <strong 
      v-if="error"
      class="small error-msg"
    >
      <span>{{ error }}</span>
    </strong>
  </div>
</template>

<script>
/* eslint-disable */
import Sprite from './Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    allowNull: {
      type: Boolean,
      default: false
    },
    hasSearch: {
      type: Boolean,
      default: false
    },
    hasActivation: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      search: null,
      _documentClick: this.onDocumentClick.bind(this)
    }
  },
  computed: {
    selected() {
      if (!this.value) return null;
      const selected = this.options.filter(o => o.value === this.value);
      return selected[0];
    },
    placeholderText() {
      if (this.selected) {
        if (this.selected.label) return this.selected.label;
        return this.selected.value;
      }

      return this.placeholder;
    },
    displayedOptions() {
      if (!this.search || !this.search.length) return this.options;

      const options = this.options.filter(o => {
        if (o.label) {
          const lower = o.label.toLowerCase();
          const index = lower.indexOf(this.search);
          return index >= 0;
        }

        return false;
      });

      return options;
    }
  },
  methods: {
    onClick(option) {
      if (this.readonly) return;
      this.open = false;
      this.search = null;
      document.removeEventListener('click', this._documentClick);
      this.$emit('onChange', option.value);
    },
    onSearch(e) {
      if (this.readonly) return;
      const { target } = e;
      const { value } = target;
      this.search = value;
    },
    onToggle() {
      if (this.readonly) return;
      this.open = !this.open;
      this.search = null;

      if (this.open) {
        document.addEventListener('click', this._documentClick);

        if (this.hasSearch) {
          setTimeout(() => {
            this.$refs.search.focus();
          }, 100);
        }
      } else {
        document.removeEventListener('click', this._documentClick);
      }
    },
    onActivation() {
      this.$emit('onActivate');
    },
    onDocumentClick(e) {
      const { target } = e;
      const isDescendant = this.isDescendant(this.$refs.container, target);
      if (!isDescendant && this.open) {
        this.onToggle();
      }
    },
    isDescendant(parent, child) {
      let node = child.parentNode;
      while (node) {
        if (node === parent) return true;
        node = node.parentNode;
      }

      return false;
    }
  }
}
</script>