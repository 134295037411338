<template>
  <router-view v-if="$route.name !== 'Parkings'"/>
  <section 
    v-else
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <table class="table" cellspacing="0" >
      <THead
        :head="thead"
        :order="order"
        @onOrder="updateOrder"
      />
      <tbody>
        <Parking
          v-for="parking in parkings"
          :key="parking.id"
          :parking="parking"
        />
      </tbody>
    </table>
    <div
      v-show="loading"
      class="main-container-loader"
    >
      <Loader />
    </div>

    <Modal-company />
  </section>
</template>

<script>
import ModalCompany from './ModalCompany';
import Loader from '@/components/elements/Loader';
import THead from '@/components/elements/THead';
import Parking from '@/components/partials/Parking';

export default {
  name: "ListParking",
  components: {
    THead,
    Loader,
    Parking,
    ModalCompany
  },
  beforeMount() {
    this.$store.commit('parkings/updateSearch', '');
    this.getData();
  },

  computed: {
    parkings() {
      const { order, list } = this.$store.state.parkings;
      const parkings = list
        .map(p => {
          p.orderProps = {
            localName: p?.localName ? p.localName.trim() : '-',
            companyName: p?.companyName ? p.companyName.trim() : '-',
          }
        return p;
      });

      const ordereds = parkings.sort((a, b) => {
        const aProp = typeof a.orderProps[order.type] === 'string' ? a.orderProps[order.type].toUpperCase() : a.orderProps[order.type];
        const bProp = typeof b.orderProps[order.type] === 'string' ? b.orderProps[order.type].toUpperCase() : b.orderProps[order.type];
        if(aProp < bProp) return order.dir === 'asc' ? -1 : 1;
        if(aProp > bProp) return order.dir === 'asc' ? 1 : -1;
        return 0;
      });

      return ordereds;
    },
    loading() {
      return this.$store.state.parkings.requests !== 0 || this.$store.state.companies.requests !== 0;
    },
    order() {
      return this.$store.state.parkings.order;
    },
    thead() {
      return [
        {
          title: this.$t('parkings.list.columns.parking_name'),
          class:"small head",
          type: 'localName'
        },
        {
          title: this.$t('parkings.list.columns.gps_coordinates'),
          class:"small head"
        },
        {
          title: this.$t('parkings.list.columns.company_name'),
          class:"small head",
          type: 'companyName'
        },
        {
          title: this.$t('parkings.list.columns.manage_assign'),
          class:"buttons-col head head-hidden"
        },
      ]
    },
    message() {
      return this.$t('parkings.list.no_parking');
    }
  },
  watch: {
    $route() {
      this.getData();
    },
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    getData() {
      if(this.$route.name.toLowerCase() === 'parkings') {
        this.$store.dispatch('parkings/getParkings');
      }
    },
    updateOrder(e) {
      this.$store.commit('parkings/updateOrder', e);
    }
  }
}
</script>