<template>
  <fieldset class="fieldset-input"  :class="{'error': error}">
    <label class="label" :for="name" :class="{'isVisible': value}">
      <span>
        {{label}}
      </span>
    </label>
    
    <div class="fieldset-input__container">
      <input 
        ref="input"
        :type="visible ? 'text' : type" 
        class="input" 
        :required="required" 
        :placeholder="label"
        :name="name"
        :id="name"
        :value="value"
        @input="onInput"
      />
      <Sprite @click="onClick" v-if="type === 'password' " :type="'eye-of-tiger'" :width="20" :height="13" />
    </div>
    <p class="error-msg">{{typeof error !== 'boolean' ? error :''}}</p>
  </fieldset>
</template>

<script>
import Sprite from '@/components/elements/Sprite.vue';

export default {
  name:"Fieldset",
  components: {
    Sprite
  },
  beforeMount() {
    if (this.propValue && this.propValue.length > 0) {
      this.value = this.propValue;
    }
  },
  data() {
    return {
      visible:false,
      value: ''
    }
  },
  props: {
    label: {
      type: String,
      default: null
    },  
    type: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },  
    error: {
      type: String,
      default: null
    },
    validation: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    propValue: {
      type: String,
      default: ''
    }
  },
  watch: {
    value(val) {
      return val;
    }
  },
  methods: {
    onInput(evt) {
      const {target} = evt;
      this.value = target.value
      this.$emit('input', evt);
    },
    onClick() {
      this.visible = !this.visible
    }
  }
}
</script>
