<template>
  <aside 
    class="modal"
    :class="isVisible ? 'modal--visible' : ''"
  >
    <div class="modal-container">
      <div class="abbr">{{abbr}}</div>
      <h3 class="h4">
        <strong v-if="nickname"> {{nickname}} </strong>
        —
        <strong v-if="fleetname"> {{fleetname}}</strong>
      </h3>
      <a class="h4 phone" href="tel:{{phone}}">{{phone}}</a>

    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        class="button button-border"
        @click="onClick"
      >
        {{$t('globals.modal.buttons.close')}}
      </button>
      <button type="button" class="button "> {{$t('globals.modal.buttons.contact')}}</button>
    </div>
  </aside>
</template>

<script>
export default {
  name: "Modal",
  props: {
    fleet: {
      type: [Object, Boolean, String],
      default:  "Freelance"
    },
  },
  computed: {
    isVisible() {
      return this.$store.state.user.modal;
    },
    driver() {
      return this.$store.state.user.driver;
    }
  },
  data() {
    return {
      abbr: '',
      phone: '',
      nickname: '',
      fleetname: '',
    }
  },
  watch: {
    driver(driver) {
      var matches = driver.driverName.match(/\b(\w)/g); // ['J','S','O','N']
      if(!matches) matches = ['n','n']
      this.abbr = matches.join(''); // JSON
      this.nickname = driver.driverName ? driver.driverName :  this.$t('globals.no_name'); 
      this.phone = driver.driverPhone;
      this.fleetname =  this.$t('globals.freelance');
    },
    fleet(fleet) {
      if(fleet) this.fleetname = fleet.name;
      else this.fleetname =  this.$t('globals.freelance');
    }
  },
  methods: {
    onClick() {
      this.$store.commit('updateModal');
    }
  }
}
</script>
