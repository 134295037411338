import {
  patchAdmin,
  getAdmin,
  createAdmin,
} from '@/calls/team-admins';
import i18n from '../i18n';
import Constants from '../helpers/constants'

const teamAdmin = {
  namespaced: true,
  state: {
    id: null,
    data: null,
    form: {
      firstname: '',
      lastname: '',
      email: ''
    },
    requests: 0
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateData(state, data) {
      state.data = data;
    },
    resetForm(state)  {
      state.form = {
        firstname: '',
        lastname: '',
        email: ''
      }
    },
    updateDataValue(state, { value, property }) {
      if (property.match(/street|zipCode|city|state/)) {
        state.data.address[property] = value;
        return;
      }

      state.data[property] = value;
    },
    updateFormValue(state, { value, property }) {
      state.form[property] = value;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    setFormFromData({ state, commit }) {
      const properties = ['firstname', 'lastname', 'email'];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        switch (property) {
          case 'firstname':
            commit('updateFormValue', {
              property, value: state.data.firstName
            });
            break;
          case 'lastname':
            commit('updateFormValue', {
              property, value: state.data.lastName
            });
            break;
        
          default:
            commit('updateFormValue', {
              property, value: state.data[property]
            });
            break;
        }
      }
    },
    // Calls
    async getData({ commit, dispatch, state }) {
      commit('increaseRequests');
      const request = await getAdmin(state.id);

      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');
      } else {
        const { tc } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: tc('notifications.errors.admins.getting', 1, { error: request.error }),
          // text: `Error while getting owner : ${request.error}`,
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async create({ commit, dispatch, state }) {
      commit('increaseRequests');
      const data = {...state.form};
      data.locale = data.locale && data.locale.length ? data.locale : 'en';

      data.createdFromApp = Constants.SOURCE_APP; 
      
      const request = await createAdmin(data);
      const { t } = i18n.global;

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.admins.creation'),
          // text: 'Fleet owner created!',
          type: 'success'
        }], { root: true });
      } else {
        if(request?.error?.response?.data) {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.admins.creation', { error: request.error.response.data }),
            // text: `Error while creating owner : ${request.error.response.data}`,
            type: 'error'
          }], { root: true });
        } else {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.admins.creation', { error: request.error }),
            // text: `Error while creating owner : ${request.error}`,
            type: 'error'
          }], { root: true });
        }

        commit('decreaseRequests');
        return { noRedirect: true }
      }

      commit('decreaseRequests');
      return request;
    },
    async saveData({ commit, state, dispatch }) {
      commit('increaseRequests');

      const data = {...state.form};
      const request = await patchAdmin(state.id, data);
      const { t } = i18n.global;

      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');

        dispatch('notifications/addNotifs', [{
          // text: 'Fleet owner updated!',
          text: t('notifications.success.admins.update'),
          type: 'success'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.admins.update'),
          // text: `Error while getting owner : ${request.error}`,
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
      return request;
    }
  }
};

export default teamAdmin;
