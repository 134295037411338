<template>
  <li class="question" >
    <h3 class="question-title">
      {{question.title}}
      <Sprite :type="'chevron'"/>
    </h3>
    <p class="question-content">{{question.content}}</p>
  </li>
</template>

<script>
import Sprite from '@/components/elements/Sprite'
export default {
  name: "Question",
  components: {
    Sprite
  },
  props: {
    question: {
      type: Object
    }
  }
}
</script>

<style>

</style>