<template>
  <div :class="`popup ${classes}`">
    <div class="popup-container">
      <div class="popup-content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>


<style lang="scss" scoped>
.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(19, 19, 21, 0.5);

  &-container {
    position: relative;
    width: auto;
    max-width: 550px;
    /* width: 100%;
    max-width: 432px; */
    max-height: calc(100% - 40px);
    overflow: auto;
    padding: 30px;
    background: $white;
    border-radius: 3px;
  }
}
</style>

