<template>
  <router-view v-if="$route.name !== 'Companies'"/>
  <section 
    v-else
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <ol class="table-head table--row">
      <li 
        v-for="head in theadcompanies"
        :key="head.title"
        :class="[head.class, { 'is-hoverable': order && head.type }]" 
        @click="updateOrder(head.type)"
      >
        <span
          v-if="order && head.type"
          class="th-wrapper"
        >
          <span class="th-text">{{ head.title }}</span>
          <span :class="['th-order', `${head.type === order.type ? order.dir : ''}`]">
            <Sprite
              :type="'chevron-up'"
              :width="16"
              :height="8"
            />
            <Sprite
              :type="'chevron-down'"
              :width="16"
              :height="8"
            />
          </span>
        </span>
        <template v-else>
          {{ head.title }}
        </template>
      </li>
    </ol>

    <template v-if="companies.length">
      <Company
        v-for="company in companies"
        :key="company.id"
        :company="company"
        :theadparkings="theadparkings"
      />
    </template>

    <!-- NO RESULTS -->
    <NoResult v-else >
      <slot>{{message}}</slot>
    </NoResult>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <Loader />
    </div>

    <modal-owner />
    <modal-unassign />
  </section>
</template>

<script>
import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";

import Loader from '@/components/elements/Loader';
import Sprite from '@/components/elements/Sprite';
import NoResult from '@/components/partials/Bookings/NoResult.vue';
import Company from '@/components/partials/Company.vue';
import ModalOwner from './ModalOwner.vue';
import ModalUnassign from './ModalUnassign.vue';

gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "ListCompanies",
  components: {
    Sprite,
    Loader,
    Company,
    ModalOwner,
    NoResult,
    ModalUnassign
  },
  beforeMount() {
    this.$store.commit('companies/updateSearch', '');
    this.getData();
  },

  computed: {
    companies() {
      const { order, list } = this.$store.state.companies;
      const companies = list.map(c => {
        const { parkingManagers } = c;
        const owners = parkingManagers.filter(o => o.isOwner);

        c.orderProps = {
          name: c.name.trim(),
          firstname: owners.length && owners[0]?.user?.firstname ? owners[0].user.firstname.trim() : '-',
          lastname: owners.length && owners[0]?.user?.lastname ? owners[0].user.lastname.trim() : '-',
          country: c?.address?.country?.name ? c.address.country.name.trim() : '-',
        }
        return c;
      });

      const ordereds = companies.sort((a, b) => {
        const aProp = typeof a.orderProps[order.type] === 'string' ? a.orderProps[order.type].toUpperCase() : a.orderProps[order.type];
        const bProp = typeof b.orderProps[order.type] === 'string' ? b.orderProps[order.type].toUpperCase() : b.orderProps[order.type];
        if(aProp < bProp) return order.dir === 'asc' ? -1 : 1;
        if(aProp > bProp) return order.dir === 'asc' ? 1 : -1;
        return 0;
      });

      return ordereds;
    },
    loading() {
      return this.$store.state.companies.requests !== 0 || this.$store.state.owners.requests !== 0;
    },
    order() {
      return this.$store.state.companies.order;
    },
    theadcompanies() {
      return [
        {
          title: this.$t('company.list.columns.company_name'),
          class:"big-col small head th",
          type: 'name'
        },
        {
          title: this.$t('company.list.columns.firstname'),
          class:"small head th",
          type: 'firstname'
        },
        {
          title: this.$t('company.list.columns.lastname'),
          class:"small head th",
          type: 'lastname'
        },
        {
          title: this.$t('company.list.columns.country'),
          class:"small head th",
          type: 'country'
        },
        {
          title: this.$t('company.list.columns.vat'),
          class:"small head th",
        },
        {
          title: this.$t('company.list.columns.edit'),
          class:"buttons-col head th head-hidden",
        },
        {
          title: this.$t('company.list.columns.toggle'),
          class:"small-col head head-hidden"
        }
      ]
    },
    theadparkings() {
        return [
        {title: this.$t('company.list.columns.parking_name'), class:"small head"},
        {title: this.$t('company.list.columns.gps_coordinates'), class:"small head"},
        {title: this.$t('company.list.columns.email'), class:"small head"},
        {title: this.$t('company.list.columns.phone'), class:"small head"},
        {title: this.$t('company.list.columns.unassign'), class:"big-col  head head-hidden"}
      ]
    },
    message() {
      return this.$t('company.list.no_company');
    }
  },
   watch: {
    $route() {
      this.getData();
    },
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.list = this.companies
    this.currentList = this.list;
  },
  beforeRouteUpdate (to, from, next) {
    this.list = this.companies
    next();
  },
  methods: {
    handleView() {
      const from = (this.opt.page - 1) * this.opt.per;
      const to = this.opt.page * this.opt.per;
      if(this.tmpBooking) this.tmpBooking = [...this.currentList].slice(from, to);
    },
    getData() {
      if(this.$route.name.toLowerCase() === 'companies') {
        this.$store.dispatch('companies/set');
        this.$store.dispatch('owners/set');
      }
    },
    updateOrder(e) {
      this.$store.commit('companies/updateOrder', e);
    }
  }
}
</script>

<style lang="scss" scoped>
.table-head {
  .th {
    &.is-hoverable {
      cursor: pointer;
      transition: color .3s $ease-out-quart;

      &:hover {
        color: $mediumgreen;
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-order {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 8px;

      &.asc {
        .svg-chevron-up {
          fill: $mediumgreen;
        }
      }

      &.desc {
        .svg-chevron-down {
          fill: $mediumgreen;
        }
      }

      .svg {
        fill: $grey;

        &:not(:first-child) {
          margin-top: 3px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
  .table--row, .table--subrow {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex: 0 0;
    flex-wrap: nowrap;
    /* flex-shrink: 0;
    flex-grow: 0; */
    align-items: center;

    &.table-head {
      border-top:0px solid $lightestgrey;
      border-bottom:0px solid $lightestgrey;
    }
  } 
  .table--row {
    border-top:1px solid $lightestgrey;
    /* border-bottom:1px solid $lightestgrey; */
    .head, .col {
      min-width: 180px;//220px;
      flex: 0 0 180px;//0 0 220px;
      font-size: rem(14px);
    }
    .col {
      /* justify-self: stretch; */
      padding: 10px 25px;
      color: $mediumgrey;

      &.btns {
        display: flex;
      }
    }
    .medium-col { 
      min-width: 150px;
      flex: 0 0 150px;
    }
    .big-col { 
      min-width: 250px;
      flex: 0 0 250px;
    }
    .button + .button {
      margin-left: 10px;
    }
  }
  .head, .col {
    flex: 0 0 auto;
    padding: 25px;
    padding-right: 0;
    @extend %p;
    &-hidden {
      opacity: 0
    }

    &.nickname {
      font-family: $primary;
      font-weight: $medium;
      font-size: rem(16px);

      line-height: 110%;
      text-transform: uppercase;
      color: $snap-text-primary;
    }
  }
  .table--subrow {
    .head, .col {
      width: 240px;//300px;
      flex: 240px;//0 0 300px;
      font-size: rem(14px);
      padding: 10px 25px;
      &.darkblue {
        color: $snap-text-primary;
        padding-left: 40px
      }
    }
  }
 
</style>