<template>
  <div class="main-container__header">
    <p class="small">
      <slot>
        No booking yet.
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "NoResult"
}
</script>
