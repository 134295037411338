<template>
  <section class="main-container main-container__form edit-profile">
    <h2 class="h4">{{ $t('profile.edit.titles.informations') }}</h2>
    <form
      v-if="form"
      novalidate
      @submit="onSubmit($event)"
      class="main-container-form"
    >
      <div class="container-row container-5-5">
        <Inputs
          ref="firstname"
          name="firstname"
          type="text" 
          :label="`${$t('labels.first_name')} *`"
          :required="true"
          :error="errors.form.firstname"
          :value="form.firstname"
          @input="onInput($event, 'firstname')"
        />
        <Inputs
          ref="lastname"
          name="lastname"
          type="text" 
          :label="`${$t('labels.last_name')} *`"
          :required="true"
          :error="errors.form.lastname"
          :value="form.lastname"
          @input="onInput($event, 'lastname')"
        />
      </div>
      <div class="container-row container-5-5">
        <Inputs
          ref="email"
          name="email"
          type="email" 
          :label="`${$t('labels.email')} *`"
          :required="true"
          :error="errors.form.email"
          :value="form.email"
          @input="onInput($event, 'email')"
        />
        <!-- <Select
          ref="locale"
          :name="'locale'"
          :label="$t('labels.language')"
          :options="langs"
          :placeholder="localePlaceholder"
          :error="errors.locale"
          :required="false"
          :class="'classic'"
          @onChange="onInput($event, 'locale')"
        /> -->
          <!-- :select="{ value: form.gender }" -->
          <!-- :placeholder="form.locale ? form.locale : $t('profile.placeholders.lang')" -->
      </div>

      <div 
        v-if="formErrors.form"
        class="form--error small"
      >
        {{ formErrors.form }}
      </div>

      <div class="main-container__form-buttons">
        <button 
          ref="submit"
          class="button"
          type="submit"
        >
          {{ $t('globals.save') }}
        </button>

        <button
          class="button button-ghost"
          type="button"
          :disabled="disabledForm"
          @click="onCancel()"
        >
          {{ $t('globals.cancel') }}
        </button>
      </div>

      <div
        v-show="requests.form !== 0"
        class="main-container-loader"
      >
        <loader />
      </div>
    </form>

    <h2 class="h4">{{ $t('profile.edit.titles.password') }}</h2>

    <form
      v-if="form"
      novalidate
      @submit="onSubmit($event, 'password')"
      class="main-container-form"
    >

      <div class="container-row container-5-5">
        <Inputs
          ref="current"
          name="current"
          type="password" 
          :label="`${$t('labels.current_password')} *`"
          :required="true"
          :error="errors.password.current"
          :value="password.current"
          @input="onInput($event, 'current', 'password')"
        />
      </div>
      <div class="container-row container-5-5">
        <Inputs
          ref="new"
          name="new"
          type="password" 
          :label="`${$t('labels.new_password')} *`"
          :required="true"
          :error="errors.password.new"
          :value="password.new"
          @input="onInput($event, 'new', 'password')"
        />
        <Inputs
          ref="lastname"
          name="lastname"
          type="password" 
          :label="`${$t('labels.confirm_new_password')} *`"
          :required="true"
          :error="errors.password.confirm"
          :value="password.confirm"
          @input="onInput($event, 'confirm', 'password')"
        />
      </div>

      <div 
        v-if="formErrors.password"
        class="form--error small"
      >
        {{ formErrors.password }}
      </div>

      <div class="main-container__form-buttons">
        <button
          class="button"
          type="submit"
        >
          {{ $t('globals.change_password') }}
        </button>
        <button
          class="button button-ghost"
          type="button"
          :disabled="disabledPassword"
          @click="onCancel('password')"
        >
          {{ $t('globals.cancel') }}
        </button>
      </div>

      <div
        v-show="requests.password !== 0"
        class="main-container-loader"
      >
        <loader />
      </div>
    </form>

     <!-- Popups -->
    <popup
      v-if="popupConfirm.visible"
      :classes="'popup-new'"
    >
      <template v-slot:content>
        <!-- Success -->
        <template v-if="popupConfirm.success">
          <h2 class="popup-title">{{ $t('globals.success') }}</h2>
          <div class="popup-main">
            <img class="popup-main-img" src="/img/success.png"/>
            <p class="popup-main-text">{{ $t('authentication.confirm_change.success.text') }}</p>
          </div>

          <form
            class="popup-form"
            novalidate
            @submit.prevent="confirmEmail"
          >
            <inputs
              :type="'text'"
              :placeholder="$t('authentication.confirm_change.code_placeholder')"
              :label="$t('authentication.confirm_change.code')"
              :value="''"
              :error="popupVerify.errors.code"
              @input="onCodeChange"
            />

            <div class="popup-actions">
              <button
                type="button"
                class="button button-popup button-border"
                @click.prevent="closePopup('confirmChange')"
              >
                {{ $t('globals.close') }}
              </button>
              <button
                class="button button-popup"
                @click="onVerify"
              >
                {{ $t('globals.verify') }}
              </button>
            </div>
          </form>
        </template>
        <template v-else>
          <!-- Form -->
          <h2 class="popup-title">{{ $t('authentication.confirm_change.title') }}</h2>
          <p class="popup-main-text">{{ $t('authentication.confirm_change.text') }}</p>

          <div class="popup-actions">
            <button
              type="button"
              class="button button-popup button-border"
              @click.prevent="closePopup('confirmChange')"
            >
              {{ $t('globals.close') }}
            </button>
            <button
              class="button button-popup"
              @click="onSubmit"
            >
              {{ $t('globals.confirm') }}
            </button>
          </div>
        </template>

        <div
          v-show="popupConfirm.requests !== 0"
          class="popup-loader"
        >
          <loader />
        </div>
      </template>
    </popup>

    <!-- Confirm popup -->
    <popup
      v-if="popupVerify.visible"
      :classes="'popup-new'"
    >
      <template v-slot:content>
        <!-- Success -->
        <template v-if="popupVerify.success">
          <h2 class="popup-title">{{ $t('globals.success') }}</h2>
          <div class="popup-main">
            <img class="popup-main-img" src="/img/success.png"/>
            <p class="popup-main-text">{{ $t('authentication.verify_email.success.text') }}</p>
            <button
              class="button button-big"
              @click="closePopup('verifyEmail')"
            >
              {{ $t('globals.ok_maj') }}
            </button>
          </div>
        </template>
        <!-- Form -->
        <template v-else>
          <h2 class="popup-title">{{ $t('authentication.verify_email.title') }}</h2>
          <p class="popup-main-text">{{ $t('authentication.verify_email.text') }}</p>
          <form
            class="popup-form"
            novalidate
            @submit.prevent="onVerify"
          >
              <!-- :label="$t('authentication.verify_email.code')" -->
            <inputs
              :type="'email'"
              :placeholder="$t('authentication.verify_email.code')"
              :value="''"
              :error="popupVerify.errors.code"
              @input="onCodeChange"
            />

            <div class="popup-actions">
              <button
                type="button"
                class="button button-popup button-border"
                @click.prevent="closePopup('verifyEmail')"
              >
                {{ $t('globals.close') }}
              </button>
              <button
                type="submit"
                class="button button-popup"
              >
                <!-- :disabled="disableAdd" -->
                {{ $t('globals.verify') }}
              </button>
            </div>
          </form>
        </template>

        <div
          v-show="popupVerify.requests !== 0"
          class="popup-loader"
        >
          <loader />
        </div>
      </template>
    </popup>
  </section>
</template>

<script>
/* eslint-disable */
import Sprite from '@/components/elements/Sprite';
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
import Select from '@/components/elements/Select';
import Popup from '@/components/partials/Popup';

export default {
  name: 'Accounting',
  components: {
    Sprite, Loader, Inputs, Select, Popup
  },
  beforeMount() {
    const { verification } = this.$route.query;

    if (verification && verification === 'true') {
      this.$store.commit('updatePopupProperty', { popup: 'verifyEmail', property: 'visible', value: true });
    }
  },
  data() {
    return {
      hasError: {
        form: false,
        password: false
      },
      errors: {
        form: {
          firstname: null,
          lastname: null,
          email: null
        },
        password: {
          current: null,
          new: null,
          confirm: null
        }
      },
      // langs: [
      //   { value: 'en', label: this.$t('globals.languages.en') },
      //   { value: 'fr', label: this.$t('globals.languages.fr') },
      //   { value: 'de', label: this.$t('globals.languages.de') },
      // ]
    }
  },
  computed: {
    password() {
      return this.$store.state.user.password;
    },
    form() {
      return this.$store.state.user.form;
    },
    requests() {
      return this.$store.state.user.requests;
    },
    formErrors() {
      return this.$store.state.user.errors;
    },
    disabledForm() {
      const { form, initialForm } = this.$store.state.user;
      if (!form || !initialForm) return true;
      const sameFirstName = form.firstname === initialForm.firstname;
      const sameLastName = form.lastname === initialForm.lastname;
      const sameEmail = form.email === initialForm.email;
      const sameLocale = form.locale === initialForm.locale;
      return sameFirstName && sameLastName && sameEmail && sameLocale;
    },
    disabledPassword() {
      const { password } = this.$store.state.user;
      const hasCurrent = password.current && password.current.length > 0;
      const hasNew = password.new && password.new.length > 0;
      const hasConfirm = password.confirm && password.confirm.length > 0;
      return !hasCurrent && !hasNew && !hasConfirm;
    },
    localePlaceholder() {
      const locale = this.$store.state.user?.form?.locale;

      if (locale) {
        const langs = [
          { value: 'en', label: this.$t('globals.languages.en') },
          { value: 'fr', label: this.$t('globals.languages.fr') },
          { value: 'de', label: this.$t('globals.languages.de') },
        ];
        const loc = langs.filter(l => l.value === locale);

        if (loc[0]) return loc[0].label;
      }

      return this.$t('profile.edit.placeholders.lang');
    },
    langs() {
      const langs = [
        { value: 'en', label: this.$t('globals.languages.en') },
        { value: 'fr', label: this.$t('globals.languages.fr') },
        { value: 'de', label: this.$t('globals.languages.de') },
      ];

      return langs;
    },
    popupVerify() {
      return this.$store.state.authentication.verifyEmail;
    },
    popupConfirm() {
      return this.$store.state.authentication.confirmChange;
    }
  },
  methods: {
    onInput($event, property, form = 'form') {
      const { target } = $event;
      let value = null;

      if (property === 'locale') {
        value = $event;
      } else {
        value = target.value;
      }

      switch (form) {
        case 'password':
          this.$store.commit('user/updatePasswordValue', { value, property });
          break;
        default:
          this.$store.commit('user/updateFormValue', { value, property });
          break;
      }
    },
    onCodeChange($event) {
      if (!$event) return;
      const { target } = $event;
      const { value } = target;
      this.$store.commit('updatePopupProperty', {
        popup: 'verifyEmail', property: 'code', value
      });
    },
    async onSubmit(e, type = 'form') {
      e.preventDefault();
      this.validation(type);

      if (this.hasError[type]) return;

      switch (type) {
        case 'password':
          this.$store.dispatch('user/updatePassword');
          break;
        default:
          this.$store.dispatch('user/updateUser');
          break;
      }
    },
    onVerify() {
      this.$store.dispatch('verifyEmail');
    },
    validation(type = 'form') {
      this.resetErrors();
      this.hasError[type] = false;
      const form = this.$store.state.user[type];
      const errors = this.errors[type];
      const properties = {
        form: ['firstname', 'lastname', 'email' ],
        password: ['current', 'new', 'confirm' ]
      };

      for (let i = 0; i < properties[type].length; i++) {
        const property = properties[type][i];
        const value = form[property];

        switch (property) {
          case 'confirm':
            if (!value || !value.length) {
              errors[property] = this.$t('notifications.errors.required');
              this.hasError[type] = true;
              break;
            }
            
            const newPwd = form.new;
            if (newPwd !== value) {
              errors[property] = this.$t('notifications.errors.passwords_not_the_same');
              this.hasError[type] = true;
            }

            break;
          case 'email':
            if (!value || !value.length) {
              errors[property] = this.$t('notifications.errors.required');
              this.hasError[type] = true;
              break;
            }

            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(value)) {
              errors[property] = this.$t('notifications.errors.email_invalid');
              this.hasError[type] = true;
            }
            break;

          default:
            if (!value || !value.length) {
              errors[property] = this.$t('notifications.errors.required');
              this.hasError[type] = true;
            }
            break;
        }
      }
    },
    resetErrors() {
      this.$store.commit('user/resetErrors');
      this.hasError.form = false;
      this.hasError.password = false;
      this.errors = {
        form: {
          firstname: null,
          lastname: null,
          email: null,
        },
        password: {
          confirm: null,
          new: null,
          current: null
        }
      };
    },
    onCancel(type = 'form') {
      if (type === 'password') {
        this.$store.commit('user/resetPassword');
        return;
      }

      const { initialForm } = this.$store.state.user;
      this.$store.commit('user/updateForm', { ...initialForm });
    },
    closePopup(popup) {
      this.$store.commit('updatePopupProperty', { popup: 'confirmChange', property: 'visible', value: false });
      this.$store.commit('updatePopupProperty', { popup: 'confirmChange', property: 'success', value: false });
      this.$store.commit('updatePopupProperty', { popup: 'verifyEmail', property: 'visible', value: false });
      this.$store.commit('updatePopupProperty', { popup: 'verifyEmail', property: 'success', value: false });
      this.$store.commit('updateErrors', { form: 'verifyEmail', property: 'code', error: null });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-profile {
  .main-container-form {
    position: relative;
  }

  // .main-container-loader {}

  .h4 {
    &:not(:first-child) {
      margin-top: 40px;
      padding-top: 40px;
      border-top: 1px solid $lightestgrey;
    }
  }

  .button:not(.button-popup) {
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
    // margin-top: 30px;
    // margin-bottom: 30px;
  }

  .form--error {
    margin-top: 40px;
    margin-bottom: -10px;
    color: $error;
  }
}
</style>
