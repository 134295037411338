import axios from 'axios';

const uploadBookings = async (file) => {
    const form = new FormData();
    form.append('File', file);

    return await await axios.post(`/admin/booking-processing/excel-file`, form)
    .then((promise) => {
      if(promise.status === 200) return promise.data
    }).catch(error => {
      console.log('error :', error);
      return {
        hasError: true,
        error
      }
    });
  }

export {
    uploadBookings
}