<template>
  <router-view v-if="$route.name !== 'Partners'"/>
  <section
    v-else
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <table class="table" cellspacing="0">
      <THead
        :head="thead"
        :order="order"
        @onOrder="updateOrder"
      />
      <tbody>
        <tr
          v-for="partner in partners"
          :key="partner.id"
          :class="[{ 'has-separation': partner.separation }]"
        >
          <td><span class="grey">{{partner.name}}</span></td>
          <td><span class="grey">{{partner.vatnumber}}</span></td>
          <td><span class="grey">{{partner.contactName}}</span></td>
          <td><span class="grey">{{partner.contactEmail}}</span></td>
          <td><span class="grey">{{partner.contactPhone}}</span></td>
          <td><span class="grey">{{partner.flex}}</span></td>
          <td class="td--buttons" style="text-align: left;">
            <router-link class="button button--small" :to="{name: 'editPartner', params: { id:partner.id } }">{{ $t('partner.list.buttons.edit') }}</router-link>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <Loader />
    </div>
  </section>
</template>

<script>
import Loader from '@/components/elements/Loader';
import THead from '@/components/elements/THead';

export default {
  name: "ListPartner",
  components: {
    THead,
    Loader
  },
  beforeMount() {
    this.$store.commit('partners/updateSearch', '');
    this.getData();
  },
  computed: {
    partners() {
      const { order, list } = this.$store.state.partners;
      const partners = list
        .filter(o => o)
        .map(o => {
          o.orderProps = {
            name: o?.name ? o.name.trim() : '-',
            vatnumber: o?.vatnumber ? o.vatnumber.trim() : '-',
            contactName: o?.contactName ? o.contactName.trim() : '-',
            contactEmail: o?.contactEmail ? o.contactEmail.trim() : '-',
            contactPhone: o?.contactPhone ? o.contactPhone.trim() : '-',
            flex: o?.flex ? o.flex : false,
          }
        return o;
      });

      const ordereds = partners.sort((a, b) => {
        const aProp = typeof a.orderProps[order.type] === 'string' ? a.orderProps[order.type].toUpperCase() : a.orderProps[order.type];
        const bProp = typeof b.orderProps[order.type] === 'string' ? b.orderProps[order.type].toUpperCase() : b.orderProps[order.type];
        if(aProp < bProp) return order.dir === 'asc' ? -1 : 1;
        if(aProp > bProp) return order.dir === 'asc' ? 1 : -1;
        return 0;
      });

      return ordereds;
    },
    loading() {
      return this.$store.state.partners.requests !== 0;
    },
    order() {
      return this.$store.state.partners.order;
    },
    thead() {
      return [
        {
          title: this.$t('partner.list.columns.name'),
          class: 'small head',
          type: 'name'
        },
        {
          title: this.$t('partner.list.columns.vat'),
          class: 'small head',
          type: 'vatnumber'
        },
        {
          title: this.$t('partner.list.columns.contact_name'),
          class: 'small head',
          type: 'contactName'
        },
        {
          title: this.$t('partner.list.columns.contact_email'),
          class: 'small head',
          type: 'contactEmail'
        },
        {
          title: this.$t('partner.list.columns.contact_phone'),
          class: 'small head',
          type: 'contactPhone'
        },
        {
          title: this.$t('partner.list.columns.flex'),
          class: 'small head',
          type: 'flex'
        },
        {
          title: this.$t('partner.list.columns.edit'),
          class: 'big-col head head-hidden'
        },
      ]
    },
    message() {
      return this.$t('partners.list.no_partner');
    }
  },
  watch: {
    $route() {
      this.$store.commit('partners/updateSearch', '');
      this.getData();
    },
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    getData() {
      if(this.$route.name.toLowerCase() === 'partners') {
        this.$store.dispatch('partners/set');
      }
    },
    updateOrder(e) {
      this.$store.commit('partners/updateOrder', e);
    }
  }
}
</script>

<style>
</style>