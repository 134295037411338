<template>
    <div class="auto-complete" ref="autoComplete">
        <div class="input-wrapper" @click="focusToInput">
            <Sprite :type="'magnify-glass'" :width="24" :height="24" />
            <input ref="input" :placeholder="placeholder" :value="value" class="input-el auto-complete" @input="onInput"
                :class="{ 'show-popup': showPopup }" />
        </div>
        <div class="auto-complete options-wrapper" v-if="showPopup" @scroll="handleScroll">
            <div class="item-wrapper" v-for="item in items" :key="`item-${item}`">
                <div class="item-row" @click="onClick(item)">
                    <span class="label">{{ item.parkingLabel }}</span>
                </div>
            </div>
            <div class="item-wrapper" v-if="items.length === 0 && showPopup && !showLoader">
                <div class="item-row">
                    <span class="label">{{ $t('partner.list.no_parking') }}</span>
                </div>
            </div>
            <loader v-if="showLoader" />
        </div>

    </div>
</template>

<script>
import Loader from '@/components/elements/Loader';
import Sprite from '@/components/elements/Sprite';
import axios from 'axios';
export default {
    name: 'AutoComplete',
    components: {
        Loader,
        Sprite
    },
    data() {
        return {
            showLoader: false,
            value: null,
            currentPage: 1,
            showPopup: false,
            items: [],
            axiosSource: null,
            stopLoad: false
        }
    },
    mounted() {

        document.addEventListener('click', this.handleClickOutside);
    },
    unmounted() {
        document.removeEventListener('click', this.handleClickOutside);

    },
    props: {
        label: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
        },
        onSelected: {
            type: Object
        },
        excludesCountries: {
            type: Array
        },
        refresh: {
            type: Number
        }

    },
    computed: {

    },
    watch: {
        refresh(newValue) {
            if (newValue) {
                this.init();
            }
        },
        items(newValue) {
            if (newValue?.length === 0) {
                this.currentPage = 0;
            }
        }
    },
    methods: {
        focusToInput() {
            this.$refs.input.focus()
        },
        onClick(e) {

            if (this.onSelected)
                this.onSelected(e);
            this.init();
            this.value = e.parkingLabel;
        },
        init() {
            this.currentPage = 0;
            this.items = []
            this.value = '';
            this.showPopup = false;
        },
        async onSearchParking() {

            const self = this;
            if (this.axiosSource) {
                this.axiosSource.cancel();
                this.axiosSource = null;
            }
            this.axiosSource = axios.CancelToken.source();
            let excludeCountries = "?";
            for (const i of this.$store.state.partner.form.countries) {
                excludeCountries += `&ExcludeCountries=${i}`
            }
            const pageSize = 20;
            return await axios.get(`/admin/partners/list-parkings${excludeCountries}`, {
                params: {
                    PageSize: pageSize,
                    CurrentPage: this.currentPage + 1,
                    SearchTerm: this.value,
                    PartnerId: this.$store.state.partner.id
                },
                cancelToken: this.axiosSource.token,
            })
                .then((promise) => {
                    if (promise.status === 200) {
                        self.items = [...self.items, ...promise.data.datas];
                        self.currentPage = self.items.length / pageSize;
                        console.log(promise.data.datas.length, promise.data.datas <= pageSize)
                        if (promise.data.datas.length != pageSize)
                            self.stopLoad = true;
                        self.showLoader = false;
                        self.loading = false;
                        self.showLoader = false;
                        return promise.data.datas
                    }
                }).catch(error => {
                    console.error(error);
                });
        },
        onFocus(e) {
            if (this.value?.length > 2) {
                this.onInput({
                    currentTarget: {
                        value: this.value
                    }
                })
            }
        },
        async onInput(e) {
            const { value } = e.currentTarget;
            this.value = value;
            if (value.length > 1) {
                this.stopLoad = false;
                this.showPopup = true;
                this.showLoader = true;
                this.currentPage = 0;
                this.items = [];
                await this.onSearchParking();
            } else {
                this.showPopup = false;
                this.showLoader = false;
            }
            if (this.maxLength && value.length > this.maxLength) {
                e.preventDefault();
                e.stopPropagation();
                this.$refs.input.value = this.value;
            }
        },
        handleScroll(event) {
            const scrollBottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight;
            if (scrollBottom <= 250 && !this.loading) {
                this.loading = true;
                this.loadMoreData();
            }
        },
        async loadMoreData() {
            const self = this;
            if (this.stopLoad)
                return;
            this.showLoader = true;
            this.loading = true;
            await this.onSearchParking();
        },
        handleClickOutside(event) {
            if (this.$refs.autoComplete && !this.$refs.autoComplete.contains(event.target)) {
                this.showPopup = false;
                this.showLoader = false;
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.auto-complete {
    width: 100%;
    margin-top: 30px;

    .input-el {
        &.show-popup {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    .input-wrapper {
        position: relative;
        background: white;
        border: 1px solid #E2E2EA;
        border-radius: 3px;
        padding: 16px 16px 16px;
        display: flex;
        flex-flow: inherit;
        align-items: flex-start;

        input {
            border: none;
            padding: 0;
            width: 92%;
            margin: 0;
            margin-left: 1rem;
            height: 2rem;
        }
    }

    .options-wrapper {
        margin-top: -4px;
        max-height: 300px;
        overflow-y: auto;
        display: flex;
        align-items: center;
        align-content: center;
        justify-items: center;
        flex-direction: column;
        border: 1px solid #E2E2EA;
        border-radius: 3px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .item-wrapper {
            width: 100%;

            .item-row {
                padding: 0.5rem;
                cursor: pointer;
                width: 100%;

                &:hover {
                    background-color: $mediumgreen;

                    .label {
                        color: white !important;
                    }
                }
            }
        }
    }

    .loader {
        width: 2rem;
        height: 2rem;
        min-height: 2rem;
        margin: 1rem 1rem;
    }
}
</style>