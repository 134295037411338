<template>
    <section ref="container" class="main-container main-container__form main-container__new"
        :class="{ 'main-container--loading': loading }">
        <form novalidate @submit.prevent="onSave">
            <!-- <h2 class="h5">Edit</h2> -->
            <fieldset>
                <h2 class="h5">{{ $t('partner.form.labels.partner_information') }}</h2>
                <div class="container-row">
                    <Inputs ref="name" name="name" type="text" :label="$t('partner.form.labels.name')" :error="errors.name"
                        :required="true" :value="form.name" @input="onInput($event, 'name')" />
                </div>
                <div class="container-row">
                    <Inputs ref="vatnumber" name="vatnumber" type="text" :label="$t('partner.form.labels.vat')"
                        :error="errors.vatnumber" :required="true" :value="form.vatnumber"
                        @input="onInput($event, 'vatnumber')" />
                </div>
            </fieldset>
            <br>
            <fieldset>
                <h2 class="h5">{{ $t('partner.form.labels.contact') }}</h2>
                <div class="container-row">
                    <Inputs ref="contactName" name="contactName" type="text" :label="$t('partner.form.labels.contact_name')"
                        :error="errors.contactName" :required="true" :value="form.contactName"
                        @input="onInput($event, 'contactName')" />
                </div>
                <div class="container-row">
                    <Inputs ref="contactEmail" name="contactEmail" type="text"
                        :label="$t('partner.form.labels.contact_email')" :error="errors.contactEmail" :required="false"
                        :value="form.contactEmail" @input="onInput($event, 'contactEmail')" />
                </div>
                <div class="container-row">
                    <Inputs ref="contactPhone" name="contactPhone" type="text"
                        :label="$t('partner.form.labels.contact_phone')" :error="errors.contactPhone"
                        :value="form.contactPhone" @input="onInput($event, 'contactPhone')" />
                </div>
                <div class="container-row">
                    <Checkbox name="flex" :label="$t('partner.form.labels.flex')" :required="false" :value="form.flex"
                        @input="onInput($event, 'flex')" />
                </div>
                <div class="container-row container-8-2">
                    <Inputs ref="apiKey" name="apiKey" type="text" :label="$t('partner.form.labels.api_key')"
                        :error="errors.apiKey" :required="true" :value="form.apiKey" :readonly="true"
                        @input="onInput($event, 'apiKey')" />

                    <a class="button" type="button" @click="generate('apiKey')">
                        {{ $t('partner.form.buttons.generate') }}
                    </a>
                </div>

               
                <div class="container-row" >
                    <AutoCompleteFleet :onSelected="onSelectedFleet" :refresh="refresh" :editMode="fleetName !== null && fleetName !== ''" :editLabel="fleetName"
                    :label="$t('partner.form.labels.partner_fleet')"
                    :noFoundlabel="$t('partner.list.no_fleet')" />
                    
                </div>
                <!-- <div class="container-row">
                    <Checkbox name="canAccessStandardApi" :label="$t('partner.form.labels.canAccessStandardApi')" :required="false" :value="form.canAccessStandardApi"
                        @input="onInput($event, 'canAccessStandardApi')" />
                </div> -->

                <div class="container-row custom-multiselect">
                    <SelectMultiple :option="countries" :selectedValues="form.countries"
                        @onInput="onInput($event, 'countries')" :label="$t('partner.form.labels.countries_exclude')" />

                </div>
                <div class="container-row center">
                    <div v-if="loadingScreen">
                        <loader />
                    </div>
                </div>
                <div class="container-row">
                    <label class="label">{{ $t('partner.form.labels.parking_settings') }}</label>
                </div>
                <div class="container-row parking-wrapper">
                    <RadioButton name="parkingSetting" :label="$t('partner.form.labels.parking_setting_none')"
                        :required="false" :value="parking_setting === 'None'"
                        @input="onInput({ target: { value: 'None' } }, 'parking_setting')" />
                    <RadioButton name="parkingSetting_exclude" :label="$t('partner.form.labels.parking_setting_exclude')"
                        :required="false" :value="parking_setting === 'Exclusion'"
                        @input="onInput({ target: { value: 'Exclusion' } }, 'parking_setting')" />
                    <RadioButton name="parkingSetting_include" :label="$t('partner.form.labels.parking_setting_include')"
                        :required="false" :value="parking_setting === 'Inclusion'"
                        @input="onInput({ target: { value: 'Inclusion' } }, 'parking_setting')" />
                </div>

                <div class="container-row container-8-2" v-if="parking_setting !== 'None'">
                    <AutoComplete :onSelected="onSelected" :excludesCountries="form.countries" :refresh="refresh" />
                    <a class="button" type="button" @click="AddParking" :class="{ disabled: !disabledForm }">
                        {{ $t('partner.form.buttons.add_parking') }}
                    </a>
                </div>

                <div class="container-row" v-if="parking_setting !== 'None'">
                    <TableParkingPartner @on-sort="onSorting" :id="id" :refresh="refresh"
                        @parkings-chaged="parkingsChanged" />
                </div>
            </fieldset>

            <button class="button" type="submit">
                {{ $t('partner.form.buttons.save') }}
            </button>

            <div v-if="hasError" class="form--error small">
                {{ $t('notifications.errors.several_required') }}
            </div>
        </form>

        <div v-if="loading" class="main-container-loader">
            <loader />
        </div>
        <Popup v-if="confirmation">
            <template v-slot:content>
                <h4 class="popup-title">{{ $t('globals.popup.confirmation_change_parking_type') }}</h4>
                <div class="popup-actions">
                    <button type="button" class="button button-popup button-border" @click.prevent="onPopupClose">
                        {{ $t('globals.no') }}
                    </button>
                    <button type="submit" class="button button-popup" @click.prevent="onPopupConfirm">
                        {{ $t('globals.yes') }}
                    </button>
                </div>
            </template>
        </Popup>
    </section>
</template>

<script>
import { isValidPhoneNumber, parsePhoneNumber, ParseError } from 'libphonenumber-js'
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
import Checkbox from '@/components/elements/Checkbox';
import RadioButton from '@/components/elements/RadioButton';
import SelectMultiple from '@/components/elements/SelectMultiple';
import AutoComplete from '@/components/elements/AutoComplete';
import AutoCompleteFleet from '@/components/elements/AutoCompleteFleet';
import Popup from '@/components/partials/Popup';
import TableParkingPartner from './TableParkingPartner';
import axios from 'axios';
export default {
    components: {
        Loader,
        Inputs,
        Checkbox,
        RadioButton,
        SelectMultiple,
        AutoComplete,
        AutoCompleteFleet,
        TableParkingPartner,
        Popup
    },
    state: {
        countries: [],
    },
    beforeMount() {
        const { id } = this.$route.params;
        this.$store.commit('partner/resetForm');
        this.$store.commit('partner/updateId', id);
        this.$store.dispatch('partner/getData');
        setTimeout(() => {
            this.form = this.$store.state.partner.form;
            this.id = this.$store.state.partner.id;
        }, 500);
        this.form = this.form || {}
    },

    data() {
        return {
            hasError: false,
            confirmation: false,
            id: null,
            refresh: null,
            errors: {
                name: '',
                vatnumber: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
            },
            form: {
                countries: [],
            },
            partnerParking: {

            },
            parkingId: '',
            parking_setting: '',
            temp_parking_setting: '',
            selectedCountries: null,
            loadingScreen: false,
            previousCountries: [],
            disableCheckExcludeCountries: false
        }
    },
    computed: {
        loading() {
            return this.$store.state.partner.requests !== 0;
        },
        countries() {
            const countries = this.$store.state.settings.minimalCountries;
            countries.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
            });

            return countries;
        },
        disabledForm() {
            return this.parkingId !== '';
        },
        fleetName(){            
            return this.$store.state.partner?.data?.fleetName
        }
    },
    watch: {
        loading() {
            if (this.$refs.container) {
                this.$refs.container.scrollTo(0, 0);
            }
        },
        countries: {
            handler() {
                this.form = this.$store.state.partner.form
            },
            immediate: true
        },
        partnerParking(newValue) {
            if (newValue)
                this.parkingId = newValue.parkingId
            else
                this.parkingId = ''
        }, form(newValue) {

            if (newValue) {
                setTimeout(() => {                    
                    this.parking_setting = newValue.parkingPartner?.linkedParkings?.length > 0 ? (newValue.parkingPartner.onExclusion ? 'Exclusion' : 'Inclusion') : 'None'
                }, 1000);

            }
        }, selectedCountries(newValue, prev) {
            if (this.disableCheckExcludeCountries)
                return;
            if (!prev)
                prev = this.previousCountries;
            if (newValue && prev && newValue.length < prev.length) {
                let difference = prev.filter(x => !newValue.includes(x));
                this.disableCheckExcludeCountries = true;
                this.checkCountryInParkingPartner(prev, difference,'can_remove_country');
            } else if (newValue && prev && newValue.length > prev.length) {
                let difference = newValue.filter(x => !prev.includes(x));
                this.disableCheckExcludeCountries = true;
                this.checkCountryInParkingPartner(prev, difference,'can_add_country');

            }
            const self = this;
            setTimeout(() => {
                self.disableCheckExcludeCountries = false;
            }, 1000);
        }


    },
    methods: {
        parkingsChanged(parkings) {
            const temp = this.form ?? {};
            temp.parkingPartner = temp.parkingPartner ?? {}
            temp.parkingPartner.linkedParkings = parkings;
            this.form = temp;
        },
        onPopupClose() {
            this.ownerId = '';
            this.confirmation = false;
        },
        async checkCountryInParkingPartner(arrayCountry, difference,message) {
            this.loadingScreen = true;
            await axios.get(`/admin/partners/${this.id}/parking/check-country/${difference[0]}`)
                .then((promise) => {
                    if (promise.status === 200) {
                        if (promise.data) {
                            this.$store.commit('partner/updateFormValue', { value: arrayCountry, property: 'countries' });
                            this.selectedCountries = arrayCountry
                            this.$store.dispatch('notifications/addNotifs', [{
                                text: this.$t('partner.list.'+message),
                                type: 'error'
                            }], { root: true });
                        }
                        this.loadingScreen = false;
                        return promise.data
                    }
                }).catch(error => {
                    this.$store.dispatch('notifications/addNotifs', [{
                        text: error.message,
                        type: 'error'
                    }], { root: true });
                    this.loadingScreen = false;
                });
        },
        async onPopupConfirm() {
            const self = this;
            await axios.delete(`/admin/partners/${this.id}/parking/clear`)
                .then((promise) => {
                    if (promise.status === 200) {
                        self.parking_setting = self.temp_parking_setting;
                        self.confirmation = false;
                        self.refresh = (self.refresh ?? 0) + 1;
                        const temp = self.form ?? {};
                        temp.parkingPartner.linkedParkings = [];
                        self.form = temp;
                        return promise.data
                    }
                }).catch(error => {
                    this.$store.dispatch('notifications/addNotifs', [{
                        text: error.message,
                        type: 'error'
                    }], { root: true });
                });

        },
        onSelected(e) {
            this.partnerParking = e;
        },
        onSelectedFleet(e) {            
            this.$store.commit('partner/updateFormValue', { value: e.fleetId, property: 'fleetId'});
        },
        validate() {
            this.hasError = false;
            this.errors = {};

            const properties = [
                'name',
                'vatnumber',
                'contactName',
                'contactEmail',
                'contactPhone'                
            ];

            for (let i = 0; i < properties.length; i++) {
                const property = properties[i];
                const data = this.form[property];

                if ((property === 'name' || property === 'vatnumber' || property === 'contactName') && (!data || !data.length)) {
                    this.hasError = true;
                    this.errors[property] = this.$t('notifications.errors.required');
                } else {
                    if (property === 'contactEmail' && (data || data.length)) {
                        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                        if (!regex.test(data)) {
                            this.hasError = true;
                            this.errors[property] = this.$t('notifications.errors.email_invalid');
                        }
                    } else if (property === 'contactPhone' && (data || data.length)) {
                        try {
                            parsePhoneNumber(data);
                        } catch (error) {
                            if (error instanceof ParseError) {
                                this.errors.contactPhone = this.$t('notifications.errors.phone_invalid')
                            }
                        }
                        if (!this.errors.contactPhone) this.errors.contactPhone = !isValidPhoneNumber(data) ? "Please enter a valid phone number" : '';
                        if (this.errors.contactPhone) this.hasError = true;
                    }
                }
            }
        },
        onInput($event, property) {
            if (property === 'parking_setting') {
                this.temp_parking_setting = $event.target.value;               
                if (this.form.parkingPartner?.linkedParkings?.length > 0) {
                    this.confirmation = true;
                } else {
                    this.parking_setting = $event.target.value;
                }

            }
            else if (property === "countries") {
                this.selectedCountries = $event;
                this.previousCountries = this.$store.state.partner.form.countries;
                this.$store.commit('partner/updateFormValue', { value: $event, property });
            } else {
                const { target } = $event;
                if (!target && (property !== 'partnerId')) return;

                switch (true) {
                    case /flex/.test(property):
                    case /canAccessStandardApi/.test(property):
                        this.$store.commit('partner/updateFormValue', { value: target.checked, property });
                        break;
                    default:
                        const { value } = target;
                        this.$store.commit('partner/updateFormValue', { value, property });
                        break;
                }
            }
        },
        generate(property) {
            const value = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = crypto.getRandomValues(new Uint8Array(1))[0] % 16 | 0;
                const v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
            this.$store.commit('partner/updateFormValue', { value, property });
        },
        async AddParking() {
            await axios.post(`/admin/partners/parking`, {
                partnerId: this.id,
                parkingId: this.partnerParking.parkingId,
                linkType: this.parking_setting
            })
                .then((promise) => {
                    if (promise.status === 200) {
                        this.refresh = (this.refresh ?? 0) + 1;
                        this.parkingId = '';
                        this.partnerParking = null;
                        return promise.data
                    }
                }).catch(error => {
                    this.$store.dispatch('notifications/addNotifs', [{
                        text: error.message,
                        type: 'error'
                    }], { root: true });
                });
        },
        async onSave() {
            this.validate();

            if (this.hasError) return;
           
            const request = await this.$store.dispatch('partner/saveData');
        }
    }
}
</script>

<style lang="scss" scoped>
.main-container {
    padding-bottom: 50px;
}

.button {
    margin-top: 30px;
}

.small.form--error {
    margin-top: 20px;
    color: $error;
}

.parking-wrapper {
    margin-top: -0.25em;
    display: flex;
}

.center {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
</style>

<style lang="scss">

</style>