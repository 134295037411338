<template>
<transition name="fade">
  <aside 
    v-if="isVisible"
    class="modal"
    :class="isVisible ? 'modal--visible' : ''"
  >
    <div class="modal-container">
      <div class="abbr"><span>{{ abbr }}</span></div>
      <h3 class="h4">
        {{parking}}
      </h3>
      <Select
        ref="Company"
        :name="$t('company.modal.unassigned.label')"
        :options="companies"
        :placeholder="company ? company : $t('company.modal.unassigned.select')"
        :required="true"
        :class="'classic'"
        @change="handleChange"
      />
    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        class="button button-primary"
        @click="onClick"
      >
       {{$t('company.modal.unassigned.cancel')}}
      </button>
      <button-save 
        :loading="loading"
        :labels="{
          default: $t('company.modal.unassigned.confirm'),
          end: $t('company.modal.unassigned.saved')
        }"
        class="button-primary"
        :class="{'disabled': !canSave}"
        @click="onSubmit"
      />
    </div>
    <div class="modal-backdrop" @click="onClick"></div>
  </aside>
</transition>
</template>

<script>
import Select from '@/components/elements/Select';
import ButtonSave from '@/components/elements/ButtonSave';

export default {
  name: "Modal",
  components: {
     ButtonSave,
     Select,
  },
  data() {
    return {
      loading: false,
      canSave: false,
      selected: null,
    }
  },
  watch: {
    isVisible() {
      this.loading = false;
    }
  },
  computed: {
    isVisible() {
      return this.$store.state.companies.modalUnassign.visible;
    },
    company() {
      return this.$store.state.companies.modalUnassign.company;
    },
    companies() {
      return this.setForSelect(this.$store.state.companies.modalUnassign.company);
    },
    parking() {
      return this.$store.state.companies.modalUnassign.parking;
    },
    abbr() {
      return this.setAbbr(this.$store.state.companies.modalUnassign.parking);
    },
    id() {
      return this.$store.state.companies.modalUnassign.id;
    }
  },
  watch: {
    company(val) {
    }
  },
  methods: {
    setAbbr(str) {
      let matches = "";
      if(!str) return;
      matches = str.match(/[A-Z]/g, '');
      if(matches) matches = matches.join('');
      if(matches) matches = matches.substring(0, 2);
      return matches;
    },
    setForSelect(id) {
      const companyName = this.$store.state.companies.byID[id];
      const tmpArr = [{
        value: id,
        label: companyName
      }]

      tmpArr.push({
        value: 0,
        label: "unassigned"
      });
      return tmpArr;
    },
    handleChange(value) {
      this.selected = value;
      this.canSave = this.selected === 0
    },
    onClick() {
      this.$store.commit('companies/updateModalUnassign', {
        company: null,
        parking: null,
        id: null
      });
      this.canSave = false;
      this.selected = null;
    },
    onClose(evt) {
      const {target} = evt;
      if(target === this.$el) this.onClick();
    },
    onSubmit() {
      this.loading = true;
      const update = this.$store.dispatch('parkings/update', {
        id: this.id,
        item: {
          parkingCompanyId: 0
        }
      });
      update.then(() => {
        this.loading = 'finish';
        this.$store.dispatch('companies/set')
        this.onClick();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s $ease-out-quad;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
