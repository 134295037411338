<template>
  <section
    ref="container"
    class="main-container main-container__form main-container__new"
    :class="{ 'main-container--loading': loading }"
  >
    <form
      novalidate
      @submit.prevent="onSave"
    >
      <!-- <h2 class="h5">Edit</h2> -->
      <fieldset>
        <div class="container-row container-5-5">
          <Inputs
            ref="firstname"
            name="firstname"
            type="text" 
            :label="$t('fleet.form.labels.firstname')"
            :error="errors.firstname"
            :required="true"
            :value="form.firstname"
            @input="onInput($event, 'firstname')"
            :readonly="true"
          />
          <Inputs
            ref="lastname"
            name="lastname"
            type="text" 
            :label="$t('fleet.form.labels.lastname')"
            :error="errors.lastname"
            :required="true"
            :value="form.lastname"
            @input="onInput($event, 'lastname')"
            :readonly="true"
          />
        </div>
        <div class="container-row container-5-5">
          <Inputs
            ref="email"
            name="email"
            type="text" 
            :label="$t('fleet.form.labels.email')"
            :error="errors.email"
            :required="true"
            :value="form.email"
            @input="onInput($event, 'email')"
            :readonly="true"
          />

          <!-- <Select
            ref="fleetCompanyId"
            :name="'fleetCompanyId'"
            :label="$t('fleet.form.labels.fleet')"
            :options="fleets"
            :placeholder="fleet ? fleet.name : $t('fleet.form.labels.fleet_select')"
            :error="errors.fleetCompanyId"
            :required="false"
            :class="'classic'"
            @change="onInput($event, 'fleetCompanyId')"
          /> -->
          <NewSelect
            :name="'fleetCompanyId'"
            :label="$t('fleet.form.labels.fleet')"
            :options="fleets"
            :placeholder=" $t('fleet.form.labels.fleet_select')"
            :value="fleet"
            :error="errors.fleetCompanyId"
            :class="'classic'"
            @onChange="onInput($event, 'fleetCompanyId')"
            :readonly="true"
          />
        </div>
      </fieldset>

      <button
        class="button"
        type="submit"
        disabled
      >
        {{$t('fleet.form.buttons.save')}}
      </button>

      <div 
        v-if="hasError"
        class="form--error small"
      >
        {{$t('notifications.errors.several_required')}}
      </div>
    </form>

    <div
      v-if="loading"
      class="main-container-loader"
    >
      <Loader />
    </div>
  </section>
</template>

<script>
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
// import Select from '@/components/elements/Select';
import NewSelect from '@/components/elements/NewSelect';

export default {
  components: {
    // Select,
    NewSelect,
    Loader,
    Inputs
  },
  beforeMount() {
    const { id } = this.$route.params;

    this.$store.commit('fleetPlanner/resetForm');
    this.$store.commit('fleetPlanner/updateId', id);
    this.$store.dispatch('fleetPlanner/getData');
    this.$store.dispatch('fleetPlanner/getFleets');
  },
  data() {
    return {
      hasError: false,
      errors: {
        firstname: '',
        lastname: '',
        email: '',
        fleet: ''
      }
    }
  },
  computed: {
    form() {
      return this.$store.state.fleetPlanner.form;
    },
    fleet() {
      const { fleets } = this.$store.state.fleetPlanner;
      const { fleetCompanyId } = this.$store.state.fleetPlanner.form;

      if (!fleets.length || !fleetCompanyId) return null;
      const fleet = fleets.filter(f => f.id === fleetCompanyId);

      if(!fleet.length) return null;
      return fleet[0].id;
    },
    fleets() {
      const fleets = this.$store.state.fleetPlanner.fleets.map(f => {
        return {
          value: f.id,
          label: f.name || this.$t('globals.no_name')
        }
      });

      fleets.unshift({
        value: 0,
        label: this.$t('owners.form.unassigned')
      });

      return fleets;
    },
    loading() {
      return this.$store.state.fleetPlanner.requests !== 0;
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    validate() {
      this.hasError = false;
      this.errors = {};

      const properties = [
        'firstname',
        'lastname',
        'email'
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const data = this.form[property];

        switch (true) {
          case /email/.test(property):
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');
              break;
            }

            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(data)) {
              this.errors[property] = this.$t('notifications.errors.email_invalid');
            }

            break;
          default:
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');
            }
            break;
        }
      }
    },
    onInput($event, property) {
      const { target } = $event;
      if (!target && property !== 'fleetCompanyId') return;

      switch (true) {
        case /fleetCompanyId/.test(property):
          this.$store.commit('fleetPlanner/updateFormValue', { value: $event, property });
          break;
        default:
          const { value } = target;
          this.$store.commit('fleetPlanner/updateFormValue', { value, property });
          break;
      }
    },
    async onSave() {
      this.validate();

      if (this.hasError) return;

      const request = await this.$store.dispatch('fleetPlanner/saveData');
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    padding-bottom: 50px;
  }
  .button {
    margin-top: 20px;
  }
</style>
