import axios from 'axios';

const get = (id) => {
  return axios.get(`/admin/admins/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};
const createAdmin = (item) => {
  return axios.post(`/admin/admins`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};
const list = async (search) => {
  return await axios.get(`/admin/admins`, {
    params: { search }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const update = (id, item) => {
  return axios.patch(`/admin/admins/${id}`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const getAdmin = (id) => {
  // return axios.get(`/admin/parkings/${id}`) => translation not returned
  return axios.get(`/admin/admins/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const patchAdmin = (id, item) => {
  return axios.patch(`/admin/admins/${id}`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);

    return {
      hasError: true,
      error
    }
  });
};


export {
  list,
  get,
  createAdmin,
  update,
  getAdmin,
  patchAdmin
};
