<template>
  <section
    ref="container"
    class="main-container main-container__form main-container__edit main-container__editAppHome"
    :class="{ 'main-container--loading': loading }"
  >
    <form
      v-if="form"
      novalidate
      @submit.prevent="onSave"
    >
      <!-- Informations -->
      <fieldset>
        <div class="container-row container-5-5">
          <div class="select-container">
            <label class="label small">{{ $t('mobile-app.home.labels.lang') }}</label>
            <NewSelect
              :name="'lang'"
              :options="languages"
              :value="lang"
              :class="'classic'"
              :placeholder="$t('mobile-app.home.labels.lang')"
              @onChange="onLangChange($event, 'country')"
            />
              <!-- :label="$t('mobile-app.home.labels.lang')" -->
              <!-- :placeholder="$t('parkings.form.placeholders.country')" -->
          </div>
        </div>
        <div class="container-row container-5-5">
          <Inputs
            name="mobile-title"
            type="text" 
            :label="$t('mobile-app.home.labels.title')"
            :error="errors.title"
            :value="form.title"
            :hasActivation="true"
            :isActive="activations.title"
            @onActivate="onActivation('title')"
            @input="onInput($event, 'title')"
          />
        </div>
        <div class="container-row container-5-5">
          <Inputs
            name="mobile-subtitle"
            type="textarea" 
            :label="$t('mobile-app.home.labels.subtitle')"
            :error="errors.subtitle"
            :value="form.subtitle"
            :hasActivation="true"
            :isActive="activations.subtitle"
            @onActivate="onActivation('subtitle')"
            @input="onInput($event, 'subtitle')"
          />
        </div>

        <div class="container-row container-5-5">
          <Inputs
            name="mobile-text"
            type="textarea" 
            :label="$t('mobile-app.home.labels.text')"
            :error="errors.text"
            :value="form.text"
            :hasActivation="true"
            :isActive="activations.text"
            @onActivate="onActivation('text')"
            @input="onInput($event, 'text')"
          />
        </div>
        <div class="container-row container-5-5">
          <Inputs
            name="mobile-url"
            type="text" 
            :label="$t('mobile-app.home.labels.button')"
            :placeholder="$t('mobile-app.home.placeholder.button-label')"
            :error="errors.buttonLabel"
            :value="form.buttonLabel"
            :hasActivation="true"
            :isActive="activations.button"
            @onActivate="onActivation('button')"
            @input="onInput($event, 'buttonLabel')"
          />
        </div>
        <div
          v-if="activations.button"
          class="container-row container-5-5"
        >
          <NewSelect
            :name="'buttonAction'"
            :label="$t('mobile-app.home.labels.button-action')"
            :placeholder="$t('mobile-app.home.placeholder.button-action')"
            :options="actions"
            :value="form.buttonAction"
            :class="'classic'"
            @onChange="onActionChange($event, 'buttonAction')"
          />
        </div>

        <div
          v-if="activations.button && form.buttonAction === 'custom'"
          class="container-row container-5-5"
        >
          <Inputs
            name="mobile-url"
            type="text" 
            :label="$t('mobile-app.home.labels.button-url')"
            :placeholder="$t('mobile-app.home.placeholder.button-url')"
            :error="errors.buttonUrl"
            :value="form.buttonUrl"
            @input="onInput($event, 'buttonUrl')"
          />
        </div>

        <!-- <div class="container-row container-5-5">
          <Inputs
            name="mobile-button"
            type="text" 
            label="Button"
            :error="errors.button"
            :value="form.button"
            :hasActivation="true"
            :isActive="activations.button"
            @onActivate="onActivation('button')"
            @input="onInput($event, 'button')"
          />
        </div> -->

        <div class="container-row container-5-5">
          <div>
            <label class="label small picture-activation-container">
              {{ $t('mobile-app.home.labels.image') }}

              <span class="picture-activation">
                <input
                  :checked="activations.image"
                  type="checkbox"
                  id="activation-image"
                  class="u-visually-hidden"
                  @input.stop="onActivation('image')"
                />
                <label for="activation-image">
                  <Sprite
                    :type="'check'"
                    :width="14"
                    :height="10"
                  />
                </label>
              </span>
            </label>

            <picture-upload
              v-if="activations.image"
              :id="`mobile-app-picture`"
              :label="$t('mobile-app.home.placeholder.image')"
              :format="'.jpg, .png'"
              :url="form.image"
              :img="form.image"
              :maxWidth="5000"
              :maxHeight="5000"
              @change="onImageChange($event, index)"
              @reset="onImageReset(index)"
            />
          </div>
        </div>

        <div
          v-if="form.buttonAction === 'SearchNavigation|NearbyMapScreen'"
          class="container-row container-5-5"
        >
          <NewSelect
            :name="'bookCountry'"
            :label="$t('mobile-app.home.labels.country')"
            :options="countries"
            :value="form.bookCountry"
            :error="errors.bookCountry"
            :placeholder="$t('mobile-app.home.placeholder.country')"
            :class="'classic'"
            :hasActivation="true"
            :isActive="activations.bookCountry"
            @onActivate="onActivation('bookCountry')"
            @onChange="onActionChange($event, 'bookCountry')"
          />
        </div>
      </fieldset>

      <div class="main-container__form-buttons">
        <button
          class="button"
          type="submit"
        >
          <!-- :disabled="disabled" -->
          {{$t('parkings.form.buttons.save.edit')}}
        </button>
      </div>

      <div 
        v-if="hasError"
        class="form--error small"
      >
        {{$t('notifications.errors.several_required')}}
      </div>
    </form>

    <div
      v-if="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
import Sprite from '@/components/elements/Sprite';
import NewSelect from '@/components/elements/NewSelect';
import PictureUpload from '@/components/elements/PictureUpload';

export default {
  name: 'EditHomeScreen',
  components: {
    Loader,
    Inputs,
    Sprite,
    NewSelect,
    PictureUpload
  },
  beforeMount() {
    const { lang } = this.$route.query;
    if (lang) this.$store.commit('mobileApp/updateLang', lang);
    this.$store.dispatch('mobileApp/getData');
  },
  data() {
    return {
      hasError: false,
    }
  },
  computed: {
    lang() {
      return this.$store.state.mobileApp.lang;
    },
    form() {
      return this.$store.state.mobileApp.form;
    },
    activations() {
      return this.$store.state.mobileApp.form.activations;
    },
    errors() {
      return this.$store.state.mobileApp.errors;
    },
    languages() {
      return this.$store.state.settings.languages.map(l => {
        return {
          value: l.code,
          label: l.nativeName
        }
      });
    },
    actions() {
      return [
        { value: 'ReconciliationNavigation|CheckInScreen', label: 'Check-in' },
        { value: 'SearchNavigation|NearbyMapScreen', label: 'Book a parking' },
        { value: 'SettingsNavigation|MyAccountScreen', label: 'My profile' },
        { value: 'SettingsNavigation|MyPaymentsScreen', label: 'My account balance' },
        { value: 'BookingNavigation|MyBookingsScreen', label: 'My stays' },
        { value: 'custom', label: 'Custom link' }
      ]
    },
    disabled() {
      return !this.form.title || !this.form.subtitle || !this.form.text;
      // return !this.form.title || !this.form.subtitle || !this.form.image.file || !this.form.text;
    },
    loading() {
      return this.$store.state.mobileApp.requests !== 0;
    },
    countries() {
      const countries = this.$store.state.settings.minimalCountries;
      countries.sort((a, b) => {
        if(a.label < b.label) return -1;
        if(a.label > b.label) return 1;
        return 0;
      });
      return countries;
    }
  },
  watch: {
     loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    },
  },
  methods: {
    onInput($event, type) {
      if (!$event) return;
      const { target } = $event;
      if (!target) return;
      this.$store.commit('mobileApp/updateFormValue', { property: type, value: target.value });
    },
    onActivation(type) {
      this.$store.commit('mobileApp/updateFormActivations', { property: type, value: !this.activations[type] });
    },
    onSave() {
      this.$store.dispatch('mobileApp/saveData');
    },
    onLangChange(e) {
      this.$store.commit('mobileApp/updateLang', e);
      this.$store.dispatch('mobileApp/getData');
    },
    onActionChange(e, type) {
      this.$store.commit('mobileApp/updateFormValue', { property: type, value: e });
    },
    onImageChange(e) {
      this.createImage(e);
    },
    onImageReset() {
      this.$store.commit('mobileApp/updateFormValue', { property: 'image', value: null });
    },
    createImage(file, index) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.$store.commit('mobileApp/updateFormValue', { property: 'image', value: e.target.result });
      };
      reader.readAsDataURL(file);
    }
  }
}
</script>

<style lang="scss" scoped>
.select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  margin-top: 60px;
}

.picture-activation-container {
  position: relative;
  margin-bottom: 20px !important;
}

.picture-activation {
  position: absolute;
  top: 0;
  right: 0;

  input:checked {
    + label {
      .svg {
        opacity: 1;
      }
    }
  }

  label {
    display: block;
    position: relative;
    width: 18px;
    height: 18px;
    cursor: pointer;
    transition: color .3s $ease-out-quart;
    font-size: rem(16px);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid rgba($grey, .4);
      border-radius: 3px;
    }

    .svg {
      position: absolute;
      top: 6px;
      left: 4px;
      transition: opacity .3s $ease-out-quart;
      opacity: 0;
    }
  }
}
</style>

<style lang="scss">
.main-container__editAppHome {
  .select-container {
    .select {
     min-width: 240px;

      .custom-select {
        padding: 14px 20px 12px;
      }
    }
  }
}
</style>