<template>
  <router-view v-if="$route.name.toLowerCase() !== 'teamadmins'"/>
  <section
    v-else
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <table class="table" cellspacing="0" >
      <thead>
        <tr>
        <th
          v-for="head in thead"
          :key="head.title" 
          :class="head.class"
        >
          {{head.title}}
        </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="admin in admins"
          :key="admin.id"
        >  
          <td>
            <span class="grey">{{ admin.firstName }}</span>
          </td>
          <td>
            <span class="grey">{{ admin.lastName }}</span>
          </td>
          <td>
            <span class="grey">{{ admin.email }}</span>
          </td>
          <td class="align-right">
            <router-link
              class="button button--small"
              :to="{name: 'editTeamAdmin', params: { id: admin.id } }"
            >
              {{$t('fleet.list.buttons.edit')}}
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import Loader from '@/components/elements/Loader';
import Owner from '@/components/partials/Owner';

export default {
  name: "ListParking",
  components: {
    Loader,
    Owner
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    admins() {
      return this.$store.state.teamAdmins.items;
    },
    loading() {
      return this.$store.state.teamAdmins.requests !== 0;
    },
    thead() {
      return [
        {title: this.$t('labels.first_name'), class:"small head"},
        {title: this.$t('labels.last_name'), class:"small head"},
        {title: this.$t('labels.email'), class:"small head"},
        {title: this.$t('labels.actions'), class:"buttons-col head head-hidden"},
      ]
    },
    message() {
      return this.$t('fleet.list.no_planner');
    }
  },
  watch: {
    $route() {
      this.getData();
    },
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    getData() {
      if(this.$route.name.toLowerCase() === 'teamadmins') {
        this.$store.dispatch('teamAdmins/set');
      }
    }
  }
}
</script>