<template>
  <!-- FILTERS -->
  <section class="main-filters">
    <Status v-if="status" />
    <div v-if="button || buttons.length > 0" class="main-filters-buttons">
      <!-- <Button v-if="button" :to="button.to" :label="$t(button.label)" :isDisabled="button.isDisabled"/> -->
      <Button
        v-if="button"
        :to="button.to"
        :label="$t(button.label)"
        :isDisabled="button.disableBasedSetting ? disableSnapReleaseButtons : button.isDisabled"
      />
      <button
        v-for="(button, index) in buttons"
        :key="`layout-button-${index}`"
        :disabled="button.disabled"
        class="button button-primary"
        @click.prevent="button.action()"
      >
        {{ button.label }}
      </button>
    </div>
    <QuickSearch
      v-if="quickSearch"
      ref="quickSearch"
      :currentList="currentList"
      @filtered="handleSearch"
      @search="onSearch"
    />
  </section>
</template>

<script>
import QuickSearch from '@/components/modules/QuickSearch';
import Status from '@/components/modules/Status';
import Button from '@/components/modules/Button';

export default {
  name: 'Filters',
  props: {
    quickSearch: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Object,
      default: () => {},
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    QuickSearch,
    Status,
    Button
  },
  computed: {
    filtersStored() {
      // return this.$store.state.bookings.filters;
      return []
    },
    currentList() {
      return []
      // return this.$store.state.bookings.currentBookingList;
    },
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  },
  watch: {
    currentList(arr) {
      return arr;
    }
  },
  data() {
    return {
      filters: {},
    }
  },
  mounted() {
    this.filters = this.filtersStored;
  },
  methods: {
    handleSearch(results) {
      this.filters.search = results;
      this.$store.commit('updateDynamicFilters', this.filters);
    },
    handleClick(active) {
      this.$store.commit('updateDynamicFilters', {
        dates: {
          start: null,
          end: null,
        },
        search: null
      });
      this.$store.commit('updateCurrentTab', active);
    },
    onSearch(value) {
      const { name } = this.$route;
     
      switch (name) {
        case 'Companies':
          this.$store.commit('companies/updateSearch', value);
          this.$store.dispatch('companies/set', value);
          break;
        case 'Parkings':
          this.$store.commit('parkings/updateSearch', value);
          this.$store.dispatch('parkings/getParkings');
          break;
        case 'fleetPlanners':          
          this.$store.commit('fleetPlanners/updateSearch', value);
          this.$store.dispatch('fleetPlanners/set', value);
          break;
        case 'Partners':
          this.$store.commit('partners/updateSearch', value);
          this.$store.dispatch('partners/set', value);
          break;
        default:
          // Owners
          this.$store.commit('owners/updateSearch', value);
          this.$store.dispatch('owners/set', value);
        break;
      }
      
      
    }
  }
}
</script>

<style lang="scss" scoped>
.main-filters {
  .main-filters-buttons {
    display: flex;
    align-items: center;
    margin-right: auto;

    .button {
      margin-right: 24px;
    }
  }

  .button {
    width: auto;
    padding: 18px 18px 16px;
    margin: 0 auto 0 0;
  }

  .stats {
    margin-right: auto;
  }
}
</style>