import {
  list,
  add,
  get, 
  update,
  assign,
  download,
  patchParking,
  getCommodities,
  patchCommodities,
  getMedias
} from '@/calls/parkings';

import {
 
  getBalanceReport
 
} from '@/calls/bookings';

import { getCompanies } from '@/calls/companies';
import moment from 'moment';
import i18n from '../i18n';

const parkings = {
  namespaced: true,
  state: {
    requests: 0,
    search: null,
    list: [],
    modal: {
      visible: false,
      id: null,
      company: null
    },
    current: null,
    currentCommodities: [],
    commoditiesLoading: false,
    currentMedias: [],
    mediasLoading: false,
    order: {
      type: 'localName',
      dir: 'asc'
    },
    companies: [],
    downloadRequests: 0,
    downloadReport: false
  },
  mutations: {
    updateParkings(state, els) {
      state.list = els;
    },
    updateOrder(state, type) {
      if (state.order.type === type) {
        state.order.dir = state.order.dir === 'asc' ? 'desc' : 'asc';
        return;
      }

      state.order.type = type;
      state.order.dir = 'asc';
    },
    updateModal(state, {id, company}) {
      state.modal.visible = !state.modal.visible;
      state.modal.id = id;
      state.modal.company = company;
    },
    updateModalProperty(state, { property, value }) {
      state.modal[property] = value;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    increaseRequest(state) {
      state.requests++;
    },
    decreaseRequest(state) {
      state.requests--;
    },
    increaseDownloadRequest(state) {
      state.downloadRequests++;
    },
    decreaseDownloadRequest(state) {
      state.downloadRequests--;
    },
    updateCommodities(state, commodities) {
      state.currentCommodities = commodities;
    },
    updateCommodity(state, { groupId, commodityId }) {
      const commodityGroup = state.currentCommodities.filter(c => c.id === groupId)[0];

      for (let i = 0; i < commodityGroup.commodities.length; i++) {
        const commodity = commodityGroup.commodities[i];
        if (commodity.id === commodityId) {
          commodity.isActive = !commodity.isActive;
          break;
        }
      }
    },
    updateCommodityScore(state, { groupId, score }) {
      const commodityGroup = state.currentCommodities.filter(c => c.id === groupId)[0];
      commodityGroup.score = score;
    },
    updateLoading(state, { type, flag }) {
      if (type === 'commodities') {
        state.commoditiesLoading = flag;
      }

      if (type === 'medias') {
        state.mediasLoading = flag;
      }
    },
    updateDownloadReport(state, value) {
      state.downloadReport = value;
    },
  },
  actions: {
    async getParkings({ state, commit, dispatch }) {
      commit('increaseRequest');
      const search = state.search && state.search.length ? state.search : null;
      let parkings = await list(search);
      const companies = await getCompanies();

      commit('companies/updateCompanies', companies, { root: true });
      const { t } = i18n.global;

      if (!parkings || parkings.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parkings.getting', { error: parkings?.error?.response?.data?.detail ? parkings?.error?.response?.data?.detail : parkings.error }),
          type: 'error'
        }], { root: true });
      } else {
        parkings = parkings.map(p => {
          const company = companies.filter(c => c.id === p.parkingCompanyId);
          p.companyName = '-';
          if (company.length) {
            p.companyName = company[0].name;
            // p.company = company[0];
          }
          return p;
        });
        commit('updateParkings', parkings);
      }

      commit('decreaseRequest');
    },
    set({ state, commit }) {
      commit('increaseRequest');
      const search = state.search && state.search.length ? state.search : null;
      const parking = list(search);
      return parking.then((promise) => {
        commit('updateParkings', promise);
        commit('decreaseRequest');
        return promise;
      });
    },
    update({}, {id, item}) {
      const parking = update(id, item);
      return parking.then(promise => {
        this.dispatch('parkings/set');
        return promise
      })
    },
    updateAssignCompany({}, {id, item}) {
      const parking = assign(id, item);
      return parking.then(promise => {
        this.dispatch('parkings/set');
        return promise
      })
    },
    new({}, item) {
      const parking = add(item);
      return parking.then(promise => {  
        this.dispatch('parkings/set');
        return promise;
      })
    },
    get({}, id) {
      const parking = get(id);
      return parking.then(promise => {
        this.dispatch('parkings/set');
        return promise;
      })
    },
    search({ dispatch }, terms) {
      if (!terms || !terms.length) {
        dispatch('set');
        return;
      }

      // TO DO
      const parking = list();
      return parking.then((promise) => {
        commit('updateParkings', promise);
        return promise;
      });
    },
    async download({ commit, dispatch }) {
      commit('increaseDownloadRequest');

      const request = await download();

      if (!request.hasError) {
          const blob = new Blob([request.data], {type: `data:${request.headers['content-type']}` });
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement('a');
          const date = moment();
          a.href = blobURL;
          a.download = `OTRA_Parkings-${date.format('DD[-]MM[-]YYYY')}.xls`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(blob);
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parkings.download', { error: request?.error?.response?.data?.detail ? request?.error?.response?.data?.detail : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseDownloadRequest');
    },

    async downloadReport({ commit, state, dispatch }) {
      commit('increaseRequest');
      commit('updateDownloadReport',true);
      const response = await getBalanceReport();
      
      if (response) {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `activity-balance-report-${new Date().getTime()}.xlsx`;
        link.click();
        URL.revokeObjectURL(link.href);
       
      }
      commit('updateDownloadReport',false);
      commit('decreaseRequest');
    }
  }
};

export default parkings;
