<template>
  <div>
    <h3 class="label small">{{ $t('parkings.form.pictures.subtitle') }}</h3>
    <div class="pictures-grid">
      <picture-upload
        v-for="(item, index) in medias"
        :key="`picture-${item.id}`"
        :id="item.id"
        :label="$t('parkings.form.pictures.additionnal_media')"
        :format="'.jpg, .png'"
        :url="item.url"
        :img="item.img"
        :multiple="true"
        :checkOrientation="true"
        @drag="onDrag"
        @dragend="onDragEnd"
        @dragenter="onDragEnter"
        @change="onChange($event, index)"
        @reset="onReset(index)"
      >
        <!-- @dragstart="onDragStart" -->
        <template v-if="index === 0" v-slot:button>
          <span class="button">{{ $t('parkings.form.pictures.buttons.main_picture') }}</span>
        </template>
      </picture-upload>
    </div>

    <div 
      v-if="hasError"
      class="form--error small"
    >
      {{$t('notifications.errors.several_required')}}
    </div>

    <!-- Popup Pay -->
    <popup
      v-if="deletePopup.visible"
      :classes="'popup-delete'"
    >
      <template v-slot:content>
        <div class="popup-header">
          <h2 class="popup-title">{{ $t('parkings.form.pictures.buttons.delete_file') }}</h2>
        </div>

         <div class="popup-main">
            <p class="popup-main-text">{{ $t('parkings.form.pictures.modal.confirm') }}</p>
            <img :src="deletePopup.src">
          </div>

        <div class="popup-actions">
          <button
            class="button button-ghost"
            @click="closeDelete"
          >
            {{ $t('parkings.form.pictures.buttons.cancel') }}
          </button>
          <button
            class="button"
            @click="confirmDelete"
          >
            {{ $t('parkings.form.pictures.buttons.delete_confirmation') }}
          </button>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import PictureUpload from '@/components/elements/PictureUpload';
import Popup from '@/components/partials/Popup';

export default {
  name: 'Pictures',
  components: {
    Popup,
    PictureUpload
  },
  data() {
    return {
      currentDrag: null,
      hasError: false,
      saving: false,
      filesToDelete: [],
      deletePopup: {
        visible: false,
        index: null,
        src: null
      }
    }
  },
  computed: {
    medias() {
      return this.$store.state.parking.medias;
    }
  },
  methods: {
    async onSave() {
      this.saving = true;

      let files = [...this.medias.filter(item => item.file || item.url)];

      // Delete medias
      if (this.filesToDelete.length > 0) {
        for (let i = 0; i < this.filesToDelete.length; i++) {
          const del = await this.$store.dispatch('parking/deleteMedia', { fileId: this.filesToDelete[i].id });
        }
      }

      // Check for file to upload
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.file) {
          const media = await this.$store.dispatch('parking/uploadMedia', { file });
          if (media !== 'error') {
            file.id = media;
          }
        }
      }

      // Save medias order
      const ids = files.map(file => file.id);
      const saveMedias = await this.$store.dispatch('parking/saveMedias', { ids });

      this.saving = 'finish';

      setTimeout(() => {
        this.saving = false;
      }, 3000);
    },
    onDrag(e) {
      if ((typeof e === 'number' || typeof e === 'string') && e !== this.currentDrag) {
        let index = null;

        for (let i = 0; i < this.medias.length; i++) {
          if(this.medias[i].id === e) {
            index = i;
            break;
          }
        }

        this.currentDrag = index;
      }
    },
    onDragEnd() {
      this.currentDrag = null;
    },
    onDragEnter(e) {
      if (typeof e !== 'number' && typeof e !== 'string') {
        return;
      }

      let index = null;

      for (let i = 0; i < this.medias.length; i++) {
        if(this.medias[i].id === e) {
          index = i;
          break;
        }
      }

      if (index === this.currentDrag) {
        return;
      }

      if (this.currentDrag || this.currentDrag === 0) {
        this.$store.commit('parking/updateMediasOrder', { index, current: this.currentDrag})
      }
    },
    onChange(file, index) {
      const current = this.medias[index];

      if (!current.url && !current.file) {
        // Update
        current.file = file;
        this.createImage(file, index);
        // this.checkImage(file, index);
      } else {
        let ind = -1;

        for (let i = 0; i < this.medias.length; i++) {
          const item = this.medias[i];
          if (!item.url && !item.file) {
            ind = i;
            break;
          }
        }

        if (ind >= 0) {
          this.$store.commit('parking/updateMediaFile', { index: ind, file });
          // this.items[ind].file = file;
          this.createImage(file, ind);
          // this.checkImage(file, ind);
        } else {
          this.addItem(file);
        }
      }

      // Adding new input if array is full
      const filledPictures = this.medias.filter(item => item.url || item.file);
      // const filledPictures = this.items.filter(item => item.url || item.file);
      if (filledPictures.length === this.medias.length) {
        this.addItem();
      }
    },
    onReset(index) {
      const media = this.medias[index];
      this.deletePopup.index = index;
      this.deletePopup.visible = true;
      this.deletePopup.src = media.url || media.img;
    },
    onDelete(index) {
      const media = this.medias[index];

      if (media.url) {
        // this.filesToDelete.push(media);
        this.$store.commit('parking/addMediaToDelete', media);
      }

      this.$store.commit('parking/removeMedia', index);

      if (this.medias.length < 5) {
        this.addItem();
      }
    },
    addItem(file, ind) {
      this.$store.commit('parking/addMedia', file);

      if (file) {
        this.createImage(file, ind);
      }
    },
    createImage(file, index) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.addImage(e.target.result, index);
      };
      reader.readAsDataURL(file);
    },
    addImage(img, index) {
      this.$store.commit('parking/updateMediaImage', { index, img });
    },
    closeDelete() {
      this.deletePopup.visible = false;
      this.deletePopup.index = null;
      this.deletePopup.src = null;
    },
    confirmDelete() {
      this.onDelete(this.deletePopup.index);
      this.deletePopup.visible = false;
      this.deletePopup.index = null;
      this.deletePopup.src = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-container {
  padding-bottom: 60px;

  .pictures-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    position: relative;
    z-index: 0;

    .upload {
      &:first-child {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }
    }
  }

  form {
    > .button {
      width: auto;

      + .loader {
        display: inline-block;
        margin-left: 40px;
        margin-top: 30px;
        vertical-align: middle;
      }
    }

    .loader {
      margin-bottom: 30px;
    }
  }
}
</style>
