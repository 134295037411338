import {
  patchOwner,
  getOwner,
  createOwner,
} from '@/calls/fleet-owners';
import i18n from '../i18n';

import { list } from '@/calls/fleet-companies';

const fleetOwner = {
  namespaced: true,
  state: {
    id: null,
    data: null,
    form: {
      firstname: '',
      lastname: '',
      email: '',
      phoneNumber: '',
      fleetCompanyId: null,
      locale: ''
    },
    requests: 0,
    fleets: []
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateData(state, data) {
      state.data = data;
    },
    resetForm(state) {
      state.form = {
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        locale: ''
      }
    },
    updateDataValue(state, { value, property }) {
      if (property.match(/street|zipCode|city|state/)) {
        state.data.address[property] = value;
        return;
      }

      state.data[property] = value;
    },
    updateFormValue(state, { value, property }) {
      state.form[property] = value;
    },
    updateFleets(state, fleets) {
      state.fleets = fleets;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    setFormFromData({ state, commit }) {
      const properties = [
        'firstname',
        'lastname',
        'email',
        'phoneNumber',
        'fleetCompanyId',
        'locale'
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];

        switch (true) {
          case /fleetCompanyId/.test(property):
            commit('updateFormValue', {
              property, value: state.data.fleetId
            });
            break;
          default:
            commit('updateFormValue', {
              property, value: state.data.user ? state.data.user[property] : ''
            });
            break;
          
          }
        }
    },
    // Calls
    async getData({ commit, dispatch, state }) {
      commit('increaseRequests');
      const request = await getOwner(state.id);

      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');
      } else {
        const { tc } = i18n.global;
        dispatch('notifications/addNotifs', [{
          // text: `Error while getting owner : ${request.error}`,
          text: tc('notifications.errors.fleet-owners.getting', 1, { error: request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async create({ commit, dispatch, state }) {
      commit('increaseRequests');
      const data = {...state.form};
      data.locale = data.locale && data.locale.length ? data.locale : 'en';
      delete data.phoneNumber;
      const request = await createOwner(data);
      const { t } = i18n.global;

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          // text: 'Fleet owner created!',
          text: t('notifications.success.fleet-owners.creation'),
          type: 'success'
        }], { root: true });
      } else {
        if(request?.error?.response?.data) {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.fleet-owners.creation', { error: request.error.response.data }),
            // text: `Error while creating owner : ${request.error.response.data}`,
            type: 'error'
          }], { root: true });
        } else {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.fleet-owners.creation', { error: request.error }),
            // text: `Error while creating owner : ${request.error}`,
            type: 'error'
          }], { root: true });
        }


        commit('decreaseRequests');
        return { noRedirect: true }
      }

      commit('decreaseRequests');
      return request;
    },
    async saveData({ commit, state, dispatch }) {
      commit('increaseRequests');

      const data = {...state.form};
      // data.locale = data.locale || data.locale.length ? data.locale : 'en';
      const request = await patchOwner(state.id, data);
      const { t } = i18n.global;

      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');

        dispatch('notifications/addNotifs', [{
          // text: 'Fleet owner updated!',
          text: t('notifications.success.fleet-owners.update'),
          type: 'success'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.fleet-owners.update', { error: request.error }),
          // text: `Error while getting owner : ${request.error}`,
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
      return request;
    },
    async getFleets({ commit, state, dispatch }) {
      commit('increaseRequests');
      const data = {...state.form};
      const request = await list(state.id, data);

      if(!request.hasError) {
        commit('updateFleets', request);
      }

      commit('decreaseRequests');
    }
  }
};

export default fleetOwner;
