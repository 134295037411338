import i18n from '../i18n';
import { getParameters, updateParameters } from '@/calls/mobile-app';

const parkings = {
  namespaced: true,
  state: {
    lang: 'en',
    data: null,
    form: {
      title: null,
      subtitle: null,
      text: null,
      image: null,
      // button: null,
      buttonLabel: null,
      buttonAction: null,
      buttonUrl: null,
      bookCountry: null,
      activations: {
        title: null,
        subtitle: null,
        text: null,
        image: null,
        button: null,
        // buttonAction: null,
        bookCountry: null
      }
    },
    errors: {
      title: null,
      subtitle: null,
      text: null,
      image: null
    },
    requests: 0
  },
  mutations: {
    updateData(state, data) {
      state.data = data;
    },
    updateLang(state, lang) {
      state.lang = lang;
    },
    updateFormValue(state, { value, property }) {
      state.form[property] = value;
    },
    updateImage(state, { property, value }) {
      state.form.image[property] = value;
    },
    updateFormActivations(state, { property, value }) {
      console.log("Activate " + property)
      state.form.activations[property] = value;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },
  },
  actions: {
    updataFormFromData({ state, commit }) {
      const properties = {
        HomeTitle: 'title',
        HomeSubTitle: 'subtitle',
        HomeDescription: 'text',
        HomeImage: 'image',
        HomeButton: 'button',
        HomeButtonLabel: 'buttonLabel',
        HomeButtonAction: 'buttonAction',
        HomeButtonUrl: 'buttonUrl',
        HomeBookCountry: 'bookCountry'
      };

      for (let i = 0; i < state.data.length; i++) {
        const data = state.data[i];
        const property = properties[data.name];

        if (property) {
          const trans = data.translations.filter(lang => lang.language === state.lang);
          const isActive = trans.length ? trans[0].isActive : false;

          if (property.match('HomeButton')) {
            if (property.match('HomeButtonLabel')) commit('updateFormActivations', { property, value: isActive });
          } else {
            commit('updateFormActivations', { property, value: isActive });
          }

          commit('updateFormValue', { property, value: trans.length ? trans[0].value : '' });
        }
      }
    },
    // Data
    async getData({ commit, dispatch, state }) {
      commit('increaseRequests');
      const { lang } = state;
      const request = await getParameters('homepage');
      const { t } = i18n.global;

      if(!request.hasError) {
        commit('updateData', request.data);
        dispatch('updataFormFromData');
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.mobile-app.getting'),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async saveData({ commit, state, rootState, dispatch }) {
      commit('increaseRequests');
      const properties = {
        HomeTitle: 'title',
        HomeSubTitle: 'subtitle',
        HomeDescription: 'text',
        HomeImage: 'image',
        HomeButton: 'button',
        HomeButtonLabel: 'buttonLabel',
        HomeButtonAction: 'buttonAction',
        HomeButtonUrl: 'buttonUrl',
        HomeBookCountry: 'bookCountry'
      };
      const data = [];
      const { t } = i18n.global;

      for (let i = 0; i < state.data.length; i++) {
        const d = state.data[i];
        if (properties[d.name]) {
          if (d.name.match(/HomeButton/)) {
            // The button's properties are activated based on the 'button' activation flag
            data.push({
              id: d.id,
              lang: state.lang,
              isActive: state.form[properties[d.name]] && state.form[properties[d.name]].length > 0 ? state.form.activations['button'] : false,
              value: state.form[properties[d.name]] && state.form[properties[d.name]].length > 0 ? state.form[properties[d.name]] : ''
            });
          } else {
            data.push({
              id: d.id,
              lang: state.lang,
              isActive: state.form[properties[d.name]] && state.form[properties[d.name]].length > 0 ? state.form.activations[properties[d.name]] : false,
              value: state.form[properties[d.name]] && state.form[properties[d.name]].length > 0 ? state.form[properties[d.name]] : ''
            });
          }
        }
      }

      const request = await updateParameters(data);

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.mobile-app.update'),
          type: 'success'
        }], { root: true });
        dispatch('getData');
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.mobile-app.update'),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
  }
};

export default parkings;
