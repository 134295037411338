<template>
  <section class="main-container main-container__form">
    <form>
      <h2 class="h5">{{ $t('owners.form.title') }}</h2>
      <fieldset class="container-row container-5-5">
        <Select
          ref="gender"
          :name="'gender'"
          :label="$t('owners.form.labels.gender')"
          :options="genders"
          :placeholder="form.gender ? form.gender : $t('owners.form.labels.gender_select')"
          :error="error.gender"
          :required="true"
          :class="'classic'"
          @change="handleGender"
        />
        <Inputs
          ref="position"
          name="position"
          type="text" 
          :label="$t('owners.form.labels.function')"
          :error="error.position"
          :required="true"
          :value="form.position"
          @input="handleInput"
        />
      </fieldset>
      <fieldset class="container-row container-5-5">
        <Inputs
          ref="firstname"
          name="firstname"
          type="text" 
          :label="$t('owners.form.labels.firstname')"
          :error="error.firstname"
          :required="true"
          :value="form.firstname"
          @input="handleInput"
        />
        <Inputs
          ref="lastname"
          name="lastname"
          type="text" 
          :label="$t('owners.form.labels.lastname')"
          :error="error.lastname"
          :required="true"
          :value="form.lastname"
          @input="handleInput"
        />
      </fieldset>
      <fieldset class="container-row container-5-5">
        <Inputs
          ref="email"
          type="email" 
          :label="$t('owners.form.labels.email')"
          :error="error.email"
          :required="true"
          :name="'email'"
          :value="form.email"
          @input="handleInput"
        />
        <NewSelect
          ref="locale"
          :name="'locale'"
          :value="form.locale ? form.locale : 'en'"
          :label="$t('labels.language')"
          :options="languages"
          :placeholder="form.locale ? form.locale : $t('labels.language_select')"
          :error="error.locale"
          :required="false"
          :class="'classic'"
          @onChange="handleLocale"
        />
        <!-- <Select
          ref="company"
          :name="'company'"
          :label="$t('owners.form.labels.company')"
          :options="companies"
          :placeholder="form.parkingCompanyId ? form.parkingCompanyId : $t('owners.form.labels.company_select')"
          :error="error.company"
          :required="false"
          :class="'classic'"
          @change="handleCompany"
        /> -->
      </fieldset>

      <button-save 
        :loading="loading"
        :labels="{
          default: $t('owners.form.buttons.save'),
          end: $t('owners.form.buttons.saved')
        }"
        :class="{disabled: !canValidate}"
        @click="onSubmit"
      />
      <div 
        v-if="hasError"
        class="form--error small"
      >
        {{$t('notifications.errors.several_required')}}
      </div>
    </form>
  </section>
</template>

<script>
import {isValidPhoneNumber, parsePhoneNumber, ParseError} from 'libphonenumber-js'

import Select from '@/components/elements/Select';
import Inputs from '@/components/elements/Inputs';
import NewSelect from '@/components/elements/NewSelect';
import ButtonSave from '@/components/elements/ButtonSave';

import langs from '@/data/languages.json';

export default {
  name: "newContact",
  components: {
    Inputs,
    Select,
    NewSelect,
    ButtonSave
  },
  mounted() {
    const { submit, ...refs } = this.$refs;
    this.inputs = Object.values(refs);
    this.submit = submit;
  },
  beforeMount() {
    if(!this.$store.state.companies.list.length && this.$store.state.companies.requests === 0) {
      this.$store.dispatch('companies/set');
    }
  },
  data() {
    return {
      companiesList: [],
      inputs: {},
      loading: false,
      canValidate: false,
      hasError: false,
      notEmpty: [],
      form: {
        firstname: '',
        lastname: '',
        email: '',
        // phoneNumber: '',
        position: '',
        gender: '',
        parkingCompanyId: "",
        locale: ''
      },
      error: {
        firstname: '',
        lastname: '',
        email: '',
        // phoneNumber: '',
        position: '',
        gender: '',
        parkingCompanyId: "",
        locale: ''
      }
    };
  },
  computed: {
    companies() {
      return this.setForSelect(this.$store.state.companies.list);
    },
    genders() {
      return [
        { value: "male", label: this.$t('owners.form.gender.male') },
        { value: "female", label: this.$t('owners.form.gender.female') },
        { value: "other", label: this.$t('owners.form.gender.other') },
      ]
    },
    languages() {
      return langs.map(l => {
        return {
          value: l.code,
          label: this.$t(`globals.languages.${l.code}`)
        }
      });
    },
  },
  watch: {
    companies(arr) {
      return arr;
    }
  },
  methods: {
    setForSelect(arr) {
      const newArr = [{
        value: 0,
        label: this.$t('owners.form.unassigned')
      }];
      arr.forEach(company => {
          newArr.push({
            value: company.id,
            label: company.name
          })
      });
      return newArr;
    },
    // On
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = true;
      this.hasError = false;
      this.error = {
        firstname: '',
        lastname: '',
        email: '',
        // phoneNumber: '',
        position: '',
        gender: '',
        parkingCompanyId: '',
        locale: ''
      }
      this.handleValidation()
    },
    // Handle
    handleInput(evt) {
      const {target} = evt;
      this.form[target.name] = target.value;
     
      this.handleError();
    },
    handleValidation() {
      const {email} = this.form
      // const {email, phone} = this.form
      if(email) this.checkEmail(email);
      // if(phone) this.checkPhone(phone);

      if(this.canValidate && !this.hasError) this.send()
    },
    handleGender(gender) {
      if(gender) {
        this.notEmpty.push(true);
        this.form.gender = gender;
      }
      else this.notEmpty.push(false);

      this.handleError();
    },
    handleCompany(company) {
      if(company != null && company != undefined) {
        this.notEmpty.push(true);
        this.form.parkingCompanyId = company;
      }
      else this.notEmpty.push(false);

      this.handleError();
    },
    handleLocale(locale) {
      if(locale != null && locale != undefined) {
        this.form.locale = locale;
      }
    },
    handleError() {
      this.notEmpty = [];
      for (const ref in this.inputs) {
        const input = this.inputs[ref];
        if(input.required) this.notEmpty.push(input.required && input.$refs.input.value !== '');
        else this.notEmpty.push(true);
      }

      this.canValidate = this.notEmpty.length === this.inputs.length && this.notEmpty.indexOf(false) === -1;
    },
    // Check
    checkEmail(email) {
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.error.email = !email.trim() ? this.$t('notifications.errors.email_required') : "";
      if(!this.error.email) this.error.email = !regex.test(email) ? this.$t('notifications.errors.email_invalid') : "";
      if(this.error.email) this.hasError = true;
    },
    checkPhone(phone) {
      this.error.phone = !phone.trim() ? this.$t('notifications.errors.phone_required') : '';

      try {
        parsePhoneNumber(phone);
      } catch(error) {
        if (error instanceof ParseError) {
          this.error.phone = this.$t('notifications.errors.phone_invalid')
        }
      }
      if(!this.error.phone) this.error.phone = !isValidPhoneNumber(phone) ? this.$t('notifications.errors.phone_invalid') : '';
      if(this.error.phone) this.hasError = true;
    },
    // Send 
    send() {
      const items = this.filterObject( this.form, (input) => input );
      items.locale = items.locale && items.locale.length ? items.locale : 'en';
      // return;
      const sent = this.$store.dispatch('owners/new', items);
      sent.then((response) => {
        if(response && response.noReload) return { noReload: true };
        if(items.parkingCompanyId) return this.$store.dispatch('companies/set')
      }).then((res) => {
        if(res && res.noReload) {
          this.loading = false;
          return { noReload: true }
        };

        this.loading = "finish";
      }).then((r) => {
        if(r && r.noReload) return { noReload: true };
        this.$router.push('/owners');
      })
    },
    filterObject(obj, callback) {
      return Object.fromEntries(Object.entries(obj).
        filter(([key, val]) => callback(val, key)));
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    padding-bottom: 50px;
  }
  .button {
    margin-top: 20px;
  }
</style>
