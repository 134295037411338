<template>
  <div>
    <component :key="name" v-for="(datas, name) in components" v-bind:is="name" :props="datas" />
  </div>
</template>

<script>
import TwoColumns from '@/components/modules/TwoColumns.vue'
import Faqs from '@/components/modules/Faqs.vue'
export default {
  name: 'Components',
  components: {
    TwoColumns,
    Faqs
  },
  props: {
    components: {
      type: Array
    }
  }
}
</script>

<style>

</style>