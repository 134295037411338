import { createRouter, createWebHistory } from 'vue-router'

// import Company from '@/views/companies/Companies.vue';
import Companies from '@/views/companies/List';
import Company from '@/views/companies/New';
import EditCompany from '@/views/companies/Edit';

import Parkings from '@/views/parkings/List';
import NewParking from '@/views/parkings/New';
import EditParking from '@/views/parkings/Edit';

import Owners from '@/views/owners/List';
import NewOwner from '@/views/owners/New';
import EditOwner from '@/views/owners/Edit';

import Team from '@/views/team/Index';
import TeamAdmins from '@/views/team/admins/List';
import NewTeamAdmin from '@/views/team/admins/New';
import EditTeamAdmin from '@/views/team/admins/Edit';

import Fleet from '@/views/fleet/Index';
import FleetOwners from '@/views/fleet/owners/List';
import NewFleetOwners from '@/views/fleet/owners/New';
import EditFleetOwners from '@/views/fleet/owners/Edit';
import FleetPlanners from '@/views/fleet/planners/List';
import NewFleetPlanners from '@/views/fleet/planners/New';
import EditFleetPlanners from '@/views/fleet/planners/Edit';
import FleetCompanies from '@/views/fleet/companies/List';
import NewFleetCompanies from '@/views/fleet/companies/New';
import EditFleetCompanies from '@/views/fleet/companies/Edit';

import EditProfile from '@/views/profile/Edit';

import App from '@/views/app/Index';
import EditHomeScreen from '@/views/app/EditHomeScreen';
import UploadBooking from '@/views/app/UploadBooking';

import Home from '@/views/Home';

import Authentication from '@/views/Authentication'
import Page from '@/views/Page'

import Help from '@/views/pages/Help'

import Partners from '@/views/partners/List';
import NewPartner from '@/views/partners/New';
import EditPartner from '@/views/partners/Edit';
const routes = [
  {
    path: '/',
    name: 'Companies',
    component: Companies,
    meta: {
      parent: 'parking',
      titleTemplate: 'pages.parking_companies.page_title',
      icon: 'settings',
      components: {
        Button: {to: 'newCompany', label: 'company.list.buttons.add_company', disableBasedSetting: true},
        QuickSearch: true,
        Pagination: true,
      }
    },
    children: [
      {
        path: '/company/edit/:id',
        name: 'editCompany',
        component: EditCompany, 
        meta: {
          sidebar: 'disable',
          parent: 'parking',
          titleTemplate: 'pages.parking_companies.edit.page_title',
          components: {}
        }
      },
      {
        path: '/company/new',
        name: 'newCompany',
        component: Company,
        meta: {
          parent: 'parking',
          titleTemplate: 'pages.parking_companies.new.page_title',
          components: {}
        }
      }
    ]
  },
  {
    path: '/parkings',
    name: 'Parkings',
    component: Parkings,
    meta: {
      parent: 'parking',
      titleTemplate: 'pages.parking_list.page_title',
      icon: 'settings',
      components: {
        Button: {to: 'newParking', label: 'parkings.list.buttons.add_parking', disableBasedSetting: true},
        QuickSearch: true,
        Pagination: true
      }
    },
    children: [
      {
        path: '/parking/new',
        name: 'newParking',
        component: NewParking, 
        meta: {
          parent: 'parking',
          titleTemplate: 'pages.parking_list.new.page_title',
          components: {}
        }
      },
      {
        path: '/parking/edit/:id',
        name: 'editParking',
        component: EditParking, 
        meta: {
          sidebar: 'disable',
          parent: 'parking',
          titleTemplate: 'pages.parking_list.edit.page_title',
          components: {}
        }
      }
    ]
  },
  {
    path: '/owners',
    name: 'Owners',
    component: Owners,
    meta: {
      parent: 'parking',
      titleTemplate: 'pages.owner_list.page_title',
      icon: 'settings',
      components: {
        Button: {to: 'newOwner', label: 'owners.list.buttons.add_owner'},
        QuickSearch: true,
        Pagination: true,
      }
    },
    children: [
      {
        path: '/owners/new',
        name: 'newOwner',
        component: NewOwner, 
        meta: {
          parent: 'parking',
          titleTemplate: 'pages.owner_list.new.page_title',
          components: {}
        }
      },
      {
        path: '/owners/edit/:id',
        name: 'editOwner',
        component: EditOwner, 
        meta: {
          sidebar: 'disable',
          parent: 'parking',
          titleTemplate: 'pages.owner_list.edit.page_title',
          components: {}
        }
      }
    ]
  },
  {
    path: '/fleet',
    name: 'fleet',
    component: Fleet,
    meta: {
      // layout: 'page',
      subbar: true,
      titleTemplate: 'pages.fleet.page_title',
    },
    children: [
      {
        path: '/fleet/companies',
        name: 'fleetCompanies',
        component: FleetCompanies, 
        meta: {
          titleTemplate: 'pages.fleet_companies.page_title',
          components: {},
          sidebar: true,
          parent: 'fleet',
          icon: 'settings',
          menuName: 'pages.fleet_companies.sidebar_title',
          components: {
            Button: {
              to: 'newFleetCompanies',
              label: 'fleet.list.buttons.add_company',
              isDisabled: true
            },
            // QuickSearch: true,
            // Pagination: true,
          }
        },
        children: [
          // {
          //   path: '/fleet/companies/new',
          //   name: 'newFleetCompanies',
          //   component: NewFleetCompanies, 
          //   meta: {
          //     titleTemplate: 'pages.fleet_companies.new.page_title',
          //     components: {},
          //     parent: 'fleet'
          //   }
          // },
          {
            path: '/fleet/companies/edit/:id',
            name: 'editFleetCompanies',
            component: EditFleetCompanies, 
            meta: {
              sidebar: 'disable',
              titleTemplate: 'pages.fleet_companies.edit.page_title',
              components: {},
              parent: 'fleet'
            }
          }
        ]
      },
      {
        path: '/fleet/owners',
        name: 'fleetOwners',
        component: FleetOwners,
        meta: {
          titleTemplate: 'pages.fleet_owners.page_title',
          components: {},
          parent: 'fleet',
          icon: 'settings',
          menuName: 'pages.fleet_owners.sidebar_title',
          components: {
            Button: {
              to: 'newFleetOwners',
              label: 'fleet.list.buttons.add_owner'
            },
            // QuickSearch: true,
            // Pagination: true,
          }
        },
        children: [
          {
            path: '/fleet/owners/new',
            name: 'newFleetOwners',
            component: NewFleetOwners, 
            meta: {
              titleTemplate: 'pages.fleet_owners.new.page_title',
              components: {},
              parent: 'fleet'
            }
          },
          {
            path: '/fleet/owners/edit/:id',
            name: 'editFleetOwners',
            component: EditFleetOwners, 
            meta: {
              sidebar: 'disable',
              titleTemplate: 'pages.fleet_companies.edit.page_title',
              components: {},
              parent: 'fleet'
            }
          }
        ]
      },
      {
        path: '/fleet/planners',
        redirect: '/fleet/members',
      },
      {
        path: '/fleet/members',
        name: 'fleetPlanners',
        component: FleetPlanners, 
        meta: {
          titleTemplate: 'pages.fleet_planners.page_title',
          components: {},
          parent: 'fleet',
          sidebar: true,
          icon: 'settings',
          menuName: 'pages.fleet_planners.sidebar_title',
          components: {
            Button: {
              to: 'newFleetPlanners',
              label: 'fleet.list.buttons.add_planner',
              isDisabled: true
            },
            QuickSearch: true,
            // Pagination: true,
          }
        },
        children: [
          // {
          //   path: '/fleet/planners/new',
          //   redirect: '/fleet/members/new',
          // },
          // {
          //   path: '/fleet/members/new',
          //   name: 'newFleetPlanners',
          //   component: NewFleetPlanners, 
          //   meta: {
          //     titleTemplate: 'pages.fleet_planners.new.page_title',
          //     components: {},
          //     parent: 'fleet'
          //   }
          // },
          {
            path: '/fleet/planners/edit/:id',
            redirect: to => {
              return { name: 'editFleetPlanners', params: { id: to.params.id } }
            }
          },
          {
            path: '/fleet/members/edit/:id',
            name: 'editFleetPlanners',
            component: EditFleetPlanners, 
            meta: {
              sidebar: 'disable',
              titleTemplate: 'pages.fleet_planners.edit.page_title',
              components: {},
              parent: 'fleet'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    meta: {
      // layout: 'page',
      subbar: true,
      titleTemplate: 'pages.team.page_title',
    },
    children: [
      {
        path: '/team/admins',
        name: 'teamAdmins',
        component: TeamAdmins, 
        meta: {
          titleTemplate: 'pages.team_admins.page_title',
          components: {},
          parent: 'team',
          icon: 'settings',
          menuName: 'pages.team_admins.sidebar_title',
          components: {
            Button: {
              to: 'newTeamAdmin',
              label: 'team.list.buttons.add_admin'
            },
          }
        },
        children: [
          {
            path: '/team/admins/new',
            name: 'newTeamAdmin',
            component: NewTeamAdmin, 
            meta: {
              titleTemplate: 'pages.team_admins.new.page_title',
              components: {},
              parent: 'team'
            }
          },
          {
            path: '/team/admins/edit/:id',
            name: 'editTeamAdmin',
            component: EditTeamAdmin, 
            meta: {
              sidebar: 'disable',
              titleTemplate: 'pages.team_admins.edit.page_title',
              components: {},
              parent: 'team'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/privacy-policy',
    name: 'Policy',
    component: Page,
    meta: {
      sidebar: 'disable',
      layout: 'page',
      titleTemplate: 'pages.privacy_policy.page_title',
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Page,
    meta: {
      sidebar: 'disable',
      layout: 'page',
      titleTemplate: 'pages.terms.page_title',
    }
  },

  {
    path: '/app',
    name: 'app',
    component: App,
    meta: {
      parent: 'app',
      titleTemplate: 'pages.app.page_title',
      icon: 'settings',
      components: {}
    },
    children: [
      {
        path: '/app/home',
        name: 'editHomeScreen',
        component: EditHomeScreen, 
        meta: {
          parent: 'app',
          titleTemplate: 'pages.app.home.page_title',
          components: {},
          icon: 'settings',
        }
      }
    ]
  },
  {
    path: '/report',
    name: 'reportScreen',
    component: App,
    meta: {
      parent: 'report',
      titleTemplate: 'pages.report.page_title',
      icon: 'settings',
    }
  },
  {
    path: '/report/upload-bookings',
    name: 'UploadBookings',
    component: UploadBooking,
    meta: {
      parent: 'report',
      titleTemplate: 'pages.upload_bookings.page_title',
      icon: 'settings'
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      layout: 'page',
      subbar: true,
      titleTemplate: 'pages.help.page_title',
      bodyClass: 'body-page'
    }
  },
  {
    path: '/profile/edit',
    name: 'EditProfile',
    component: EditProfile,
    meta: {
      titleTemplate: 'pages.profile.page_title',
      // layout: 'page',
      notInSidebar: true
      // subbar: true
    }
  },
  // SUBBAR
  // Authentication
  {
    path: '/lost-password',
    name: 'LostPassword',
    component: Authentication, 
    hidden: true,
    meta: {
      titleTemplate: 'pages.lost_password.page_title',
      subbar: false,
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    hidden: true,
    component: Authentication, 
    meta: {
      titleTemplate: 'pages.reset_password.page_title',
      subbar: false,
    }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners,
    meta: {
      parent: 'parking',
      titleTemplate: 'pages.partner.page_title',
      icon: 'settings',
      components: {
        Button: {to: 'newPartner', label: 'partner.list.buttons.add_partner'},
        QuickSearch: true,
        Pagination: true,
      }
    },
    children: [
      {
        path: '/partners/new',
        name: 'newPartner',
        component: NewPartner, 
        meta: {
          parent: 'parking',
          titleTemplate: 'pages.partner.new.page_title',
          components: {}
        }
      },
      {
        path: '/partners/edit/:id',
        name: 'editPartner',
        component: EditPartner, 
        meta: {
          sidebar: 'disable',
          parent: 'parking',
          titleTemplate: 'pages.partner.edit.page_title',
          components: {}
        }
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
