import axios from 'axios';

const list = async (search) => {
  return await axios.get(`/admin/parking-owners`, {
    params: { search }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const add = async (item) => {
  return await axios.post(`/admin/parking-owners`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    // console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const update = async ({id, item}) => {
  return await axios.patch(`/admin/parking-owners/${id}`, item)
  .then((promise) => {    
    if(promise.status === 200) return promise.data
  }).catch(error => {        
    console.log(error);
    return {hasError: true, error: error};
  });
};
const get = async (id) => {
  return await axios.get(`/admin/parking-owners/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const resendActivationLink = (id) => {
  return axios.post(`/admin/parking-owners/resendactivationlink/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {hasError: true, error: error};
  });
};


export {
  list,
  add,
  update,
  get,
  resendActivationLink
}
