import axios from 'axios';

const list = (id) => {
  return axios.get('/admin/fleet-companies/')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};


const createCompany = (item) => {
  return axios.post(`/admin/fleet-companies`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const getCompany = (id) => {
  // return axios.get(`/admin/parkings/${id}`) => translation not returned
  return axios.get(`/admin/fleet-companies/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const patchCompany = (id, item) => {
  return axios.patch(`/admin/fleet-companies/${id}`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);

    return {
      hasError: true,
      error
    }
  });
};

const updateOwner = (companyId, ownerId) => {
  return axios.post(`/admin/fleet-companies/${companyId}/owner/${ownerId}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);

    return {
      hasError: true,
      error
    }
  });
}


export {
  list,
  createCompany,
  getCompany,
  patchCompany,
  updateOwner
}