<template>
  <div class="authentification-form">
    <h2 class="authentification__title authentification__titleForm">{{ $t('authentication.login.title') }}</h2>
    <fieldset v-if="errors.global" class="fieldset-input__message" :class="{'error': errors.global}">
      <p class="error-msg">
        {{ errors.global }}
        <router-link
          v-if="errors.link"
          class="error-msg-link"
          :to="{ name: errors.link.to, query: errors.link.query}"
        >
          {{ errors.link.label }}
        </router-link>
      </p>
    </fieldset>

    <!-- Username -->
    <Fieldset 
      :type="'email'" 
      :label="$t('authentication.labels.email')"
      :name="'email'"
      :required="true"
      :error="errors.email"
      @input="onInput"
    /> 

     <Fieldset 
      :type="'password'" 
      :label="$t('authentication.labels.password')"
      :name="'password'"
      :required="true"
      :error="errors.password"
      @input="onInput"
    /> 

    <a class="small authentification-link" @click="reset">{{ $t('authentication.login.forgot') }}</a>

    <button type="button" @click="signIn" class="button">{{ $t('authentication.login.submit') }}</button>

    <hr class="hr-h">
    <div class="authentification-footer">
      <a href="https://www.weareotra.com/wp-content/uploads/privacy-policy-for-otra.pdf" target="_blank" class="small authentification-footer__link">{{$t('authentication.login.privacy')}}</a>
      <!-- <router-link class="small authentification-footer__link" :to="'privacy-policy'">Privacy policy</router-link> -->
      <!-- <router-link class="small authentification-footer__link" :to="'terms'">{{$t('authentication.login.terms')}}</router-link> -->
    </div>
  </div>
</template>

<script>
// import { CognitoUserPool, CookieStorage, CognitoUserAttribute, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import Fieldset from '@/components/modules/Authentication/Fieldset.vue'

export default {
  name: 'Login',
  components: {
    Fieldset
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      error: {
        email: '',
        password: '',
        global: '',
      }
    }
  },
  computed: {
    errors() {
      return this.$store.state.authentication.login.errors;
    }
  },
  methods: {
    onInput(evt) {
      const {target} = evt;
      this.form[target.name] = target.value;
    },
    async signIn() {
      const form = 'login';
      const { email, password } = this.form
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // Email
      this.$store.commit('updateErrors', {
        form, property: 'email', error: !email.trim() ? this.$t('notifications.errors.authentication.email_required') : ''
      });

      if(!this.errors.email) {
        this.$store.commit('updateErrors', {
          form, property: 'email', error: !regex.test(email) ? this.$t('notifications.errors.authentication.email_invalid') : ''
        });
      }

       // Password
      this.$store.commit('updateErrors', {
        form, property: 'password', error: !password ? this.$t('notifications.errors.authentication.wrong_password') : ''
      });

      // Global
      this.$store.commit('updateErrors', {
        form, property: 'global', error: ''
      });
      
      if(this.errors.email ||this.errors.password) return;
      this.$store.dispatch('signin', { email, password });
    },
    reset() {
      this.$store.commit('updateAuthState', 'lost-password');
      this.$router.push('lost-password')
    }
  }
}
</script>
