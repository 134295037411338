import axios from 'axios';

const getUser = async () => {
  return await axios.get('users/profile')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}

const updateUser = async (infos) => {
  return await axios.patch('users/profile/', infos)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}

const checkEmailVerified = async (email) => {
  return await axios.get(`users/check-email-verified/${email}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}

export {
  getUser,
  updateUser,
  checkEmailVerified
}