<template>
  <div 
    class="grid-page"
  >
    <Header />
    <main 
      class="main"
      :class="[className]"
    >
      <router-view/>
    </main>
    <Footer />
   

    <!-- MODAL -->
    <Modal />
    <Notifications />
  </div>
</template>

<script>

import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';

import Modal from '@/components/modules/Modal.vue';
import Notifications from '@/components/common/Notifications.vue';

export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    Modal,
    Notifications
  },
  computed:{
    className() {
      return `main-${this.$route.name ? this.$route.name.toLowerCase() : ''}`;
    },
    title() {
      return this.$route.meta.titleTemplate;
    },
    dateComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Date : null;
    },
    calendarComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Calendar : null;
    },
    quickSearchComponent() {
      return this.$route.meta.components ? this.$route.meta.components.QuickSearch : null;
    },
    statusComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Status : null;
    },
    availableComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Available : null;
    },
    paginateComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Pagination : null;
    },
    currentParking() {
      return this.$store.state.parkings.current;
    }
  },
  watch: {
    // currentParking(current) {
    //   this.$store.dispatch('setSales', current.id);
    //   this.$store.dispatch('setBookings', current.id);
    //   this.$store.dispatch('setBooked', current.id);
    //   this.$store.dispatch('setCheckin', current.id);
    //   this.$store.dispatch('setCheckout', current.id);
    //   this.$store.commit('updatePagination', {
    //     per: 10,
    //     page: 1
    //   });
    // },
    // $route() {
    //   this.$store.commit('updateDynamicFilters', {
    //     dates: {
    //       start: null,
    //       end: null,
    //     },
    //     search: null
    //   });

    //   this.$store.commit('updateCurrentBookingList', null);
    //   this.$store.commit('updateCurrentBookingsPaginate', null);

    //   this.$store.commit('updatePagination', {
    //     per: 10,
    //     page: 1
    //   });
    // }
  },
  created() {
    // this.setSettings();
  },
  methods: {
    // setSettings() {
    //   const parkings = this.$store.dispatch('setParkings');
      

    //   this.$store.dispatch('setLanguages');
    //   this.$store.dispatch('setCountries');
    //   this.$store.dispatch('setCurrencies');
    //   this.$store.dispatch('setFilters');
    //   this.$store.dispatch('setStatus');
    //   this.$store.dispatch('setSlots');
      
    //   parkings.then((promise) => {
    //     const current = this.$route.params.id ? this.$route.params : promise[0];
    //     this.$store.dispatch('setCurrent', current.id);
    //     this.$store.dispatch('setSales', current.id);
    //     this.$store.dispatch('setBookings', current.id);
    //     this.$store.dispatch('setBooked', current.id);
    //     this.$store.dispatch('setCheckin', current.id);
    //     this.$store.dispatch('setCheckout', current.id);
    //   })
    // }
  }
}
</script>

<style lang="scss">
  /* SCSS */
</style>