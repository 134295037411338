<template>
  <section ref="container" class="main-container main-container__form main-container__new"
    :class="{ 'main-container--loading': loading }">
    <form novalidate @submit.prevent="onSave">
      <!-- <h2 class="h5">Edit</h2> -->
      <fieldset>
        <h2 class="h5">{{ $t('partner.form.labels.partner_information') }}</h2>
        <div class="container-row">
          <Inputs ref="name" name="name" type="text" :label="$t('partner.form.labels.name')" :error="errors.name"
            :required="true" :value="form.name" @input="onInput($event, 'name')" />
        </div>
        <div class="container-row">
          <Inputs ref="vatnumber" name="vatnumber" type="text" :label="$t('partner.form.labels.vat')"
            :error="errors.vatnumber" :required="true" :value="form.vatnumber" @input="onInput($event, 'vatnumber')" />
        </div>
      </fieldset>
      <br>
      <fieldset>
        <h2 class="h5">{{ $t('partner.form.labels.contact') }}</h2>
        <div class="container-row">
          <Inputs ref="contactName" name="contactName" type="text" :label="$t('partner.form.labels.contact_name')"
            :error="errors.contactName" :required="true" :value="form.contactName"
            @input="onInput($event, 'contactName')" />
        </div>
        <div class="container-row">
          <Inputs ref="contactEmail" name="contactEmail" type="text" :label="$t('partner.form.labels.contact_email')"
            :error="errors.contactEmail" :required="false" :value="form.contactEmail"
            @input="onInput($event, 'contactEmail')" />
        </div>
        <div class="container-row">
          <Inputs ref="contactPhone" name="contactPhone" type="text" :label="$t('partner.form.labels.contact_phone')"
            :error="errors.contactPhone" :value="form.contactPhone" @input="onInput($event, 'contactPhone')" />
        </div>
        <div class="container-row">
          <Checkbox name="flex" :label="$t('partner.form.labels.flex')" :required="false" :value="form.flex"
            @input="onInput($event, 'flex')" />
        </div>
        <div class="container-row container-8-2">
          <Inputs ref="apiKey" name="apiKey" type="text" :readonly="true" :label="$t('partner.form.labels.api_key')"
            :error="errors.apiKey" :required="true" :value="form.apiKey" @input="onInput($event, 'apiKey')" />

          <a class="button" type="button" @click="generate('apiKey')">
            {{ $t('partner.form.buttons.generate') }}
          </a>
        </div>
        <!-- <div class="container-row">
          <Checkbox name="canAccessStandardApi" :label="$t('partner.form.labels.canAccessStandardApi')" :required="false" :value="form.canAccessStandardApi"
            @input="onInput($event, 'canAccessStandardApi')" />
        </div> -->

        <div class="container-row">
          <SelectMultiple :option="countries" 
            :selectedValues="this.selectedCountries"
            @onInput="onInput($event, 'countries')" 
            :label="$t('partner.form.labels.countries_exclude')"/>
        </div>
        
      </fieldset>

      <button class="button" type="submit">
        {{ $t('partner.form.buttons.save') }}
      </button>

      <div v-if="hasError" class="form--error small">
        {{ $t('notifications.errors.several_required') }}
      </div>
    </form>

    <div v-if="loading" class="main-container-loader">
      <loader />
    </div>
  </section>
</template>

<script>
import { isValidPhoneNumber, parsePhoneNumber, ParseError } from 'libphonenumber-js'
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
import Checkbox from '@/components/elements/Checkbox';
import SelectMultiple from '@/components/elements/SelectMultiple';

export default {
  components: {
    Loader,
    Inputs,
    Checkbox,
    SelectMultiple
  },
  state: {
    countries: []
  },
  beforeMount() {
    this.$store.commit('partner/resetForm');
    this.$store.dispatch('countryAbbreviations/getAllData');
  },
  data() {
    return {
      hasError: false,
      errors: {
        name: '',
        vatnumber: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        selectedCountries: []
      }
    }
  },
  computed: {
    form() {
      return this.$store.state.partner.form;
    },
    loading() {
      return this.$store.state.partner.requests !== 0;
    },
    countriesData() {
      return this.$store.state.countryAbbreviations.dataCountryAbbreviations;
    },
    countries() {
      const countries = this.$store.state.settings.minimalCountries;
      countries.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
      return countries;
    },
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    validate() {
      this.hasError = false;
      this.errors = {};

      const properties = [
        'name',
        'vatnumber',
        'contactName',
        'contactEmail',
        'contactPhone'
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const data = this.form[property];

        if ((property === 'name' || property === 'vatnumber' || property === 'contactName') && (!data || !data.length)) {
          this.hasError = true;
          this.errors[property] = this.$t('notifications.errors.required');
        } else {
          if (property === 'contactEmail' && (data || data.length)) {
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!regex.test(data)) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.email_invalid');
            }
          } else if (property === 'contactPhone' && (data || data.length)) {
            try {
              parsePhoneNumber(data);
            } catch (error) {
              if (error instanceof ParseError) {
                this.errors.contactPhone = this.$t('notifications.errors.phone_invalid')
              }
            }
            if (!this.errors.contactPhone) this.errors.contactPhone = !isValidPhoneNumber(data) ? "Please enter a valid phone number" : '';
            if (this.errors.contactPhone) this.hasError = true;
          }
        }
      }
    },
    onInput($event, property) {
      if (property === "countries") {
        this.$store.commit('partner/updateFormValue', { value: $event, property });
      } else {
        const { target } = $event;
        if (!target) return;

        switch (true) {
          case /flex/.test(property):
          case /canAccessStandardApi/.test(property):
            this.$store.commit('partner/updateFormValue', { value: target.checked, property });
            break;
          default:
            const { value } = target;
            this.$store.commit('partner/updateFormValue', { value, property });
            break;
        }
      }
    },
    generate(property) {
      const value = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = crypto.getRandomValues(new Uint8Array(1))[0] % 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
      this.$store.commit('partner/updateFormValue', { value, property });
    },
    async onSave() {
      this.validate();

      if (this.hasError) return;

      const request = await this.$store.dispatch('partner/create');

      if (!request.noRedirect) {
        const { id } = request;
        this.$router.push(`/partners/edit/${id}`);
      }
    },

    getCountryAbbreviations() {
      const countries = this.$store.state.countryAbbreviations.dataCountryAbbreviations
        .map(c => {
          return { value: c.id, label: c.code }
        });
      // this.$state.countries = countries
      return countries;
    }

  }
}
</script>

<style lang="scss" scoped>
.main-container {
  padding-bottom: 50px;
}

.button {
  margin-top: 30px;
}

.small.form--error {
  margin-top: 20px;
  color: $error;
}

.h5 {
  margin-bottom: 24px;

  &:not(:first-child) {
    margin-top: 60px;
  }
}
</style>
