<template>
  <section
    ref="container"
    class="main-container main-container__form main-container__edit"
    :class="{ 'main-container--loading': loading }"
  >
    <form
      novalidate
      @submit.prevent="onSave"
    >
      <h2 class="h5">{{ $t('parkings.form.title') }}</h2>
      <!-- Informations -->
      <fieldset>
        <div class="container-row container-5-5">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.international_name')"
            :error="errors.internationalName"
            :value="form.internationalName"
            :maxLength="parkingProperties.internationalName.maxLength"
            @input="onInput($event, 'internationalName')"
          />

          <Inputs
            type="text"
            :label="$t('parkings.form.labels.local_name')"
            :error="errors.localName"
            :value="form.localName"
            :maxLength="parkingProperties.localName.maxLength"
            @input="onInput($event, 'localName')"
          />
        </div>
        <div class="container-row">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.address')"
            :error="errors.street"
            :required="false"
            :value="form.street"
            :maxLength="parkingProperties.street.maxLength"
            @input="onInput($event, 'street')"
          />
        </div>
        <div class="container-row container-5-5">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.zip_code')"
            :error="errors.zipCode"
            :value="form.zipCode"
            :maxLength="parkingProperties.zipCode.maxLength"
            @input="onInput($event, 'zipCode')"
          />
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.city')"
            :error="errors.city"
            :value="form.city"
            :maxLength="parkingProperties.city.maxLength"
            @input="onInput($event, 'city')"
          />
        </div>
        
        <div class="container-row container-5-5">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.state')"
            :error="errors.state"
            :required="false"
            :value="form.state"
            :maxLength="parkingProperties.state.maxLength"
            @input="onInput($event, 'state')"
          />
          <Select
            :name="'country'"
            :label="$t('parkings.form.labels.country')"
            :options="countries"
            :placeholder="$t('parkings.form.placeholders.country')"
            :error="errors.country"
            :class="'classic classic-country'"
            @change="onInput($event, 'country')"
          />
        </div>
        <div class="container-row container-5-5">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.highway')"
            :error="errors.roadName"
            :value="form.roadName"
            :maxLength="parkingProperties.roadName.maxLength"
            @input="onInput($event, 'roadName')"
          />

          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.road_direction')"
            :error="errors.roadDirection"
            :value="form.roadDirection"
            :maxLength="parkingProperties.roadDirection.maxLength"
            @input="onInput($event, 'roadDirection')"
          />
        </div>

        <div class="container-row container-5-5">
          <Inputs
            type="text"
            :label="$t('parkings.form.labels.contact_phone')"
            :required="false"
            :error="errors.contactPhone"
            :value="form.contactPhone"
            :maxLength="parkingProperties.contactPhone.maxLength"
            @input="onInput($event, 'contactPhone')"
          />
          <!-- <Select
            :name="'ruleset'"
            :label="$t('parkings.form.labels.ruleset')"
            :options="rulesets"
            :placeholder="$t('parkings.form.placeholders.ruleset')"
            :error="errors.rulesets"
            :class="'classic'"
            @change="onInput($event, 'rulesetId')"
          /> -->
          <NewSelect
            :name="'ruleset'"
            :options="rulesets"
            :value="form.rulesetId"
            :class="'classic'"
            :label="$t('parkings.form.labels.ruleset')"
            :placeholder="$t('parkings.form.placeholders.ruleset')"
            @onChange="onInput($event, 'rulesetId')"
          />
        </div>


        <div class="container-row container-5-5">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.gps_coordinates')"
            :error="errors.latitude"
            :value="form.latitude"
            :maxLength="parkingProperties.latitude.maxLength"
            @input="onInput($event, 'latitude')"
          />
          <Inputs
            type="text"
            name="longitude"
            :error="errors.longitude"
            :value="form.longitude"
            :maxLength="parkingProperties.longitude.maxLength"
            @input="onInput($event, 'longitude')"
          />
        </div>

        <div class="container-map">
          <label class="label small">{{$t('parkings.form.labels.parking_location')}}</label>
          <div class="map">
            <Map 
              :street="form.street"
              :zipCode="form.zipCode"
              :country="form.country"
              :city="form.city"
              :lat="form.latitude"
              :lng="form.longitude"
              :lang="$i18n.locale"
              @coordinates="onInput($event, 'coordinates')"
            />
          </div>
        </div>
      </fieldset>
      <button
        class="button"
        type="submit"
        :disabled="isLoading"
      >
        {{$t('parkings.form.buttons.save.edit')}}
      </button>

      <div 
        v-if="hasError"
        class="form--error small"
      >
        {{$t('notifications.errors.several_required')}}
      </div>
    </form>

    <div
      v-if="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import Map from '@/components/modules/Map';
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
import Select from '@/components/elements/Select';
import Checkbox from '@/components/elements/Checkbox';
import NewSelect from '@/components/elements/NewSelect';
import ButtonSave from '@/components/elements/ButtonSave';

import Properties from '@/data/properties.json';

export default {
  name: 'New',
  components: {
    Loader,
    Inputs,
    Map,
    Select,
    Checkbox,
    NewSelect,
    ButtonSave,
  },
  beforeMount() {
    this.$store.dispatch('parking/getRulesets');
    // const { id } = this.$route.params;
    // this.$store.commit('parking/updateId', id);

    // this.$store.dispatch('parking/getData');
    // this.$store.dispatch('parking/getMedias');
    // this.$store.dispatch('parking/getCommodities');
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      errors: {},
      errors: {},
      form: {
        internationalName: "",
        localName: "",
        street: "",
        zipCode: "",
        city: "",
        state: "",
        country: "",
        roadName: "",
        roadDirection: "",
        contactPhone: "",
        latitude: 0,
        longitude: 0,
        rulesetId: 1,
      },
      parkingProperties: Properties.parkings
    };
  },
  computed: {
    countries() {
      const countries = this.$store.state.settings.minimalCountries;
      countries.sort((a, b) => {
        if(a.label < b.label) return -1;
        if(a.label > b.label) return 1;
        return 0;
      });
      return countries;
    },
    rulesets() {
      return this.$store.state.parking.rulesets.map(r => {
        return {
          label: r.label,
          value: r.value,
        }
      });
    },
    loading() {
      return this.$store.state.parking.requests !== 0;
    },
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    },
  },
  methods: {
    validate() {
      this.hasError = false;
      this.errors = {};

      const properties = [
        'internationalName',
        'localName',
        'street',
        'city',
        'country',
        'roadName',
        'roadDirection',
        'latitude',
        'longitude'
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const data = this.form[property];
        
        switch (property) {
          case 'longitude':
          case 'latitude':
            
            if(!data || !data.toString().length) {
                this.hasError = true;
                this.errors[property] = this.$t('notifications.errors.required');
            } else if(data) {
              const dataStringify = data.toString()
              const hasDot =  dataStringify.match(/\./g)
              const hasDash =  dataStringify.match(/\-/g)
              
              const isNotValidFormat = hasDot && hasDot.length > 1
                                      || hasDash && hasDash.length > 1
                                      || dataStringify.indexOf('-') > 0
                                      || dataStringify.indexOf(',') != -1



              const isBetweenLat = data >= -90 && data <= 90
              const isBetweenLng = data >= -180 && data <= 180

              if(isNotValidFormat) {
                this.hasError = true;
                // this.errors[property] = 'Please enter a valid format ex: "-34.432229" ';
                this.errors[property] = this.$t('notifications.errors.coord_format');
              }

              if(property === "latitude" &&  !isBetweenLat && !isNotValidFormat ) {
                this.hasError = true;
                // this.errors[property] = 'Please enter a latitude between -90 and 90';
                this.errors[property] = this.$t('notifications.errors.lat_value');
              }
              if(property === "longitude" &&  !isBetweenLng && !isNotValidFormat) {
                this.hasError = true;
                // this.errors[property] = 'Please enter a longitude between -180 and 180';
                this.errors[property] = this.$t('notifications.errors.long_value');
              }


            }
            break;
        
          default:
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');
            }
            break;
        }
      }
    },
    // Events
    onInput($event, property) {
      const { target } = $event;
      if (!target && (property !== 'coordinates' && property !== 'country' && property !== 'rulesetId')) return;
      switch (property) {
        case 'longitude':
        case 'latitude':
          const val = target.value
          .replace(/\,/g, '.') // replace all ',' by '.'
          .replace(/[^\d\.-]|^[\.]*$/g, '') // only allow digits "." and "-"

          target.value = val
          this.form[property] = target.value
          break;
        case 'rulesetId':
        case 'country':
          this.form[property] = $event;
          break;
        default:
          const { value } = target;
          this.form[property] = value
          break;
      }
    },
    onSave() {
      this.validate();

      if (this.hasError) return;

      this.isLoading = true;
      // this.$store.dispatch('parking/saveParking');
      const sent = this.$store.dispatch('parkings/new', this.form);
      sent.then((promise) => {
        this.isLoading = true;
        if(promise){
          this.$router.push('/parkings');
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    padding-bottom: 100px;
  }

  .container-row {
    &:first-child {
      margin-top: 0;
    }
  }

  .container-map {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .small.form--error {
    margin-top: 20px;
    color: $error;
  }

  .container-additionnal {
    margin-top: 32px;
    margin-bottom: 24px;

    .container-row {
      justify-content: flex-start;
    }

    .input {
      width: 220px;

      &:not(:first-child) {
        margin-left: 24px;
      }
    }
  }

  .h5 {
    margin-bottom: 20px;

    &:not(:first-child) {
      margin-top: 60px;
    }
  }

  .button {
    margin-top: 60px;
  }

  .classic-country{
    z-index: 2;
  }
</style>
