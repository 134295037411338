<template>
  <nav class="tabs">
    <ul class="tabs-items">
      <router-link 
        class="tabs-item"
        :class="{'tabs-item--active': this.$route.meta.parent === 'parking'}"
        :to="{name: 'Companies'}"
      >
        {{$t('pages.parking.page_title')}}
      </router-link>
      <router-link 
        :class="['tabs-item', { 'tabs-item--active': isFleet }]"
        :to="{name: 'fleetCompanies'}"
        >
        {{$t('pages.fleet.page_title')}}
      </router-link>
      <router-link 
        :class="['tabs-item', { 'tabs-item--active': isTeam }]"
        :to="{name: 'teamAdmins'}"
      >
        {{$t('pages.team.page_title')}}
      </router-link>
       <router-link 
        :class="['tabs-item', { 'tabs-item--active': isApp }]"
        :to="{name: 'editHomeScreen'}"
      >
        {{$t('pages.app.page_title')}}
      </router-link>
      <router-link 
        :class="['tabs-item', { 'tabs-item--active': isReport }]"
        :to="{name: 'reportScreen'}"
      >
        {{$t('pages.report.tab_title')}}
      </router-link>

    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Tabs',
  computed: {
    isFleet() {
      const { $route } = this;
      return $route && $route.meta.parent && $route.meta.parent === 'fleet';
    },
    isTeam() {
      const { $route } = this;
      return $route && $route.meta.parent && $route.meta.parent === 'team';
    },
    isApp() {
      const { $route } = this;
      return $route && $route.meta.parent && $route.meta.parent === 'app';
    },
    isReport() {
      const { $route } = this;
      return $route && $route.meta.parent && $route.meta.parent === 'report';
    }

  }
}
</script>
