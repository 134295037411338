import i18n from '../i18n';
import { list } from '@/calls/team-admins';

const teamAdmins = {
  namespaced: true,
  state: {
    requests: 0,
    search: null,
    items: [],
  },
  mutations: {
    updateItems(state, items) {
      state.items = items;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    increaseRequest(state) {
      state.requests++;
    },
    decreaseRequest(state) {
      state.requests--;
    }
  },
  actions: {
    async set({ state, commit, dispatch }) {
      commit('increaseRequest');
      const search = state.search && state.search.length ? state.search : null;
      const admins = await list(search);

      if (admins.hasError) {
        const { tc } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: tc('notifications.errors.admins.getting', 2, { error: admins.error }),
          type: 'error'
        }], { root: true });
      } else {
        commit('updateItems', admins);
      }

      commit('decreaseRequest');
    }
  }
};

export default teamAdmins;
