<template>
  <main class="authentification">
    <header class="authentification-header">
      <Sprite 
        :type="'logo'"
        :width="76"
        :height="72"
      />
      <h1 v-html="$t('authentication.title')" class="authentification__title"/>
    </header>
    <section class="authentification-container">
      <LostPassword v-if="this.$route.name === 'LostPassword'" />
      <ResetPassword v-else-if="this.$route.name === 'ResetPassword'" />
      <Login v-else />
    </section>
  </main>
</template>


<script>
import Login from '@/views/authentication/Login.vue';
import LostPassword from '@/views/authentication/LostPassword.vue';
import ResetPassword from '@/views/authentication/ResetPassword.vue';
import Sprite from '@/components/elements/Sprite.vue';

export default {
  name: 'AuthStateApp',
  components: {
    Login,
    LostPassword,
    ResetPassword,
    Sprite
  },
  computed: {
    authState() {
      return this.$store.state.authentication.authState;
    },
    // user() {
    //   return this.$store.state.authentication.tokens;
    // }
  },
}
</script>


<style lang="scss">
  .authentification {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $darkgrey;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 100vh;
    overflow: auto;

    &-container {
      width: 380px;
      margin-bottom: auto;
    }

    &-header {
      width: 380px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: auto;
      margin-bottom: 30px;
      color: $white;
    }
    &-link {
      font-size: rem(12px);
      color: $grey;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &--green {
        color: $mediumgreen;
      }
      &--blue {
        color: $snap-primary;
      }
    }
    &-form {
      background: $white;
      border-radius: 3px;
      padding: 30px;
      box-shadow: $snap-elevation-2;

      text-align: center;
      color: $snap-text-primary;
    }

    &__title {
      font-size: rem(34px);
      letter-spacing: 0;
      text-transform: none;
    }
    &__titleForm {
      margin-bottom: 30px;
    }

    &-footer {
      margin-top: 20px;
      display: flex; 
      justify-content: center;
      align-items: center;

      &__link {
        font-size: rem(12px);
        color: $grey;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      a + a:before {
        content:'';
        width:3px;
        height:3px;
        border-radius: 50%;
        display: inline-block;
        background: $grey;
        margin: 0 20px;
        margin-bottom: 2px;
      }
    }
    .button {
      width: 100%;
      font-size: rem(14px);
      margin-top: 30px;
      text-transform: uppercase;
      padding: 15px 30px;
    }
  }
  .input {
    border: 1px solid $lightestgrey;
    border-radius: 3px;

    padding: 15px 16px;

    width: 100%;
    transition: border .3s $ease-out-quart;

    @extend %p;
    font-size: rem(16px);
    color: $snap-primary;
    margin-bottom: 15px;
    &::placeholder {
      color: $mediumgrey;
    }
    .error & {
      border: 1px solid $error !important;
    }
  }
  .lost-password {
    margin-top: 10px;
    display: block;
    text-align: center;
  }
  
</style>
