<template>
  <div class="upload">
    <!-- Image -->
    <div
      v-if="url || img"
      class="upload-picture"
      @drag="onDragPicture"
      @dragstart="onDragStartPicture"
      @dragend="onDragEndPicture"
      @dragenter="onDragEnterPicture"
      @dragleave="onDragReset"
      @dragover="onDragResetFalse"
      draggable="true"
    >
      <img :src="url ? url : img"/>
      <button
        class="upload-reset"
        type="button"
        @click.prevent="onReset"
      />
    </div>

    <template v-else>
      <input
        :id="`picture-${id}`"
        :multiple="multiple"
        type="file"
        accept=".jpg,.png,.jpeg"
        @change.prevent.stop="onChange"
        :disabled="disableSnapReleaseButtons"
      >
      <!-- UI -->
      <div class="upload-ui">
        <label
          :for="`picture-${id}`"
          class="upload-label"
          @drag="onDragReset"
          @dragstart="onDragReset"
          @dragenter="onDragReset"
          @dragleave="onDragReset"
          @dragend="onDragReset"
          @dragover="onDragResetFalse"
          @drop="onDropFile"
        >
          <span class="upload-label-center">
            <span class="upload-label-plus"></span>
            {{ label }}
            <small>{{ format }}</small>
          </span>
        </label>
      </div>
    </template>

    <div v-if="$slots.button" class="upload-button">
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    format: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    url: { type: String },
    img: { type: String },
    maxWidth: {
      type: Number,
      default: 600
    },
    maxHeight: {
      type: Number,
      default: 350
    },
    checkOrientation: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange(e) {
      const $input = e.currentTarget;
      const files = $input.files;

      for (let i = 0; i < files.length; i++) {
        this.check(files[i]);
        // this.$emit('change', files[i]);
      }
    },
    onReset() {
      this.$emit('reset');
    },
    onDragPicture(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('drag', this.id);
    },
    onDragEnterPicture(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('dragenter', this.id);
    },
    onDragStartPicture(e) {
      e.stopPropagation();
      e.dataTransfer.setData('text/plain', `${this.id}`);
    },
    onDragEndPicture(e) {
      this.$emit('dragend');
    },
    // Dropzone input file
    onDragReset(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    onDragResetFalse(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    },
    onDropFile(e) {
      e.preventDefault();
      e.stopPropagation();

      const { dataTransfer } = e;
      const { files } = dataTransfer;

      for (let i = 0; i < files.length; i++) {
        this.check(files[i]);
        // this.$emit('change', files[i]);
      }
    },
    check(file) {
      const accepted = /jpg|jpeg|png/g
      const split = file.name.split('.');
      const ext = split[split.length - 1];

      // Check extension
      if (!ext.match(accepted)) {
        this.$store.dispatch('notifications/addNotifs', [{
          text: this.$t('notifications.errors.files.wrong_extensions', {file_name: file.name}),
          type: 'error'
        }], { root: true });

        return;
      }

      const _URL = window.URL || window.webkitURL;
      const img = new Image();
      const objectUrl = _URL.createObjectURL(file);

      img.onload = (e) => {
        _URL.revokeObjectURL(objectUrl);

        const { width, height } = img;
        const isLandscape = width > height;

        // Check size
        // if (width > 1000 || height > 585) {
        if (width > this.maxWidth || height > this.maxHeight) {
          this.$store.dispatch('notifications/addNotifs', [{
            text: this.$t('notifications.errors.files.max_allowed_size_picture', {width: width, height:height, file_name: file.name}),
            type: 'error'
          }], { root: true });
          return;
        }

        // Check landscpae
        if (this.checkOrientation) {
          if (isLandscape) {
              // 600*350
            this.$emit('change', file);
          } else {
            this.$store.dispatch('notifications/addNotifs', [{
              text: this.$t('notifications.errors.files.orientation_picture',{file:file.name}),
              type: 'error'
            }], { root: true });
          }
        } else {
          console.log('this emit change');
          this.$emit('change', file);
        }
      };

      img.src = objectUrl;
    }
  },
  computed: {
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  }
}
</script>

<style lang="scss" scoped>
.upload {
  $ratio: (330 / 437) * 100%;
  position: relative;

  &:before {
    padding-top: $ratio;
    display: block;
    content: '';
  }

  input {
    display: none;
  }

  // Image
  // -----------------------------------------------------------------------------
  &-picture {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }
  }

  &-reset {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $mediumgreen;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 6px);
      width: 12px;
      height: 2px;
      background-color: $white;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  // UI
  // -----------------------------------------------------------------------------
  &-ui {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
  }

  &-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 1px dashed $snap-action-default;
    border-radius: 3px;
    color: $snap-action-default;
    font-size: rem(16px);
    font-family: $secondary;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;

    * {
      pointer-events: none;
    }

    &-center {
      display: flex;
      flex-direction: column;
      align-items: center;

      small {
        margin-top: 6px;
        font-size: rem(14px);
      }
    }

    &-plus {
      position: relative;
      width: 22px;
      height: 22px;
      margin-bottom: 20px;

      &::before,
      &::after {
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 10px);
        width: 20px;
        height: 2px;
        content: '';
        background-color: $mediumgreen;
      }

      &::after {
        transform: rotate(90deg);
      }
    }
  }

  &-button {
    display: flex;
    position: absolute;
    bottom: 24px;
    left: 24px;
    z-index: 1;
    pointer-events: none;
  }
}
</style>

<style lang="scss">
.upload {
    .upload-button {
    .button {
      padding: 11px 30px 8px !important;
    }
  }
}
</style>
