<template>
  <aside 
    ref="modal"
    class="modal"
    :class="isVisible ? 'modal--visible' : ''"
  >
    <div class="modal-container">
      <div class="abbr"><span>{{$t('company.modal.owner.abbr')}}</span></div>
      <h3 class="h4">
        {{$t('company.modal.owner.title')}}
      </h3>
      <Select
        ref="Owner"
        :name="$t('company.modal.owner.label')"
        :options="owners"
        :placeholder="$t('company.modal.owner.select')"
        :required="true"
        :value="owner"
        :class="'classic'"
        @onChange="handleChange"
      />
      <!-- <Select
        ref="Owner"
        :name="$t('company.modal.owner.label')"
        :options="owners"
        :placeholder="owner ? owner :$t('company.modal.owner.select')"
        :required="true"
        :class="'classic'"
        @change="handleChange"
      /> -->
    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        class="button button-primary"
        @click="onClick"
      >
       {{$t('company.modal.owner.cancel')}}
      </button>
      <button-save 
        :loading="loading"
        :labels="{
          default: $t('company.modal.owner.confirm'),
          end: $t('company.modal.owner.saved')
        }"
        :class="'button-primary'"
        @click="onSubmit"
        :disabled="disableSnapReleaseButtons"
      />
      <!-- <button @click="onSubmit" type="button" class="button  button-primary">Confirm</button> -->
    </div>
    <div class="modal-backdrop" @click="onClick"></div>
  </aside>
</template>

<script>
// import Select from '@/components/elements/Select';
import Select from '@/components/elements/NewSelect';
// import Select from '@/components/elements/Select';
import ButtonSave from '@/components/elements/ButtonSave';

export default {
  name: "Modal",
  components: { 
    Select,
    ButtonSave
  },
  data() {
    return {
      selected: null,
      loading: false
    }
  },
  watch: {
    owner(val) {
      return val
    },
    company(val) {
      if(!this.owner) this.owner = "unassigned"; 
      return val
    },
    isVisible() {
      this.loading = false;
    }
  },
  computed: {
    isVisible() {
      return this.$store.state.companies.modal.visible;
    },
    owner() {
      const owner = this.$store.state.companies.modal.owner;
      return owner;
    },
    startOwner() {
      const owner = this.$store.state.companies.modal.startOwner;
      return owner;
    },
    company() {
      return this.$store.state.companies.modal.id;
    },
    owners() {
      const owners = this.setForSelect(this.$store.state.owners.list);
      return owners;
    },
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  },
  methods: {
    setForSelect(arr) {
      const tmpArr = [{
        value: "unassigned",
        label: "unassigned"
      }]
      if(!arr) return
      arr.forEach(owner => {
          tmpArr.push({
            value: owner.id,
            label: owner.user ? owner.user.fullName : ''
          })
      });
      return tmpArr.filter(item => item.label);
    },
    onClick() {
      this.$store.commit('companies/updateModal', {id: null, owner: "unassigned"});
      this.owner = this.$t('company.modal.owner.select')
    },
    onClose(evt) {
      const {target} = evt;
      if(target === this.$el) this.onClick(); 
    },
    handleChange(value) {
      this.selected = value;
      this.$store.commit('companies/updateModalProperty', {
        property: 'owner',
        value
      });
    },
    onSubmit(evt) {
      let unassign, update;
      if(this.selected === null) return;
      this.loading = true;
   
      if(this.startOwner !== 0) {
        unassign = this.$store.dispatch('owners/update', {
          id: this.startOwner,
          item: {
            parkingCompanyId: 0
          }
        })
      }

      if(this.selected !== "unassigned" && this.selected !== null){ 
        update = this.$store.dispatch('owners/update', {
          id: this.selected,
          item: {
            parkingCompanyId: this.company
          }
        })
      }
      Promise.all([unassign, update]).then(() => {
        this.loading = 'finish';
        this.$store.dispatch('companies/set')
        this.onClick();
      });
    }
  }
}
</script>

