<template>
  <div class="feature">
    <span class="feature-icon">
      <sprite
        :type="`feature-${feature.icon}`"
        :width="20"
        :height="23"
      />
    </span>
    <!-- <h4 class="feature-title">{{ GetTranslation(feature.translations, 'name') }}</h4> -->
    <h4 class="feature-title">{{ translations[`${feature.translationKey}.name`] }}</h4>
    <div class="feature-rating" :data-rating="feature.score">
      <sprite
        v-for="i in 5"
        :key="`feature-rating-${index}-${i}`"
        :type="'star'"
       
      />
    </div>
    <div class="feature-content">
      <button
        v-for="(item, ind) in feature.commodities"
        :key="`feature-${index}-${ind}`"
        class="feature-content-item"
        type="button"
        @click="$emit('feature', {
          groupId: feature.id,
          commodityId: item.id
        })"
        disabled
      >
        <span class="icon">
          <sprite
            v-if="item.isActive"
            :type="`check`"
            :width="12"
            :height="9"
          />
        </span>
        {{ translations[`${item.translationKey}.name`] }}
        <!-- {{ GetTranslation(item.translations, 'name') }} -->
      </button>
    </div>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

import features from '@/data/features.json';

export default {
  components: {
    Sprite
  },
  props: {
    feature: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    language() {
      return this.$store.state.settings.language
    },
    translations() {
      return this.$store.state.translations.keys;
    }
  },
  methods: {
    GetTranslation(obj, of) {
      let translation
      translation = obj.filter(f => f.language === this.language).shift()
      if(!translation) translation = obj.filter(f => f.language === 'en').shift()
      return translation[of]
    }
  },
  data() {
    return {
      features
    }
  }
};
</script>



<style lang="scss" scoped>
.feature {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 10px;
  grid-template-areas: 
    "icon title"
    "icon rating"
    "content content"
  ;

  &-icon {
    grid-area: icon;
  }

  &-title {
    grid-area: title;
    padding-top: 3px;
    font-size: rem(16px);
    line-height: 1.12;
    text-transform: uppercase;
  }

  &-rating {
    display: flex;
    grid-area: rating;
    margin-top: 4px;
    justify-self: flex-start;

    @for $i from 1 through 5 {
      &[data-rating="#{$i}"]:not(:hover) {
        .svg:nth-child(-n + #{$i}) {
          fill: $yellow;
        }
      }
    }

    // &:hover {
    //   .svg {
    //     fill: $yellow;

    //     &:hover {
    //       ~ .svg {
    //         fill: rgba($grey, .5);
    //       }
    //     }
    //   }
    // }

    .svg {
      fill: rgba($grey, .5);
      cursor: pointer;
    }
  }

  &-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-area: content;
    margin-top: 20px;

    &-item {
      position: relative;
      width: calc(50% - 10px);
      flex-shrink: 0;
      padding-left: 38px;
      padding-top: 4px;
      min-height: 21px;
      transition: color .3s $ease-out-quart;
      color: $snap-text-primary;
      font-family: $secondary;
      font-weight: 500;
      font-size: rem(14px);
      line-height: 1.12;
      text-align: left;
      cursor: pointer;

      &:hover {
        color: $mediumgreen;
      }

      &:nth-child(n + 3) {
        margin-top: 10px;
      }

      &:nth-child(even) {
        margin-left: 20px;
      }

      &:nth-child(odd):last-child {
        width: 100%;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 0;
        width: 21px;
        height: 21px;
        border: 1px solid rgba($grey, .2);
        border-radius: 3px;
        transform: translateY(-50%);

        .svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>
