<template>
    <a
      class="download-button"
      href="#"
    
    >
    <span class="svg" v-if="!loading" ><img src="../../assets/svg/download.svg" /></span>
    <span class="svg"  v-if="loading"><img src="../../assets/svg/loader-black.svg" /></span>
    <template v-if="label"><span class="download-label"> {{ label }} </span></template>       
    </a>
</template>
  
  <script>
  export default {
    name: 'DownloadButton',
    props: {
        label: {
            type: String,
            default: null
        } ,
         loading :{
            type:Boolean,
            default:false
         }
    }
  }
  </script>

<style lang="scss" scoped>
.download-button {
  margin-top: 2px;
  margin-left: 10px;
}
.download-label {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 100%;
  text-transform: none;
  padding-left: 8px;
}
</style>