import {
  getCompanies,
  addCompany,
  updateCompany,
  getCompany
} from '@/calls/companies';

const companies = {
  namespaced: true,
  state: {
    requests: 0,
    search: null,
    list: [],
    byID: [],
    modal: {
      visible: false,
      id: null,
      owner: 0,
      startOwner: 0
    },
    modalUnassign: {
      visible: false,
      id: null,
      company: null,
      parking: null,
    },
    order: {
      type: 'name',
      dir: 'asc'
    }
  },
  mutations: {
    updateCompanies(state, list) {
      state.list = list;
    },
    updateByID(state, byID) {
      state.byID = byID;
    },
    updateOrder(state, type) {
      if (state.order.type === type) {
        state.order.dir = state.order.dir === 'asc' ? 'desc' : 'asc';
        return;
      }

      state.order.type = type;
      state.order.dir = 'asc';
    },
    updateModalProperty(state, { property, value }) {
      state.modal[property] = value;
    },
    updateModal(state, {id, owner}) {
      state.modal.visible = !state.modal.visible;
      state.modal.id = id;
      state.modal.owner = owner;
      state.modal.startOwner = owner;
    },
    updateModalUnassign(state, {company, parking, id}) {
      state.modalUnassign.visible = !state.modalUnassign.visible;
      state.modalUnassign.id = id;
      state.modalUnassign.company = company;
      state.modalUnassign.parking = parking;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    increaseRequest(state) {
      state.requests++;
    },
    decreaseRequest(state) {
      state.requests--;
    }
  },
  actions: {
    set({ state, commit }) {
      commit('increaseRequest');
      const search = state.search && state.search.length ? state.search : null;
      const companies = getCompanies(search);
      const arr = [];
      return companies.then((promise) => {
        promise.forEach(element => {
          arr[element.id] = element.name
        });
        commit('updateCompanies', promise);
        commit('updateByID', arr);
        commit('decreaseRequest');
        return promise;
      });
    },
    new({ commit, dispatch}, item) {
      const request = addCompany(item);

      if (!request.hasError) {
        dispatch('set');
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parking-company.creation', { error: request }),
          type: 'error'
        }], { root: true });
      }

      return request;
    },
    update({commit}, obj) {
      const company = updateCompany(obj)
      return company.then(promise => {
        this.dispatch('companies/set')
      })
    },
    search({ commit, dispatch }, terms) {
      if (!terms || !terms.length) {
        dispatch('set');
        return;
      }

      // TO DO
      const companies = getCompanies();
      const arr = [];
      return companies.then((promise) => {
        promise.forEach(element => {
          arr[element.id] = element.name
        });
        commit('updateCompanies', promise);
        
        commit('updateByID', arr);
        return promise;
      });
    },
    async getCompany({ commit, dispatch }, id) {
      const company = await getCompany(id);
      return company;
    }
  }
};

export default companies;
