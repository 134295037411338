import {
  getSettings,
  getCountries,
  vatTest,
  getContext
} from '@/calls/settings';

const settings = {
  namespaced: true,
  state: {
    language: 'en',
    languages: [],
    settings: [],
    currency: 'eur',
    country: 'be',
    countries: [],
    minimalCountries: [],
    context: null,
    requests : {
      settings: 0
    },
    disableSnapReleaseButtons: false
  },
  mutations: {
    updateSettings(state, settings) {
      state.settings = settings;
    },

    updateLanguage(state, language) {
      state.language = language;
    },
    updateLanguages(state, languages) {
      state.languages = languages;
    },
    updateCountry(state, country) {
      state.country = country;
    },
    updateCurrency(state, currency) {
      state.currency = currency;
    },
    updateCountries(state, countries) {
      state.countries = countries;
    },
    updateCountriesMinimal(state, minimalCountries) {
      state.minimalCountries = minimalCountries;
    },
    updateCurrencies(state, currencies) {
      state.currencies = currencies;
    },
    updateContext(state, context) {
      state.context = context;
    },
    updateDisableSnapReleaseButtons(state, isDisable) {
      state.disableSnapReleaseButtons = isDisable;
    },
    increaseRequests(state, type) {
      state.requests[type]++;
    },
    decreaseRequests(state, type) {
      state.requests[type]--;
    }
  },
  actions: {
    global({ commit }) {
      const settings = getSettings();
      settings.then((promise) => {
        return commit('updateSettings', promise);
      })
    },
    vat({}, vat) {
      const test = vatTest(vat);
      return test.then((promise) => {
        return promise
      })
    },
    country({ commit }, country) {
      return commit('updateCountry', country);
    },
    currency({ commit }) {
      return commit('updateCurrency', '');
    },
    async countries({ commit }) {
      commit('increaseRequests', 'settings');
      const countries = await getCountries();

      const arr = countries.map(country => {
        return {
          value: country.value.toLowerCase(),
          label: country.label
        }
      });

      commit('updateCountriesMinimal', arr);
      commit('updateCountries', countries);

      commit('decreaseRequests', 'settings');
    },
    setContext({ commit }) {
      const context = getContext();
      context.then((promise) => {
        return commit('updateContext', promise);
      });
    },
    async setSettings({ commit, state }, types = ['languages']) {
      commit('increaseRequests', 'settings');
      const settings = await getSettings();

      for (let i = 0; i < types.length; i++) {
        const type = types[i];
        const typeUpper = type.charAt(0).toUpperCase() + type.slice(1);
        commit(`update${typeUpper}`, settings[type]);
      }
      commit('updateDisableSnapReleaseButtons', settings['sitesSettings']['adminSettings'].disableSnapReleaseButtons);
      
      commit('decreaseRequests', 'settings');
    }
  }
};

export default settings;
