<template>
  <section
    ref="container"
    class="main-container main-container__form main-container__new"
    :class="{ 'main-container--loading': loading }"
  >
    <form
      novalidate
      @submit.prevent="onSave"
    >
      <!-- <h2 class="h5">Edit</h2> -->
      <fieldset>
        <div class="container-row container-5-5">
          <Inputs
            ref="name"
            name="name"
            type="text" 
            :label="$t('fleet.form.labels.name')"
            :error="errors.name"
            :required="true"
            :value="form.name"
            @input="onInput($event, 'name')"
            :readonly="true"
          />
          <Inputs
            ref="vatnumber"
            name="vatnumber"
            type="text" 
            :label="$t('fleet.form.labels.vat')"
            :error="errors.vatnumber"
            :required="true"
            :value="form.vatnumber"
            @input="onInput($event, 'vatnumber')"
            :readonly="true"
          />
        </div>
        <div class="container-row container-5-5">
          <Inputs
            ref="email"
            name="email"
            type="text" 
            :label="$t('fleet.form.labels.email')"
            :error="errors.email"
            :required="true"
            :value="form.email"
            @input="onInput($event, 'email')"
            :readonly="true"
          />

          <Inputs
            ref="phoneNumber"
            name="phoneNumber"
            type="text" 
            :label="$t('fleet.form.labels.phone')"
            :error="errors.phoneNumber"
            :value="form.phoneNumber"
            @input="onInput($event, 'phoneNumber')"
            :readonly="true"
          />
        </div>

        <div class="container-row container-5-5">
          <Inputs
            ref="street"
            name="street"
            type="text" 
            :label="$t('fleet.form.labels.street')"
            :error="errors.street"
            :value="form.street"
            @input="onInput($event, 'street')"
            :readonly="true"
          />

          <Inputs
            ref="zipCode"
            name="zipCode"
            type="text" 
            :label="$t('fleet.form.labels.zip')"
            :error="errors.zipCode"
            :value="form.zipCode"
            @input="onInput($event, 'zipCode')"
            :readonly="true"
          />
        </div>

        <div class="container-row container-5-5">
          <Inputs
            ref="city"
            name="city"
            type="text" 
            :label="$t('fleet.form.labels.city')"
            :error="errors.city"
            :value="form.city"
            @input="onInput($event, 'city')"
            :readonly="true"
          />

          <Inputs
            ref="state"
            name="state"
            type="text" 
            :label="$t('fleet.form.labels.state')"
            :error="errors.state"
            :value="form.state"
            @input="onInput($event, 'state')"
            :readonly="true"
          />
        </div>

        <div class="container-row container-5-5">
          <Select
            :name="'country'"
            :label="$t('fleet.form.labels.country')"
            :options="countries"
            :placeholder="country ? country.name : $t('fleet.form.labels.country_select')"
            :error="errors.country"
            :class="'classic'"
            @change="onInput($event, 'country')"
            :readonly="true"
          />

          <NewSelect
            :name="'clientType'"
            :options="clientType"
            :value="form.clientType"
            :class="'classic'"
            :label="$t('fleet.form.labels.client_type')"
            @onChange="onInput($event, 'clientType')"
            :readonly="true"
          />

        </div>

        <div class="container-row container-5-5">
          <Checkbox
            name="flex"
            label="Flex option"
            :required="false"
            :value="form.isFlex"
            @input="onInput($event, 'isFlex')"
            :disable="true"
          />
        </div>
      </fieldset>

      <button
        class="button"
        type="submit"
        disabled
      >
        {{$t('fleet.form.buttons.save')}}
      </button>

      <div 
        v-if="hasError"
        class="form--error small"
      >
        {{$t('notifications.errors.several_required')}}
      </div>
    </form>

    <div
      v-if="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </section>
</template>

<script>
import {isValidPhoneNumber, parsePhoneNumber, ParseError} from 'libphonenumber-js'

import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
import Select from '@/components/elements/Select';
import Checkbox from '@/components/elements/Checkbox';
import NewSelect from '@/components/elements/NewSelect';
import ClientType from '@/data/clienttype.json';

export default {
  components: {
    Select,
    Loader,
    Inputs,
    Checkbox,
    NewSelect
  },
  beforeMount() {
    const { id } = this.$route.params;

    this.$store.commit('fleetCompany/resetForm');
    this.$store.commit('fleetCompany/updateId', id);
    this.$store.dispatch('fleetCompany/getData');
  },
  data() {
    return {
      hasError: false,
      errors: {
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        fleet: ''
      }
    }
  },
  computed: {
    form() {
      return this.$store.state.fleetCompany.form;
    },
    country() {
      const address = this.$store.state.fleetCompany?.data?.address;
      if (!address) return null;
      return address.country;
    },
    countries() {
      const countries = this.$store.state.settings.minimalCountries;
      countries.sort((a, b) => {
        if(a.label < b.label) return -1;
        if(a.label > b.label) return 1;
        return 0;
      });
      return countries;
    },
    loading() {
      return this.$store.state.fleetCompany.requests !== 0;
    },
    clientType() {
      return ClientType
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    validate() {
      this.hasError = false;
      this.errors = {};

      const properties = [
        'name',
        'email',
        'vatnumber',
        'phoneNumber',
        'zipCode',
        'city',
        'country'
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const data = this.form[property];

        switch (true) {
          case /email/.test(property):
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] =  this.$t('notifications.errors.required');
              break;
            }

            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(data)) {
              this.errors[property] =  this.$t('notifications.errors.email_invalid');
            }

            break;
          case /phoneNumber/.test(property):
            if (data && data.length) {
              try {
                parsePhoneNumber(data);
              } catch(error) {
                if (error instanceof ParseError) {
                  this.errors.phoneNumber = this.$t('notifications.errors.phone_invalid')
                }
              }
              if(!this.errors.phoneNumber) this.errors.phoneNumber = !isValidPhoneNumber(data) ? "Please enter a valid phone number" : '';
              if(this.errors.phoneNumber) this.hasError = true;
            }
            break;
          case /vatnumber/.test(property):
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');
              break;
            }

            if(!data.match(/^[A-z0-9]+$/)) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.vat_invalid');
            }
            break;
          default:
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] =  this.$t('notifications.errors.required');
            }
            break;
        }
      }
    },
    onInput($event, property) {
      const { target } = $event;
      if (!target && property !== 'country' && property !== 'clientType') return;

      switch (true) {
        case /country/.test(property):
        case /clientType/.test(property):
          this.$store.commit('fleetCompany/updateFormValue', { value: $event, property });
          break;
        case /isFlex/.test(property):
          const checked = target.checked;
          this.$store.commit('fleetCompany/updateFormValue', { value: checked, property });
          break;
        default:
          const { value } = target;
          this.$store.commit('fleetCompany/updateFormValue', { value, property });
          break;
      }
    },
    async onSave() {
      this.validate();

      if (this.hasError) return;

      const request = await this.$store.dispatch('fleetCompany/saveData');
      if (request) {
        this.$router.push({ name: 'fleetCompanies' });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    padding-bottom: 50px;
  }
  .button {
    margin-top: 20px;
  }
  .small.form--error {
    margin-top: 20px;
    color: $error;
  }
</style>
