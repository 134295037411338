<template>
  <div 
    class="select"
    :class="error ? 'error' : '' "
  >
    <label v-if="label" class="label">{{ label }}</label>
      <div 
        class="custom-select"
        :class="{'custom-select--open': open}"
      >
        <strong 
        @click="!readonly && (open = !open)" 
          class="selected"
        > 
          {{ selected && selected.label ? selected.label : selected.value }}
          <span class="selected-icon">
            <Sprite 
              :type="'chevron'"
            />
          </span>
        </strong>
        <ul
          ref="ul"
          class="items"
          v-show="open && !readonly"
        >
          <li 
            class="item"
            v-for="option in options"
            :key="option.value"
            :rel="option.value"
            :class="Number(option.value) === Number(value) ? 'item--selected' : ''"
            @click="!readonly && onClick(option)"
          >   
            {{ option.label ? option.label : option.value }}
          </li>
        </ul>
      </div>
      <select
        ref="input"
        :name="name"
        :required="required"
        autocomplete="off"
        :value="selected ? selected.value : null"
        @change="onChange"
        :readonly="readonly"
      >
        <option 
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :selected="selected && selected.value === option.value"
        >
          {{ option.label ? option.label : option.value }}
        </option>
      </select>
      <strong 
        v-if="error"
        class="small error-msg"
      >
        <span>{{ error }}</span>
        
      </strong>
  </div>
</template>

<script>
import Sprite from './Sprite';
export default {
  components: {
    Sprite
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      placeholder: 0,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      selected: {
        value: this.placeholder,
        label: null,
      },
      value: null,
      open: false,
    };
  },
  watch: {
    placeholder(value) {
      this.selected.value = value;
      if(this.options) {
        const obj = this.options.filter(opt => this.placeholder === opt.value).shift()
        if(this.selected && obj && obj.value) {
          this.selected.label = obj.label ? obj.label : null ;
          this.selected.value = obj.value;
        }
      }
      return value;
    },
    options(arr){
      return arr;
    },
  },
  mounted() {
    this.selected.value = this.placeholder;
    if(this.options) {
      const obj = this.options.filter(opt => this.placeholder === opt.value).shift()
      if(this.selected && obj && obj.value) {
        this.selected.label = obj.label ? obj.label : null ;
        this.selected.value = obj.value;
      }
    }
  },
  methods: {
    onClick(option) {
      this.selected = option
      this.open = false;
      this.$emit('change', this.selected.value)
      this.$emit('onChange', this.selected.value)
    },
    onChange(evt) {
      this.$emit('change', this.selected.value)
      this.$emit('onChange', this.selected.value)
    }
  }
};
</script>

<style>

</style>
