<template>
  <section
    ref="container"
    class="main-container main-container__form main-container__new"
    :class="{ 'main-container--loading': loading }"
  >
    <form
      novalidate
      @submit.prevent="onSave"
    >
      <!-- <h2 class="h5">Edit</h2> -->
      <fieldset>
        <div class="container-row container-5-5">
          <Inputs
            ref="firstname"
            name="firstname"
            type="text" 
            :label="$t('labels.first_name')"
            :error="errors.firstname"
            :required="true"
            :value="form.firstname"
            @input="onInput($event, 'firstname')"
          />
          <Inputs
            ref="lastname"
            name="lastname"
            type="text" 
            :label="$t('labels.last_name')"
            :error="errors.lastname"
            :required="true"
            :value="form.lastname"
            @input="onInput($event, 'lastname')"
          />
        </div>
        <div class="container-row container-5-5">
          <Inputs
            ref="email"
            name="email"
            type="text" 
            :label="$t('labels.email')"
            :error="errors.email"
            :required="true"
            :value="form.email"
            @input="onInput($event, 'email')"
          />
        </div>
      </fieldset>

      <button
        class="button"
        type="submit"
      >
        {{$t('globals.save')}}
      </button>

      <div 
        v-if="hasError"
        class="form--error small"
      >
        {{$t('notifications.errors.several_required')}}
      </div>
    </form>

    <div
      v-if="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </section>
</template>

<script>
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';

export default {
  components: {
    Loader,
    Inputs
  },
  beforeMount() {
    const { id } = this.$route.params;

    this.$store.commit('teamAdmin/resetForm');
    this.$store.commit('teamAdmin/updateId', id);
    this.$store.dispatch('teamAdmin/getData');
  },
  data() {
    return {
      hasError: false,
      errors: {
        firstname: '',
        lastname: '',
        email: ''
      }
    }
  },
  computed: {
    form() {
      return this.$store.state.teamAdmin.form;
    },
    loading() {
      return this.$store.state.teamAdmin.requests !== 0;
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    validate() {
      this.hasError = false;
      this.errors = {};

      const properties = [ 'firstname', 'lastname', 'email'];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const data = this.form[property];

        switch (true) {
          case /email/.test(property):
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');
              break;
            }

            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(data)) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.email_invalid');
            }

            break;
          default:
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');
            }
            break;
        }
      }
    },
    onInput($event, property) {
      const { target } = $event;
      if (!target) return;
      const { value } = target;
      this.$store.commit('teamAdmin/updateFormValue', { value, property });
    },
    async onSave() {
      this.validate();
      if (this.hasError) return;
      const request = await this.$store.dispatch('teamAdmin/saveData');
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    padding-bottom: 50px;
  }
  .button {
    margin-top: 20px;
  }
</style>
