<template>
  <router-view v-if="$route.name.toLowerCase() !== 'fleetowners'"/>
  <section
    v-else
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <table class="table" cellspacing="0" >
      <thead>
        <tr>
        <th
          v-for="head in thead"
          :key="head.title" 
          :class="head.class"
        >
          {{head.title}}
        </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="owner in owners"
          :key="owner.id"
        >  
          <td>
            <span class="grey">{{ owner.user.firstname }}</span>
          </td>
          <td>
            <span class="grey">{{ owner.user.lastname }}</span>
          </td>
          <td>
            <span class="grey">{{ owner.user.email }}</span>
          </td>
          <td>
            <span class="grey">{{ owner.fleet && owner.fleet.name ? owner.fleet.name : '-' }}</span>
          </td>
          <td>
            <router-link
              class="button button--small"
              :to="{name: 'editFleetOwners', params: { id: owner.id } }"
            >
              {{$t('fleet.list.buttons.edit')}}
            </router-link>
          </td>
          <td>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import Loader from '@/components/elements/Loader';
import Owner from '@/components/partials/Owner';

export default {
  name: "ListParking",
  components: {
    Loader,
    Owner
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    owners() {
      const owners = this.$store.state.fleetOwners.items;
      return owners.map(o => {
        if (!o.user) {
          o.user = {
            firstname: '',
            lastname: '',
            email: ''
          }
        };

        return o
      });
    },
    loading() {
      return this.$store.state.fleetOwners.requests !== 0;
    },
    thead() {
      return [
        {title: this.$t('fleet.list.columns.firstname'), class:"small head"},
        {title: this.$t('fleet.list.columns.lastname'), class:"small head"},
        {title: this.$t('fleet.list.columns.email'), class:"small head"},
        {title: this.$t('fleet.list.columns.company_name'), class:"small head"},
        {title: this.$t('fleet.list.columns.actions'), class:"buttons-col head head-hidden"},
        // {title: "Assign", class:"small-col head head-hidden"},
      ]
    },
    message() {
      return this.$t('fleet.list.no_owner');
    }
  },
  watch: {
    $route() {
      this.getData();
    },
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    getData() {
      if(this.$route.name.toLowerCase() === 'fleetowners') {
        this.$store.dispatch('fleetOwners/set');
      }
    }
  }
}
</script>