<template>
  <button
    v-if ="isDisabled"       
    class="button button-disabled"
    disabled
  >
    {{ label }}
  </button>
  <router-link v-else class="button button-primary another-class" :to="{name: to}">
    {{label}}
  </router-link>
</template>

<script>
export default {
  name: "Button",
  props: {
    to: {
      type: String,
    },
    label: {
      type: String,
    },
    isDisabled:{
      type: Boolean,
    }
  }
}
</script>

<style lang="scss" scoped>

</style>