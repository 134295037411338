<template>
  <nav 
    class="sidebar"
    :class="{'sidebar--small': !sidebar}"
  >
    <ul
    >
      <!-- @mouseenter="handleHover"
      @mouseleave="handleHover" -->
      <template v-if="this.$route.meta.parent === 'parking'">
        <li 
          v-for="route in router"
          :key="route.name"
          :class="{'router-link-hidden': route.hidden}"
        >
          <router-link
            class="sidebar-item"
            :class="{'hasChildren': route.children, 'router-link-active': current.matched[0].path === route.path}"
            :to='setPath(route.path)'
          >
            <span class="sidebar-item__content">
              <Sprite 
                :type="route.meta.icon ? route.meta.icon: 'settings'"
                :width="18"
                :height="18"
              />
              <span class="sidebar-text">
                {{route.meta.menuName ? $t(`${route.meta.menuName}`) : $t(`${route.meta.titleTemplate}`)}}
              </span>
            </span>
            <Sprite 
              v-if="route.children"
              :type="'chevron'" 
              :width="12"
              :height="12"
            />
          </router-link>
          <ul class="sidebar-children" v-if="route.children">
            <li 
              v-for="child in InSidebar(route.children)"
              :key="child.name"
            >
              <router-link 
                class="small sidebar-children-item"
                :to='setPath(child.path)'
              >
                {{child.meta.menuName ? $t(`${child.meta.menuName}`) : $t(`${child.meta.titleTemplate}`)}}
              </router-link>
            </li>
          </ul>
        </li>
      </template>

      <template v-if="this.$route.meta.parent === 'fleet'">
        <!-- {{ fleet }} -->
        <li 
          v-for="route in fleet"
          :key="route.name"
        >
          <router-link
            class="sidebar-item"
            :class="{'hasChildren': route.children, 'router-link-active': current.matched[0].path === route.path}"
            :to='setPath(route.path)'
          >
            <span class="sidebar-item__content">
              <Sprite 
                :type="route.meta.icon"
                :width="18"
                :height="18"
              />
              <span class="sidebar-text">
                {{route.meta.menuName ? $t(`${route.meta.menuName}`) : $t(`${route.meta.titleTemplate}`)}}
              </span>
            </span>
            <Sprite 
              v-if="route.children"
              :type="'chevron'" 
              :width="12"
              :height="12"
            />

          </router-link>
          <ul class="sidebar-children" v-if="route.children">
            <li 
              v-for="child in InSidebar(route.children)"
              :key="child.name"
            >
              <router-link 
                class="small sidebar-children-item"
                :to='setPath(child.path)'
              >
              {{child.meta.menuName ? $t(`${child.meta.menuName}`) : $t(`${child.meta.titleTemplate}`)}}
              </router-link>
            </li>
          </ul>
        </li>
      </template>

      <template v-if="this.$route.meta.parent === 'team'">
        <!-- {{ fleet }} -->
        <li 
          v-for="route in team"
          :key="route.name"
        >
          <router-link
            class="sidebar-item"
            :class="{'hasChildren': route.children, 'router-link-active': current.matched[0].path === route.path}"
            :to='setPath(route.path)'
          >
            <span class="sidebar-item__content">
              <Sprite 
                :type="route.meta.icon"
                :width="18"
                :height="18"
              />
              <span class="sidebar-text">
                {{route.meta.menuName ? $t(`${route.meta.menuName}`) : $t(`${route.meta.titleTemplate}`)}}
              </span>
            </span>
            <Sprite 
              v-if="route.children"
              :type="'chevron'" 
              :width="12"
              :height="12"
            />
          </router-link>
          <ul class="sidebar-children" v-if="route.children">
            <li 
              v-for="child in InSidebar(route.children)"
              :key="child.name"
            >
              <router-link 
                class="small sidebar-children-item"
                :to='setPath(child.path)'
              >
                {{child.meta.menuName ? $t(`${child.meta.menuName}`) : $t(`${child.meta.titleTemplate}`)}}
              </router-link>
            </li>
          </ul>
        </li>
      </template>

      <template v-if="this.$route.meta.parent === 'app'">
        <!-- {{ fleet }} -->
        <li 
          v-for="route in app"
          :key="route.name"
        >
          <router-link
            class="sidebar-item"
            :class="{'hasChildren': route.children, 'router-link-active': current.matched[0].path === route.path}"
            :to='setPath(route.path)'
          >
            <span class="sidebar-item__content">
              <Sprite 
                :type="route.meta.icon"
                :width="18"
                :height="18"
              />
              <span class="sidebar-text">
                {{route.meta.menuName ? $t(`${route.meta.menuName}`) : $t(`${route.meta.titleTemplate}`)}}
              </span>
            </span>
            <Sprite 
              v-if="route.children"
              :type="'chevron'" 
              :width="12"
              :height="12"
            />
          </router-link>
          <ul class="sidebar-children" v-if="route.children">
            <li 
              v-for="child in InSidebar(route.children)"
              :key="child.name"
            >
              <router-link 
                class="small sidebar-children-item"
                :to='setPath(child.path)'
              >
                {{child.meta.menuName ? $t(`${child.meta.menuName}`) : $t(`${child.meta.titleTemplate}`)}}
              </router-link>
            </li>
          </ul>
        </li>
      </template>

      <template v-if="this.$route.meta.parent === 'report'">
        <!-- {{ fleet }} -->
        <li 
          v-for="route in report"
          :key="route.name"
        >
          <router-link
            class="sidebar-item"
            :class="{'hasChildren': route.children, 'router-link-active': current.matched[0].path === route.path}"
            :to='setPath(route.path)'
          >
            <span class="sidebar-item__content">
              <Sprite 
                :type="route.meta.icon"
                :width="18"
                :height="18"
              />
              <span class="sidebar-text">
                <!-- {{'Download activity report'}} -->
                {{route.meta.menuName ? $t(`${route.meta.menuName}`) : $t(`${route.meta.titleTemplate}`)}}
              </span>
            </span>
            <Sprite 
              v-if="route.children"
              :type="'chevron'" 
              :width="12"
              :height="12"
            />
          </router-link>
          <ul class="sidebar-children" v-if="route.children">
            <li 
              v-for="child in InSidebar(route.children)"
              :key="child.name"
            >
              <router-link 
                class="small sidebar-children-item"
                :to='setPath(child.path)'
              >
                {{child.meta.menuName ? $t(`${child.meta.menuName}`) : $t(`${child.meta.titleTemplate}`)}}
              </router-link>
            </li>
          </ul>
        </li>
      </template>

    </ul>

  </nav>
</template>

<script>
import Sprite from '@/components/elements/Sprite.vue';

export default {
  name: 'Sidebar',
  components: { 
    Sprite 
  },
  data() {
    return {
      isSmall: false
    };
  },
  computed: {
    id() {
      return 0 //this.$store.state.parkings.current.id;
    },
    router() {
      return this.$router.options.routes.filter(r => !r.meta.subbar && !r.meta.sidebar && !r.meta.notInSidebar && r.meta.parent === 'parking');
    },
    sidebar() {
      return this.$store.state.user.sidebar;
    },
    burgerClick() {
      return this.$store.state.user.burgerClick;
    },
    current() {
      return this.$route;
    },
    fleet() {
      const { routes } = this.$router.options;
      const fleet = routes.filter(r => r.name.toLowerCase() === 'fleet')[0];
      const links = fleet.children.filter(r => r.meta && r.meta.sidebar);
      return links;
    },
    team() {
      const { routes } = this.$router.options;
      const team = routes.filter(r => r.name.toLowerCase() === 'team')[0];
      const links = team.children;
      return links;
    },
    app() {
      const { routes } = this.$router.options;
      const team = routes.filter(r => r.name.toLowerCase() === 'app')[0];
      const links = team.children;
      return links;
    },
    report() {
      const { routes } = this.$router.options;
      const links = routes.filter(r => r.meta.parent === 'report');
      return links;
    },
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  },
  methods: {
    setPath(path) {
      const the_path = path.replace(':id', this.id);
      if(the_path.includes('undefined')) return '/';
      return the_path;
    },
    handleHover() {
      if(this.burgerClick && window.innerWidth >= 1201) this.$store.dispatch('setSidebar');
      // if(!this.sidebar) this.$el.classList.toggle('sidebar--small');
    },
    InSidebar(arr) {
      return arr.filter(r => r.meta && !r.meta.subbar && !r.meta.sidebar 
        && (r.name === "newCompany" ? !this.disableSnapReleaseButtons : true)
        && (r.name === "newParking" ? !this.disableSnapReleaseButtons : true)
      );
    }
  }
}
</script>
