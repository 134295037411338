import { list } from '@/calls/fleet-companies';
import {
  list as listOwners,
  // update as updateOwner
} from '@/calls/fleet-owners';
import {
  list as listPlanners,
} from '@/calls/fleet-planners';
import {
  patchCompany,
  getCompany,
  updateOwner
} from '@/calls/fleet-companies';
import i18n from '../i18n';


const fleetCompanies = {
  namespaced: true,
  state: {
    requests: 0,
    search: null,
    items: [],
    order: {
      type: 'name',
      dir: 'asc'
    },
    modals: {
      owner: {
        visible: false,
        requests: 0,
        // fleet: null,
        fleetId: null,
        ownerId: null,
        currentOwnerId: null,
        owners: []
      }
    }
  },
  mutations: {
    updateItems(state, items) {
      state.items = items;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    updateOrder(state, type) {
      if (state.order.type === type) {
        state.order.dir = state.order.dir === 'asc' ? 'desc' : 'asc';
        return;
      }

      state.order.type = type;
      state.order.dir = 'asc';
    },
    updateModalProperty(state, { modal, property, value }) {
      state.modals[modal][property] = value;
    },
    increaseRequest(state) {
      state.requests++;
    },
    decreaseRequest(state) {
      state.requests--;
    }
  },
  actions: {
    async set({ state, commit }) {
      commit('increaseRequest');
      const search = state.search && state.search.length ? state.search : null;
      const companies = await list(search);

      if (companies.hasError) {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting companies : ${companies.error}`,
          type: 'error'
        }], { root: true });
      } else {
        commit('updateItems', companies);
      }

      commit('decreaseRequest');
    },
    async updateOwner({ state, commit, dispatch }) {
      commit('updateModalProperty', {
        modal: 'owner', property: 'requests', value: state.modals.owner.requests + 1
      });

      const { fleetId, ownerId, currentOwnerId } = state.modals.owner;

      if (ownerId && currentOwnerId && ownerId === currentOwnerId) {
        commit('updateModalProperty', {
          modal: 'owner', property: 'requests', value: state.modals.owner.requests - 1
        });

        return;
      }

      // if (currentOwnerId) {
      //   try {
      //     const unassign = await updateOwner(currentOwnerId, {
      //       fleetCompanyId: 0
      //     });
      //   } catch (error) {
      //     dispatch('notifications/addNotifs', [{
      //       text: `Error while unassigning : ${request.error}`,
      //       type: 'error'
      //     }], { root: true });
      //   }
      // }

      // if (ownerId) {
      try {
        const assign = await updateOwner(fleetId, ownerId);
        commit('updateModalProperty', { modal: 'owner', property: 'visible', value: false });
        commit('updateModalProperty', { modal: 'owner', property: 'fleet', value: null });
        commit('updateModalProperty', { modal: 'owner', property: 'ownerId', value: null });
        commit('updateModalProperty', { modal: 'owner', property: 'currentOwnerId', value: null });
        dispatch('set');
      } catch (error) {
        dispatch('notifications/addNotifs', [{
          text: `Error while assigning : ${error}`,
          type: 'error'
        }], { root: true });
      }
      // }

      commit('updateModalProperty', {
        modal: 'owner', property: 'requests', value: state.modals.owner.requests - 1
      });
    },
    async getCompany({ state, commit }) {
      commit('updateModalProperty', {
        modal: 'owner', property: 'requests', value: state.modals.owner.requests + 1
      });

      const request = await getCompany(state.modals.owner.fleetId);

      if(!request.hasError) {
      } else {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting company : ${request.error}`,
          type: 'error'
        }], { root: true });
      }

      commit('updateModalProperty', {
        modal: 'owner', property: 'requests', value: state.modals.owner.requests - 1
      });
    },
    async getOwners({ state, commit }) {
      commit('updateModalProperty', {
        modal: 'owner', property: 'requests', value: state.modals.owner.requests + 1
      });

      const { fleetId } = state.modals.owner;
      const request = await listPlanners(null, fleetId);

      if (request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting owners : ${owners.error}`,
          type: 'error'
        }], { root: true });
      } else {
        const owners = request
        .filter(o => o.user && o.user.fullName)
        .map(o => {
          return {
            value: o.id,
            label: o.user.fullName
          }
        });

        const { t } = i18n.global;

        owners.unshift({
          value: 0,
          label: t('globals.unassigned')
        });

        commit('updateModalProperty', {
          modal: 'owner', property: 'owners', value: owners
        });
      }

      commit('updateModalProperty', {
        modal: 'owner', property: 'requests', value: state.modals.owner.requests - 1
      });
    }
  }
};

export default fleetCompanies;
