<template>
    <section ref="container" class="main-container main-container__form main-container__edit"
        :class="{ 'main-container--loading': loading }">
        <form v-if="form" novalidate @submit.prevent="onUpload">
            <!-- Informations -->
            <div class="sample-box">
                <h3 class="nickname">{{ $t('pages.upload_bookings.download_sample_label') }}</h3>
                <a class="link-sample" href="/sample-files/sample_bookings_file.xlsx"><img class="img-download-sample" src="../../assets/svg/download.svg" /></a>
            </div>
            <fieldset class="container-additionnal">
                <h3 class="nickname">{{ $t('pages.upload_bookings.upload_label') }}</h3>
                <div class="upload-panel">
                    <div class="container-row container-5-5">
                        <FileUploads
                          :name="'bookings_file'"
                          :error="errors.booking_file"
                          :filename="filename"
                          :extensions="'.xls,.xlsx'"
                          @onReset="onResetFileUpload()"
                          @onChange="onChange($event)"
                          @input="onFileInput($event)"
                        />
                    </div>
                </div>
            </fieldset>
            <button
                class="button"
                type="submit"
            >
                {{$t('globals.upload')}}
            </button>
        </form>
        <Popup v-if="hasData">
            <template v-slot:content>
                <h2 class="popup-title">{{ $t('pages.upload_bookings.popup.title') }}</h2>
                <div class="popup-main">
                    <p class="popup-main-text text-center">{{ $t('pages.upload_bookings.popup.text') }}</p>
                    <button
                        type="button"
                        class="button button-big"
                        @click.prevent="onClosePopup"
                    >
                        {{ $t('globals.close') }}
                    </button>
                </div>
            </template>
        </Popup>
        <div v-if="loading" class="main-container-loader">
            <loader />
        </div>
    </section>
</template>
  
<script>
  /* eslint-disable */
import Loader from '@/components/elements/Loader';
import FileUploads from '@/components/elements/FileUploads';
import Popup from '@/components/partials/Popup';

export default {
    name: 'UploadBooking',
    components: {
        Loader,
        FileUploads,
        Popup
    },
    data() {
        return {
            hasError: false,
            errors: {}
        }
    },
    computed: {
        form() {
            return this.$store.state.bookingFile.form;
        },
        data() {
            return this.$store.state.bookingFile.data;
        },
        hasData() {
            return this.$store.state.bookingFile.data !== null;
        },
        loading() {
            return this.$store.state.bookingFile.requests !== 0;
        },
        filename() {
            return this.$store.state.bookingFile.form.filename;
        }
    },
    watch: {
        loading() {
            if (this.$refs.container) {
                this.$refs.container.scrollTo(0, 0);
            }
        },
    },
    methods: {
        onFileInput($event) {
            let file = $event.target.files[0];
            this.form['file'] = file;
            this.form['filename'] = file.name;
        },
        onResetFileUpload(property) {
            this.form['file'] = null;
            this.form['filename'] = null;
        },
        onChange($event) {
            let file = $event.target.files[0];
            const accepted = /xls|xlsx/g
            const split = file.name.split('.');
            const ext = split[split.length - 1];

            // Check extension
            if (!ext.match(accepted)) {
                this.$store.dispatch('notifications/addNotifs', [{
                    text: this.$t('notifications.errors.files.wrong_extension', { file_name: file.name }),
                    type: 'error'
                }], { root: true });

                this.form['file'] = null;
                this.form['filename'] = null;

                return false;
            }
        },
        onUpload() {
            this.$store.dispatch('bookingFile/uploadBookings');
        },
        onClosePopup() {
            var a = document.createElement("a"); 
            a.href = "data:application/octet-stream;base64," + this.data; 
            a.download = "result_" + this.form['filename']; 
            a.click();

            this.form['file'] = null;
            this.form['filename'] = null;
            this.$store.state.bookingFile.data = null;
        }
    }
}

</script>

<style lang="scss" scoped>
  .main-container {
    padding-bottom: 100px;
  }

  .text-center {
    text-align: center;
  }

  .sample-box {
    display: flex;
    align-items: center;
  }

  .link-sample {
    padding-left: 8px;
    padding-top: 6px;
  }

  .img-download-sample {
    width: 35px;
  }

  .container-row {
    &:first-child {
      margin-top: 0;
    }
  }

  .container-map {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .small.form--error {
    margin-top: 20px;
    color: $error;
  }

  .container-additionnal {
    margin-top: 32px;
    margin-bottom: 24px;

    .container-row:not(.container-5-5) {
      justify-content: flex-start;

       .input {
        width: 220px;

        &:not(:first-child) {
          margin-left: 24px;
        }
      }
    }
  }

  .h5 {
    margin-bottom: 20px;

    &:not(:first-child) {
      margin-top: 60px;
    }
  }

  .button {
    margin-top: 60px;
  }

  .upload-panel {
    margin-top: 25px;
  }

</style>
