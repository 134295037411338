<template>
  <span :class="`svg svg-${type}`">

    <!-- Accounting -->
    <svg
      v-if="type === 'accounting'"
      :class="'icon-' + type"
      :width="width" 
      :height="height" 
      viewBox="0 0 20 16"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20 0V6C18.9 6 18 6.9 18 8C18 9.1 18.9 10 20 10V16H0V10C1.11 10 2 9.1 2 8C2 6.9 1.11 6 0.01 6V0H20ZM9 13.5H11V11.5H9V13.5ZM11 9H9V7H11V9ZM9 4.5H11V2.5H9V4.5Z"/>
    </svg>
    <!-- Alert -->
    <svg 
      v-if="type === 'alert'"
      :class="'icon-' + type"
      :width="width" 
      :height="height" 
      viewBox="0 0 20 20"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z"/>
    </svg>

    <!-- Arrow -->
    <svg 
      v-else-if="type === 'arrow'"
      :class="'icon-' + type"
      :width="width" 
      :height="height"
      viewBox="0 0 14 8"
    >
        <path d="M6.6692 0.485829C6.91609 0.222672 7.33391 0.222672 7.5808 0.48583L13.1739 6.44737C13.5483 6.84648 13.2653 7.5 12.7181 7.5L1.53192 7.5C0.98466 7.5 0.701676 6.84648 1.07612 6.44737L6.6692 0.485829Z"/>
    </svg>

    <!-- Calendar -->
    <svg
      v-else-if="type === 'calendar'"
      :class="'icon-' + type"
      :width="width" 
      :height="height" 
      viewBox="0 0 20 22"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 2H20V22H0V2H3V0H5V2H15V0H17V2ZM2 20H18V7H2V20Z"/>
    </svg>

    <!-- Car -->
    <svg 
      v-else-if="type === 'car'"
      :class="'icon-' + type"
      :width="width" 
      :height="height" 
      viewBox="0 0 18 16"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.43 0H15.58L18 7V16H15V14H3V16H0V7L2.43 0ZM2 9.5C2 10.33 2.67 11 3.5 11C4.33 11 5 10.33 5 9.5C5 8.67 4.33 8 3.5 8C2.67 8 2 8.67 2 9.5ZM14.5 11C13.67 11 13 10.33 13 9.5C13 8.67 13.67 8 14.5 8C15.33 8 16 8.67 16 9.5C16 10.33 15.33 11 14.5 11ZM3.5 1.5L2 6H16L14.5 1.5H3.5Z"/>
    </svg>

    <!-- Check -->
    <svg
      v-else-if="type === 'check'"
      :width="width" 
      :height="height" 
      viewBox="0 0 11 8"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.67388 6.76396C4.28759 7.13221 3.68018 7.13221 3.29388 6.76396L0.373884 4.16396C0.084299 3.93141 -0.0525485 3.55752 0.0184922 3.19297C0.0895329 2.82843 0.356758 2.53329 0.712474 2.42649C1.06819 2.31969 1.45379 2.41883 1.71388 2.68396L3.93388 4.68396L8.34388 0.343959C8.59033 0.0604626 8.97487 -0.0610067 9.33945 0.0294744C9.70403 0.119955 9.98716 0.407123 10.0725 0.772952C10.1578 1.13878 10.0308 1.52156 9.74388 1.76396L4.67388 6.76396Z"/>
    </svg>

    <!-- Chevron-small -->
    <svg 
      v-else-if="type === 'chevron-small'"
      :width="width" 
      :height="height" 
      viewBox="0 0 6 10"
    >
      <path d="M0.240479 4.97638C0.240479 5.18634 0.323486 5.367 0.484619 5.52814L4.23462 9.18536C4.36157 9.31232 4.51782 9.38068 4.70337 9.38068C5.08423 9.38068 5.38696 9.07794 5.38696 8.70197C5.38696 8.51642 5.31372 8.34064 5.177 8.20392L1.85669 4.98126L5.177 1.75372C5.30884 1.617 5.38696 1.44611 5.38696 1.25568C5.38696 0.8797 5.08423 0.576965 4.70337 0.576965C4.51782 0.576965 4.36157 0.645325 4.22974 0.772278L0.484619 4.4295C0.318604 4.6004 0.240479 4.7713 0.240479 4.97638Z"/>
    </svg>

    <!-- Chevron -->
    <svg 
      v-else-if="type === 'chevron'"
      :width="width" 
      :height="height" 
      viewBox="0 0 12 8"
    >
      <path d="M1.4865 -4.8816e-07L6.32693 4.82989L11.1674 -6.49958e-08C11.9886 0.821209 11.9886 2.15265 11.1674 2.97386L6.32693 7.8143L1.4865 2.97386C0.665288 2.15265 0.665288 0.821209 1.4865 -4.8816e-07Z"/>
    </svg>
     <!-- Chevron Round -->
    <svg 
      v-else-if="type === 'chevron-round'"
      :width="width" 
      :height="height" 
      viewBox="0 0 7 12"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29828 0.587626C5.61397 0.265134 6.13306 0.265134 6.44876 0.587626C6.75508 0.900546 6.75508 1.40093 6.44876 1.71385L2.25296 6L6.44876 10.2861C6.75508 10.5991 6.75508 11.0995 6.44876 11.4124C6.13306 11.7349 5.61397 11.7349 5.29828 11.4124L0.684789 6.69953C0.304253 6.3108 0.304254 5.68919 0.684789 5.30046L5.29828 0.587626Z" fill="#00A963"/>

    </svg>
    <svg
      v-else-if="type === 'chevron-up'"
      :width="width" 
      :height="height"
      viewBox="0 0 15 11"
    >
      <path d="M13.6917 10.8462L7.5 4.66796L1.30828 10.8462C0.257819 9.79573 0.257819 8.09259 1.30828 7.04213L7.5 0.850411L13.6917 7.04213C14.7422 8.09259 14.7422 9.79573 13.6917 10.8462Z"/>
    </svg>

    <svg
      v-else-if="type === 'chevron-down'"
      :width="width" 
      :height="height"
      viewBox="0 0 15 11"
    >
      <path d="M1.30828 0.153808L7.5 6.33204L13.6917 0.153809C14.7422 1.20427 14.7422 2.90741 13.6917 3.95787L7.5 10.1496L1.30828 3.95787C0.257818 2.90741 0.257818 1.20427 1.30828 0.153808Z"/>
    </svg>
    <!-- Chart -->
    <svg 
      v-else-if="type === 'chart'"
      :width="width" 
      :height="height" 
      viewBox="0 0 24 24"
    >
      <path d="M3.5 18.4898L9.5 12.4798L13.5 16.4798L22 6.91977L20.59 5.50977L13.5 13.4798L9.5 9.47977L2 16.9898L3.5 18.4898Z"/>
    </svg>
    <!-- Criterion -->
    <svg 
      v-else-if="type === 'criterion'"
      :width="width" 
      :height="height"
      viewBox="0 0 14 15"
    >
      <circle cx="7" cy="7.5" r="7" fill="#FF0B37"/>
      <path class="success" d="M4.21447 6.2688L6.19922 8.24493L10.1527 4.30005C10.8234 4.97078 10.8234 6.05826 10.1527 6.72899L6.19922 10.6825L4.21447 8.69774C3.54374 8.02701 3.54374 6.93953 4.21447 6.2688Z" fill="white"/>
      <path class="error" fill-rule="evenodd" clip-rule="evenodd" d="M7.00029 9.12825L4.62507 11.5L3 9.87375L5.37146 7.50059L3 5.13084L4.63201 3.5L5.71758 4.58479L7.00089 5.86998L9.36916 3.5L11 5.13201L9.91521 6.21758L8.62973 7.50119L11 9.87493L9.37375 11.5L7.00029 9.12825Z" fill="white"/>
    </svg>

    <!-- Email -->
    <svg
      v-else-if="type === 'email'"
      :width="width" 
      :height="height"
      viewBox="0 0 12 10" 
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.166687 0.333496H11.8334V9.66683H0.166687V0.333496ZM6 5.58333L10.6667 2.66667V1.5L6 4.41667L1.33333 1.5V2.66667L6 5.58333Z" fill="#00A963"/>
    </svg>

    <!-- Email send -->
    <svg 
      v-else-if="type === 'email-send'"
      :width="width" 
      :height="height" 
      viewBox="0 0 90 98"
    >
      <g clip-path="url(#email-sended)">
        <path d="M88.8075 37.0329L86.648 50.4121L86.234 52.9775C86.234 54.9665 81.988 81.1566 79.7879 81.1566L45.0004 57.1179L6.38911 83.9088C4.18901 83.9088 1.87526 55.7216 1.87526 53.7325L1.72101 50.404L1.104 37.0735L7.31461 31.7072L42.5162 1.28741C43.9694 0.240133 46.0233 0.240133 47.4765 1.28741L82.6456 31.7072L88.8075 37.0329Z" fill="#211F44"/>
        <path d="M72 37.1489V77.4999H17V25.4999H59.958L72 37.1489Z" fill="white"/>
        <path d="M72 37.1458V38.5H59V25.5H60.3476L72 37.1458Z" fill="#2C447D"/>
        <path d="M72 37.3692H60.1821L60.1821 25.4999L72 37.3692Z" fill="white"/>
        <path d="M45.8445 64.9278L65.7996 44.5748C71.036 39.2329 78.5212 36.1722 86.3717 36.1722C88.2146 36.1722 89.7084 37.39 89.7084 38.8838V66.6164V93.2855C89.7084 94.7874 87.2161 97.0605 85.3732 97.0605H74.0398L45.8445 68.305C44.9027 67.347 44.9027 65.8857 45.8445 64.9278Z" fill="#262F5F"/>
        <path d="M44.1559 64.8873L24.2008 44.5344C18.9644 39.1924 11.4792 36.1318 3.62867 36.1318C1.78578 36.1318 0.291992 37.3495 0.291992 38.8433V66.5759V93.5779C0.291992 95.0798 2.43526 97.0607 4.27815 97.0607L15.9687 97.0201L44.1559 68.2646C45.0977 67.3066 45.0977 65.8534 44.1559 64.8873Z" fill="#262F5F"/>
        <path d="M89.7085 82.1552V93.8863C89.7085 95.6399 87.9224 97.0606 85.7223 97.0606H4.27815C2.07805 97.0606 0.291992 95.6399 0.291992 93.8863V82.1552L42.516 55.3481C43.2061 54.9097 44.026 54.6824 44.8541 54.658C45.7796 54.6337 46.7132 54.861 47.4764 55.3481L89.7085 82.1552Z" fill="#2C447D"/>
        <path d="M0.291992 77.2597L37.0361 57.6293L37.7586 58.3599L0.291992 82.1551V77.2597Z" fill="#20284E"/>
        <path d="M89.7083 77.2597L52.9642 57.6293L52.2417 58.3599L89.7083 82.1551V77.2597Z" fill="#20284E"/>
      </g>
      <defs>
        <clipPath id="email-sended">
          <rect width="89.4165" height="96.5607" fill="white" transform="translate(0.291992 0.5)"/>
        </clipPath>
      </defs>
    </svg>
    <!-- Eye of tiger -->
    <svg 
      v-else-if="type === 'eye-of-tiger'"
      :width="width" 
      :height="height" 
      viewBox="0 0 20 14"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.5C5.45455 0.5 1.57273 3.19533 0 7C1.57273 10.8047 5.45455 13.5 10 13.5C14.5455 13.5 18.4273 10.8047 20 7C18.4273 3.19533 14.5455 0.5 10 0.5ZM10.5 11.5C8.016 11.5 6 9.484 6 7C6 4.516 8.016 2.5 10.5 2.5C12.984 2.5 15 4.516 15 7C15 9.484 12.984 11.5 10.5 11.5ZM8 7C8 5.61667 9.11667 4.5 10.5 4.5C11.8833 4.5 13 5.61667 13 7C13 8.38333 11.8833 9.5 10.5 9.5C9.11667 9.5 8 8.38333 8 7Z" fill="#1F2B60"/>
    </svg>

    <!-- Feature truck -->
    <svg
      v-else-if="type === 'feature-truck'"
      :width="width" 
      :height="height"
      viewBox="0 0 20 23"
    >
      <g clip-path="url(#clip0)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.37564 3C5.6299 3 5.02536 3.60454 5.02536 4.35028V13.2142C4.25425 13.2161 3.62974 13.8418 3.62974 14.6134V18.8109H4.32921V19.3006C4.32921 19.687 4.64242 20.0002 5.02879 20.0002H6.07816C6.46453 20.0002 6.77774 19.687 6.77774 19.3006V18.8109H13.7034V19.3006C13.7034 19.687 14.0166 20.0002 14.403 20.0002H15.4523C15.8387 20.0002 16.1519 19.687 16.1519 19.3006V18.8109H16.7819V18.81L17.411 18.6677V17.0304L16.7819 16.7815V14.6134C16.7819 13.9961 16.3821 13.4721 15.8273 13.2863V4.35028C15.8273 3.60454 15.2228 3 14.477 3V13.2142H6.37564V13.2137H14.4731V6.49768C14.4731 5.33857 13.5334 4.39893 12.3743 4.39893H8.10689C7.38822 4.39893 6.75391 4.76016 6.37564 5.31096V3ZM3 17.0304L3.62962 16.7813V18.8101L3 18.6677V17.0304Z" fill="#131315"/>
        <path d="M7.05072 8.40073C7.05072 8.02786 7.35299 7.72559 7.72586 7.72559H13.127C13.4999 7.72559 13.8021 8.02786 13.8021 8.40073V10.4262H7.05072V8.40073Z" fill="white"/>
        <rect x="7.72583" y="14.4766" width="4.72599" height="2.02542" rx="0.559665" fill="white"/>
        <rect x="4.35025" y="16.502" width="2.02542" height="1.35028" rx="0.559665" fill="white"/>
        <rect x="13.8019" y="16.502" width="2.02542" height="1.35028" rx="0.559665" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="23" fill="white"/>
        </clipPath>
      </defs>
    </svg>

    <!-- <svg
      v-else-if="type === 'feature-security'"
      :width="width" 
      :height="height"
      viewBox="0 0 20 23"
    >
      <g clip-path="url(#clip0)">
        <g clip-path="url(#clip1)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.12988 7.82959L9.76453 4.88086L16.3992 7.82959V12.2527C16.3992 16.344 13.5684 20.17 9.76453 21.0989C5.96066 20.17 3.12988 16.344 3.12988 12.2527V7.82959ZM14.9249 12.9825H9.76466V6.49532L4.60438 8.78796V12.9899H9.76466V19.5729C12.507 18.7252 14.5342 16.0197 14.9249 12.9825Z" fill="#131315"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0"><rect width="20" height="23" fill="white"/></clipPath>
        <clipPath id="clip1"><rect width="18" height="18" fill="white" transform="translate(1.12988 2.88867)"/></clipPath>
      </defs>
    </svg> -->

    <svg
      v-else-if="type === 'feature-security'"
      :width="width" 
      :height="height"
      viewBox="0 0 21 21"
    >
      <path d="M10.052 19.0347C10.3093 19.1721 10.6321 19.1712 10.8894 19.0339C17.8999 15.2679 18.3978 7.90997 18.3916 5.81785C18.3908 5.4731 18.186 5.16685 17.8701 5.02772V5.02772C13.3488 2.25013 7.64294 2.25324 3.12026 5.0286V5.0286C2.80963 5.16772 2.60663 5.46873 2.60226 5.8091C2.57251 7.89073 2.99163 15.2644 10.052 19.0347ZM7.58976 9.00635L9.59613 11.0127L13.3525 7.25635L14.5898 8.4936L9.59613 13.4872L6.35251 10.2436L7.58976 9.00635Z" fill="#131315"/>
    </svg>

    <svg
      v-else-if="type === 'feature-sanitary'"
      :width="width" 
      :height="height"
      viewBox="0 0 20 23"
    >
      <g clip-path="url(#clip0)">
        <g clip-path="url(#clip1)">
          <path d="M9.64422 5.58008V21.3486H11.1295V5.58008H9.64422Z" fill="#131315"/>
          <path d="M15.8023 8.03591C15.0844 8.03591 14.5025 7.45407 14.5025 6.73622C14.5025 6.01849 15.0844 5.43652 15.8023 5.43652C16.52 5.43652 17.1019 6.01849 17.1019 6.73622C17.1019 7.45407 16.52 8.03591 15.8023 8.03591Z" fill="#131315"/>
          <path d="M15.9947 16.2768L15.9942 20.8232C15.9942 21.6411 17.2197 21.6411 17.2197 20.8232L17.2202 16.2768H18.8443L17.1066 10.2485H17.3797L18.4031 13.6921C18.6467 14.4432 19.6778 14.1346 19.4419 13.3304L18.3057 9.58922C18.1838 9.16259 17.6443 8.40625 16.7104 8.40625H15.8492H14.9224C13.9968 8.40625 13.4548 9.16897 13.3147 9.58922L12.178 13.3267C11.9506 14.1305 12.9737 14.4635 13.2173 13.6799L14.2403 10.2485H14.5361L12.7797 16.2768H14.4108V20.8292C14.4108 21.6411 15.6313 21.6371 15.6313 20.8292V16.2768H15.9947Z" fill="#131315"/>
          <path d="M6.52719 8.40137C7.44172 8.40137 8.17722 9.15787 8.17722 10.1015V14.1232C8.17722 14.9051 7.04905 14.9051 7.04905 14.1232V10.4457H6.78197V20.6354C6.78197 21.6808 5.27962 21.6501 5.27962 20.6354V14.67H5.02088V20.6354C5.02088 21.6501 3.51032 21.6808 3.51032 20.6354V10.4457H3.24949V14.1232C3.24949 14.9111 2.12718 14.9111 2.12922 14.1232V10.1255C2.12922 9.25543 2.79539 8.40317 3.79949 8.40317L6.52719 8.40137Z" fill="#131315"/>
          <path d="M5.15767 8.03591C4.4399 8.03591 3.85798 7.45407 3.85798 6.73622C3.85798 6.01849 4.4399 5.43652 5.15767 5.43652C5.8754 5.43652 6.45737 6.01849 6.45737 6.73622C6.45737 7.45407 5.8754 8.03591 5.15767 8.03591Z" fill="#131315"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0"><rect width="20" height="23" fill="white" transform="translate(0 0.555664)"/></clipPath>
        <clipPath id="clip1"><rect width="17.3471" height="16" fill="white" transform="matrix(-1 0 0 1 19.4766 5.43652)"/></clipPath>
      </defs>
    </svg>

    <svg
      v-else-if="type === 'feature-comfort'"
      :width="width" 
      :height="height"
      viewBox="0 0 20 23"
    >
      <g clip-path="url(#clip0)">
        <g clip-path="url(#clip1)">
          <g clip-path="url(#clip2)">
            <path d="M4.28058 8.64364C4.82184 8.71604 5.40653 8.94151 5.77266 9.31729C6.57799 10.1426 6.83655 11.3286 6.91171 12.358C7.18544 12.2952 7.29714 12.2559 7.58604 12.2559H12.929C13.1841 12.2559 13.2613 12.287 13.5033 12.3345C13.5819 11.3141 13.8439 10.1254 14.6465 9.30901C15.012 8.93806 15.5932 8.71535 16.131 8.64296C14.7713 7.51493 12.6235 6.78613 10.2061 6.78613C7.78875 6.78613 5.64096 7.51493 4.28058 8.64364Z" fill="#131315"/>
            <path d="M16.5015 9.29324C14.3875 9.29324 14.3158 12.6477 14.3289 13.4544C13.9421 13.2406 13.4573 13.1124 12.9292 13.1124H7.58626C7.00984 13.1124 6.48444 13.2654 6.08246 13.5157C6.09901 12.8359 6.08315 9.29324 3.91123 9.29324C3.91123 9.29324 2.13025 9.16706 2.13025 10.3013C2.13025 11.4941 3.37342 11.3093 3.37342 11.3093C3.37342 11.3093 3.39617 17.1811 4.31734 17.1811H4.59935V17.9796H5.86734V17.1811H14.5081V17.9796H15.7754V17.1811H16.1043V17.179C17.0165 17.1397 17.0393 11.3093 17.0393 11.3093C17.0393 11.3093 18.2825 11.4941 18.2825 10.3013C18.2825 9.1712 16.5015 9.29324 16.5015 9.29324Z" fill="#131315"/>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0"><rect width="20" height="23" fill="white" transform="translate(0 0.555664)"/></clipPath>
        <clipPath id="clip1"><rect width="18" height="18" fill="white" transform="translate(1.12988 3.44434)"/></clipPath>
        <clipPath id="clip2"><rect width="16.1522" height="11.1933" fill="white" transform="translate(2.12988 6.78516)"/></clipPath>
      </defs>
    </svg>

    <svg
      v-else-if="type === 'feature-health'"
      :width="width" 
      :height="height"
      viewBox="0 0 20 23"
    >
      <g clip-path="url(#clip0)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7969 4.99219H7.46352V10.3258H2.12988V15.6592H7.46352V20.9922H12.7969V15.6592H18.1299V10.3258H12.7969V4.99219Z" fill="#131315"/>
      </g>
      <defs>
        <clipPath id="clip0"><rect width="20" height="23" fill="white" transform="translate(0 0.111328)"/></clipPath>
      </defs>
    </svg>

    <svg
      v-else-if="type === 'feature-food'"
      :width="width" 
      :height="height"
      viewBox="0 0 20 23"
    >
      <g clip-path="url(#clip0)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0134 10.9905C15.6542 12.3497 13.8162 12.7771 12.5083 12.1702L11.2516 13.4268L17.1331 19.3083L15.9278 20.5137L10.0463 14.6322L4.15627 20.5051L2.95091 19.2998L8.85039 13.4003L7.92159 12.5079L6.71196 13.7175L3.13007 10.1442C1.79649 8.80203 1.79649 6.63923 3.13007 5.30564L9.13122 11.2982L10.0299 12.2208L11.2944 10.9563C10.6874 9.64834 11.1149 7.81038 12.4741 6.45115C14.1154 4.81836 16.4578 4.51061 17.7059 5.75871C18.9625 7.01536 18.6462 9.35769 17.0134 10.9905Z" fill="#131315"/>
      </g>
      <defs>
        <clipPath id="clip0"><rect width="20" height="23" fill="white" transform="translate(0 0.111328)"/></clipPath>
      </defs>
    </svg>

    <svg
      v-else-if="type === 'feature-shopping' || type === 'feature-shop'"
      :width="width" 
      :height="height"
      viewBox="0 0 20 23"
    >
      <g clip-path="url(#clip0)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5097 14.4511L5.67621 15.9666H14.7689V17.482H3.11511L5.37312 13.3827L2.64532 7.63165H1.12988V6.11621H3.60763L4.31989 7.63165H16.8147L13.4807 13.6707C13.2231 14.1405 12.723 14.4511 12.1547 14.4511H6.5097ZM5.67564 18.2394C4.84215 18.2394 4.16777 18.9214 4.16777 19.7549C4.16777 20.5884 4.84215 21.2703 5.67564 21.2703C6.50913 21.2703 7.19108 20.5884 7.19108 19.7549C7.19108 18.9214 6.50913 18.2394 5.67564 18.2394ZM13.2523 18.2394C12.4188 18.2394 11.7445 18.9214 11.7445 19.7549C11.7445 20.5884 12.4188 21.2703 13.2523 21.2703C14.0858 21.2703 14.7678 20.5884 14.7678 19.7549C14.7678 18.9214 14.0858 18.2394 13.2523 18.2394Z" fill="#131315"/>
      </g>
      <defs>
        <clipPath id="clip0"><rect width="20" height="23" fill="white" transform="translate(0 0.389648)"/></clipPath>
      </defs>
    </svg>

    <svg
      v-else-if="type === 'feature-services'"
      :width="width" 
      :height="height"
      viewBox="0 0 20 23"
    >
      <g clip-path="url(#clip0)">
        <path d="M11.8043 13.3053L9.96585 15.1437L16.451 21.6289L18.2894 19.7905L11.8043 13.3053Z" fill="#131315"/>
        <path d="M14.4761 11.9502C16.1488 11.9502 17.5094 10.5895 17.5094 8.91692C17.5094 8.41426 17.3707 7.94628 17.1541 7.53029L14.8141 9.87022L13.5228 8.57893L15.8628 6.239C15.4468 6.02234 14.9788 5.88367 14.4761 5.88367C12.8035 5.88367 11.4429 7.2443 11.4429 8.91692C11.4429 9.27224 11.5122 9.61023 11.6249 9.92222L10.0216 11.5255L8.47899 9.98288L9.09431 9.36757L7.87235 8.14561L9.70962 6.30833C8.69565 5.29436 7.04904 5.29436 6.03507 6.30833L2.96716 9.37624L4.18912 10.5982H1.7452L1.12988 11.2135L4.19779 14.2814L4.8131 13.6661V11.2135L6.03507 12.4355L6.65038 11.8202L8.193 13.3628L1.7712 19.7846L3.60847 21.6219L13.4708 11.7682C13.7828 11.8808 14.1208 11.9502 14.4761 11.9502Z" fill="#131315"/>
      </g>
      <defs>
        <clipPath id="clip0"><rect width="20" height="23" fill="white" transform="translate(0 0.666992)"/></clipPath>
      </defs>
    </svg>

    <!-- Help -->
    <svg 
      v-else-if="type === 'help'"
      :width="width" 
      :height="height" 
      viewBox="0 0 20 20"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 17V15H11V17H9ZM12.17 10.17L13.07 9.25C13.64 8.68 14 7.88 14 7C14 4.79 12.21 3 10 3C7.79 3 6 4.79 6 7H8C8 5.9 8.9 5 10 5C11.1 5 12 5.9 12 7C12 7.55 11.78 8.05 11.41 8.41L10.17 9.67C9.45 10.4 9 11.4 9 12.5V13H11C11 11.5 11.45 10.9 12.17 10.17Z"/>
    </svg>
    
    <!-- Logo -->
    <svg 
      v-else-if="type === 'logo'"
      :width="width" 
      :height="height" 
      viewBox="0 0 288.01 139.97"
    >    
      <polygon class="cls-1" fill="#fff" points="0 0 0 104.13 35.85 139.97 288.01 139.97 288.01 0 0 0"/>
      <g>
          <g>
              <path class="cls-2" fill="#e52127"
                    d="M69.17,68.54c-1.5-1.58-3.33-3.02-5.42-4.27-2.03-1.21-4.35-2.37-6.9-3.45-1.8-.75-3.42-1.48-4.81-2.18-1.35-.67-2.51-1.36-3.46-2.05-.86-.63-1.52-1.3-1.97-1.98-.38-.58-.57-1.23-.57-1.98,0-.68,.16-1.26,.5-1.79,.37-.58,.89-1.08,1.59-1.51,.75-.47,1.71-.84,2.84-1.11,2.06-.48,4.61-.56,7.45-.17,1.2,.16,2.44,.42,3.68,.77,1.2,.34,2.41,.78,3.58,1.3,1.14,.52,2.2,1.12,3.16,1.79l2.41,1.69v-17.1l-.98-.38c-2.22-.86-4.65-1.5-7.23-1.9-2.57-.4-5.55-.61-8.85-.61s-6.68,.38-9.73,1.12c-3.12,.76-5.9,1.96-8.29,3.57-2.43,1.65-4.38,3.78-5.79,6.33-1.42,2.57-2.14,5.66-2.14,9.18,0,4.52,1.32,8.44,3.92,11.66,2.53,3.14,6.39,5.81,11.47,7.95,1.89,.78,3.65,1.55,5.28,2.3,1.56,.72,2.92,1.47,4.05,2.24,1.05,.71,1.87,1.49,2.46,2.3,.51,.71,.75,1.49,.75,2.39,0,.64-.15,1.22-.45,1.77-.31,.55-.8,1.05-1.47,1.48-.74,.48-1.69,.86-2.83,1.14-1.19,.29-2.62,.44-4.25,.44-2.81,0-5.65-.51-8.42-1.51-2.76-1-5.36-2.52-7.73-4.52l-2.52-2.14v18.27l.83,.43c2.38,1.23,5.21,2.17,8.42,2.77,3.16,.6,6.53,.9,10.02,.9s6.71-.34,9.8-1c3.16-.68,5.99-1.82,8.39-3.4,2.46-1.61,4.43-3.73,5.86-6.33,1.44-2.6,2.16-5.82,2.16-9.57,0-2.72-.41-5.16-1.23-7.25-.82-2.09-2.03-3.98-3.57-5.6Z"/>
              <path class="cls-2" fill="#e52127"
                    d="M125.85,70.95c0,1.06,0,2.04,.02,2.93-.06-.09-.12-.18-.18-.27l-25.32-38.93h-17.57V100.56h16.39v-36.05c0-1.62-.01-3.09-.04-4.41,.06,.1,.13,.2,.2,.3l26.28,40.16h16.61V34.69h-16.39v36.27Z"/>
              <path class="cls-2" fill="#e52127"
                    d="M171.69,34.69l-23.76,65.88h18.5l4.38-13.97h19.89l4.43,13.97h18.56l-23.21-65.88h-18.78Zm14.82,37.98h-11.74l5.88-18.5,5.85,18.5Z"/>
          </g>
          <path class="cls-2" fill="#e52127"
                d="M243.13,34.69h-23.52V100.56h17.1V48.62h3.98c8.68,0,9.68,4.65,9.68,8.15,0,5.71-3.08,8.37-9.68,8.37h-2.42l3.36,13.8h.24c7.88,0,14.29-2.09,19.06-6.22,4.84-4.19,7.29-9.72,7.29-16.47,0-14.3-8.44-21.55-25.09-21.55Z"/>
      </g>
    </svg>

    <!-- Message -->
    <svg 
      v-else-if="type === 'message'"
      :width="width" 
      :height="height" 
      viewBox="0 0 28 24"
    >
      <g filter="url(#filter0_d)">
        <rect width="23.8" height="23.8" rx="11.9"/>
      </g>
      <path d="M18 14.2058L14.4864 13.7991L12.8062 15.4793C10.9194 14.5192 9.37258 12.9791 8.4125 11.0856L10.0993 9.39874L9.69261 5.89844H6.01896C5.63226 12.6857 11.2127 18.2662 18 17.8795V14.2058Z" fill="white"/>
      <defs>
        <filter id="filter0_d" x="-4" y="-2" width="31.8" height="31.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>

    <!-- Notification -->
    <svg 
      v-else-if="type === 'notification'"
      :width="width" 
      :height="height" 
      viewBox="0 0 19 23"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.25 10.0938V15.7188L18.5 17.9688V19.0938H0.5V17.9688L2.75 15.7188V10.0938C2.75 6.62875 4.58375 3.74875 7.8125 2.98375V0.53125H11.1875V2.98375C14.405 3.74875 16.25 6.64 16.25 10.0938ZM11.75 20.2188C11.75 21.4562 10.7375 22.4688 9.5 22.4688C8.25125 22.4688 7.25 21.4562 7.25 20.2188H11.75Z"/>
    </svg>
   
    <!-- Parking -->
    <svg 
      v-else-if="type === 'parking'"
      :width="width" 
      :height="height" 
      viewBox="0 0 24 24"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 7.15C4.56 4.59 8.1 3 12 3C15.9 3 19.44 4.59 22 7.14L17 12.14C15.72 10.86 13.95 10.07 12 10.07C10.05 10.07 8.28 10.87 7 12.15L2 7.15ZM8 17C8 14.7909 9.79086 13 12 13C14.2091 13 16 14.7909 16 17C16 19.2091 14.2091 21 12 21C9.79086 21 8 19.2091 8 17Z"/>
    </svg>
    
    <!-- Phone -->
    <svg 
      v-else-if="type === 'phone'"
      :width="width" 
      :height="height" 
      viewBox="0 0 18 18"
    >
      <path d="M17.9992 12.461L12.7292 11.851L10.2092 14.371C7.37917 12.931 5.05917 10.621 3.61917 7.78098L6.14917 5.25098L5.53917 0.000976562H0.0291701C-0.55083 10.181 7.81917 18.551 17.9992 17.971V12.461Z"/>
    </svg>

    <!-- Phone-2 -->
    <svg 
      v-else-if="type === 'phone-2'"
      :width="width" 
      :height="height"
      viewBox="0 0 12 11" 
    >
      <path d="M10.9659 0.528737L8.65456 0.0131227C8.40342 -0.0427355 8.14561 0.0840196 8.04337 0.311749L6.97659 2.71795C6.88324 2.92849 6.94547 3.17556 7.12994 3.3195L8.47676 4.3851C7.67667 6.03292 6.27873 7.40359 4.53853 8.19205L3.43618 6.89013C3.28505 6.71181 3.03169 6.65165 2.81389 6.74189L0.324713 7.77312C0.0869081 7.87409 -0.044218 8.1233 0.0135663 8.36607L0.546961 10.6004C0.602523 10.8324 0.81588 11 1.06702 11C6.75878 11 11.3793 6.54209 11.3793 1.03146C11.3793 0.790841 11.2082 0.582447 10.9659 0.528737Z" fill="#00A963"/>
    </svg>

    <!-- Review -->
    <svg 
      v-else-if="type === 'review'"
      :width="width" 
      :height="height" 
      viewBox="0 0 20 20"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20 0H0V20L4 16H20V0ZM4 12V9.53L10.88 2.65C11.08 2.45 11.39 2.45 11.59 2.65L13.36 4.42C13.56 4.62 13.56 4.93 13.36 5.13L6.47 12H4ZM8.5 12H16V10H10.5L8.5 12Z"/>
    </svg>

    <!-- Search -->
    <svg 
      v-else-if="type === 'search'"
      :width="width" 
      :height="height" 
      viewBox="0 0 14 14"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.93603 11.8871C7.25711 11.8871 8.4924 11.4405 9.4875 10.7018L12.1726 13.3902C12.5088 13.7269 13.0544 13.7269 13.3906 13.3902C13.7269 13.0535 13.7262 12.5079 13.389 12.1721L10.7056 9.49939C11.4434 8.50307 11.8895 7.28344 11.8895 5.94356C11.8895 2.66258 9.23015 0 5.95319 0C2.67623 0 -0.000244141 2.67975 -0.000244141 5.94356C-0.000244141 9.20736 2.67623 11.8871 5.93603 11.8871ZM6.03726 1.75C8.40758 1.75 10.3248 3.60849 10.3248 5.90625C10.3248 8.20401 8.40758 10.0625 6.03726 10.0625C3.66693 10.0625 1.74976 8.20401 1.74976 5.90625C1.74976 3.60849 3.68436 1.75 6.03726 1.75Z"/>
    </svg>
    
    <!-- Security -->
    <svg
      v-else-if="type === 'security'"
      :width="width" 
      :height="height" 
      viewBox="0 0 24 24"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 5L12 1L21 5V11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5ZM19 11.99H12V3.19L5 6.3V12H12V20.93C15.72 19.78 18.47 16.11 19 11.99Z"/>
    </svg>

    <!-- Settings -->
    <svg 
      v-else-if="type === 'settings'"
      :width="width" 
      :height="height" 
      viewBox="0 0 20 20"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.425 11.01L17.435 10.99L19.545 12.65C19.735 12.79 19.875 12.91 19.875 12.91L17.445 17.11L14.565 15.96L14.585 15.92C14.055 16.33 13.475 16.67 12.855 16.93H12.865L12.485 19.58C12.455 19.82 12.425 20 12.425 20H7.57499L7.12499 16.92H7.15499C6.52499 16.66 5.95499 16.32 5.42499 15.91L5.44499 15.95L2.56499 17.1L0.13499 12.9C0.13499 12.9 0.27499 12.79 0.46499 12.64L2.56499 10.99L2.57499 11.01C2.52499 10.68 2.49499 10.34 2.49499 10C2.49499 9.66 2.52499 9.34 2.56499 9.01L2.55499 9.03L0.11499 7.11L2.55499 2.89L5.43499 4.06L5.42499 4.07C5.94499 3.67 6.51499 3.33 7.13499 3.07H7.12499L7.56499 0H12.435L12.895 3.06H12.885C13.505 3.32 14.075 3.66 14.595 4.06L14.585 4.05L17.455 2.89L19.885 7.11L17.445 9.03L17.435 9.01C17.475 9.34 17.505 9.66 17.505 10C17.505 10.34 17.465 10.68 17.425 11.01ZM6.49499 10C6.49499 11.93 8.06499 13.5 9.99499 13.5C11.925 13.5 13.495 11.93 13.495 10C13.495 8.07 11.925 6.5 9.99499 6.5C8.06499 6.5 6.49499 8.07 6.49499 10Z"/>
    </svg>

    <!-- Star -->
    <svg
      v-else-if="type === 'star'"
      :width="width" 
      :height="height"
      viewBox="0 0 15 13"
    >
      <path d="M7.29409 0L9.63353 3.98865L14.1764 4.96556L11.0794 8.40759L11.5476 13L7.29409 11.1386L3.04057 13L3.5088 8.40759L0.411743 4.96556L4.95465 3.98865L7.29409 0Z"/>
    </svg>

    <!-- Success -->
    <svg 
      v-else-if="type === 'notification-success'"
      :width="width" 
      :height="height" 
      viewBox="0 0 141 140" 
    >
      <g clip-path="url(#succesnot)">
        <path d="M40.8088 57.9679L60.6562 77.7292L100.191 38.2804C106.899 44.9877 106.899 55.8625 100.191 62.5698L60.6562 102.105L40.8088 82.2573C34.1014 75.55 34.1014 64.6752 40.8088 57.9679Z" fill="#00A963"/>
        <circle cx="71" cy="70" r="70" fill="#2DCA73"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="140" height="140">
        <circle cx="71" cy="70" r="70" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
        <rect x="29" y="42.0002" width="84" height="101.5" rx="2.01923" fill="white"/>
        <rect x="37.75" y="78.7502" width="66.5" height="7" rx="3.5" fill="#ADB0BA" fill-opacity="0.4"/>
        <rect x="37.75" y="92.7507" width="66.5" height="7" rx="3.5" fill="#ADB0BA" fill-opacity="0.4"/>
        <rect x="37.75" y="106.75" width="43.75" height="7" rx="3.5" fill="#ADB0BA" fill-opacity="0.4"/>
        </g>
        <g filter="url(#filter0_dsucess)">
        <circle cx="71" cy="41.9996" r="21" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M65.2476 38.7905C64.5695 38.1019 63.4615 38.0933 62.7728 38.7714C62.0841 39.4495 62.0755 40.5575 62.7536 41.2462L69.3014 47.8962L79.2106 37.9871C79.894 37.3037 79.894 36.1957 79.2106 35.5122C78.5271 34.8288 77.4191 34.8288 76.7357 35.5122L69.3207 42.9272L65.2476 38.7905Z" fill="white"/>
        <path d="M62.4515 38.0826L68.5001 44.1051L80.5488 32.0827V32.0827C82.5929 34.1268 82.5929 37.441 80.5488 39.4851L68.5001 51.5338L62.4515 45.4851C60.4073 43.441 60.4073 40.1268 62.4515 38.0826V38.0826Z" fill="#2DCA73"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_dsucess" x="46.5" y="19.2496" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1.75"/>
        <feGaussianBlur stdDeviation="1.75"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.12549 0 0 0 0 0.12549 0 0 0 0.219897 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <clipPath id="succesnot">
        <rect width="140" height="140" fill="white" transform="translate(0.5 0.00012207)"/>
        </clipPath>
        </defs>

    </svg>

    <svg v-else-if="type === 'magnify-glass'" 
        :width="width" 
        :height="height" fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 490.4 490.4" xml:space="preserve">
<g>
	<path d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796
		s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z
		 M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z"/>
</g>
</svg>
 


  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
};
</script>
