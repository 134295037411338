<template>
    <div 
      class="input"
      :class="[{ 'error': error }]"
    >
      <!-- :class="error ? 'error' : '' " -->
      <label v-if="label"
        class="label small"
        :class="{'no-label': !label}"
      >
        {{ label }}
      </label>

      <div v-if="!onUpload"
        class="file-uploads">
        <p class="filename-info">{{ filename }}</p>
        <button
          class="upload-reset"
          type="button"
          @click.prevent="onReset"
        />
      </div>
      <input v-else
        ref="file-upload"
        :type="'file'"
        :accept="extensions"
        :name="name"
        :required="required"
        class="file-uploads"
        @input="onInput"
        @change.prevent.stop="onChange"
        :readonly="readonly"
      />
      <strong 
        v-if="error"
        class="small error-msg"
      >
        <span>{{ error }}</span>
        
      </strong>
    </div>
</template>

<script>
export default {
  name: 'FileUploads',
  props: {
    label: {
      type: String,
      default: null
    },  
    error: {
      type: String,
      default: null
    },
    validation: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    name: {
      type: String
    },
    filename: {
      type: String
    },
    extensions: {
      type: String
    }
  },
  computed: {
    onUpload() {
      return !this.filename;
    }
  },
  watch: {
    value(val) {
      return val;
    }
  },
  methods: {
    onReset() {
      this.$emit('onReset');
    },
    onChange(e) {
      this.$emit('onChange', e);
    },
  }
}
</script>

<style lang="scss" scoped>
.file-uploads {
  border: 1px solid $lightestgrey;
  border-radius: 3px;

  padding: 10px 6px 8px;

  width: 100%;
  transition: border .3s $ease-out-quart;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  justify-items: center;

  @extend %p;
  color: $snap-action-default;
  &::placeholder {
    color: $mediumgrey;
  }
  .error & {
    border: 1px solid $error !important;
  }
}

.upload {
  &-reset {
    position: relative;
    float: inline-end;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $mediumgreen;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 6px);
      width: 12px;
      height: 2px;
      background-color: $white;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}
</style>