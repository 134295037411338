<template>
  <tr>  
    <td><span class="nickname">{{ parking.localName }}</span></td>
    <td class="grey">{{ $t('globals.latitude_abbr') }} {{parking.latitude}} {{ $t('globals.longitude_abbr') }} {{parking.longitude}}</td>
    <td class="nickname">{{ parking.companyName }}</td>
    <td class="td--buttons">
      <!-- <a class="button button--small" target="_blank" rel="noopener" :href="url+'/parking/'+parking.id+'/details'">Manage</a> -->
      <router-link class="button button--small" :to="{name: 'editParking', params: { id:parking.id } }">{{ $t('parkings.list.buttons.manage') }}</router-link>
      <button
        class="button button--small"
        @click="handleModal"
      >
        {{ $t('parkings.list.buttons.assign') }}
      </button>
    </td>
    <!-- <td>
    </td> -->
  </tr>
</template>

<script>
export default {
  name: "Parking",
  props: {
    parking: {
      type: Object,
      required: true
    }
  },
  computed: {
    url() {
      const loc = location.origin.replace('admin', 'pmng');
      return loc
    },
    companiesByID() {
      return this.$store.state.companies.byID[this.parking.parkingCompanyId]
    } 
  },
  methods: {
    handleModal() {
      this.$store.commit('parkings/updateModal', {
        id: this.parking.id,
        company: this.parking.parkingCompanyId
        // company: this.companiesByID
      });
    }
  }
}
</script>