import {
  list,
} from '@/calls/country-abbreviations';
import i18n from '../i18n';

const countryAbbreviations = {
  namespaced: true,
  state: {
    id: null,
    dataCountryAbbreviations: [],
    requests: 0    
  },
  mutations: {
    updateValueData(state, data) {
      state.value = data;
    },
    updateData(state, data) {
      state.dataCountryAbbreviations = data;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    // Calls
    getAllData({state, commit}) {
      const countryAbbreviations = list();
      return countryAbbreviations.then((promise) => {
        commit('updateData', promise);        
        return promise;
      }).catch(error => {
        console.log(error)
      });
    }
  }
};

export default countryAbbreviations;
