<template>
  <router-view v-if="$route.name.toLowerCase() !== 'fleetplanners'"/>
  <section
    v-else
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
  <div>
    <table class="table" cellspacing="0">
      <THead
        :head="thead"
        :order="order"
        @onOrder="updateOrder"
      />
      <tbody>
        <tr
          v-for="owner in owners"
          :key="owner.id"
          :class="[{ 'has-separation': owner.separation }]"
        >
          <td>
            <span class="highlight">{{ owner.user.fullName }}</span>
            <div class="">{{ owner.user.email }}</div>
          </td>
          <td>
            <span class="grey">{{ owner.fleet && owner.fleet.name ? owner.fleet.name : '-' }}</span>
          </td>
          <td>
            <span class="grey">{{ owner.isOwner ? $t('fleet.list.roles.owner') : $t('fleet.list.roles.planner') }}</span>
          </td>
          <td class="td--buttons">
            <button
              class="button button--small"
              @click="resendActivationLink(owner.id)"
              disabled
            >
              {{$t('globals.buttons.resend_activation_link')}}
            </button>
            <router-link
              class="button button--small"
              :to="{name: 'editFleetPlanners', params: { id: owner.id } }"
            >
              {{$t('fleet.list.buttons.edit')}}
            </router-link>
          </td>
          <td>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-show="showLoader"
      class="main-container-loader"
      style="position: fixed;"
    >
      <loader />
    </div>
  </div>
    <Popup v-if="confirmation">
      <template v-slot:content>
        <h4 class="popup-title">{{ $t('globals.popup.resend_activation_link_title') }}</h4>
        <div class="popup-actions">
          <button
            type="button"
            class="button button-popup button-border"
            @click.prevent="onPopupClose"
          >
            {{ $t('globals.no') }}
          </button>
          <button
            type="submit"
            class="button button-popup"
            @click.prevent="onPopupConfirm"
            :disabled="disableSnapReleaseButtons"
          >
            <!-- :disabled="disableAdd" -->
            {{ $t('globals.yes') }}
          </button>
        </div>
      </template>
    </Popup>

    <NoResult v-if="!loading && !owners.length">
      <slot>{{message}}</slot>
    </NoResult>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
    
      

  </section>
</template>

<script>
/* eslint-disable */
import Loader from '@/components/elements/Loader';
import Owner from '@/components/partials/Owner';
import THead from '@/components/elements/THead';
import NoResult from '@/components/partials/Bookings/NoResult.vue';
import Popup from '@/components/partials/Popup';

export default {
  name: "ListParking",
  components: {
    THead,
    Loader,
    Owner,
    NoResult,
    Popup
  },
  beforeMount() {
    this.getData();
  },
  data() {
    return {
      confirmation: false,
      ownerId: '',
      showLoader: false
    };
  },
  computed: {
    owners() {
      const { search, order, items } = this.$store.state.fleetPlanners;
      const owners = items.map(o => {
        o.orderProps = {
          fullName: o.fullName.trim(),
          fleetName: o.fleetName.trim(),
          isOwner: o.isOwner
        }
        return o;
      });

      let ordereds = owners
        .sort((a, b) => {
          const aProp = typeof a.orderProps[order.type] === 'string' ? a.orderProps[order.type].toUpperCase() : a.orderProps[order.type];
          const bProp = typeof b.orderProps[order.type] === 'string' ? b.orderProps[order.type].toUpperCase() : b.orderProps[order.type];
          if(aProp < bProp) return order.dir === 'asc' ? -1 : 1;
          if(aProp > bProp) return order.dir === 'asc' ? 1 : -1;
          return 0;
        });

      // if (search && search.length > 2) {
      //   // ordereds = ordereds.filter(p => p.fullName.match(search));
      //   const byFullName = ordereds.filter(p => p.fullName.match(search));
      //   const byFleetName = ordereds.filter(p => !byFullName.includes(p) && p.fleetName.match(search));
      //   ordereds = [...byFullName, ...byFleetName];

      //   if (byFullName.length && byFleetName.length) {
      //     const alreadySeparated = false;
      //     ordereds = ordereds.map(o => {
      //       o.separation = byFleetName[0].id === o.id;

      //       return o;
      //     });
      //   }
      // }

      return ordereds.map(o => {
        if (!o.user) o.user = { firstname: '', lastname: '', fullName: '', email: '' };

        return o
      });
    },
    loading() {
      return this.$store.state.fleetPlanners.requests !== 0;
    },
    thead() {
      return [
        // {title: this.$t('fleet.list.columns.firstname'), class:"small head"},
        {
          title: `Name`,
          class:"small head",
          type: 'fullName'
        },
        // {title: `Name<br> ${this.$t('fleet.list.columns.email')}`, class:"small head"},
        // {title: this.$t('fleet.list.columns.lastname'), class:"small head"},
        // {title: this.$t('fleet.list.columns.email'), class:"small head"},
        {
          title: this.$t('fleet.list.columns.company_name'),
          class:"small head",
          type: 'fleetName'
        },
        {
          title: this.$t('fleet.list.columns.role'),
          class:"small head",
          type: 'isOwner'
        },
        {
          title: this.$t('fleet.list.columns.actions'),
          class:"buttons-col head head-hidden"
        }
      ]
    },
    order() {
      return this.$store.state.fleetPlanners.order;
    },
    message() {
      return this.$t('fleet.list.no_planner');
    },
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  },
  watch: {
    $route() {
      this.getData();
    },
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    updateOrder(e) {
      this.$store.commit('fleetPlanners/updateOrder', e);
    },
    getData() {
      if(this.$route.name.toLowerCase() === 'fleetplanners') {
        this.$store.dispatch('fleetPlanners/set');
      }
    },
    resendActivationLink(id) {
      this.ownerId = id;
      this.confirmation = true;
    },
    onPopupClose() {
      this.ownerId = '';
      this.confirmation = false;
    },
    async onPopupConfirm() {      
      if (this.confirmation) {
        this.confirmation = false; 
        this.showLoader = true;
        await this.$store.dispatch('fleetPlanner/resendActivationLink', this.ownerId);
        this.showLoader = false;
      }
      this.confirmation = false;      
      this.ownerId = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  td {
    vertical-align: middle;
  }
}
</style>