<template>
  <div class="quicksearch">
    <input 
      ref="input"
      type="search" 
      :placeholder="placeholder()"
      @input="onInput"
      @search="onClear"
    >
    <!-- placeholder="Search truck or driver" -->
  </div>
</template>

<script>
// import Fuse from 'fuse.js';
import _debounce from 'lodash/debounce';

export default {
  name: 'QuickSearch',
  data() {
    return {
      results: null,
      // fuse: null,
      // // currentListArr: {},
      // options: {
      //   threshold: 0.2,
      //   keys: ['slotValue', 'trailLicensePlate', 'truckLicensePlate', 'driverName']
      //   },
    }
  },
  // props: {
  //   currentList: {
  //     type: Array
  //   }
  // },
  watch: {
    // currentList(arr) {
    //   // if(arr) this.fuse = new Fuse(arr, this.options)
    //   return arr;
    // },
    $route() {
      this.pleaseClear()
      // this.$refs.input.value = '';
    }
  },
  methods: {
    // onInput(evt) {
    //   const { value } = evt.target;
    //   this.debouncedUpdate(value);
    // },
    // debouncedUpdate: _debounce(function(value) {
    //   if(!this.currentList) return;
    //   this.results = value != '' && this.fuse ? this.fuse.search(value) : this.currentList;
    //   if(value !== '' ) {
    //     this.$el.classList.add('onInput');
    //     this.results = this.results.map(element => {
    //       return element.item;
    //     });
    //   }
    //   else this.$el.classList.remove('onInput');
    //   this.$emit('filtered', this.results);
    // }, 100),
    // onClear() {
    //   this.$emit('filtered', this.currentList);
    // },
    // pleaseClear() {
    //   this.$refs.input.value = '';
    // },
    onInput(evt) {
      const { value } = evt.target;
      this.debouncedUpdate(value);
    },
    debouncedUpdate: _debounce(function(value) {
      this.$emit('search', value);
    }, 300),
    onClear() {
      this.$refs.input.value = '';
      this.$emit('search', '');
    },
    pleaseClear() {
      this.$refs.input.value = '';
    },
    placeholder() {
      const { name } = this.$route;
      switch (name) {
        case ('Owners'):
          return this.$t('components.search.placeholder.search_owner');
        case ('Companies'):
          return this.$t('components.search.placeholder.search_company');
        case ('Parkings'):
          return this.$t('components.search.placeholder.search_parking');
        default:
          return this.$t('components.search.placeholder.search');
          case ('Partners'):
          return this.$t('components.search.placeholder.search_partner');
      }
    }
  },
}
</script>
