<template>
  <div
    ref="container"
    class="profile"
  >
    <div
      class="profile-toggle"
      @click="handleOpen"
    >
      <span class="profile-abbr">{{ abbr }}</span>
      <strong class="profile-name">{{ profileName }}</strong>
      <Sprite 
        :type="'chevron'"
        :width="10"
        :height="15"
      />
    </div>
    <nav v-show="isOpen" class="profile-menu">
      <router-link
        :to="'/profile/edit'"
        class="profile-menu-link"
      >
        <span @click="handleOpen">
          {{$t('globals.my_profile')}}
        </span>
      </router-link>
      <button type="button" @click="signOut" class="button small">{{$t('globals.signout')}}</button>
    </nav>
  </div>
</template>
<script>
/* eslint-disable */
import { Auth } from 'aws-amplify';
import Sprite from '@/components/elements/Sprite';
export default {
  name: 'Profile',
  components: {
    Sprite
  },
  data() {
    return {
      isOpen: false,
      _documentClick: this.onDocumentClick.bind(this)
    }
  },
  computed: {
    profileName() {
      const { profile } = this.$store.state.user;
      if(!profile) return null;
      if(profile.firstname && profile.lastname) return `${profile.firstname} ${profile.lastname}`;
      return profile.email;
    },
    abbr() {
      const { profile } = this.$store.state.user;
      if(!profile) return null;
      if(profile.firstname && profile.lastname) return `${profile.firstname.charAt(0)}${profile.lastname.charAt(0)}`;
      if (profile.email) profile.email.charAt(0);
      return '-';
    },
    user() {
      return this.$store.state.user.profile;
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this._documentClick);
  },
  methods: {
    handleOpen() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.addEventListener('click', this._documentClick);
        // return;
      } else {
        document.removeEventListener('click', this._documentClick);
      }
    },
    async signOut() {
      const requestAuthentification = Auth.signOut()
      requestAuthentification.then(promise => {
        this.$store.commit('updateAuthState', null);
        this.$router.push('/');
        this.$store.commit('notifications/reset');
        this.$store.commit('settings/updateContext', null);

        setTimeout(() => {
          window.location.reload();
        }, 50);
      })
    },
    onDocumentClick(e) {
      const { target } = e;
      const isDescendant = this.isDescendant(this.$refs.container, target);
      if (!isDescendant && this.isOpen) {
        this.isOpen = false;
        document.removeEventListener('click', this._documentClick);
      }
    },
    isDescendant(parent, child) {
      let node = child.parentNode;
      while (node) {
        if (node === parent) return true;
        node = node.parentNode;
      }

      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .profile {
    position: relative;
    z-index: 11;

    &-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: calc(100% + 20px);
      right: 0;
      width: 100%;
      min-width: max-content;

      background-color: $white;
      padding: 20px;
      border-radius: 3px;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, .1);

      &-link {
        color: $snap-action-default;
        transition: color .3s $ease-out-quart;

        &:hover {
          color: $black;
        }
      }

      .button {
        margin-top: 15px;
        margin-bottom: 0;
        width: 100%;
      }
    }
  }
</style>
