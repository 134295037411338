<template>
  <section class="navigation">
    <button 
      type="button"
      class="burger"
      @click="onClick"
    >
      <span class="burger-line"></span>
      <span class="burger-line"></span>
      <span class="burger-line"></span>
    </button>
  </section>
</template>

<script>
export default {
  name: 'Navigation',
  methods: {
    onClick() {
      this.$store.dispatch('setGrid');
    },
    mounted() {
      document.addEventListener('keydown', evt => {
        const isEscape = this.$store.state.user.burgerClick && evt.keyCode === 27;
        if (isEscape) this.onClick();
      })
      document.addEventListener('click', evt => {
        const isOutside = this.$store.state.user.burgerClick && !evt.target.closest('.navigation');
        if (isOutside) this.onClick();
      })
    }
  }
}
</script>
