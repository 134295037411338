import i18n from '../i18n';

const popups = {
    namespaced: true,
    state: {
        partnerParking: {
            visible: false,
            requests: 0,
            item: null,
            success: false
        }

    },
    mutations: {
        // Popups
        updatePopup(state, { type, property, value }) {
            state[type][property] = value;
        },
        updatePopupField(state, { type, field, value }) {
            state[type].fields[field] = value;
        },
    },
    actions: {
        // Get
        
    }
};

export default popups;
