import {
  patchCompany,
  getCompany,
  createCompany,
} from '@/calls/fleet-companies';

const fleetCompany = {
  namespaced: true,
  state: {
    id: null,
    data: null,
    form: {
      name: '',
      email: '',
      vatnumber: '',
      phoneNumber: '',
      street: '',
      zipCode: '',
      city: '',
      state: '',
      country: '',
      clientType : "0"
    },
    requests: 0,
    fleets: []
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateData(state, data) {
      state.data = data;
    },
    resetData(state) {
      state.data = null;
    },
    resetForm(state)  {
      state.form = {
        name: '',
        email: '',
        vatnumber: '',
        phoneNumber: '',
        street: '',
        zipCode: '',
        city: '',
        state: '',
        country: '',
        clientType : "0"
      }
    },
    updateDataValue(state, { value, property }) {
      if (property.match(/street|zipCode|city|state/)) {
        state.data.address[property] = value;
        return;
      }

      state.data[property] = value;
    },
    updateFormValue(state, { value, property }) {
      state.form[property] = value;
    },
    updateFleets(state, fleets) {
      state.fleets = fleets;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    setFormFromData({ state, commit }) {
      const properties = [
        'name',
        'email',
        'vatnumber',
        'phoneNumber',
        'street',
        'zipCode',
        'city',
        'state',
        'country',
        'isFlex',
        'clientType'
      ];
      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const address = state?.data?.address;

        switch (true) {
          case /country/.test(property):
            if (address) {
              commit('updateFormValue', {
                property, value: state.data.address.countryCode
              });
            }
            break;
          case /street|zipCode|city|state/.test(property):
            if (address) {
              commit('updateFormValue', {
                property, value: state.data.address[property]
              });
            }
            break;
            case /phoneNumber/.test(property):
              commit('updateFormValue', {
                property, value: state.data.mobile
              });
              break;
            case /clientType/.test(property):
              if (state.data?.clientType) {
                commit('updateFormValue', {
                  property, value: state.data?.clientType.toLowerCase() === 'tms' ? "1" : "0"
                });                
              }
              break;
          default:
            commit('updateFormValue', {
              property, value: state.data[property]
            });
            break;
          }
        }
    },
    // Calls
    async getData({ commit, dispatch, state }) {
      commit('increaseRequests');
      const request = await getCompany(state.id);

      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');
      } else {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting company : ${request.error}`,
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async create({ commit, dispatch, state }) {
      commit('increaseRequests');
      const data = {...state.form};
      const request = await createCompany(data);

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: 'Fleet company created!',
          type: 'success'
        }], { root: true });
      } else {
        if(request?.error?.response?.data) {
          dispatch('notifications/addNotifs', [{
            text: `Error while creating company : ${request.error.response.data}`,
            type: 'error'
          }], { root: true });
        } else {
          dispatch('notifications/addNotifs', [{
            text: `Error while creating company : ${request.error}`,
            type: 'error'
          }], { root: true });
        }

        commit('decreaseRequests');
        return { noRedirect: true }
      }

      commit('decreaseRequests');
      return request;
    },
    async saveData({ commit, state, dispatch }) {
      commit('increaseRequests');

      const data = {...state.form};
      data.flex = data.isFlex ? 1 : 0;
      delete data.isFlex;
      const request = await patchCompany(state.id, data);

      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');

        // dispatch('notifications/addNotifs', [{
        //   text: 'Fleet company updated!',
        //   type: 'success'
        // }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting company : ${request.error}`,
          type: 'error'
        }], { root: true });
        commit('decreaseRequests');
        return false;
      }

      commit('decreaseRequests');
      return true;
    }
  }
};

export default fleetCompany;
