<template>
  <section class="faqs">
    
    <h2 class="faqs-title">{{props.title}}</h2>
    <ul class="questions">
      <Question 
        :ref="'question'+index"
        :key="index" v-for="(question, index) in props.questions"
        :class="{'question--open': isOpen === index}"
        :question="question"
        @click="() => onClick(index)"
      />
    </ul>
  </section>
</template>

<script>
import Question from '@/components/elements/Question.vue';

export default {
  components: {
  //   Sprite
    Question
  },
  props: ['props'],
  data() {
    return {
      isOpen: 0,
      current: null,
      els: []
    }
  },
  mounted() {
    this.els = Object.values(this.$refs);
    this.els.forEach(el => {
      el.$el.height = {
        close: el.$el.querySelector('.question-title').clientHeight ,
        open: el.$el.scrollHeight
      };
      el.$el.style.height = `${el.$el.height.close}px`
    });
    this.current = this.els[0].$el
  },
  methods: {
    onClick(index) {
      this.isOpen = index;
      this.current.style.height = `${this.current.height.close}px`;
      this.els[index].$el.style.height = `${this.els[index].$el.height.open}px`;
      this.current = this.els[index].$el
    }
  }
}
</script>

<style lang="scss">
  .faqs {
    margin: 60px 0;
    &-title {
      font-weight: 400;
      font-size: rem(20px);
      color: $black;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .questions {
    width: 100%;
    max-width: 900px;
    margin: 30px auto 0;

    list-style: none;
    padding-left: 0;

  }
  .question {
    border-top: 1px solid $grey-background;
    overflow: hidden;
    transition: height .3s ease;
    padding-bottom: 24px;
    cursor: pointer;

    &:last-child {
      border-bottom: 1px solid $grey-background;
    }
    &--open {
      .question-title {
        color: $mediumgreen;
        .svg {
          transform: rotate(-180deg);
        }
      }
      .question-content {
        visibility: visible;
        opacity: 1;
      }
    }
    &-title {
      padding: 24px 24px 24px 0;
      font-weight: 400;
      font-size: rem(18px);
      color: $snap-primary;

      width: 100%;
      display: flex;
      justify-content: space-between;

      .svg {
        display: block;
        transform: rotate(0deg);
        transition: transform .3s $ease-out-quad;
      }
    }
    &-content {
      @extend %p;
      color: $snap-primary;
      font-size: rem(14px);

      visibility: hidden;
      opacity: 0;

      transition:opacity .3s ease;
    }
  }
</style>