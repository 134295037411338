<template>
  <thead>
    <tr>
      <th 
        v-for="h in head"
        :key="h.title"
        :class="[h.class, { 'is-hoverable': order && h.type }]" 
        @click="onClick(h)"
      >
        <span
          v-if="order && h.type"
          class="th-wrapper"
        >
          <span class="th-text">{{ h.title }}</span>
          <span :class="['th-order', `${h.type === order.type ? order.dir : ''}`]">
            <Sprite
              :type="'chevron-up'"
              :width="16"
              :height="8"
            />
            <Sprite
              :type="'chevron-down'"
              :width="16"
              :height="8"
            />
          </span>
        </span>
         <template v-else>
          {{ h.title }}
        </template>
      </th>
    </tr>
  </thead>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  name: 'THead',
  components: {
    Sprite
  },
  props:{
    head: {
      type: Array
    },
    order: {
      type: Object,
      default: null
    },
  },
  methods: {
    onClick(e) {
      this.$emit('onOrder', e.type);
    }
  }
}
</script>

<style lang="scss" scoped>
.th {
  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-order {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;

    &.asc {
      .svg-chevron-up {
        fill: $mediumgreen;
      }
    }

    &.desc {
      .svg-chevron-down {
        fill: $mediumgreen;
      }
    }

    .svg {
      fill: $grey;

      &:not(:first-child) {
        margin-top: 3px;
      }
    }
  }
}

</style>