<template>
  <section
    ref="container"
    class="main-container main-container__form main-container__edit"
    :class="{ 'main-container--loading': loading }"
  >
    <form
      v-if="data && form"
      novalidate
      @submit.prevent="onSave"
    >
      <h2 class="h5">{{ $t('parkings.form.title') }}</h2>
      <!-- Informations -->
      <fieldset>
        <div class="container-row container-5-5">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.international_name')"
            :error="errors.internationalName"
            :value="form.internationalName"
            :maxLength="parkingProperties.internationalName.maxLength"
            @input="onInput($event, 'internationalName')"
            :readonly="disableSnapReleaseButtons"
          />

          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.local_name')"
            :error="errors.localName"
            :value="form.localName"
            :maxLength="parkingProperties.localName.maxLength"
            @input="onInput($event, 'localName')"
            :readonly="disableSnapReleaseButtons"
          />
        </div>
        <div class="container-row">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.address')"
            :error="errors.street"
            :required="false"
            :value="form.street"
            :maxLength="parkingProperties.street.maxLength"
            @input="onInput($event, 'street')"
            :readonly="disableSnapReleaseButtons"
          />
        </div>
        <div class="container-row container-5-5">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.zip_code')"
            :error="errors.zipCode"
            :value="form.zipCode"
            :maxLength="parkingProperties.zipCode.maxLength"
            @input="onInput($event, 'zipCode')"
            :readonly="disableSnapReleaseButtons"
          />
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.city')"
            :error="errors.city"
            :value="form.city"
            :maxLength="parkingProperties.city.maxLength"
            @input="onInput($event, 'city')"
            :readonly="disableSnapReleaseButtons"
          />
        </div>
        
        <div class="container-row container-5-5">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.state')"
            :error="errors.state"
            :required="false"
            :value="form.state"
            :maxLength="parkingProperties.state.maxLength"
            @input="onInput($event, 'state')"
            :readonly="disableSnapReleaseButtons"
          />
          <NewSelect
            :name="'country'"
            :label="$t('parkings.form.labels.country')"
            :options="countries"
            :value="form.country"
            :error="errors.country"
            :placeholder="$t('parkings.form.placeholders.country')"
            :class="'classic'"
            @onChange="onChange($event, 'country')"
            :readonly="disableSnapReleaseButtons"
          />
          <!-- <Select
            :name="'country'"
            :label="$t('parkings.form.labels.country')"
            :options="countries"
            :placeholder="country ? country.name : $t('parkings.form.placeholders.country')"
            :error="errors.country"
            :class="'classic'"
            @change="onInput($event, 'country')"
          /> -->
        </div>
        <div class="container-row container-5-5">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.highway')"
            :error="errors.roadName"
            :value="form.roadName"
            :maxLength="parkingProperties.roadName.maxLength"
            @input="onInput($event, 'roadName')"
            :readonly="disableSnapReleaseButtons"
          />

          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.road_direction')"
            :error="errors.roadDirection"
            :value="form.roadDirection"
            :maxLength="parkingProperties.roadDirection.maxLength"
            @input="onInput($event, 'roadDirection')"
            :readonly="disableSnapReleaseButtons"
          />
        </div>

        <div class="container-row container-5-5">
          <Inputs
            type="text"
            :label="$t('parkings.form.labels.contact_phone')"
            :required="false"
            :error="errors.contactPhone"
            :value="form.contactPhone"
            :maxLength="parkingProperties.contactPhone.maxLength"
            @input="onInput($event, 'contactPhone')"
            :readonly="disableSnapReleaseButtons"
          />
        </div>


        <div class="container-row container-5-5">
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.gps_coordinates')"
            :error="errors.latitude"
            :value="form.latitude"
            :maxLength="parkingProperties.latitude.maxLength"
            @input="onInput($event, 'latitude')"
            :readonly="disableSnapReleaseButtons"
          />
          <Inputs
            type="text"
            name="longitude"
            :error="errors.longitude"
            :value="form.longitude"
            :maxLength="parkingProperties.longitude.maxLength"
            @input="onInput($event, 'longitude')"
            :readonly="disableSnapReleaseButtons"
          />
        </div>

        <div class="container-map">
          <label class="label small">{{$t('parkings.form.labels.parking_location')}}</label>
          <div class="map">
            <Map 
              :street="form.street"
              :zipCode="form.zipCode"
              :country="form.country"
              :city="form.city"
              :lat="form.latitude"
              :lng="form.longitude"
              :lang="$i18n.locale"
              @coordinates="onInput($event, 'coordinates')"
            />
          </div>
        </div>
      </fieldset>

      <fieldset class="container-additionnal">
        <h3 class="nickname">{{$t('parkings.form.labels.status.otra_status')}}</h3>
        <div class="container-row">
          <Checkbox
            name="bookable"
            :label="$t('parkings.form.labels.status.bookable')"

            :required="false"
            :value="canBeBookable && form.bookable"
            :detail="bookableMessage"
            @input="onInput($event, 'bookable')"
            :disable="disableSnapReleaseButtons || !canBeBookable"
          />

          <Checkbox
            name="status"
            :label="$t('parkings.form.labels.status.visible')"
            :required="false"
            :value="form.status"
            @input="onInput($event, 'status')"
            :disable="disableSnapReleaseButtons"
          />

          <Checkbox
            ref="payAtParking"
            name="payAtParking"
            :label="$t('parkings.form.labels.status.pay_at')"
            :required="false"
            :value="form.payAtParking"
            @input="onInput($event, 'payAtParking')"
            :disable="disableSnapReleaseButtons"
          />
        </div>
      </fieldset>

      <fieldset class="container-additionnal">
        <h3 class="nickname">{{$t('parkings.form.labels.parkmanager.title')}}</h3>
        <div class="container-row">
          <Checkbox
            :name="'parkManagerType'"
            :label="$t('parkings.form.labels.parkmanager.label')"
            :required="false"
            :value="typeof form.parkManagerType === 'string'"
            @input="onParkmanagerChange"
            :disable="disableSnapReleaseButtons"
          />
            <!-- @input="onParkManagerTypeChange" -->
        </div>
        <div
          v-if="typeof form.parkManagerType === 'string'"
          class="container-row container-5-5"
        >
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.parkmanager.label-input')"
            :error="errors.parkManagerType"
            :value="form.parkManagerType"
            @input="onInput($event, 'parkManagerType')"
            :readonly="disableSnapReleaseButtons"
          />
        </div>
        <div class="container-row">
          <NewSelect
            :name="'parkingFlow'"
            :label="$t('parkings.form.labels.integration.label')"
            :options="parkingFlows"
            :value="form.parkingFlow"
            :error="errors.parkingFlow"
            :class="'classic'"
            @onChange="onChange($event, 'parkingFlow')"
            :readonly="disableSnapReleaseButtons"
          />
        </div>
        <div
          v-if="typeof form.parkingFlow === 'string' && form.parkingFlow === 'StandardApi'"
          class="container-row container-5-5"
        >
          <Inputs
            type="text" 
            :label="$t('parkings.form.labels.integration.stardard_api_token_label_input')"
            :error="errors.standardApiAuthToken"
            :value="form.standardApiAuthToken"
            @input="onInput($event, 'standardApiAuthToken')"
            :readonly="disableSnapReleaseButtons"
          />
        </div>
        <div
          v-if="typeof form.parkingFlow === 'string' && form.parkingFlow === 'Triton'"
          class="upload-panel"
        >
          <div class="container-row container-5-5">
            <FileUploads
              :name="'ca_certs_file'"
              :label="$t('parkings.form.labels.certificates.ca_certs')"
              :error="errors.ca_certs_file"
              :filename="uploadedFilename('ca_certs_file')"
              @onReset="onResetFileUpload('ca_certs_file')"
              @input="onFileInput($event, 'ca_certs_file')"
            />
            <FileUploads
              :name="'certs_file'"
              :label="$t('parkings.form.labels.certificates.certs')"
              :error="errors.certs_file"
              :filename="uploadedFilename('certs_file')"
              @onReset="onResetFileUpload('certs_file')"
              @input="onFileInput($event, 'certs_file')"
            />
          </div>
          <div class="container-row container-5-5">
            <FileUploads
              :name="'private_key_file'"
              :label="$t('parkings.form.labels.certificates.private_key')"
              :error="errors.private_key_file"
              :filename="uploadedFilename('private_key_file')"
              @onReset="onResetFileUpload('private_key_file')"
              @input="onFileInput($event, 'private_key_file')"
            />
            <FileUploads
              :name="'password_file'"
              :label="$t('parkings.form.labels.certificates.password')"
              :error="errors.password_file"
              :filename="uploadedFilename('password_file')"
              @onReset="onResetFileUpload('password_file')"
              @input="onFileInput($event, 'password_file')"
            />
          </div>
        </div>
      </fieldset>

       <fieldset class="container-additionnal">
        <h3 class="nickname">{{$t('parkings.form.labels.permissions.title')}}</h3>
        <div class="container-row">
          <Checkbox
            :label="$t('parkings.form.labels.permissions.whitelist')"
            :required="false"
            :value="form.whitelistable"
            :detail="whitelistMessage"
            name="whitelist"
            @input="onInput($event, 'whitelistable')"
            :disable="(typeof form.parkManagerType !== 'string' || form.parkManagerType.length === 0) || disableSnapReleaseButtons"
          />

          <Checkbox
            :label="$t('parkings.form.labels.permissions.subscriptions')"
            :required="false"
            :value="form.subscribable"
            name="subscriptions"
            @input="onInput($event, 'subscribable')"
            :disable="disableSnapReleaseButtons"
          />
        </div>
      </fieldset>

      <fieldset class="container-additionnal">
        <h3 class="nickname"> {{$t('parkings.form.labels.permissions.limitAvailablehour')}}</h3>
        <div class="container-row">
          <Checkbox
            :name="'isLimitHour'"
            :label="$t('parkings.form.labels.permissions.limithour')"
            :required="false"
            :value="form.isLimitHour"
            @input="onLimitHourChange"
            :disable="disableSnapReleaseButtons"
          />

        
        </div>
        <div
          v-if="form.isLimitHour"
          class="container-row"
        >
          <TimeHours           
            :startTime="form.startAtString" 
            :endTime="form.endAtString"    
            :error="errors.timehours"
            @onChange="onTimeHourChange"          
            />
        </div>

      </fieldset>

      <fieldset
        v-if="ruleset"
        class="container-additionnal"
      >
        <h3 class="nickname">{{$t('parkings.form.labels.ruleset')}}</h3>
        <div class="container-row">
          <span class="label small text-value">{{ ruleset.label }}</span>
        </div>
      </fieldset>

      <!-- Medias -->
      <h2 class="h5">{{$t('parkings.form.pictures.title')}}</h2>
      <pictures />

      <!-- Commodities -->
      <h2 class="h5">{{ $t('parkings.form.details_features.title') }}</h2>
      <features />

      <button
        class="button"
        type="submit"
        :disabled="disableSnapReleaseButtons"
      >
        {{$t('parkings.form.buttons.save.edit')}}
      </button>

      <div 
        v-if="hasError"
        class="form--error small"
      >
        {{$t('notifications.errors.several_required')}}
      </div>
    </form>

    <Popup v-if="confirmation">
      <template v-slot:content>
        <template v-if="confirmationPropertyType === 'parkingFlow'">
          <h2 class="popup-title">{{ $t('parkings.modal.confirmation_parkingflow.title') }}</h2>
          <p class="popup-main-text">{{ $t('parkings.modal.confirmation_parkingflow.text') }}</p>
          <div class="popup-actions">
            <button
              type="button"
              class="button button-popup button-border"
              @click.prevent="onClose"
            >
              {{ $t('globals.close') }}
            </button>
            <button
              type="button"
              class="button button-popup"
              @click.prevent="onParkingFlowConfirmation"
            >
              <!-- :disabled="disableAdd" -->
              {{ $t('globals.confirm') }}
            </button>
          </div>
        </template>
        <template v-else-if="confirmationPropertyType === 'parkingFlowNonStandard'">
          <h2 class="popup-title">{{ $t('parkings.modal.confirmation_parkingflow_non_standard.title') }}</h2>
          <div class="popup-main">
            <p class="popup-main-text">{{ $t('parkings.modal.confirmation_parkingflow_non_standard.text') }}</p>
            <button
              type="button"
              class="button button-big"
              @click.prevent="onClose"
            >
              {{ $t('globals.close') }}
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="popup-title">{{ $t('parkings.modal.confirmation.title') }}</h2>
          <p class="popup-main-text">{{ $t('parkings.modal.confirmation.text') }}</p>
          <div class="popup-actions">
            <button
              type="button"
              class="button button-popup button-border"
              @click.prevent="onClose"
            >
              {{ $t('globals.close') }}
            </button>
            <button
              type="submit"
              class="button button-popup"
              @click.prevent="onSave"
            >
              <!-- :disabled="disableAdd" -->
              {{ $t('globals.save') }}
            </button>
          </div>
        </template>
      </template>
    </Popup>

    <div
      v-if="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import Map from '@/components/modules/Map';
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
import FileUploads from '@/components/elements/FileUploads';
import NewSelect from '@/components/elements/NewSelect';
import Checkbox from '@/components/elements/Checkbox';
import Pictures from '@/components/partials/Pictures';
import Features from '@/components/partials/Features';
import Popup from '@/components/partials/Popup';

import Properties from '@/data/properties.json';
import TimeHours from '@/components/modules/TimeHours';

const REGEX_URL = /(?<fullname>(api\x2E)(parking\x2E)?(?<groupname>[a-zA-Z1-9\x2D]+)\x2E.*?)[\x3A\x2F]/;

const CERTIFICATE_TYPE = {
  ca_certs_file: 0,
  certs_file: 1,
  private_key_file: 2,
  password_file: 3
};

export default {
  name: 'Edit',
  components: {
    Loader,
    Inputs,
    Map,
    NewSelect,
    Checkbox,
    Pictures,
    Features,
    Popup,
    TimeHours,
    FileUploads
},
  beforeMount() {
    const { id } = this.$route.params;
    this.$store.commit('parking/updateId', id);

    this.$store.dispatch('parking/getData');
    this.$store.dispatch('parking/getMedias');
    this.$store.dispatch('parking/getCommodities');
    this.$store.dispatch('parking/getRulesets');
    this.$store.dispatch('parking/getCertificates');
  },
  data() {
    return {
      hasError: false,
      errors: {},
      parkingProperties: Properties.parkings,
      confirmation: false,
      confirmationPropertyType: '',
      selectedParkingFlow: ''
    };
  },
  computed: {
    form() {
      return this.$store.state.parking.form;
    },
    data() {
      return this.$store.state.parking.data;
    },
    country() {
      if (!this.$store.state.parking.data) return null;
      return this.$store.state.parking.data.address.country;
    },
    countries() {
      const countries = this.$store.state.settings.minimalCountries;
      countries.sort((a, b) => {
        if(a.label < b.label) return -1;
        if(a.label > b.label) return 1;
        return 0;
      });
      return countries;
    },
    parkingFlows() {
      return this.$store.state.parking.parkingFlows;
    },
    ruleset() {
      const { rulesetId } = this.$store.state.parking.form;
      const { rulesets } = this.$store.state.parking;
      if (!rulesets.length) return null;
      const rset = rulesets.filter(r => r.value === rulesetId);
      return rset[0] ? rset[0] : null;
    },
    loading() {
      return this.$store.state.parking.requests !== 0;
    },
    canBeBookable() {
      return this.$store.state.parking.data && this.$store.state.parking.data.hasOtraSlots;
    },
    bookableMessage() {
      if (!this.$store.state.parking.data) return null;
      if (!this.$store.state.parking.data.hasOtraSlots)  return 'This parking can not be bookable, it has no slot available';
      return null;
    },
    whitelistMessage() {
      if (!this.$store.state.parking.data) return null;
      if (typeof this.form.parkManagerType !== 'string' || this.form.parkManagerType.length === 0) {
        return this.$t('parkings.form.labels.permissions.whitelist_message');
      }

      return null;
    },
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    },
  },
  methods: {
    validate() {
      this.hasError = false;
      this.errors = {};

      const properties = [
        'internationalName',
        'localName',
        'street',
        'city',
        'country',
        'roadName',
        'roadDirection',
        'latitude',
        'longitude',
        'timehours',
        'standardApiAuthToken',
        'parkingFlow',
        'parkManagerType',
        'ca_certs_file',
        'certs_file',
        'private_key_file',
        'password_file'
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const data = this.form[property];
        
        switch (property) {
          case 'longitude':
          case 'latitude':
            
            if(!data || !data.toString().length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');
            } else if(data) {

              const dataStringify = data.toString()
              const hasDot =  dataStringify.match(/\./g)
              const hasDash =  dataStringify.match(/\-/g)
              
              const isNotValidFormat = hasDot && hasDot.length > 1
                                      || hasDash && hasDash.length > 1
                                      || dataStringify.indexOf('-') > 0
                                      || dataStringify.indexOf(',') != -1

              const isBetweenLat = data >= -90 && data <= 90
              const isBetweenLng = data >= -180 && data <= 180

              if(isNotValidFormat) {
                this.hasError = true;
                this.errors[property] = this.$t('notifications.errors.coord_format');
              }

              if(property === "latitude" &&  !isBetweenLat && !isNotValidFormat ) {
                this.hasError = true;
                // this.errors[property] = 'Please enter a latitude between -90 and 90';
                this.errors[property] = this.$t('notifications.errors.lat_value');
              }
              if(property === "longitude" &&  !isBetweenLng && !isNotValidFormat) {
                this.hasError = true;
                // this.errors[property] = 'Please enter a longitude between -180 and 180';
                this.errors[property] = this.$t('notifications.errors.long_value');
              }
            }
            break;
          case 'timehours':
           
            const startHour = parseInt(this.form['startAtString'].replace(':', ''));
            const endHour = parseInt(this.form['endAtString'].replace(':', ''));
            if (startHour > endHour)
            {
              console.log('timehour error')
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.limithour.starthourbigger');
            }           

            break;
          case 'parkManagerType':
            if (this.form['parkingFlow'] === 'Triton') {
              var matched = REGEX_URL.exec(data);
              
              if (!matched) {
                this.hasError = true;
                this.errors[property] = this.$t('notifications.errors.parking.integration_api.invalid_parkmanager_id');
              }
            } 

            break;
          case 'standardApiAuthToken':
            if (this.form['parkingFlow'] === 'StandardApi') {
              if (!data || !data.length) {
                this.hasError = true;
                this.errors[property] = this.$t('notifications.errors.required');
              }
            }
            break;
          case 'parkingFlow':
            const onNonStandard = data !== 'Standard'

            if (onNonStandard) {
              if (!this.form['parkManagerType'] || !this.form['parkManagerType'].length) {
                this.hasError = true;
                this.errors['parkManagerType'] = this.$t('notifications.errors.required');
                
                this.confirmation = true;
                this.confirmationPropertyType = 'parkingFlowNonStandard';
              }
            }
            break;
          case 'ca_certs_file':
          case 'certs_file':
          case 'private_key_file':
          case 'password_file':
            if (this.form['parkingFlow'] === 'Triton') {
              const parkManagerType = this.form['parkManagerType'];
              
              const matched = parkManagerType.match(REGEX_URL);
              
              if (!matched) {
                this.hasError = true;
                this.errors['parkManagerType'] = this.$t('notifications.errors.parking.integration_api.invalid_parkmanager_id');

                break;
              } else {
                const { fullname, groupname } = matched.groups;
                const index = CERTIFICATE_TYPE[property];
                const file = this.form['certificateFiles'][index];
                
                if (!file) {
                  this.hasError = true;
                  this.errors[property] = this.$t('notifications.errors.required');

                  break;
                }

                let valid = true;
                let validFormat = '';
                const parts = fullname.split(".");

                switch(property) {
                  case 'ca_certs_file':
                    validFormat = `${groupname}.${parts[parts.length - 1]}-cacert.pem`;
                    break;
                  case 'certs_file':
                    validFormat = `cli.otra.${fullname}-cert.pem`;
                    break;
                  case 'private_key_file':
                    validFormat = `cli.otra.${fullname}-key.pem`;
                    break;
                  default:
                    validFormat = `${groupname}.pass.txt`;
                    break;
                }

                valid = file.filename === validFormat;

                if (!valid) {
                  this.hasError = true;
                  this.errors[property] = this.$t('notifications.errors.parking.integration_api.invalid_certs_filename', {format: validFormat});
                }
              }
            }
            break;
          default:
            if (!data || !data.length) {
              this.hasError = true;
              // this.errors[property] = 'This field is required';
              this.errors[property] = this.$t('notifications.errors.required');
            }
            break;
        }
        
      }
    },
    uploadedFilename(property) {
      const index = CERTIFICATE_TYPE[property];
      return this.form.certificateFiles[index]?.filename;
    },
    // Events
    onInput($event, property) {
      const { target } = $event;
      if (!target && property !== 'coordinates' && property !== 'securityLevelId') return;

      switch (property) {
        case "coordinates":
          this.$store.commit('parking/updateFormValue', { value: $event.lng, property: 'longitude' });
          this.$store.commit('parking/updateFormValue', { value: $event.lat, property: 'latitude' });
          break;
        case "longitude":
        case "latitude":
          const val = target.value 
              .replace(/\,/g, '.') // replace all ',' by '.'
              .replace(/[^\d\.-]|^[\.]*$/g, '') // only allow digits "." and "-"
              
          this.$store.commit('parking/updateFormValue', { value: val, property });
          target.value = val
          break;
        case "bookable":
        case "status":
        case "payAtParking":
        case "subscribable":
        case "whitelistable":
          const checked = target.checked;
          this.$store.commit('parking/updateFormValue', { value: checked, property });
          break;
        case "securityLevelId":
          this.$store.commit('parking/updateFormValue', { value: $event, property });
          break;
        case "parkManagerType":
          if (!target.value.length) { 
            this.$store.commit('parking/updateFormValue', { value: false, property: "whitelistable" });
          }

          this.$store.commit('parking/updateFormValue', { value: target.value, property });
          break;
        case "standardApiAuthToken":
          this.$store.commit('parking/updateFormValue', { value: target.value, property });
          break;
        default:
          const { value } = target;
          this.$store.commit('parking/updateFormValue', { value, property });
          break;
      }
    },
    onChange($event, property) {
      switch (property) {
        case "parkingFlow":
          if ($event !== this.form['parkingFlow']) {
            this.confirmation = true;
            this.confirmationPropertyType = property;
            this.selectedParkingFlow = $event;
            return;
          }
          break;
        default:
          this.$store.commit('parking/updateFormValue', { value: $event, property });
          break;
      }
    },
    onTimeHourChange({value, type}) {      
      let property = 'startAtString'     
      if (type === 'end') {
        property = 'endAtString'
      }
      this.$store.commit('parking/updateFormValue', { value, property });
    },
    onParkmanagerChange({ name, value }) {
      if (!name) return;

      if (value) {
        const { parkManagerType } = this.data;
        
        
        this.$store.commit('parking/updateFormValue', {
          value: parkManagerType ? parkManagerType : '',
          property: 'parkManagerType'
        });
        return;
      }

      this.$store.commit('parking/updateFormValue', { value: null, property: 'parkManagerType' });
      this.$store.commit('parking/updateFormValue', { value: false, property: 'whitelistable' });
    },
    onLimitHourChange({ name, value }) {
      if (!name) return;

      this.$store.commit('parking/updateFormValue', { value: value, property: 'isLimitHour' });
      
    },
    onFileInput($event, name) {
      const reader = new FileReader();
      const index = CERTIFICATE_TYPE[name];
      let file = $event.target.files[0];

      reader.onload = () => {
        const fileBase64 = reader.result.split(',')[1];

        this.form['certificateFiles'][index] = {
          fileType: index,
          contentType: file.type,
          filename: file.name,
          contentBase64: fileBase64
        };
      };

      reader.readAsDataURL(file);      
    },
    onResetFileUpload(property) {
      const index = CERTIFICATE_TYPE[property];
      
      if (this.form['certificateFiles'][index]) {
        this.form['certificateFiles'][index] = null;
      }
    },
    onClose() {
      this.confirmation = false;
      this.confirmationPropertyType = '';
      this.selectedParkingFlow = '';
    },
    onParkingFlowConfirmation() {
      this.$store.commit('parking/updateFormValue', { value: this.selectedParkingFlow, property: 'parkingFlow' });

      if (this.selectedParkingFlow !== 'Triton') {
        this.$store.commit('parking/updateFormValue', { value: [], property: 'certificates' });
      }

      if (this.selectedParkingFlow === 'StandardApi') {
        const { standardApiAuthToken } = this.data;
        
        this.$store.commit('parking/updateFormValue', {
          value: standardApiAuthToken ? standardApiAuthToken : '',
          property: 'standardApiAuthToken'
        });
      } else {

        this.$store.commit('parking/updateFormValue', { value: null, property: 'standardApiAuthToken' });
      }
      
      this.confirmation = false;  
      this.confirmationPropertyType = '';

      if (this.selectedParkingFlow !== 'Standard' && (!this.form['parkManagerType'] || !this.form['parkManagerType'].length)) {
        this.confirmation = true;
        this.confirmationPropertyType = 'parkingFlowNonStandard';
        this.selectedParkingFlow = '';

        return;
      }
    },
    onSave() {     
      this.validate();

      if (this.hasError) return;
           
      const { parkManagerType } = this.form;

      if (this.data.parkManagerType && parkManagerType !== this.data.parkManagerType && !this.confirmation) {
        this.confirmation = true;
        this.confirmationPropertyType = 'parkManagerType';
        return;
      }

      this.confirmation = false;
      this.confirmationPropertyType = '';
      this.$store.dispatch('parking/saveParking');
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    padding-bottom: 100px;
  }

  .container-row {
    &:first-child {
      margin-top: 0;
    }
  }

  .container-map {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .small.form--error {
    margin-top: 20px;
    color: $error;
  }

  .container-additionnal {
    margin-top: 32px;
    margin-bottom: 24px;

    .container-row:not(.container-5-5) {
      justify-content: flex-start;

       .input {
        width: 220px;

        &:not(:first-child) {
          margin-left: 24px;
        }
      }
    }


    /* .text-value {
      color: $snap-primary;
    } */
  }

  .h5 {
    margin-bottom: 20px;

    &:not(:first-child) {
      margin-top: 60px;
    }
  }

  .button {
    margin-top: 60px;
  }

  .upload-panel {
    margin-top: 25px;
  }

</style>
