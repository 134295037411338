<template>
  <button 
    ref="submit"
    class="button"
    type="submit"
    @click.prevent
    :class="{'loading': isLoading}"
  >
    <span class="submit-label" :class="{'hide':isLoading}">
      {{label}}
    </span>

    <div class="loader" :class="{'hide': !isLoading}">
      <span>●</span>
      <span>●</span>
      <span>●</span>
    </div>

  </button>
</template>

<script>
export default {
  name:"ButtonSave",
  props: {
    loading: {
      type: [String, Boolean],
    },
    labels: {
      type: [Array, Object],
    },
    hasError: {
      type: String
    }
  },
  data() {
    return {
      label: this.labels.default,
      isLoading: false
    }
  },
  watch: {
    loading(value) {
      if(value === true) {
        this.isLoading = true;
      }
      else if(value === false) {
        this.isLoading = false;
      }
      else if (value === "finish"){
        this.label = this.labels.end
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .submit-label {
    transition: opacity .3s $ease-out-quad;
  }
  .hide {
    opacity: 0;
  }
  .button {
    position: relative;
    
    display: flex;
    justify-content: center;
    transition: opacity .3s $ease-out-quad;
    &.loading {
      opacity: .8;
    }

  }
  .button:not(.button-primary) {
    margin-top: 20px;

  }
  .loader {
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-style: normal;
    overflow: hidden;
    transition: opacity .3s .2s $ease-out-quad;
    span {
      font-size: rem(20px);
      opacity: 0;
      display:inline-block;
    }

    @keyframes lol {
      0% {
        opacity: 0;
        transform: translateX(-300px);
      }
      33%, 66% {
        opacity: 1;
        transform: translateX(0px);
      }
      100% {
        opacity: 0;
        transform: translateX(300px);
      }
    }
    span:nth-child(3) {
      animation: lol 3s infinite $ease-in-out-quad;
    }
    span:nth-child(2) {
      animation: lol 3s .1s infinite $ease-in-out-quad;
    }
    span:nth-child(1) {
      animation: lol 3s .2s infinite $ease-in-out-quad;
    }
  }
</style>