import {
  patchPlanner,
  getPlanner,
  createPlanner,
  resendActivationLink
} from '@/calls/fleet-planners';
import i18n from '../i18n';

import { list } from '@/calls/fleet-companies';
import { list as listPlanners } from '@/calls/fleet-planners';
import Constants from '../helpers/constants'

const fleetOwner = {
  namespaced: true,
  state: {
    id: null,
    data: null,
    form: {
      firstname: '',
      lastname: '',
      email: '',
      phoneNumber: '',
      fleetCompanyId: null
    },
    requests: 0,
    fleets: [],
    existingEmails: [],
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateData(state, data) {
      state.data = data;
    },
    resetForm(state) {
      state.form = {
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: ''
      }
    },
    updateDataValue(state, { value, property }) {
      if (property.match(/street|zipCode|city|state/)) {
        state.data.address[property] = value;
        return;
      }

      state.data[property] = value;
    },
    updateFormValue(state, { value, property }) {
      state.form[property] = value;
    },
    updateExistingEmails(state, emails) {
      state.existingEmails = emails;
    },
    updateFleets(state, fleets) {
      state.fleets = fleets;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    setFormFromData({ state, commit }) {
      const properties = [
        'firstname',
        'lastname',
        'email',
        'phoneNumber',
        'fleetCompanyId',
        'locale'
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];

        switch (true) {
          case /fleetCompanyId/.test(property):
            commit('updateFormValue', {
              property, value: state.data.fleetId
            });
            break;
          default:
            commit('updateFormValue', {
              property, value: state.data.user[property]
            });
            break;
          
          }
        }
    },
    // Calls
    async getData({ commit, dispatch, state }) {
      commit('increaseRequests');
      const request = await getPlanner(state.id);

      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');
      } else {
        dispatch('notifications/addNotifs', [{
          // text: `Error while getting member : ${request.error}`,
          text: tc('notifications.errors.fleet-owners.getting', 1, { error: request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async create({ commit, dispatch, state }) {      
      commit('increaseRequests');
      const { t } = i18n.global;
      const data = {...state.form};
      const index = state.existingEmails.indexOf(data.email);
      if (index >= 0) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.fleet-members.exists'),
          type: 'error'
        }], { root: true });

        commit('decreaseRequests');
        return { noRedirect: true }
      }

      data.locale = data.locale && data.locale.length ? data.locale : 'en';
      delete data.phoneNumber;

      data.createdFromApp = Constants.SOURCE_APP; 

      const request = await createPlanner(data);

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          // text: 'Fleet member created!',
          text: t('notifications.success.fleet-members.creation'),
          type: 'success'
        }], { root: true });
      } else {
        if(request?.error?.response?.data) {
          dispatch('notifications/addNotifs', [{
            // text: `Error while creating member : ${request.error.response.data}`,
            text: t('notifications.errors.fleet-members.creation', { error: request?.error?.response?.data }),
            type: 'error'
          }], { root: true });
        } else {
          dispatch('notifications/addNotifs', [{
            // text: `Error while creating member : ${request.error}`,
            text: t('notifications.errors.fleet-members.creation', { error: request.error }),
            type: 'error'
          }], { root: true });
        }

        commit('decreaseRequests');
        return { noRedirect: true }
      }

      commit('decreaseRequests');
      return request;
    },
    async saveData({ commit, state, dispatch }) {
      commit('increaseRequests');

      const data = {...state.form};
      if(data.fleetCompanyId == null) data.fleetCompanyId = 0;
      
      const request = await patchPlanner(state.id, data);
      const { t } = i18n.global;

      if(!request.hasError) {
        commit('updateData', request);
        dispatch('setFormFromData');

        dispatch('notifications/addNotifs', [{
          // text: 'Fleet member updated!',
          text: t('notifications.success.fleet-members.update'),
          type: 'success'
        }], { root: true });
      } else {       
        dispatch('notifications/addNotifs', [{         
          text: t('notifications.errors.fleet-members.update', { error: request?.error?.response?.data?.detail ?? request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
      return request;
    },
    async getFleets({ commit, state, dispatch }) {
      commit('increaseRequests');
      const data = {...state.form};
      const request = await list(state.id, data);

      if(!request.hasError) {
        commit('updateFleets', request);
      }

      commit('decreaseRequests');
    },
    async getEmails({ commit, state }) {
      commit('increaseRequests');
      const planners = await listPlanners();

      if (!planners.hasError) {       
        const emails = planners.filter(p => p.user && p.user.email).map(p => p.user.email);
        commit('updateExistingEmails', emails);        
      }
      // else {
      //   dispatch('notifications/addNotifs', [{
      //     text: `Error while getting planners : ${planners.error}`,
      //     type: 'error'
      //   }], { root: true });
      // }

      commit('decreaseRequests');
    },
    async resendActivationLink({ commit, state, dispatch }, id) {
       const request = await resendActivationLink(id);
       const { t } = i18n.global;

      if(!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.resend_activation_link'),
          type: 'success'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.resend_activation_link', { error: request.error }),
          type: 'error'
        }], { root: true });
      }
      return request;
    }
  }
};

export default fleetOwner;
