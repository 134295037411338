<template>
  <section
    ref="container"
    class="main-container main-container__form"
    :class="{ 'main-container--loading': loading }"
  >
    <form
      v-if="data && form"
      novalidate
      @submit.prevent="onSave"
    >
      <h2 class="h4">{{$t('company.form.title')}}</h2>
      <fieldset class="container-row container-5-5">
        <Inputs
          :type="'text'"
          :label="$t('company.form.labels.company_name')"
          :error="errors.name"
          :name="'name'"
          :value="form.name"
          @input="onInput($event, 'name')"
          :readonly="disableSnapReleaseButtons"
        />
        <Inputs
          :type="'text'"
          :label="$t('company.form.labels.vat')"
          :error="errors.vatnumber"
          :name="'vatnumber'"
          :value="form.vatnumber"
          @input="onInput($event, 'vatnumber')"
          :readonly="disableSnapReleaseButtons"
        />
      </fieldset>
      <fieldset class="container-row ">
        <Inputs
          :type="'text'"
          :label="$t('company.form.labels.street')"
          :error="errors.street"
          :name="'street'"
          :value="form.street"
          @input="onInput($event, 'street')"
          :readonly="disableSnapReleaseButtons"
        />
      </fieldset>
      <fieldset class="container-row container-5-5">
         <Inputs
          :type="'text'" 
          :label="$t('company.form.labels.state')"
          :error="errors.state"
          :required="false"
          :name="'state'"
          :value="form.state"
          @input="onInput($event, 'state')"
          :readonly="disableSnapReleaseButtons"
        />
        <Inputs
          :type="'text'" 
          :label="$t('company.form.labels.city')"
          :error="errors.city"
          :name="'city'"
          :value="form.city"
          @input="onInput($event, 'city')"
          :readonly="disableSnapReleaseButtons"
        />
      </fieldset>
      <fieldset class="container-row container-5-5">
        <Inputs
          type="text" 
          :label="$t('company.form.labels.zip')"
          :error="errors.zipCode"
          :name="'zipCode'"
          :value="form.zipCode"
          @input="onInput($event, 'zipCode')"
          :readonly="disableSnapReleaseButtons"
        />
        <!-- <Select
          :name="'country'"
          :label="$t('company.form.labels.country')"
          :options="countries"
          :placeholder="form.country ? form.country : $t('company.form.labels.country_select')"
          :error="errors.country"
          :class="'classic'"
          @change="handleChange"
        /> -->
        <NewSelect
          :name="'country'"
          :label="$t('company.form.labels.country')"
          :options="countries"
          :value="form.country"
          :error="errors.country"
          :placeholder="$t('company.form.labels.country_select')"
          :class="'classic'"
          @onChange="onChange($event, 'country')"
          :readonly="disableSnapReleaseButtons"
        />
      </fieldset>
      <fieldset class="container-row container-5-5">
        <Inputs
          :type="'email'"
          :label="$t('company.form.labels.email')"
          :error="errors.email"
          :name="'email'"
          :value="form.email"
          @input="onInput($event, 'email')"
          :readonly="disableSnapReleaseButtons"
        />
        <Inputs
          :type="'mobile'" 
          :label="$t('company.form.labels.phone')"
          :error="errors.mobile"
          :name="'mobile'"
          :value="form.mobile"
          @input="onInput($event, 'mobile')"
          :readonly="disableSnapReleaseButtons"
        />
      </fieldset>
      <button
        class="button"
        type="submit"
        :disabled="disableSnapReleaseButtons"
      >
        {{$t('company.form.buttons.save')}}
      </button>

      <div 
        v-if="hasError"
        class="form--error small"
      >
        {{$t('notifications.errors.several_required')}}
      </div>
    </form>

    <div
      v-if="loading"
      class="main-container-loader"
    >
      <Loader />
    </div>
  </section>
</template>

<script>
import {isValidPhoneNumber, parsePhoneNumber, ParseError} from 'libphonenumber-js'

import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
import NewSelect from '@/components/elements/NewSelect';

export default {
  name: "newCompany",
  components: {
    Loader,
    Inputs,
    NewSelect
  },
  beforeMount() {
    const { id } = this.$route.params;
    this.$store.commit('company/updateId', id);
    this.$store.dispatch('company/getData');
  },
  computed: {
    form() {
      return this.$store.state.company.form;
    },
    data() {
      return this.$store.state.company.data;
    },
    country() {
      if (!this.$store.state.company.data) return null;
      return this.$store.state.company.data.address.country;
    },
    countries() {
      const countries = this.$store.state.settings.minimalCountries;
      countries.sort((a, b) => {
        if(a.label < b.label) return -1;
        if(a.label > b.label) return 1;
        return 0;
      });
      return countries;
    },
    loading() {
      return this.$store.state.company.requests !== 0;
    },
    disableSnapReleaseButtons(){
      return this.$store.state.settings.disableSnapReleaseButtons;
    }
  },
  watch: {
     loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    },
  },
  data() {
    return {
      requests: 0,
      hasError: false,
      errors: {}
    };
  },
  methods: {
    validate() {
      this.hasError = false;
      this.errors = {};

      const properties = [
        'name',
        'vatnumber',
        'street',
        'city',
        'zipCode',
        'country',
        'mobile',
        'email'
      ];

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const data = this.form[property];
        
        switch (true) {
          case /email/.test(property):
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');
              break;
            }

            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(data)) this.errors[property] = this.$t('notifications.errors.email_invalid');
            break;
          case /mobile/.test(property):
            try {
              parsePhoneNumber(data);
            } catch(error) {
              if (error instanceof ParseError) {
                this.errors.mobile = this.$t('notifications.errors.phone_invalid')
              }
            }

            if(!this.errors.mobile) this.errors.mobile = !isValidPhoneNumber(data) ? this.$t('notifications.errors.phone_invalid') : '';
            if(this.errors.mobile) this.hasError = true;
            break;
          case /vatnumber/.test(property):
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');
              break;
            }

            if(!data.match(/^[A-z0-9]+$/)) {
              this.errors.vatnumber = this.$t('notifications.errors.vat_invalid');
              this.hasError = true;
            }
    
            break;
          default:
            if (!data || !data.length) {
              this.hasError = true;
              this.errors[property] = this.$t('notifications.errors.required');
            }
            break;
        }
      }
    },
    // Handle
    onInput($event, property) {
      const { target } = $event;
      if (!target) return;
      const { value } = target;

      this.$store.commit('company/updateFormValue', { value, property });
    },
    onChange($event, property) {
      this.$store.commit('company/updateFormValue', { value: $event, property });
    },

    // On
    async onSave() {
      this.validate();
      if (this.hasError) return;
      const request = await this.$store.dispatch('company/saveData');
    },

    // Check
    // checkPhone(mobile) {
    //   this.errors.mobile = !mobile.trim() ? this.$t('notifications.errors.email_required') : '';

    //   try {
    //     parsePhoneNumber(mobile);
    //   } catch(error) {
    //     if (error instanceof ParseError) {
    //       this.errors.mobile = this.$t('notifications.errors.phone_invalid')
    //     }
    //   }
    //   if(!this.errors.mobile) this.errors.mobile = !isValidPhoneNumber(mobile) ? this.$t('notifications.errors.phone_invalid') : '';
    //   if(this.errors.mobile) this.hasError = true;
    // },
    // checkVat(vat) {
    //   if(!vat.match(/^[A-z0-9]+$/)) this.errors.vatnumber = this.$t('notifications.errors.vat_invalid');
      
    //   if(this.errors.vatnumber) {
    //     this.hasError = true;
    //     return false;
    //   }
    //   else return true;
    // }
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  padding-bottom: 50px;
}
.button {
  margin-top: 20px;
}
</style>